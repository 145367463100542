// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import * as yup from 'yup'

import { compact, isEmpty, isString } from 'lodash'

import { FAILED_STATUS_OPTIONS } from 'globals/constants/selectOptions'
import { VALUE_REGEX } from 'globals/constants/valueStore'

import { formatValue, sentenceValue } from 'hooks/useNode'

import { INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'
import { ITestStepStatus } from 'data/tests'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.check_sftp_fileExist,
	isLive: true,
	isMenuOption: true,
	isPro: false,
	isAccessibility: false,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.variantType',
			type: 'variantType',
			label: 'Command',
			options: [
				INodeVariantTypes.check_sftp_fileExist,
				INodeVariantTypes.check_sftp_fileNotExist,
				INodeVariantTypes.check_sftp_fileCount,
			],
		},
		{
			property: '',
			type: 'divider',
			label: 'Connection',
		},
		{
			property: 'attributes.value6',
			type: 'text-with-tags',
			defaultValue: 'sftp://',
			label: 'URL',
			validation: yup.string().trim().required('URL is required'),
			grid: 9,
		},
		{
			property: 'attributes.value7',
			type: 'dropdown',
			label: 'Port',
			defaultValue: '22',
			options: [{ label: '22', value: '22' }],
			grid: 3,
		},
		{
			property: 'attributes.value8',
			type: 'text-with-tags',
			label: 'User',
			grid: 6,
		},
		{
			property: 'attributes.value9',
			type: 'text-with-tags',
			label: 'Password',
			grid: 6,
		},
		{
			property: '',
			type: 'divider',
			label: 'File',
		},
		{
			property: 'attributes.value2',
			type: 'text-with-tags',
			label: 'File Location',
			defaultValue: '/',
			validation: yup.string().test('is-valid-path', 'Must start with "/"', (value: any) => {
				if (isString(value) && !isEmpty(value)) {
					return value.match(VALUE_REGEX) ? true : /^\//gim.test(value)
				}
				return false
			}),
		},
		{
			property: 'attributes.config.failedStatus',
			type: 'button-group',
			label: 'Status on fail',
			defaultValue: ITestStepStatus.failed,
			options: FAILED_STATUS_OPTIONS,
		},
	],
	label: 'Check SFTP file exists',
	sentence: ({ isExample, value2 }: INodeRenderProps) => {
		return isExample ? `Check SFTP file exists` : `Check SFTP ${sentenceValue(value2)} exists`
	},
	family: INodeVariantTypes.check_sftp_fileExist.split('_')[0],
	type: INodeVariantTypes.check_sftp_fileExist.split('_')[1],
	subType: INodeVariantTypes.check_sftp_fileExist.split('_')[2],
	tags: compact([
		INodeVariantTypes.check_sftp_fileExist.split('_')[0],
		INodeVariantTypes.check_sftp_fileExist.split('_')[1],
		INodeVariantTypes.check_sftp_fileExist.split('_')[2],
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ isExample, value2 }: INodeRenderProps) => {
			return (
				<>
					{isExample ? (
						<>Check SFTP file</>
					) : (
						<>
							Check SFTP <strong>{formatValue(value2)}</strong> exists
						</>
					)}
				</>
			)
		},
	}
}
