import * as yup from 'yup'

export const validationSchema = yup.object({
	attributes: yup.object({
		title: yup.string().trim().required('enter a name for this schedule'),
		recipe: yup.mixed().when('selection', {
			is: 'recipe',
			then: (schema) => schema.required('Recipe is required'),
			otherwise: (schema) => schema.notRequired(),
		}),
	}),
})
