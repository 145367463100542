// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import * as yup from 'yup'

import { compact, isEmpty, isString } from 'lodash'

import { STORAGE_NAME_VALIDATION } from 'globals/constants/validations'
import { VALUE_REGEX } from 'globals/constants/valueStore'

import { formatValue, sentenceValue } from 'hooks/useNode'

import { INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.integration_sftp_list,
	isLive: true,
	isMenuOption: true,
	isPro: false,
	isAccessibility: false,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.variantType',
			type: 'variantType',
			label: 'Command',
			options: [INodeVariantTypes.integration_sftp_list, INodeVariantTypes.integration_sftp_upload],
		},
		{
			property: '',
			type: 'divider',
			label: 'Connection',
		},
		{
			property: 'attributes.value6',
			type: 'text-with-tags',
			defaultValue: 'sftp://',
			label: 'URL',
			validation: yup.string().trim().required('URL is required'),
			grid: 9,
		},
		{
			property: 'attributes.value7',
			type: 'dropdown',
			label: 'Port',
			defaultValue: '22',
			options: [{ label: '22', value: '22' }],
			grid: 3,
		},
		{
			property: 'attributes.value8',
			type: 'text-with-tags',
			label: 'User',
			grid: 6,
		},
		{
			property: 'attributes.value9',
			type: 'text-with-tags',
			label: 'Password',
			grid: 6,
		},
		{
			property: '',
			type: 'divider',
			label: 'Directory',
		},
		{
			property: 'attributes.value2',
			type: 'text-with-tags',
			label: 'Path',
			defaultValue: '/',
			validation: yup.string().test('is-valid-path', 'Path must start with "/"', (value: any) => {
				if (isString(value) && !isEmpty(value)) {
					return value.match(VALUE_REGEX) ? true : /^\//gim.test(value)
				}
				return false
			}),
		},
		{
			property: 'attributes.value3',
			type: 'dropdown',
			label: 'Type',
			defaultValue: 'files',
			options: [{ label: 'Files', value: 'files' }],
		},
		{
			property: 'attributes.value5',
			type: 'text',
			label: 'Response Storage Name',
			validation: STORAGE_NAME_VALIDATION,
			isValueStoreKey: true,
			helper: 'Returned value is a comma separated list',
		},
	],
	label: 'SFTP List',
	sentence: ({ isExample, value2 }: INodeRenderProps) => {
		return isExample ? `SFTP List` : `SFTP List: ${sentenceValue(value2)}`
	},
	family: INodeVariantTypes.integration_sftp_list.split('_')[0],
	type: INodeVariantTypes.integration_sftp_list.split('_')[1],
	subType: INodeVariantTypes.integration_sftp_list.split('_')[2],
	tags: compact([
		INodeVariantTypes.integration_sftp_list.split('_')[0],
		INodeVariantTypes.integration_sftp_list.split('_')[1],
		INodeVariantTypes.integration_sftp_list.split('_')[2],
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ isExample, value2 }: INodeRenderProps) => {
			return (
				<>
					SFTP List
					{!isExample && (
						<>
							{': '}
							<strong>{formatValue(value2)}</strong>
						</>
					)}
				</>
			)
		},
	}
}
