// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import reactStringReplace from 'react-string-replace'
import debug from 'debug'

import { replace, round, toLower } from 'lodash'

import { COMPARISON_TYPES } from 'globals/constants/labels'
import { VALUE_REGEX } from 'globals/constants/valueStore'

import * as nodes from 'components/Nodes/library'
import { INodeComparisonTypes, INodeVariantTypes, IUseNode } from 'data/nodes'

// ? ---
// ?	Constants
// ? ---
const namespace = 'hooks-useNode'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Formatting Helpers
// ? ---
export const formatComparisonType = (comparisonType?: INodeComparisonTypes) =>
	toLower(comparisonType ? COMPARISON_TYPES[comparisonType] : '')
export const formatElement = (element?: string) => (element ? element : '""')
export const formatMFA = (mfa?: string) => (mfa ? mfa : '""')
export const sentenceElement = (element?: string) => (element ? `\`${element}\`` : '`No element selected`')

export const sentenceMFA = (mfa?: string) => (mfa ? `\`${mfa}\`` : '`No MFA selected`')

export const formatValue = (value?: string) => {
	return reactStringReplace(value || '""', VALUE_REGEX, (match, i) => {
		const tag = JSON.parse(match)
		log('reactStringReplace', value, match, tag, tag.value)
		return (
			<span key={i} className={'node-value-tag'}>
				{tag.value}
			</span>
		)
	})
}

export const formatValueAsString = (value?: string) => {
	if (!value) return '""'
	return replace(value, VALUE_REGEX, (match) => {
		log('formatValue asString', value, match)
		const tag = JSON.parse(replace(replace(match, '-{{-', ''), '-}}-', ''))
		return `[${tag.value}]`
	})
}

export const sentenceValue = (value?: string) => {
	if (!value) return '""'

	const finalValue = replace(value, VALUE_REGEX, (match) => {
		const tag = JSON.parse(replace(replace(match, '-{{-', ''), '-}}-', ''))
		return `[${tag.value}]`
	})

	if (value === finalValue) {
		return `"${value}"`
	} else {
		return `${finalValue}`
	}
}

export const formatPercentage = (value?: string) => {
	const valueAsNum = value ? parseFloat(value) : 0
	return round(valueAsNum * 100, 1)
}
export const formatEnumValue = (type: { [key: string]: string }, value?: string) =>
	value && type[value] ? type[value] : '""'

// ? ---
// ?	Hook
// ? ---
export default function useNode() {
	return {
		// * ---
		// *	Special
		// * ---
		[INodeVariantTypes.special_group]: nodes.special_group.useNode(),

		// * ---
		// *	Starters
		// * ---
		[INodeVariantTypes.starter_open]: nodes.starter_open.useNode(),
		[INodeVariantTypes.starter_open_advanced]: nodes.starter_open_advanced.useNode(),

		// * ---
		// *	Browser
		// * ---
		[INodeVariantTypes.action_goTo_absolute]: nodes.action_goTo_absolute.useNode(),
		[INodeVariantTypes.action_goTo_relative]: nodes.action_goTo_relative.useNode(),
		[INodeVariantTypes.action_goTo_reload]: nodes.action_goTo_reload.useNode(),
		[INodeVariantTypes.action_goTo_back]: nodes.action_goTo_back.useNode(),
		[INodeVariantTypes.action_goTo_forward]: nodes.action_goTo_forward.useNode(),

		// * ---
		// *	Actions
		// * ---
		[INodeVariantTypes.action_touch_single]: nodes.action_touch_single.useNode(),
		[INodeVariantTypes.action_touch_double]: nodes.action_touch_double.useNode(),
		[INodeVariantTypes.action_touch_hover]: nodes.action_touch_hover.useNode(),
		[INodeVariantTypes.action_scroll_intoView]: nodes.action_scroll_intoView.useNode(),
		[INodeVariantTypes.action_scroll_page]: nodes.action_scroll_page.useNode(),
		[INodeVariantTypes.action_input_setValue]: nodes.action_input_setValue.useNode(),
		[INodeVariantTypes.action_input_sendKeys]: nodes.action_input_sendKeys.useNode(),
		[INodeVariantTypes.action_input_setTinyMce]: nodes.action_input_setTinyMce.useNode(),
		[INodeVariantTypes.action_input_clearValue]: nodes.action_input_clearValue.useNode(),
		[INodeVariantTypes.action_input_selectOption]: nodes.action_input_selectOption.useNode(),
		[INodeVariantTypes.action_input_file]: nodes.action_input_file.useNode(),
		[INodeVariantTypes.action_dragDrop]: nodes.action_dragDrop.useNode(),
		[INodeVariantTypes.action_input_setMFA]: nodes.action_input_setMFA.useNode(),

		// * ---
		// *	Checks
		// * ---
		[INodeVariantTypes.check_is_displayed]: nodes.check_is_displayed.useNode(),
		[INodeVariantTypes.check_is_notDisplayed]: nodes.check_is_notDisplayed.useNode(),
		[INodeVariantTypes.check_is_visible]: nodes.check_is_visible.useNode(),
		[INodeVariantTypes.check_is_notVisible]: nodes.check_is_notVisible.useNode(),
		[INodeVariantTypes.check_is_enabled]: nodes.check_is_enabled.useNode(),
		[INodeVariantTypes.check_is_disabled]: nodes.check_is_disabled.useNode(),
		[INodeVariantTypes.check_is_clickable]: nodes.check_is_clickable.useNode(),
		[INodeVariantTypes.check_is_notClickable]: nodes.check_is_notClickable.useNode(),
		[INodeVariantTypes.check_is_exist]: nodes.check_is_exist.useNode(),
		[INodeVariantTypes.check_is_notExist]: nodes.check_is_notExist.useNode(),
		[INodeVariantTypes.check_is_focused]: nodes.check_is_focused.useNode(),
		[INodeVariantTypes.check_is_notFocused]: nodes.check_is_notFocused.useNode(),
		[INodeVariantTypes.check_text]: nodes.check_text.useNode(),
		[INodeVariantTypes.check_value]: nodes.check_value.useNode(),
		[INodeVariantTypes.check_input_tinyMce]: nodes.check_input_tinyMce.useNode(),
		[INodeVariantTypes.check_attribute]: nodes.check_attribute.useNode(),
		[INodeVariantTypes.check_computed_label]: nodes.check_computed_label.useNode(),
		[INodeVariantTypes.check_computed_role]: nodes.check_computed_role.useNode(),
		[INodeVariantTypes.check_computed_style]: nodes.check_computed_style.useNode(),
		[INodeVariantTypes.check_count]: nodes.check_count.useNode(),
		[INodeVariantTypes.check_browserTitle]: nodes.check_browserTitle.useNode(),
		[INodeVariantTypes.check_browserAddress]: nodes.check_browserAddress.useNode(),
		[INodeVariantTypes.check_cookie_value]: nodes.check_cookie_value.useNode(),
		[INodeVariantTypes.check_localStorage_value]: nodes.check_localStorage_value.useNode(),
		[INodeVariantTypes.check_sessionStorage_value]: nodes.check_sessionStorage_value.useNode(),
		[INodeVariantTypes.check_valueStore]: nodes.check_valueStore.useNode(),
		[INodeVariantTypes.check_links_valid]: nodes.check_links_valid.useNode(),
		[INodeVariantTypes.check_sftp_fileExist]: nodes.check_sftp_fileExist.useNode(),
		[INodeVariantTypes.check_sftp_fileNotExist]: nodes.check_sftp_fileNotExist.useNode(),
		[INodeVariantTypes.check_sftp_fileCount]: nodes.check_sftp_fileCount.useNode(),

		// * ---
		// *	Runner
		// * ---
		[INodeVariantTypes.action_wait_forElement]: nodes.action_wait_forElement.useNode(),
		[INodeVariantTypes.action_wait_forElementNotDisplayed]: nodes.action_wait_forElementNotDisplayed.useNode(),
		[INodeVariantTypes.action_wait_forElementVisible]: nodes.action_wait_forElementVisible.useNode(),
		[INodeVariantTypes.action_wait_forElementNotVisible]: nodes.action_wait_forElementNotVisible.useNode(),
		[INodeVariantTypes.action_wait_forElementEnabled]: nodes.action_wait_forElementEnabled.useNode(),
		[INodeVariantTypes.action_wait_forElementDisabled]: nodes.action_wait_forElementDisabled.useNode(),
		[INodeVariantTypes.action_wait_forElementExist]: nodes.action_wait_forElementExist.useNode(),
		[INodeVariantTypes.action_wait_forElementNotExist]: nodes.action_wait_forElementNotExist.useNode(),
		[INodeVariantTypes.action_wait_forElementClickable]: nodes.action_wait_forElementClickable.useNode(),
		[INodeVariantTypes.action_wait_forElementNotClickable]: nodes.action_wait_forElementNotClickable.useNode(),
		[INodeVariantTypes.runner_wait_forElementText]: nodes.runner_wait_forElementText.useNode(),
		[INodeVariantTypes.action_wait_forSeconds]: nodes.action_wait_forSeconds.useNode(),
		[INodeVariantTypes.action_switch_window]: nodes.action_switch_window.useNode(),
		[INodeVariantTypes.action_switch_frame]: nodes.action_switch_frame.useNode(),
		[INodeVariantTypes.action_switch_parentFrame]: nodes.action_switch_parentFrame.useNode(),
		[INodeVariantTypes.runner_stop_elementDisplayed]: nodes.runner_stop_elementDisplayed.useNode(),
		[INodeVariantTypes.runner_stop_elementNotDisplayed]: nodes.runner_stop_elementNotDisplayed.useNode(),
		[INodeVariantTypes.runner_stop_value]: nodes.runner_stop_value.useNode(),
		[INodeVariantTypes.runner_header_add]: nodes.runner_header_add.useNode(),
		[INodeVariantTypes.runner_header_set]: nodes.runner_header_set.useNode(),
		[INodeVariantTypes.runner_header_removeAll]: nodes.runner_header_removeAll.useNode(),
		[INodeVariantTypes.runner_generate_file]: nodes.runner_generate_file.useNode(),
		[INodeVariantTypes.runner_generate_image]: nodes.runner_generate_image.useNode(),

		// * ---
		// *	Change Detection
		// * ---
		[INodeVariantTypes.change_element_snapshot]: nodes.change_element_snapshot.useNode(),
		[INodeVariantTypes.change_element_position]: nodes.change_element_position.useNode(),

		// * ---
		// *	Mail
		// * ---
		[INodeVariantTypes.mail_inbox_open]: nodes.mail_inbox_open.useNode(),
		[INodeVariantTypes.mail_message_select]: nodes.mail_message_select.useNode(),
		[INodeVariantTypes.mail_message_open]: nodes.mail_message_open.useNode(),
		[INodeVariantTypes.mail_wait_forMessage]: nodes.mail_wait_forMessage.useNode(),
		[INodeVariantTypes.mail_inbox_close]: nodes.mail_inbox_close.useNode(),

		// * ---
		// *	Validators
		// * ---
		[INodeVariantTypes.validate_lighthouse_checkAccessibility]:
			nodes.validate_lighthouse_checkAccessibility.useNode(),
		[INodeVariantTypes.validate_lighthouse_checkPerformance]: nodes.validate_lighthouse_checkPerformance.useNode(),
		[INodeVariantTypes.validate_lighthouse_checkSeo]: nodes.validate_lighthouse_checkSeo.useNode(),
		[INodeVariantTypes.validate_lighthouse_checkPractices]: nodes.validate_lighthouse_checkPractices.useNode(),
		[INodeVariantTypes.validate_lighthouse_checkPwa]: nodes.validate_lighthouse_checkPwa.useNode(),
		[INodeVariantTypes.validate_pa11y]: nodes.validate_pa11y.useNode(),

		// * ---
		// *	Data
		// * ---
		[INodeVariantTypes.data_store_elementText]: nodes.data_store_elementText.useNode(),
		[INodeVariantTypes.data_store_elementHTML]: nodes.data_store_elementHTML.useNode(),
		[INodeVariantTypes.data_store_elementValue]: nodes.data_store_elementValue.useNode(),
		[INodeVariantTypes.data_store_elementAttribute]: nodes.data_store_elementAttribute.useNode(),
		[INodeVariantTypes.data_store_elementCount]: nodes.data_store_elementCount.useNode(),
		[INodeVariantTypes.data_store_alias]: nodes.data_store_alias.useNode(),
		[INodeVariantTypes.data_store_set]: nodes.data_store_set.useNode(),
		[INodeVariantTypes.data_process_calculate]: nodes.data_process_calculate.useNode(),
		[INodeVariantTypes.data_process_replace]: nodes.data_process_replace.useNode(),
		[INodeVariantTypes.data_cookie_set]: nodes.data_cookie_set.useNode(),
		[INodeVariantTypes.data_cookie_delete]: nodes.data_cookie_delete.useNode(),
		[INodeVariantTypes.data_cookie_deleteAll]: nodes.data_cookie_deleteAll.useNode(),
		[INodeVariantTypes.data_localStorage_set]: nodes.data_localStorage_set.useNode(),
		[INodeVariantTypes.data_localStorage_delete]: nodes.data_localStorage_delete.useNode(),
		[INodeVariantTypes.data_localStorage_deleteAll]: nodes.data_localStorage_deleteAll.useNode(),
		[INodeVariantTypes.data_sessionStorage_set]: nodes.data_sessionStorage_set.useNode(),
		[INodeVariantTypes.data_sessionStorage_delete]: nodes.data_sessionStorage_delete.useNode(),
		[INodeVariantTypes.data_sessionStorage_deleteAll]: nodes.data_sessionStorage_deleteAll.useNode(),

		// * ---
		// *	Express Nodes
		// * ---
		[INodeVariantTypes.express_doesqa_run]: nodes.express_doesqa_run.useNode(),
		[INodeVariantTypes.express_adyen_card]: nodes.express_adyen_card.useNode(),
		[INodeVariantTypes.express_adyen_paypal]: nodes.express_adyen_paypal.useNode(),
		[INodeVariantTypes.express_adyen_googlepay]: nodes.express_adyen_googlepay.useNode(),
		[INodeVariantTypes.express_adyen_afterpay]: nodes.express_adyen_afterpay.useNode(),
		[INodeVariantTypes.express_adyen_klarna]: nodes.express_adyen_klarna.useNode(),

		// * ---
		// *	Integration
		// * ---
		[INodeVariantTypes.integration_api_get]: nodes.integration_api_get.useNode(),
		[INodeVariantTypes.integration_api_put]: nodes.integration_api_put.useNode(),
		[INodeVariantTypes.integration_api_post]: nodes.integration_api_post.useNode(),
		[INodeVariantTypes.integration_api_delete]: nodes.integration_api_delete.useNode(),
		[INodeVariantTypes.integration_slack_send]: nodes.integration_slack_send.useNode(),
		[INodeVariantTypes.integration_googleMaps_position]: nodes.integration_googleMaps_position.useNode(),
		[INodeVariantTypes.integration_googleMaps_pan]: nodes.integration_googleMaps_pan.useNode(),
		[INodeVariantTypes.integration_googleMaps_zoom]: nodes.integration_googleMaps_zoom.useNode(),
		[INodeVariantTypes.integration_sftp_list]: nodes.integration_sftp_list.useNode(),
		[INodeVariantTypes.integration_sftp_upload]: nodes.integration_sftp_upload.useNode(),

		// * ---
		// *	Utilities
		// * ---
		[INodeVariantTypes.utility_comment_flow]: nodes.utility_comment_flow.useNode(),
		[INodeVariantTypes.utility_comment_test]: nodes.utility_comment_test.useNode(),
		[INodeVariantTypes.utility_name_test]: nodes.utility_name_test.useNode(),
	} as { [key: string]: IUseNode }
}
