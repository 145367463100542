// ? ---
// ?	Fragment
// ? ---
import gql from 'graphql-tag'

import { MAX_ITEMS_PER_PAGE, MAX_SELECT_ITEMS_PER_PAGE } from 'globals/constants/api'

export const RECIPE_FRAGMENT = `
	fragment recipeFragment on RecipeEntity {
		id
		attributes {
			uid
			title
			value
			withAll
			withoutAny
		}
	}
`

// ? ---
// ?	Queries
// ? ---
export const QUERY_RECIPES = gql(/* GraphQL */ `
	${RECIPE_FRAGMENT}
	query query_recipes_main($filters: RecipeFiltersInput!) {
		recipes(filters: $filters, pagination: { pageSize: ${MAX_ITEMS_PER_PAGE} }, sort:"title:asc") {
			data {
				...recipeFragment
			}
		}
	}
`)

export const QUERY_RECIPES_SELECT = gql(/* GraphQL */ `
    query query_recipes_select($filters: RecipeFiltersInput!) {
        recipes(filters: $filters, pagination: { pageSize: ${MAX_SELECT_ITEMS_PER_PAGE} }, sort:"title:asc") {
            data {
                id
                attributes {
                    title
                }
            }
        }
    }
`)

// ? ---
// ?	Mutations
// ? --
export const ADD_RECIPE = gql(/* GraphQL */ `
	mutation create_recipe($data: RecipeInput!) {
		createRecipe(data: $data) {
			data {
				id
			}
		}
	}
`)

export const EDIT_RECIPE = gql(/* GraphQL */ `
	${RECIPE_FRAGMENT}
	mutation update_recipe($id: ID!, $data: RecipeInput!) {
		updateRecipe(id: $id, data: $data) {
			data {
				...recipeFragment
			}
		}
	}
`)

export const NUKE_RECIPE = gql(/* GraphQL */ `
	mutation delete_recipe($id: ID!) {
		deleteRecipe(id: $id) {
			data {
				id
			}
		}
	}
`)
