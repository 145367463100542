export const MAX_ITEMS_PER_PAGE = 100
export const MAX_ELEMENTS_PER_PAGE = 10000
export const MAX_SELECT_ITEMS_PER_PAGE = 10000
export const MAX_ELEMENTS_PER_PAGE_VIEW = 100
export const MAX_RUN_TEST_ITEMS = 1000
export const MAX_FLOW_NODES = 10000
export const MAX_TEST_STEPS = 1000
export const MAX_GROUPED_NODES = 1000
export const MAX_FLOWS_PER_PAGE = 1000
export const MAX_TRIGGERS_PER_PAGE = 1000
export const MAX_TESTS_PER_PAGE = 50
export const MAX_RUNS_PER_PAGE = 20

export const INTERNAL_WEBHOOK_KEY = '3e9f73ea3d1a421b'

export const GRAPHQL_SERVER_RETRY_MAX = 10
export const GRAPHQL_SERVER_RETRY_DELAY = 300

export const GRAPHQL_CLIENT_RETRY_MAX = 3
export const GRAPHQL_CLIENT_RETRY_DELAY = 100
