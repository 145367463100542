// ? ---
// ?	Imports
// ? ---
import debug from 'debug'

import { filter, find, flatMap, forEach, get, head, includes, map, reject, startsWith, uniq } from 'lodash'

import { MAX_TEST_STEPS } from 'globals/constants/api'

import { IFlow } from 'data/flows'

// ? ---
// ?	Constants
// ? ---
const namespace = 'data-flows-helpers-extractTests'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Export
// ? ---
export const extractTests = (flow: IFlow) => {
	if (!flow || !flow.attributes || !flow.attributes?.edgeMeta || !flow.attributes.nodeMeta) return []

	const connectedEdges = reject(
		flow.attributes.edgeMeta,
		(edge) =>
			!includes(map(flow.attributes.nodeMeta, 'id'), edge.source) ||
			!includes(map(flow.attributes.nodeMeta, 'id'), edge.target)
	)

	const starterNodes = map(
		filter(flow.attributes.nodeMeta, (node) => startsWith(get(node, 'data.attributes.variantType'), 'starter_')),
		'id'
	)

	const finalNodes = map(
		filter(flow.attributes.nodeMeta, (node) => find(connectedEdges, { source: node.id }) === undefined),
		'id'
	)
	const findPath = (edges: any[], currentPath: string[]) => {
		const connectedNodesIds = map(filter(edges, { target: head(currentPath) }), 'source')
		let temp: any = []
		forEach(connectedNodesIds, (connectedId) => {
			const newPath = [connectedId, ...currentPath]
			if (includes(starterNodes, connectedId)) {
				temp = [...temp, newPath]
			} else {
				// ! ---
				// !  Throw error test is already too long
				// ! ---
				if (temp.length >= MAX_TEST_STEPS) {
					log(`!! Test Too Large, ${temp.length} >= ${MAX_TEST_STEPS}`)
					throw new Error(`Test Too Large, ${temp.length} >= ${MAX_TEST_STEPS}`)
				}

				temp = [...temp, ...findPath(edges, newPath)]
			}
		})

		// ! ---
		// !  Throw error if infinite loop detected
		// !   - Check is the deduped array is shorter than the original
		// ! ---
		if (temp.length > uniq(temp).length) {
			log(`!! Infinite loop detected, ${temp.length} > ${uniq(temp).length}`)
			throw new Error(`Infinite loop detected, ${temp.length} > ${uniq(temp).length}`)
		}

		return temp
	}

	const completeTests = flatMap(finalNodes, (nodeId) => findPath(flow.attributes?.edgeMeta, [nodeId]))
	log('extractTests: completeTests', { totalTests: completeTests.length, completeTests })

	return completeTests
}
