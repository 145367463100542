// ? ---
// ?	Fragment
// ? ---
import gql from 'graphql-tag'

import { MAX_ELEMENTS_PER_PAGE, MAX_SELECT_ITEMS_PER_PAGE } from 'globals/constants/api'

export const ELEMENT_FRAGMENT = `
	fragment elementFragment on ElementEntity {
		id
		attributes {
			title
			type
			selector
			isDynamic
			nodes {
				data {
					id
					attributes {
						variantType
					}
				}
			}
			nodes2 {
				data {
					id
					attributes {
						variantType
					}
				}
			}
		}
	}
`

// ? ---
// ?	Queries
// ? --
export const QUERY_ELEMENTS = gql(/* GraphQL */ `
    ${ELEMENT_FRAGMENT}
    query query_elements_main($filters: ElementFiltersInput!) {
        elements(filters: $filters, pagination: { pageSize: ${MAX_ELEMENTS_PER_PAGE} }, sort:"title:asc") {
            data {
                ...elementFragment
            }
        }
    }
`)

export const QUERY_ELEMENTS_STAT = gql(/* GraphQL */ `
    query query_elements_select($filters: ElementFiltersInput!) {
        elements(filters: $filters, pagination: { pageSize: ${MAX_SELECT_ITEMS_PER_PAGE} }, sort:"title:asc") {
            data {
                id
                attributes {
                    title
		                type
                }
            }
        }
    }
`)

export const QUERY_ELEMENTS_SELECT = gql(/* GraphQL */ `
    query query_elements_select($filters: ElementFiltersInput!) {
        elements(filters: $filters, pagination: { pageSize: ${MAX_SELECT_ITEMS_PER_PAGE} }, sort:"title:asc") {
            data {
                id
                attributes {
                    title
                }
            }
        }
    }
`)

export const QUERY_ELEMENTS_VIEW = gql(/* GraphQL */ `
	${ELEMENT_FRAGMENT}
	query query_elements_view($filters: ElementFiltersInput!, $pagination: PaginationArg!, $sort: [String]) {
		elements(filters: $filters, pagination: $pagination, sort: $sort) {
			meta {
				pagination {
					total
					page
					pageSize
					pageCount
				}
			}
			data {
				...elementFragment
			}
		}
	}
`)

// ? ---
// ?	Mutations
// ? --
export const ADD_ELEMENT = gql(/* GraphQL */ `
	${ELEMENT_FRAGMENT}
	mutation create_element($data: ElementInput!) {
		createElement(data: $data) {
			data {
				...elementFragment
			}
		}
	}
`)

export const EDIT_ELEMENT = gql(/* GraphQL */ `
	${ELEMENT_FRAGMENT}
	mutation update_element($id: ID!, $data: ElementInput!) {
		updateElement(id: $id, data: $data) {
			data {
				...elementFragment
			}
		}
	}
`)

export const NUKE_ELEMENT = gql(/* GraphQL */ `
	${ELEMENT_FRAGMENT}
	mutation delete_element($id: ID!) {
		deleteElement(id: $id) {
			data {
				...elementFragment
			}
		}
	}
`)
