// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import { useMutation } from '@apollo/client'
import { AppDataContext, IAppDataContext } from 'context/appData'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import debug from 'debug'
import hash from 'object-hash'

import { omit, random } from 'lodash'

import { ADD_EVENT, EDIT_EVENT, IEventContext, NUKE_EVENT } from 'data/events'

import useActivity from './useActivity'

// ? ---
// ?	Constants
// ? ---
const namespace = 'hooks-useEvents'
const log = debug(`app:${namespace}`)
const type = 'Event'
const model = 'events'

dayjs.extend(utc)

// ? ---
// ?	Hook
// ? ---
export default function useEvents() {
	// * ---
	// *	Context
	// * ---
	const AppData = React.useContext<IAppDataContext>(AppDataContext)
	const _activity = useActivity()

	// * ---
	// *	Mutation
	// * ---
	const [add] = useMutation(ADD_EVENT)
	const [edit] = useMutation(EDIT_EVENT)
	const [nuke] = useMutation(NUKE_EVENT)

	// * ---
	// *	Insert
	// * ---
	const insert: IEventContext['insert'] = async (input, options) => {
		log('insert', { input, options })
		const response = await add({
			variables: {
				data: {
					...omit(input.attributes, ['__typename']),
					key: hash([random(10000, 99999)]).substring(0, 28),
				},
			},
		})
		_activity.trackEventCreated(input?.attributes?.title || 'UNKNOWN_NAME')
		if (options?.onSuccess) options.onSuccess(response.data[`create${type}`].data)
		if (options?.onComplete) options.onComplete(response.data[`create${type}`].data)
		return response.data[`create${type}`].data
	}

	// * ---
	// *	Update
	// * ---
	const update: IEventContext['update'] = async (input, options) => {
		log('update', { input, options })
		const response = await edit({
			variables: {
				id: input.id,
				data: {
					...omit(input.attributes, ['__typename', 'updatedAt']),
				},
			},
		})
		if (options?.onSuccess) options.onSuccess(response.data[`update${type}`].data)
		if (options?.onComplete) options.onComplete(response.data[`update${type}`].data)
		return response.data[`update${type}`].data
	}

	// * ---
	// *	Remove
	// * ---
	const remove: IEventContext['remove'] = async (item, options) => {
		log('remove', { item, options })
		if (options?.force) {
			await nuke({
				variables: {
					id: item.id,
				},
			})
			if (options?.onSuccess) options.onSuccess()
			if (options?.onComplete) options.onComplete()
		} else {
			await AppData.openDeleteConfirmation(
				{},
				{
					onSuccess: async () => {
						await nuke({
							variables: {
								id: item.id,
							},
						})
						if (options?.onSuccess) options.onSuccess()
						if (options?.onComplete) options.onComplete()
					},
				}
			)
		}
	}

	// * ---
	// *	Upsert
	// * ---
	const upsert: IEventContext['upsert'] = async (input, options) => {
		log('upsert', { input, options })
		if (input.id === undefined || input.id === '') {
			return await insert(input, options)
		} else {
			return await update(input, options)
		}
	}

	// * ---
	// *	Clone
	// * ---
	const clone: IEventContext['clone'] = async (item, options) => {
		log('clone', { item, options })
		const response = await insert(
			{
				...item,
				attributes: {
					...omit(item.attributes, ['__typename']),
					title: `${item.attributes?.title} (copy)`,
					key: hash([random(10000, 99999)]).substring(0, 28),
				},
			},
			options
		)
		if (options?.onSuccess) options.onSuccess(response)
		if (options?.onComplete) options.onComplete(response)
		return response
	}

	// * ---
	// *	Return
	// * ---
	return {
		insert,
		update,
		upsert,
		clone,
		remove,
		openUpsert: AppData[model].openUpsert,
		closeUpsert: AppData[model].closeUpsert,
	}
}
