// ? ---
// ?	Types & Enums
// ? ---
export enum IAccountValueTypeOptions {
	string = 'String',
	number = 'Number',
	boolean = 'True / False',
	json = 'Object / Array',
}

export enum IAccountIntegrationTypeOptions {
	slack = 'slack',
}

// ? ---
// ?	Interfaces
// ? ---
export interface IAccountValue {
	id?: string
	name: string
	type: 'string' | 'number' | 'boolean' | 'json'
	stringValue: string
	numberValue: number
	booleanValue: boolean
	jsonValue: { [key: string]: any }
	stringValue_private?: string
	numberValue_private?: number
	booleanValue_private?: boolean
	jsonValue_private?: { [key: string]: any }
	isPrivate: boolean
}

export interface IAccountWebhookKey {
	id?: string
	name: string
	key?: string
	isActive: boolean
	userId: string
	activeAt: string
	inactiveAt: string
	slackChannelId: string
}

export interface IAccountTag {
	id?: string
	label: string
	value: string
}

export interface IAccountIntegration {
	id?: string
	type: IAccountIntegrationTypeOptions
	isActive: boolean
}

export interface IAccountOptions {
	id?: string
	maxConcurrency: number
	blockedHostnames: string[]
	abandonQueuedOnFirstFail: boolean
	stepTimeoutSeconds: number
}

export interface IAccount {
	__typename: 'AccountEntity'
	id: string
	attributes: {
		values: IAccountValue[]
		keys: IAccountWebhookKey[]
		integrations: IAccountIntegration[]
		options: IAccountOptions
		tags: IAccountTag[]
	}
}

export interface IAccountResponse {
	data: IAccount[]
}

export interface IAccountQueryResponse {
	accounts: IAccountResponse
}

export interface IAccountInput {
	id?: string
	attributes?: {
		keys?: IAccountWebhookKey[]
		values?: IAccountValue[]
		options?: IAccountOptions
		tags?: IAccountTag[]
	}
}

export interface IAccountUsers {
	[key: string]: {
		id: string
		firstName: string
		lastName: string
		fullName: string
		avatar: string
	}
}

export interface IAccountSlackChannel {
	__typename: 'AccountEntity'
	id: string
	attributes: {
		values: IAccountValue[]
		keys: IAccountWebhookKey[]
	}
}

export interface IAccountContextOptions {
	force?: boolean
	onSuccess?: (account?: IAccount) => any
	onError?: (error?: any) => any
	onComplete?: (account?: IAccount) => any
}

export interface IAccountContext {
	insert: (accountInput: IAccountInput, options?: IAccountContextOptions) => Promise<IAccount>
	update: (accountInput: IAccountInput, options?: IAccountContextOptions) => Promise<IAccount>
	upsert: (accountInput: IAccountInput, options?: IAccountContextOptions) => Promise<IAccount>
	openUpsert: (account?: Partial<IAccount>, options?: IAccountContextOptions) => Promise<void>
	closeUpsert: (options?: IAccountContextOptions) => Promise<void>

	openMinutesRequired: () => Promise<void>
	closeMinutesRequired: () => Promise<void>

	openOnlyAccountOwners: () => Promise<void>
	closeOnlyAccountOwners: () => Promise<void>
}

export interface IAccountValueContext {
	upsert: (valueInput: IAccountValue, options?: IAccountContextOptions) => Promise<IAccount>
	clone: (value: IAccountValue, options?: IAccountContextOptions) => Promise<IAccount>
	remove: (value: IAccountValue, options?: IAccountContextOptions) => Promise<void>
	openUpsert: (value?: Partial<IAccountValue>, options?: IAccountContextOptions) => Promise<void>
	closeUpsert: (options?: IAccountContextOptions) => Promise<void>
}

export interface IAccountWebhookKeyContext {
	upsert: (valueInput: IAccountWebhookKey, options?: IAccountContextOptions) => Promise<IAccount>
	clone: (value: IAccountWebhookKey, options?: IAccountContextOptions) => Promise<IAccount>
	remove: (value: IAccountWebhookKey, options?: IAccountContextOptions) => Promise<void>
	openUpsert: (value?: Partial<IAccountWebhookKey>, options?: IAccountContextOptions) => Promise<void>
	closeUpsert: (options?: IAccountContextOptions) => Promise<void>
}

export interface IAccountTagContext {
	upsert: (valueInput: IAccountTag, options?: IAccountContextOptions) => Promise<IAccount>
	clone: (value: IAccountTag, options?: IAccountContextOptions) => Promise<IAccount>
	remove: (value: IAccountTag, options?: IAccountContextOptions) => Promise<void>
	openUpsert: (value?: Partial<IAccountTag>, options?: IAccountContextOptions) => Promise<void>
	closeUpsert: (options?: IAccountContextOptions) => Promise<void>
}

// ? ---
// ?	Type Guards
// ? ---
export const isAccount = (account: IAccount): boolean => {
	return account.__typename === 'AccountEntity'
}
