// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import { useMutation } from '@apollo/client'
import axios from 'axios'
import { AppDataContext, IAppDataContext } from 'context/appData'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import debug from 'debug'

import { event as gaEvent } from 'nextjs-google-analytics/dist/interactions/event'
import { get, omit } from 'lodash'

import { gaEventName } from 'globals/constants/integrations'

import { useAuth } from 'components/_Global/_____AuthProvider'
import { ADD_RECIPE, EDIT_RECIPE, IRecipeContext, NUKE_RECIPE } from 'data/recipes'

import useAccount from './useAccount'
import useActivity from './useActivity'

// ? ---
// ?	Constants
// ? ---
const namespace = 'hooks-useRecipes'
const log = debug(`app:${namespace}`)
const type = 'Recipe'
const model = 'recipes'

dayjs.extend(utc)

// ? ---
// ?	Hook
// ? ---
export default function useRecipes() {
	// * ---
	// *	Context
	// * ---
	const AppData = React.useContext<IAppDataContext>(AppDataContext)
	const { openUpgrade, user } = useAuth()
	const _activity = useActivity()
	const _account = useAccount()

	// * ---
	// *	Mutation
	// * ---
	const [add] = useMutation(ADD_RECIPE)
	const [edit] = useMutation(EDIT_RECIPE)
	const [nuke] = useMutation(NUKE_RECIPE)

	// * ---
	// *	Insert
	// * ---
	const insert: IRecipeContext['insert'] = async (input, options) => {
		log('insert', { input, options })
		const response = await add({
			variables: {
				data: {
					...omit(input.attributes, ['__typename']),
				},
			},
		})
		_activity.trackScheduleCreated(input?.attributes?.title || 'UNKNOWN_NAME')
		if (options?.onSuccess) options.onSuccess(response.data[`create${type}`].data)
		if (options?.onComplete) options.onComplete(response.data[`create${type}`].data)
		return response.data[`create${type}`].data
	}

	// * ---
	// *	Update
	// * ---
	const update: IRecipeContext['update'] = async (input, options) => {
		log('update', { input, options })
		const response = await edit({
			variables: {
				id: input.id,
				data: {
					...omit(input.attributes, ['__typename', 'updatedAt']),
				},
			},
		})
		if (options?.onSuccess) options.onSuccess(response.data[`update${type}`].data)
		if (options?.onComplete) options.onComplete(response.data[`update${type}`].data)
		return response.data[`update${type}`].data
	}

	// * ---
	// *	Remove
	// * ---
	const remove: IRecipeContext['remove'] = async (item, options) => {
		log('remove', { item, options })
		if (options?.force) {
			await nuke({
				variables: {
					id: item.id,
				},
			})
			if (options?.onSuccess) options.onSuccess()
			if (options?.onComplete) options.onComplete()
		} else {
			await AppData.openDeleteConfirmation(
				{},
				{
					onSuccess: async () => {
						await nuke({
							variables: {
								id: item.id,
							},
						})
						if (options?.onSuccess) options.onSuccess()
						if (options?.onComplete) options.onComplete()
					},
				}
			)
		}
	}

	// * ---
	// *	Upsert
	// * ---
	const upsert: IRecipeContext['upsert'] = async (input, options) => {
		log('upsert', { input, options })
		if (input.id === undefined || input.id === '') {
			return await insert(input, options)
		} else {
			return await update(input, options)
		}
	}

	// * ---
	// *	Clone
	// * ---
	const clone: IRecipeContext['clone'] = async (item, options) => {
		log('clone', { item, options })
		const response = await insert(
			{
				...item,
				attributes: {
					...omit(item.attributes, ['__typename', 'uid']),
					title: `${item.attributes?.title} (copy)`,
				},
			},
			options
		)
		if (options?.onSuccess) options.onSuccess(response)
		if (options?.onComplete) options.onComplete(response)
		return response
	}

	// * ---
	// *	Run Recipe
	// * ---
	const run: IRecipeContext['run'] = async (recipe, options) => {
		log('run', { recipe, options })

		const runStatus = await axios
			.post(
				`/api/hook/${_account.accountId}?key=app&recipe=${recipe.id}&email=${user?.Email}&userId=${
					user?.Uid
				}&name=${user?.FullName || user?.Email}`
			)
			.then((response) => {
				return response.status
			})
			.catch((err) => {
				log('!! runHook failed', err)
				if (get(err, 'response.status') === 402) {
					log('!! runHook: upgrade required')
					openUpgrade()
					_activity.trackPlanUpgradeViewed('webhook-response')
					gaEvent(gaEventName('upgrade', 'webhook-response', 'recipe'))
				}
				if (options?.onError) options.onError(err)
			})

		if (runStatus === 200 && options?.onSuccess) options.onSuccess()
		if (options?.onComplete) options.onComplete()
	}

	// * ---
	// *	Return
	// * ---
	return {
		insert,
		update,
		upsert,
		clone,
		remove,
		run,
		openUpsert: AppData[model].openUpsert,
		closeUpsert: AppData[model].closeUpsert,
	}
}
