// ? ---
// ?	Imports
// ? ---
import * as React from 'react'

import { compact } from 'lodash'

import { FAILED_STATUS_OPTIONS } from 'globals/constants/selectOptions'

import { formatValue, sentenceValue } from 'hooks/useNode'

import { INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'
import { ITestStepStatus } from 'data/tests'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.validate_pa11y,
	isLive: true,
	isMenuOption: true,
	isPro: false,
	isAccessibility: true,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.value',
			type: 'button-group',
			label: 'WCAG2 Conformance Level',
			defaultValue: 'AA',
			options: [
				{
					value: 'A',
					label: 'A',
				},
				{
					value: 'AA',
					label: 'AA',
				},
				{
					value: 'AAA',
					label: 'AAA',
				},
			],
		},
		{
			property: 'attributes.config.failedStatus',
			type: 'button-group',
			label: 'Status on fail',
			defaultValue: ITestStepStatus.failed,
			options: FAILED_STATUS_OPTIONS,
		},
	],
	label: 'Pa11y',
	sentence: ({ isExample, value }: INodeRenderProps) => {
		return isExample ? `Check Pa11y` : `Check Pa11y ${sentenceValue(value)} Conformance`
	},
	family: INodeVariantTypes.validate_pa11y.split('_')[0],
	type: INodeVariantTypes.validate_pa11y.split('_')[1],
	subType: INodeVariantTypes.validate_pa11y.split('_')[2],
	tags: compact([
		INodeVariantTypes.validate_pa11y.split('_')[0],
		INodeVariantTypes.validate_pa11y.split('_')[1],
		INodeVariantTypes.validate_pa11y.split('_')[2],
		'test',
		'expect',
		'assert',
		'check',
		'valid',
		'validator',
		'accessibility',
		'pally',
		'pa11y',
		'wcag',
		'wcag2',
		'conformance',
		'ally',
		'a11y',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ isExample, value }: INodeRenderProps) => {
			return (
				<>
					Pa11y{' '}
					{!isExample && (
						<>
							<strong>{formatValue(value)}</strong> Conformance
						</>
					)}
				</>
			)
		},
	}
}
