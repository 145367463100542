// ? ---
// ?	Imports
// ? ---
import { IAccountOptions } from 'data/accounts'
import { IRunData } from 'data/runs'
import { ITestStep } from 'data/tests/steps'

// ? ---
// ?	Types & Enums
// ? ---
export enum ITestStatus {
	never_run = 'never_run',
	preview = 'preview',
	pending = 'pending',
	queued = 'queued',
	accepted = 'accepted',
	running = 'running',
	ignored = 'ignored',
	passed = 'passed',
	passed_with_warning = 'passed_with_warning',
	failed = 'failed',
	rejected = 'rejected',
	terminated = 'terminated',
}

export enum ITestRunTriggerType {
	user = 'user',
	hook = 'hook',
	schedule = 'schedule',
	child = 'child',
}

export enum ITestResponseType {
	text = 'text',
	preformatted = 'preformatted',
	network = 'network',

	// Deprecated
	info = 'info',
	error = 'error',
}

export enum ITestResponseLevel {
	log = 'log',
	info = 'info',
	success = 'success',
	warning = 'warning',
	error = 'error',
}

export enum ITestArtifactType {
	image = 'image',
	video = 'video',
	link = 'link',
}

export enum ITestArtifactTopic {
	viewport = 'viewport',
	element = 'element',
	full_page = 'full_page',
	lighthouse = 'lighthouse',
	lighthouse_flow = 'lighthouse_flow',
	full_test = 'full_test',
	thumbnail = 'thumbnail',
	control = 'control',
	actual = 'actual',
	diff = 'diff',
	diffAtoC = 'diffAtoC',
	diffCtoA = 'diffCtoA',
}

export type ITestResponseObject = {
	type: ITestResponseType
	level: ITestResponseLevel
	timestamp: string
	message: string | string[]
}

export type ITestArtifact = {
	key: string
	type: ITestArtifactType
	topic: ITestArtifactTopic
	name: string
	width?: number
	height?: number
}

export type ITestArtifactModal = {
	isActive?: boolean
	label?: string
	artifact?: ITestArtifact
}[]

// ? ---
// ?	Interfaces
// ? ---
export interface ITest {
	__typename: 'TestEntity'
	id: string
	attributes: {
		uid: string
		title: string
		hash: string
		status: ITestStatus
		createdAt: string
		updatedAt: string
		startedAt: string
		completedAt: string
		stepData: ITestStep[]
		accountData: IAccountOptions
		runId: string
		hasEmail: boolean
		runData: {
			runId: string
			triggerType: ITestRunTriggerType
			triggerName: string
			triggerEmail?: string
			triggerUserId?: string
			triggerLabel?: string
			values: { [key: string]: any }
		}
		flowId: string
		flowData: {
			flowId: string
			title: string
			video: boolean
			lighthouse: boolean
			stepTimeoutSeconds: number
			stopTestAfterFirstError: boolean
		}
		artifacts: {
			key: string
			type: ITestArtifactType
			topic: ITestArtifactTopic
			name: string
			width?: number
			height?: number
		}[]
		response: ITestResponseObject[]
		statusHistory: {
			status: ITestStatus
			startedAt: string
		}[]
	}
}

export interface ITestResponse {
	meta?: {
		pagination: {
			total: number
		}
	}
	data: ITest[]
}

export interface ITestQueryResponse {
	tests: ITestResponse
}

export interface ITestInput {
	id?: string
	attributes: {
		title?: string
		hash?: string
		status: ITestStatus.preview | ITestStatus.pending
		stepData?: ITestStep[]
		accountData?: IAccountOptions
		additionalTriggers?: string[]
		runId?: string
		runData?: IRunData
		flowId?: string
		flowData?: {
			flowId: string
			title: string
			video: boolean
			lighthouse: boolean
			stepTimeoutSeconds: number
			stopTestAfterFirstError: boolean
		}
	}
}

export interface ITestContextOptions {
	force?: boolean
	startImmediately?: boolean
	onSuccess?: (test?: ITest) => any
	onError?: (error?: any) => any
	onComplete?: (test?: ITest) => any
	[key: string]: any
}

export interface ITestContext {
	insert: (testInput: ITestInput, options?: ITestContextOptions) => Promise<ITest>
	update: (testInput: ITestInput, options?: ITestContextOptions) => Promise<ITest>
	clone: (test: ITest, options?: ITestContextOptions) => Promise<ITest>
	upsert: (testInput: ITestInput, options?: ITestContextOptions) => Promise<ITest>
	run: (test: ITest, options?: ITestContextOptions) => Promise<void>
	// clone: (test: ITest, options?: ITestContextOptions) => Promise<ITest>
	remove: (test: { id: string }, options?: ITestContextOptions) => Promise<void>
	openUpsert: (test?: Partial<ITest>, options?: ITestContextOptions) => Promise<void>
	closeUpsert: (options?: ITestContextOptions) => Promise<void>
	openViewArtifact: (data?: ITestArtifactModal) => Promise<void>
	closeViewArtifact: () => Promise<void>
	openViewArtifactScreenshots: (data?: ITest, options?: ITestContextOptions) => Promise<void>
	closeViewArtifactScreenshots: () => Promise<void>
	openViewInformation: (data?: ITest) => Promise<void>
	closeViewInformation: () => Promise<void>
}

// ? ---
// ?	Type Guards
// ? ---
export const isTest = (test: ITest): boolean => {
	return test.__typename === 'TestEntity'
}
