// ? ---
// ?	Imports
// ? ---
import * as React from 'react'

import { compact, includes, map } from 'lodash'

import { STANDARD_COMPARISON_TYPE_CONDITION } from 'globals/client/conditions'
import { FAILED_STATUS_OPTIONS } from 'globals/constants/selectOptions'
import {
	COMPARISON_TYPE_VALIDATION,
	ELEMENT_VALIDATION,
	STANDARD_COMPARISON_TYPE_CONDITIONAL_VALIDATION,
} from 'globals/constants/validations'
import { CHECK_COMPUTED_GROUP } from 'globals/constants/variantGroups'

import { formatComparisonType, formatElement, formatValue, sentenceElement, sentenceValue } from 'hooks/useNode'

import {
	INodeCheckComputedStyleOptions,
	INodeComparisonTypes,
	INodeRenderProps,
	INodeVariantTypes,
	IUseNode,
	IUseNodeConfig,
} from 'data/nodes'
import { ITestStepStatus } from 'data/tests'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.check_computed_style,
	isLive: true,
	isMenuOption: true,
	isPro: false,
	isAccessibility: false,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.variantType',
			type: 'variantType',
			label: 'Type',
			options: CHECK_COMPUTED_GROUP,
		},
		{
			property: 'attributes.element',
			type: 'element',
			label: 'Element',
			validation: ELEMENT_VALIDATION,
		},
		{
			property: 'attributes.value',
			type: 'dropdown',
			label: 'Property',
			options: map(INodeCheckComputedStyleOptions, (label, value) => {
				return {
					label,
					value,
				}
			}),
		},
		{
			property: 'attributes.comparisonType',
			type: 'comparisonType',
			defaultValue: 'equals',
			label: 'Comparison Type',
			validation: COMPARISON_TYPE_VALIDATION,
		},
		{
			property: 'attributes.value2',
			type: 'text-with-tags',
			label: 'Value',
			conditional: STANDARD_COMPARISON_TYPE_CONDITION,
			validation: STANDARD_COMPARISON_TYPE_CONDITIONAL_VALIDATION,
		},
		{
			property: 'attributes.config.failedStatus',
			type: 'button-group',
			label: 'Status on fail',
			defaultValue: ITestStepStatus.failed,
			options: FAILED_STATUS_OPTIONS,
		},
	],
	label: 'Check computed style',
	sentence: ({ comparisonType, element, isExample, value, value2 }: INodeRenderProps) => {
		return isExample
			? `Check computed style`
			: `Check ${sentenceElement(element)} computed style ${sentenceValue(value)} ${formatComparisonType(
					comparisonType
			  )} ${
					!includes([INodeComparisonTypes.hasValue, INodeComparisonTypes.notHasValue], comparisonType) &&
					sentenceValue(value2)
			  }`
	},
	family: INodeVariantTypes.check_computed_style.split('_')[0],
	type: INodeVariantTypes.check_computed_style.split('_')[1],
	subType: INodeVariantTypes.check_computed_style.split('_')[2],
	tags: compact([
		INodeVariantTypes.check_computed_style.split('_')[0],
		INodeVariantTypes.check_computed_style.split('_')[1],
		INodeVariantTypes.check_computed_style.split('_')[2],
		'test',
		'expect',
		'assert',
		'element',
		'computed',
		'css',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ comparisonType, element, isExample, value, value2 }: INodeRenderProps) => {
			return (
				<>
					{isExample ? (
						'Check style'
					) : (
						<>
							Check <strong>{formatElement(element)}</strong> computed style{' '}
							<strong>{formatValue(value)}</strong> <em>{formatComparisonType(comparisonType)}</em>
							{!includes(
								[INodeComparisonTypes.hasValue, INodeComparisonTypes.notHasValue],
								comparisonType
							) && (
								<>
									{' '}
									<strong>{formatValue(value2)}</strong>
								</>
							)}
						</>
					)}
				</>
			)
		},
	}
}
