// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import { ReactSortable } from 'react-sortablejs'
import { useQuery } from '@apollo/client'
import debug from 'debug'

import { event as gaEvent } from 'nextjs-google-analytics/dist/interactions/event'
import {
	Autocomplete,
	Box,
	CircularProgress,
	FormControl,
	Grid,
	IconButton,
	TextField,
	Typography,
	useTheme,
} from '@mui/material'
import Icon from '@mdi/react'
import { compact, filter, find, get, isEmpty, isEqual, isNil, map, reject, values } from 'lodash'

import { icons } from 'globals/constants/icons'
import { gaEventName } from 'globals/constants/integrations'
import { NODE_FAMILY } from 'globals/constants/labels'

import useNodes from 'hooks/useNodes'

import Node from 'components/Nodes/Node'
import { INode, INodeQueryResponse, INodeVariantTypes, IUseNodeField, QUERY_NODES_GROUPED } from 'data/nodes'

// ? ---
// ?	Types
// ? ---
type Props = {
	formik: any
	field: IUseNodeField
	parentNode: INode
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Dialogs-UpsertFields-SelectNodes'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function SelectNodes({ field, formik, parentNode }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const _nodes = useNodes()
	const theme = useTheme()
	const currentSelection = get(formik?.values, field.property, [])

	// * ---
	// *	State
	// * ---
	const [loaded, $loaded] = React.useState<boolean>(false)
	const [allNodes, $allNodes] = React.useState<INode[]>([])
	const [selectedNodes, $selectedNodes] = React.useState<INode[]>([])

	// * ---
	// *	Data
	// * ---
	const variables = formik.initialValues.id
		? {
				filters: {
					group: { id: { eq: formik.initialValues.id } },
				},
		  }
		: { filters: { group: { id: { notNull: true } } } }

	const {
		data: nodes,
		loading,
		refetch,
	} = useQuery<INodeQueryResponse>(QUERY_NODES_GROUPED, {
		variables,
		fetchPolicy: 'no-cache',
		onCompleted: (data) => {
			if (!loaded) {
				log('... initial load', data, currentSelection)
				$selectedNodes(compact(map(currentSelection, (id) => find(data.nodes.data, { id }))))
				$allNodes(data.nodes.data)
				$loaded(true)
			}
		},
	})

	// * ---
	// *	Effect: Update allNodes
	// * ---
	React.useEffect(() => {
		const temp = get(nodes, 'nodes.data', [])
		if (loaded && !isEqual(temp, allNodes)) {
			log('.. update allNodes')
			$allNodes(temp)
			$selectedNodes((currentValue) =>
				compact(map(currentValue, (selectedNode) => find(temp, { id: selectedNode.id })))
			)
		}
	}, [nodes, allNodes, loaded])

	// * ---
	// *	Effect: Update Formik
	// * ---
	React.useEffect(() => {
		log('.. update Formik')
		if (loaded) {
			formik.setFieldValue(field.property, map(selectedNodes, 'id'))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedNodes])

	// * ---
	// *	Method: Insert Node
	// * ---
	const insertNode = async (variantType: INodeVariantTypes) => {
		log('Insert Node', variantType)

		const node = await _nodes.insert(
			{ attributes: { variantType, group: parentNode.id } },
			{ useDefaultValues: true }
		)
		if (isNil(node) || isEmpty(node)) return

		log('new node', node)
		await refetch()
		$selectedNodes((currentValue) => [...currentValue, node])
	}

	// * ---
	// *	Method: Edit Node
	// * ---
	const editNode = async (node: INode) => {
		log('Edit Node', node)
		// gaEvent(gaEventName('node-group', 'edit', 'open', get(node, 'attributes.variantType', 'unknown type')))
		await _nodes.openUpsertChild(node, {
			onSuccess: () => {
				refetch()
				gaEvent(gaEventName('node-group', 'edit', 'save', get(node, 'attributes.variantType', 'unknown type')))
			},
		})
	}

	// * ---
	// *	Method: Remove Node
	// * ---
	const removeNode = (node: INode) => {
		$selectedNodes((currentValue) => reject(currentValue, { id: node.id }))
	}

	// * ---
	// *	Return
	// * ---
	return (
		<FormControl variant='outlined' fullWidth>
			<Typography
				component='legend'
				variant='caption'
				sx={{ ml: 0.75, mb: 0.5, fontWeight: 500, color: theme.palette.text.secondary }}
			>
				{field.label}
			</Typography>

			{loading || !loaded ? (
				<Box sx={{ textAlign: 'center' }}>
					<CircularProgress size={75} sx={{ margin: '0 auto' }} />
				</Box>
			) : (
				<>
					<Box
						sx={{
							mb: theme.spacing(1),
							'& .sortable-drag': {
								cursor: 'move !important',
								opacity: `0 !important`,
							},
							'& .sortable-chosen': {
								cursor: 'move !important',
								backgroundColor: theme.palette.background.default,
							},
							'& .sortable-ghost': {
								cursor: 'move !important',
								opacity: `1 !important`,
								backgroundColor: theme.palette.background.default,
							},
							'& .drag-item:first-of-type': {
								borderTop: `1px solid ${theme.palette.divider}`,
							},
						}}
					>
						<ReactSortable
							list={selectedNodes}
							setList={$selectedNodes}
							direction={'vertical'}
							handle={'.drag-handle'}
							draggable={'.drag-item'}
						>
							{map(selectedNodes, (node) => (
								<Grid
									container
									key={node.id}
									className={'drag-item'}
									direction='row'
									justifyContent='space-between'
									alignItems='center'
									sx={{
										pt: theme.spacing(0.5),
										pb: theme.spacing(0.5),
										backgroundColor: theme.palette.background.paper,
										borderBottom: `1px solid ${theme.palette.divider}`,
									}}
								>
									<Grid item className={'drag-handle'} sx={{ lineHeight: 0, p: 0.5, cursor: 'move' }}>
										<Icon path={icons.dragIcon} size={1} />
									</Grid>
									<Grid item xs>
										<Box
											sx={{
												container: 'node-wrapper / inline-size',
												lineHeight: 0,
											}}
										>
											<Node node={node} flat={true} showValidation={true} dynamicWidth={true} />
										</Box>
									</Grid>
									<Grid item>
										<IconButton data-test-id='vPcfth_QUI7TuCXm98MyT' onClick={() => editNode(node)}>
											<Icon path={icons.editIcon} size={1} color={theme.palette.text.secondary} />
										</IconButton>
									</Grid>
									<Grid item>
										<IconButton
											data-test-id='v4_OUOdxF-dfMNaDSrhRN'
											onClick={() => removeNode(node)}
										>
											<Icon
												path={icons.removeIcon}
												size={1}
												color={theme.palette.text.secondary}
											/>
										</IconButton>
									</Grid>
								</Grid>
							))}
						</ReactSortable>
					</Box>
					<Autocomplete
						data-test-id={`${namespace}--${field.property}-input`}
						fullWidth
						loading={loading}
						value={null}
						onChange={(event, node) => {
							node?.key && insertNode(node.key)
						}}
						blurOnSelect={true}
						getOptionLabel={(node) => node.key}
						options={filter(values(_nodes.allMenuNodes), (node) => node.hasInput && node.hasOutput)}
						groupBy={(node) => get(NODE_FAMILY, node.family, '')}
						renderInput={(params) => <TextField {...(params as any)} label={'Add a node'} />}
						renderOption={(props, node) => (
							<Box component='li' {...(props as any)} key={node.key}>
								<Node
									node={{ attributes: { variantType: node.key } } as INode}
									isExample={true}
									flat={true}
									dynamicWidth={true}
								/>
							</Box>
						)}
					/>
				</>
			)}
		</FormControl>
	)
}
