// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'
import { useFormik } from 'formik'
import { useAnimation } from 'framer-motion'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Grid, Stack } from '@mui/material'
import { grey } from '@mui/material/colors'
import Icon from '@mdi/react'
import { isEmpty } from 'lodash'

import { icons } from 'globals/constants/icons'
import { DIALOG_MEDIUM_WIDTH } from 'globals/constants/sizes'

import useAccount from 'hooks/useAccount'

import ShakeablePaperMedium from 'components/_Global/Dialogs/helpers/ShakeablePaperMedium'
import { Transition } from 'components/_Global/Dialogs/helpers/Transition'
import Field from 'components/_Global/Forms/Field'
import { IAccountTag, tagValidationSchema } from 'data/accounts'

// ? ---
// ?	Types
// ? ---
type Props = {
	state: boolean
	data: undefined | Partial<IAccountTag>
	options?: {
		[key: string]: any
	}
	close: () => void
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Tags-TagDialog'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function TagDialog({ close, data, options, state }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const _account = useAccount()
	const controls = useAnimation()

	// * ---
	// *	State
	// * ---
	const [saving, $saving] = React.useState(false)

	// * ---
	// *	Formik
	// * ---
	const formik = useFormik({
		validationSchema: tagValidationSchema,
		initialValues: {
			id: data?.id,
			label: data?.label || '',
		} as IAccountTag,
		onSubmit: async (values) => {
			$saving(true)
			log('onSubmit', values)
			await _account.tagUpsert(values, {
				onSuccess: (account) => {
					log('onSubmit onSuccess', { account, options })
					close()
					$saving(false)
					if (options?.onSuccess) options.onSuccess(account)
					if (options?.onComplete) options.onComplete(account)
				},
			})
		},
	})

	// * ---
	// *	Return: Good
	// * ---
	return (
		<Dialog
			open={state}
			TransitionComponent={Transition}
			sx={{
				'& .MuiDialog-paperFullWidth': {
					maxWidth: DIALOG_MEDIUM_WIDTH,
				},
			}}
			fullWidth={true}
			onClose={() => (formik.dirty ? controls.start('start') : close())}
			PaperComponent={ShakeablePaperMedium}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			PaperProps={{ controls }}
			aria-labelledby={namespace}
		>
			<form onSubmit={formik.handleSubmit}>
				<DialogTitle id={namespace} className={'dragHandle'}>
					{isEmpty(data?.id) ? 'Create Tag' : 'Update Tag'}
				</DialogTitle>
				<DialogContent>
					<Stack sx={{ pt: 2, maxHeight: '78vh' }}>
						<Field
							field={{
								property: 'label',
								type: 'text',
								label: 'Tag',
							}}
							formik={formik}
						/>
					</Stack>
				</DialogContent>
				<DialogActions>
					<Grid
						container
						direction='row'
						justifyContent='space-between'
						alignItems='center'
						spacing={1}
						sx={{ pl: 1, pr: 1 }}
					>
						<Grid item></Grid>
						<Grid item>
							<Button
								data-test-id='C_2WESp7CukLdGNYTqSfL'
								color={'secondary'}
								onClick={() => {
									close()
								}}
							>
								Cancel
							</Button>
						</Grid>
					</Grid>
					<Fab
						color='primary'
						aria-label='Save Schedule'
						type='submit'
						disabled={saving}
						sx={{
							backgroundColor: saving || !formik.isValid ? grey[400] : undefined,
						}}
					>
						<Icon path={saving ? icons.loadingIcon : icons.agreeIcon} size={1} spin={saving ? 1 : 0} />
					</Fab>
				</DialogActions>
			</form>
		</Dialog>
	)
}
