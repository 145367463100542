// ? ---
// ?	Imports
// ? ---
import omitDeep from 'omit-deep-lodash'

import { forEach, get, reject, unionBy } from 'lodash'

import { ITestStep } from 'data/tests/steps'
import { ITest } from 'data/tests/type'

// ? ---
// ?	Export
// ? ---
export const getTestCaseElements = (testCase: ITest): { [key: string]: any } => {
	// * Used Elements
	let usedElements: any[] = []
	forEach(get(testCase, 'attributes.stepData', {}), (step: ITestStep) => {
		usedElements = unionBy(
			usedElements,
			[
				{
					id: get(step.node, 'elementId', ''),
					title: get(step.node, 'elementTitle', ''),
					type: get(step.node, 'elementType', ''),
					selector: get(step.node, 'elementSelector', ''),
				},
				{
					id: get(step.node, 'element2Id', ''),
					title: get(step.node, 'element2Title', ''),
					type: get(step.node, 'element2Type', ''),
					selector: get(step.node, 'element2Selector', ''),
				},
			],
			'id'
		)
	})

	// * Return
	return omitDeep(reject(usedElements, { id: 0 }), 'id')
}
