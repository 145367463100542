// ? ---
// ?	Imports
// ? ---
import * as React from 'react'

// import debug from 'debug'
import { get } from 'lodash'

import Node from 'components/Nodes/Node'
import { IElementSelectorType } from 'data/elements'
import { INodeComparisonTypes, INodeVariantTypes } from 'data/nodes'
import { ITestStep } from 'data/tests/steps'

// ? ---
// ?	Constants
// ? ---
// const namespace = 'components-Tests-TestStepNode'
// const log = debug(`app:${namespace}`)

// ? ---
// ?	Props
// ? ---
type Props = {
	step: ITestStep
}

// ? ---
// ?	Component
// ? ---
export default function TestStepNode({ step }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	const node = step.node

	// * ---
	// *	Return: Good
	// * ---
	return (
		<Node
			flat={true}
			dynamicWidth={true}
			isMuted={true}
			node={{
				id: `${node.nodeId}`,
				__typename: 'NodeEntity',
				attributes: {
					variantType: get(node, 'variantType') as INodeVariantTypes,
					comparisonType: get(node, 'comparisonType') as INodeComparisonTypes,
					element: {
						data: {
							id: `${node.elementId}`,
							__typename: 'ElementEntity',
							attributes: {
								title: get(node, 'elementTitle', ''),
								type: get(node, 'elementType') as IElementSelectorType,
								selector: get(node, 'elementSelector', ''),
								nodes: {},
								nodes2: {},
							},
						},
					},
					element2: {
						data: {
							id: `${node.element2Id}`,
							__typename: 'ElementEntity',
							attributes: {
								title: get(node, 'element2Title', ''),
								type: get(node, 'element2Type') as IElementSelectorType,
								selector: get(node, 'element2Selector', ''),
								nodes: {},
								nodes2: {},
							},
						},
					},
					mfa: {
						data: {
							id: `${node.mfaId}`,
							__typename: 'MfaEntity',
							attributes: {
								title: get(node, 'mfaTitle', ''),
							},
						},
					},
					label: node.label || '',
					value: node.value || '',
					value2: node.value2 || '',
					value3: node.value3 || '',
					value4: node.value4 || '',
					value5: node.value5 || '',
					value6: node.value6 || '',
					value7: node.value7 || '',
					value8: node.value8 || '',
					value9: node.value9 || '',
					value10: node.value10 || '',
					value11: node.value11 || '',
					value12: node.value12 || '',
					value13: node.value13 || '',
					value14: node.value14 || '',
					value15: node.value15 || '',
					value16: node.value16 || '',
					value17: node.value17 || '',
					value18: node.value18 || '',
					value19: node.value19 || '',
					value20: node.value20 || '',
				},
			}}
		/>
	)
}
