// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import clsx from 'clsx'
import Color from 'color'

// import debug from 'debug'
import { Box, Typography, useTheme } from '@mui/material'
import Icon from '@mdi/react'
import { get } from 'lodash'

import { colors } from 'globals/constants/colors'
import { icons } from 'globals/constants/icons'
import { NODE_ICON_AREA_WIDTH, NODE_MAXIMUM_LABEL_WIDTH } from 'globals/constants/sizes'

import useNode from 'hooks/useNode'
import useThemeMode from 'hooks/useThemeMode'

import { INode, INodeVariantTypes, validationSchema } from 'data/nodes'

// ? ---
// ?	Constants
// ? ---
// const namespace = 'components-Nodes-Node'
// const log = debug(`app:${namespace}`)

// ? ---
// ?	Props
// ? ---
type Props = {
	node: INode
	flat?: boolean
	selected?: boolean
	isExample?: boolean
	disabled?: boolean
	dynamicWidth?: boolean
	showValidation?: boolean
	isMuted?: boolean
}

// ? ---
// ?	Component
// ? ---
export default function Node({
	disabled,
	dynamicWidth,
	flat,
	isExample,
	isMuted,
	node,
	selected,
	showValidation,
}: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	const theme = useTheme()
	const _theme = useThemeMode()
	const variantType = get(node, 'attributes.variantType', INodeVariantTypes.action_touch_single)
	const _node = useNode()
	const _currentNode = _node[variantType]
	const isSpecialGroup = variantType === INodeVariantTypes.special_group
	const isMultiLine = get(_currentNode, 'isMultiLine', false)

	// * ---
	// *	Color Shades
	// * ---
	const BORDER_RADIUS = 8
	const ERROR_BORDER_SHADE = 700

	const COLOR_TAG_BACKGROUND = _theme.conditional(
		colors[_currentNode.nodeStyle || _currentNode.family][700],
		colors[_currentNode.nodeStyle || _currentNode.family][800]
	)

	const COLOR_BACKGROUND = _theme.conditional(
		Color(colors[_currentNode.nodeStyle || _currentNode.family][600])
			.lighten(0.25)
			.desaturate(0.05)
			.string(),
		colors[_currentNode.nodeStyle || _currentNode.family][700]
	)

	const COLOR_TEXT = _theme.conditional(
		colors[_currentNode.nodeStyle || _currentNode.family][800],
		theme.palette.common.white
	)
	const COLOR_ICON = _theme.conditional(
		Color(colors[_currentNode.nodeStyle || _currentNode.family][900])
			.darken(0.2)
			.string(),
		theme.palette.common.white
	)

	const COLOR_LABEL_BACKGROUND = _theme.conditional(
		Color(colors[_currentNode.nodeStyle || _currentNode.family][100])
			.lighten(0.1)
			.string(),
		Color(colors[_currentNode.nodeStyle || _currentNode.family][900])
			.darken(0.2)
			.string()
	)

	// * ---
	// *	State
	// * ---
	const [isValid, $isValid] = React.useState(true)

	// * ---
	// *	Effect: isValid
	// * ---
	React.useEffect(() => {
		let abort = false
		const checkValid = async (nodeToValidate: INode, isTop: boolean) => {
			let tempIsValid = true

			if (nodeToValidate.attributes.variantType === INodeVariantTypes.special_group) {
				// ? Node Group - check each child to see if valid
				for (const childNode of nodeToValidate.attributes.nodes?.data || []) {
					const childValid = await checkValid(childNode, false)
					if (!childValid) {
						tempIsValid = false
						break
					}
				}
			}
			try {
				const variantType = get(nodeToValidate, 'attributes.variantType', INodeVariantTypes.action_touch_single)
				await validationSchema(_node[variantType]).validate({
					...nodeToValidate,
					attributes: {
						...nodeToValidate.attributes,
						element: get(nodeToValidate, 'attributes.element.data.id', ''),
						element2: get(nodeToValidate, 'attributes.element2.data.id', ''),
						mfa: get(nodeToValidate, 'attributes.mfa.data.id', ''),
					},
				})
			} catch (error) {
				tempIsValid = false
			}
			if (!abort && tempIsValid !== isValid && isTop) $isValid(tempIsValid)
			return tempIsValid
		}
		if ((!isExample && !flat) || showValidation) {
			checkValid(node, true)
		}
		return () => {
			abort = true
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [node, _node, isExample, flat, showValidation])

	// * ---
	// *	Return: Good
	// * ---
	return (
		<Box
			className={clsx({
				[variantType]: true,
				isSelected: selected,
				isPro: _currentNode.isPro,
				isValid,
				isInvalid: !isValid,
				isFlat: flat,
				isExample,
				inView: (!isExample && !flat) || showValidation,
			})}
			sx={{
				display: 'inline-flex',
				overflow: 'auto',
				borderRadius: `${BORDER_RADIUS}px`,
				borderStyle: 'solid',
				borderWidth: 3,
				borderColor: COLOR_BACKGROUND,

				boxShadow: flat || disabled ? 'none' : theme.shadows[0],

				backgroundColor: COLOR_BACKGROUND,

				'&.isSelected': {
					boxShadow: `0 0 0 1px ${colors[_currentNode.nodeStyle || _currentNode.family][300]}, 0 0 6px 0 ${
						colors[_currentNode.nodeStyle || _currentNode.family][300]
					}`,
				},
				'&.inView.isInvalid': {
					borderColor: colors.error[ERROR_BORDER_SHADE],
					boxShadow: `0 0 0 1px ${colors.error[ERROR_BORDER_SHADE]}, 0 0 1px 0 ${colors.error[ERROR_BORDER_SHADE]}`,

					'&.isSelected': {
						boxShadow: `0 0 0 1px ${colors.error[ERROR_BORDER_SHADE]}, 0 0 6px 0 ${colors.error[ERROR_BORDER_SHADE]}`,
					},
				},
				'&:active': {
					boxShadow: flat || disabled ? 'none' : theme.shadows[1],
				},
			}}
		>
			<Box
				className={clsx({
					[variantType]: true,
				})}
				sx={{
					position: 'relative',
					width: NODE_ICON_AREA_WIDTH,
					flexGrow: 0,
					textAlign: 'center',
					pt: 0,
					color: COLOR_ICON,
					backgroundColor: COLOR_BACKGROUND,
				}}
			>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						lineHeight: 0,
						transform: 'translateX(-50%) translateY(-50%)',
					}}
				>
					<Icon path={icons[`${variantType}Icon`]} size={1} />
				</Box>
			</Box>
			<Box
				className={clsx({
					[variantType]: true,
				})}
				sx={{
					flexGrow: 1,
					zIndex: 1000,
					pointerEvents: 'none',
					borderTopLeftRadius: `${BORDER_RADIUS}px`,
					borderBottomLeftRadius: `${BORDER_RADIUS}px`,
					backgroundColor: COLOR_LABEL_BACKGROUND,
				}}
			>
				<Typography
					variant={'body2'}
					sx={{
						fontWeight: isSpecialGroup && !isMuted ? 700 : 500,
						letterSpacing: `-0.3px`,
						display: 'inline-block',
						textOverflow: 'ellipsis',
						whiteSpace: isMultiLine ? 'pre-line' : 'nowrap',
						overflow: 'hidden',
						verticalAlign: 'top',
						wordBreak: 'break-all',

						maxWidth: dynamicWidth
							? `calc(100cqw - ${NODE_ICON_AREA_WIDTH}px - ${theme.spacing(1.4)})`
							: NODE_MAXIMUM_LABEL_WIDTH,

						color: COLOR_TEXT,

						pt: theme.spacing(0.3),
						pb: theme.spacing(0.3),
						pl: theme.spacing(0.5),
						pr: theme.spacing(0.5),

						'& em': {
							fontStyle: 'normal',
							marginLeft: theme.spacing(0.1),
							marginRight: theme.spacing(0.1),
						},
						'& strong': {
							fontWeight: 700,
							marginLeft: theme.spacing(0.1),
							marginRight: theme.spacing(0.1),
						},
						'& .node-value-tag': {
							p: `1px 3px 2px`,
							ml: `2px`,
							borderRadius: `${BORDER_RADIUS / 2}px`,
							color: 'white',
							backgroundColor: COLOR_TAG_BACKGROUND,
						},
					}}
				>
					{_currentNode.render({
						isExample,
						label: node.attributes.label,
						element: node.attributes.element?.data?.attributes?.title,
						element2: node.attributes.element2?.data?.attributes?.title,
						mfa: node.attributes.mfa?.data?.attributes?.title,
						comparisonType: node.attributes.comparisonType,
						value: node.attributes.value,
						value2: node.attributes.value2,
						value3: node.attributes.value3,
						value4: node.attributes.value4,
						value5: node.attributes.value5,
						value6: node.attributes.value6,
						value7: node.attributes.value7,
						value8: node.attributes.value8,
						value9: node.attributes.value9,
						value10: node.attributes.value10,
						value11: node.attributes.value11,
						value12: node.attributes.value12,
						value13: node.attributes.value13,
						value14: node.attributes.value14,
						value15: node.attributes.value15,
						value16: node.attributes.value16,
						value17: node.attributes.value17,
						value18: node.attributes.value18,
						value19: node.attributes.value19,
						value20: node.attributes.value20,
					})}
				</Typography>
			</Box>
		</Box>
	)
}
