// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter'
import debug from 'debug'
import { flatten } from 'flat'

import { Box, useTheme } from '@mui/material'
import { forEach, includes, isEmpty } from 'lodash'

import syntaxHighlighting from 'globals/client/syntaxHighlighting'

import useAccount from 'hooks/useAccount'

import { ITest } from 'data/tests'
import { getTestCaseValues } from 'data/tests/helpers/getTestCaseValues'

// ? ---
// ?	Types
// ? ---
type Props = {
	data?: ITest
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Dialogs-TestInformationDialogValues'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function TestInformationDialogValues({ data }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const _account = useAccount()
	const theme = useTheme()

	// * ---
	// *	Callback: Test Values
	// * ---
	const testValues = React.useCallback(() => {
		if (!data || !_account.data) return {}
		const flatValues = flatten(getTestCaseValues(data, _account.data)) as unknown as { [key: string]: any }
		const result: { [key: string]: any } = {}
		forEach(flatValues, (value, key: string) => {
			if (includes(key, '.')) {
				const [root, ...path] = key.split('.')
				result[`[${root}].${path.join('.')}`] = value
			} else {
				result[`[${key}]`] = value
			}
		})
		return result
	}, [data, _account.data])

	// ! ---
	// !	Return: No values
	// ! ---
	if (isEmpty(testValues())) {
		return (
			<Box
				sx={{
					minHeight: '100%',
					color: theme.palette.text.secondary,
					p: 1,
					fontFamily: 'monospace',
					fontWeight: 500,
					fontSize: '0.8rem',
				}}
			>
				No values used in test case.
			</Box>
		)
	}

	// * ---
	// *	Return: Good
	// * ---
	return (
		<Box
			sx={{
				fontSize: '80%',
				['& pre']: {
					m: `0 !important`,
				},
				['& pre code']: {
					whiteSpace: `break-spaces !important`,
					overflowWrap: `anywhere !important`,
				},
			}}
		>
			{/* @ts-expect-error Server Component */}
			<SyntaxHighlighter
				language='json'
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				style={syntaxHighlighting(theme)}
			>
				{JSON.stringify(testValues(), null, 2)}
			</SyntaxHighlighter>
		</Box>
	)
}
