// ? ---
// ?	Imports
// ? ---
import { includes } from 'lodash'

import { INodeComparisonTypes } from 'data/nodes'

// ? ---
// ?	Exports
// ? ---
export const STANDARD_COMPARISON_TYPE_CONDITION = ({ values }: { values: { [key: string]: any } }) => {
	return !includes(
		[INodeComparisonTypes.hasValue, INodeComparisonTypes.notHasValue],
		values.attributes.comparisonType
	)
}
