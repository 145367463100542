// ? ---
// ?	Imports
// ? ---
import axios from 'axios'
import debug from 'debug'

// ? ---
// ?	Constants
// ? ---
const namespace = 'data-tests-helpers-getSignedArtifact'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Export
// ? ---
export const getSignedArtifact = async (key: string): Promise<string | false> =>
	await axios
		.get(`/api/artifacts/${key}`)
		.then((response) => response.data)
		.catch((err) => {
			log('!! getSignedUrl failed', err)
			return false
		})
