// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'
import useInterval from 'use-interval'

import { Alert, Box, Grid, LinearProgress, Typography, useTheme } from '@mui/material'
import Icon from '@mdi/react'
import { floor, isEmpty, isNull, round } from 'lodash'

import { colors } from 'globals/constants/colors'
import { icons } from 'globals/constants/icons'

import useMfas from 'hooks/useMfas'
import useThemeMode from 'hooks/useThemeMode'

import { IMfaGenerateResponse } from 'data/mfas'

// ? ---
// ?	Types
// ? ---
type Props = {
	secret?: string
	mfaId?: string
	onError?: () => void
	onSuccess?: (response: IMfaGenerateResponse) => void
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-MFA-MfaCode'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function MfaCode({ mfaId, onError, onSuccess, secret }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const theme = useTheme()
	const _theme = useThemeMode()
	const _mfas = useMfas()

	// * ---
	// *	State
	// * ---
	const [error, $error] = React.useState<null | string>(null)
	const [busy, $busy] = React.useState(false)
	const [token, $token] = React.useState<null | string>(null)
	const [timeRemaining, $timeRemaining] = React.useState(0)

	// * ---
	// *	Method: Generate MFA Code
	// * ---
	const generateCode = async () => {
		// ! Drop out if busy, secret is empty, or not locked
		if (busy || isEmpty(secret || mfaId)) return
		$busy(true)

		const response = await _mfas.generateCode(secret ? { secret } : { id: mfaId })

		// ! Drop out if generate failed
		if (isNull(response)) {
			$error('Failed to generate MFA code, please check your secret and try again.')
			if (onError) onError()
			$timeRemaining(30)
			$busy(false)
			return
		}

		if (onSuccess) onSuccess(response)
		$error(null)
		$token(response.token)
		$timeRemaining(response.timeRemaining)
		$busy(false)
	}

	// * ---
	// *	Interval: Count down time remaining
	// * ---
	useInterval(
		() => {
			if (timeRemaining <= 1 && timeRemaining === floor(timeRemaining)) {
				generateCode()
			}
			$timeRemaining(round(timeRemaining - 0.5, 1))
		},
		!busy && (secret || mfaId) ? 500 : null
	)

	// ! ---
	// !  Return: Bad - missing secret or mfaId
	// ! ---
	if (!secret && !mfaId) return <></>

	// ! ---
	// !  Return: Bad - error
	// ! ---
	if (error)
		return (
			<Alert
				severity='error'
				sx={{ border: _theme.conditional(`1px solid ${theme.palette.error.dark}`, undefined) }}
			>
				{error}
			</Alert>
		)

	// * ---
	// *  Return: Good
	// * ---
	return (
		<Box>
			{token ? (
				<Box>
					<Box
						sx={{
							borderTop: `1px solid ${theme.palette.divider}`,
							borderLeft: `1px solid ${theme.palette.divider}`,
							borderRight: `1px solid ${theme.palette.divider}`,
							backgroundColor: theme.palette.background.paper,
							borderTopLeftRadius: theme.shape.borderRadius,
							borderTopRightRadius: theme.shape.borderRadius,
							fontSize: '60px',
							lineHeight: 1.2,
							py: 1,
							px: 1,
							textAlign: 'center',
							letterSpacing: 20,
						}}
					>
						{token}
					</Box>
					<LinearProgress
						variant='determinate'
						value={(timeRemaining / 30) * 100}
						color={timeRemaining < 5 ? 'error' : timeRemaining < 10 ? 'warning' : 'primary'}
					/>
					<Typography variant='caption'>
						Enter this code in your MFA provider to confirm the secret ({round(timeRemaining)}s)
					</Typography>
				</Box>
			) : busy ? (
				<Grid container direction='row' justifyContent='center' alignItems='center'>
					<Grid item>
						<Icon path={icons.loadingIcon} size={3} spin={1} color={colors.grey[400]} />
					</Grid>
				</Grid>
			) : (
				<></>
			)}
		</Box>
	)
}
