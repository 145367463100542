// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter'
import debug from 'debug'

import { Box } from '@mui/material'
import { isEmpty } from 'lodash'

import syntaxHighlighting from 'globals/client/syntaxHighlighting'
import { colors } from 'globals/constants/colors'

import useThemeMode from 'hooks/useThemeMode'

import { ITest } from 'data/tests'
import { getTestCaseElements } from 'data/tests/helpers/getTestCaseElements'

// ? ---
// ?	Types
// ? ---
type Props = {
	data?: ITest
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Dialogs-TestInformationDialogElements'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function TestInformationDialogElements({ data }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const theme = useThemeMode()

	// * ---
	// *	Callback: Test Values
	// * ---
	const testValues = React.useCallback(() => {
		if (!data) return {}
		return getTestCaseElements(data)
	}, [data])

	// ! ---
	// !	Return: No values
	// ! ---
	if (isEmpty(testValues())) {
		return (
			<Box
				sx={{
					minHeight: '100%',
					backgroundColor: '#f5f5f5',
					color: colors.grey[800],
					p: 1,
					fontFamily: 'monospace',
					fontWeight: 500,
					fontSize: '0.8rem',
				}}
			>
				No elements used in test case.
			</Box>
		)
	}

	// * ---
	// *	Return: Good
	// * ---
	return (
		<Box
			sx={{
				fontSize: '80%',
				['& pre']: {
					m: `0 !important`,
				},
				['& pre code']: {
					whiteSpace: `break-spaces !important`,
					overflowWrap: `anywhere !important`,
				},
			}}
		>
			{/* @ts-expect-error Server Component */}
			<SyntaxHighlighter
				language='json'
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				style={syntaxHighlighting(theme)}
			>
				{JSON.stringify(testValues(), null, 2)}
			</SyntaxHighlighter>
		</Box>
	)
}
