// ? ---
// ?	Imports
// ? ---

// ? ---
// ?	Export
// ? ---
export const KEYS = [
	{ value: 'Enter', label: 'Enter' },
	{ value: 'Return', label: 'Return' },
	{ value: 'Tab', label: 'Tab' },
	{ value: 'Backspace', label: 'Backspace' },
	{ value: 'Delete', label: 'Delete' },
	{ value: 'Escape', label: 'Escape' },
	{ value: 'Up', label: '↑' },
	{ value: 'Down', label: '↓' },
	{ value: 'Left', label: '←' },
	{ value: 'Right', label: '→' },
	{ value: 'SelectAll', label: 'Select all' },
	{ value: 'Copy', label: 'Copy' },
	{ value: 'Paste', label: 'Paste' },
	{ value: 'Undo', label: 'Undo' },
]
