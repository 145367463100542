// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import { useLazyQuery } from '@apollo/client'
import MixedTags from '@yaireo/tagify/dist/react.tagify'
import debug from 'debug'

import '@yaireo/tagify/dist/tagify.css'

import Link from 'next/link'
import { useRouter } from 'next/router'
import { Box, FormControl, FormHelperText, Grid, IconButton, useTheme } from '@mui/material'
import Icon from '@mdi/react'
import { get, isArray } from 'lodash'

import { PLAIN_LINK_STYLE, TAGIFY_STYLE_WITH_HELPER } from 'globals/client/styles'
import { icons } from 'globals/constants/icons'
import { VALUES_URL } from 'globals/constants/urls'
import { GLOBAL_VALUES } from 'globals/constants/valueStore'

import useFlow from 'hooks/useFlow'

import { IFlowQueryResponse, QUERY_FLOWS } from 'data/flows'

// ? ---
// ?	Types
// ? ---
type Props = {
	label: string
	name: string
	value: string
	onChange: (event: any) => void
	hasError?: boolean
	guide?: boolean
	helperMessage?: string
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Forms-Fields-Partials-TextWithTagsInput'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function TextWithTagsInput({
	hasError,
	helperMessage,
	label,
	name,
	onChange,
	value,
}: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const theme = useTheme()
	const _flow = useFlow()
	const router = useRouter()

	// * ---
	// *	Ref
	// * ---
	const tagifyRef = React.useRef<Tagify<{ value: string; example?: string | undefined }> | undefined>()

	// * ---
	// *	State
	// * ---
	const [loaded, $loaded] = React.useState(false)
	const [disabled, $disabled] = React.useState(true)

	// * ---
	// *	Current Flow
	// * ---
	const [loadFlow] = useLazyQuery<IFlowQueryResponse>(QUERY_FLOWS, {
		fetchPolicy: 'network-only',
	})

	// * ---
	// *	Effect: Load Flow Values
	// * ---
	React.useEffect(() => {
		log('..')
		const load = async (flowId: string) => {
			const flowResponse = await loadFlow({
				variables: {
					filters: {
						id: { eq: flowId },
					},
				},
			})
			log('loadFlow flowResponse', flowResponse)
			if (flowResponse.data?.flows.data[0] && tagifyRef.current) {
				tagifyRef.current.settings.whitelist = _flow.valuesInStore(flowResponse.data?.flows.data[0])
			}
			$loaded(true)
		}

		if (router.query.id && !loaded) {
			const flowId = isArray(router.query?.id) ? router.query.id[0] : router.query?.id
			load(flowId)
		}
		$loaded(true)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// * ---
	// *	Return
	// * ---
	return (
		<FormControl variant='outlined' fullWidth data-field={name}>
			<Box sx={TAGIFY_STYLE_WITH_HELPER(theme)}>
				<fieldset>
					<legend>{label}</legend>

					<Grid container direction='row' justifyContent='flex-start' alignItems='center' spacing={0}>
						<Grid item sx={{ px: 0.5 }}>
							<IconButton
								id={'dynamic-values-button'}
								data-test-id='agG27f7cWDSiwxYVDzLhn'
								aria-label='Dynamic Values'
								size='small'
								sx={{ color: theme.palette.secondary.main }}
								disabled={disabled}
								onClick={() => {
									tagifyRef.current?.dropdown.hide()
									const input = document.querySelector(`[data-field='${name}'] [contenteditable]`)

									// @ts-expect-error focus works but doesn't appear on type
									if (input) input.focus()

									tagifyRef.current?.dropdown.show()

									const dropdown = document.querySelector(`.tagify__dropdown`)

									// @ts-expect-error focus works but doesn't appear on type
									if (dropdown) setTimeout(() => dropdown.focus(), 500)
								}}
							>
								<Icon path={icons.valueStoreIcon} size={1} />
							</IconButton>
						</Grid>

						<Grid data-test-id='Nim1RBMsB926HcIrEUMP2' item xs onClick={() => $disabled(false)}>
							{/* @ts-expect-error Server Component */}
							<MixedTags
								data-test-id={`${namespace}`}
								name={name}
								tagifyRef={tagifyRef}
								settings={{
									mode: 'mix',
									pattern: /\$/,
									placeholder: '',
									whitelist: GLOBAL_VALUES,
									onChangeAfterBlur: false,
									duplicates: true,
									mixTagsInterpolator: ['-{{-', '-}}-'],
									dropdown: {
										enabled: 0,
										maxItems: 100,
										position: 'text',
										includeSelectedTags: true,
										mapValueTo: (item: { value: string; example?: string }) =>
											item.example ? `${item.value} ( eg. ${item.example} )` : item.value,
									},
									callbacks: {
										focus: () => $disabled(false),
										click: () => $disabled(false),
										keydown: () => $disabled(false),
										'dropdown:select': () => $disabled(true),
										blur: (event) =>
											get(event, 'detail.relatedTarget.id') !== 'dynamic-values-button' &&
											$disabled(true),
									},
									mixMode: {
										insertAfterTag: '',
									},
								}}
								onChange={onChange}
								defaultValue={value}
							/>
						</Grid>
					</Grid>
				</fieldset>
				{helperMessage || hasError ? (
					<FormHelperText error={hasError}>{helperMessage || 'This field is required'}</FormHelperText>
				) : (
					<FormHelperText>
						Insert{' '}
						<Link
							data-test-id={'dynamic-values-link'}
							href={VALUES_URL}
							target={'_blank'}
							style={{
								...(PLAIN_LINK_STYLE(theme) as any),
								textDecoration: 'underline',
							}}
						>
							dynamic values
						</Link>{' '}
						by typing <strong>$</strong>.
					</FormHelperText>
				)}
			</Box>
		</FormControl>
	)
}
