// ? ---
// ?	Imports
// ? ---
import * as React from 'react'

import { compact, constant } from 'lodash'

import { BROWSER_BASIC_GROUP } from 'globals/constants/variantGroups'

import { INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.action_goTo_reload,
	isLive: true,
	isMenuOption: true,
	isPro: false,
	isAccessibility: true,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.variantType',
			type: 'variantType',
			label: 'Type',
			options: BROWSER_BASIC_GROUP,
		},
	],
	label: 'Reload',
	sentence: constant('Reload browser'),
	family: 'browser',
	type: INodeVariantTypes.action_goTo_reload.split('_')[1],
	subType: INodeVariantTypes.action_goTo_reload.split('_')[2],
	tags: compact([
		INodeVariantTypes.action_goTo_reload.split('_')[0],
		INodeVariantTypes.action_goTo_reload.split('_')[1],
		INodeVariantTypes.action_goTo_reload.split('_')[2],
		'refresh',
		'f5',
		'browser',
		'open',
		'navigate',
		'website',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: () => {
			return <>Reload</>
		},
	}
}
