//
// ? ---
// ?	Imports
// ? ---
import * as React from 'react'

import { IOpenDeleteConfirmation, IOpenMessage, IOpenSuggestReload } from 'globals/client/types'

import {
	IAccount,
	IAccountContext,
	IAccountTagContext,
	IAccountUsers,
	IAccountValueContext,
	IAccountWebhookKeyContext,
} from 'data/accounts'
import { IElementContext } from 'data/elements'
import { IEventContext } from 'data/events'
import { IFlowContext } from 'data/flows'
import { IMfaContext } from 'data/mfas'
import { INodeContext } from 'data/nodes'
import { IRecipeContext } from 'data/recipes'
import { IScheduleContext } from 'data/schedules'
import { ITestContext } from 'data/tests'

// ? ---
// ?	Types
// ? ---
export interface IAppDataContext {
	version: string
	title: string
	$title: (value: string) => void
	menuLibrary: boolean
	$menuLibrary: (value: boolean) => void
	reload?: any
	reloadNodesMenu?: any
	save?: any
	// saving: boolean
	// $saving: (value: boolean) => void
	run?: any
	preview?: any
	openMessage: IOpenMessage
	openDeleteConfirmation: IOpenDeleteConfirmation
	openSuggestReload: IOpenSuggestReload
	busy: {
		tasks: { [key: string]: { startedAt: string; isBlocking: boolean; label: string } | undefined } | undefined
		hasTask: (task: string) => boolean
		addTask: (task: string, options?: { isBlocking?: boolean }) => void
		removeTask: (task: string) => void
	}
	account: IAccount | null
	accountUsers: IAccountUsers
	accountRefetch: any
	accountValues: {
		openUpsert: IAccountValueContext['openUpsert']
		closeUpsert: IAccountValueContext['closeUpsert']
	}
	accountWebhookKeys: {
		openUpsert: IAccountWebhookKeyContext['openUpsert']
		closeUpsert: IAccountWebhookKeyContext['closeUpsert']
	}
	accountMinutesRequired: {
		openUpsert: IAccountContext['openMinutesRequired']
		closeUpsert: IAccountContext['closeMinutesRequired']
	}
	onlyAccountOwners: {
		open: IAccountContext['openOnlyAccountOwners']
		close: IAccountContext['closeOnlyAccountOwners']
	}
	elements: {
		openUpsert: IElementContext['openUpsert']
		closeUpsert: IElementContext['closeUpsert']
	}
	flows: {
		openUpsert: IFlowContext['openUpsert']
		closeUpsert: IFlowContext['closeUpsert']
	}
	events: {
		openUpsert: IEventContext['openUpsert']
		closeUpsert: IEventContext['closeUpsert']
	}
	schedules: {
		openUpsert: IScheduleContext['openUpsert']
		closeUpsert: IScheduleContext['closeUpsert']
	}
	recipes: {
		openUpsert: IRecipeContext['openUpsert']
		closeUpsert: IRecipeContext['closeUpsert']
	}
	mfas: {
		openUpsert: IMfaContext['openUpsert']
		closeUpsert: IMfaContext['closeUpsert']
		openCode: IMfaContext['openCode']
		closeCode: IMfaContext['closeCode']
	}
	tags: {
		openUpsert: IAccountTagContext['openUpsert']
		closeUpsert: IAccountTagContext['closeUpsert']
	}
	nodes: {
		clone?: any
		openUpsert: INodeContext['openUpsert']
		closeUpsert: INodeContext['closeUpsert']
		openUpsertChild: INodeContext['openUpsert']
		closeUpsertChild: INodeContext['closeUpsert']
	}
	tests: {
		openViewArtifact: ITestContext['openViewArtifact']
		closeViewArtifact: ITestContext['closeViewArtifact']
		openViewArtifactScreenshots: ITestContext['openViewArtifactScreenshots']
		closeViewArtifactScreenshots: ITestContext['closeViewArtifactScreenshots']
		openViewInformation: ITestContext['openViewInformation']
		closeViewInformation: ITestContext['openViewInformation']
	}
}

// ? ---
// ?	Constants
// ? ---
const appDataInitialContext: IAppDataContext = {} as IAppDataContext

// ? ---
// ?	Export
// ? ---
export const AppDataContext = React.createContext(appDataInitialContext)
