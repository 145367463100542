// ? ---
// ?	Fragment
// ? ---
import gql from 'graphql-tag'

import { MAX_ITEMS_PER_PAGE } from 'globals/constants/api'

export const SCHEDULE_FRAGMENT = `
	fragment scheduleFragment on ScheduleEntity {
		id
		attributes {
			title
			selection
			withAll
			withoutAny
			schedule
			failureCount
			status
			recipe {
				data {
					id
					attributes {
						title
					}
				}
			}
		}
	}
`

// ? ---
// ?	Queries
// ? ---
export const QUERY_SCHEDULES = gql(/* GraphQL */ `
	${SCHEDULE_FRAGMENT}
	query query_schedules_main($filters: ScheduleFiltersInput!) {
		schedules(filters: $filters, pagination: { pageSize: ${MAX_ITEMS_PER_PAGE} }, sort: "createdAt:desc") {
			data {
				...scheduleFragment
			}
		}
	}
`)

// ? ---
// ?	Mutations
// ? --
export const ADD_SCHEDULE = gql(/* GraphQL */ `
	mutation create_schedule($data: ScheduleInput!) {
		createSchedule(data: $data) {
			data {
				id
			}
		}
	}
`)

export const EDIT_SCHEDULE = gql(/* GraphQL */ `
	${SCHEDULE_FRAGMENT}
	mutation update_schedule($id: ID!, $data: ScheduleInput!) {
		updateSchedule(id: $id, data: $data) {
			data {
				...scheduleFragment
			}
		}
	}
`)

export const NUKE_SCHEDULE = gql(/* GraphQL */ `
	mutation delete_schedule($id: ID!) {
		deleteSchedule(id: $id) {
			data {
				id
			}
		}
	}
`)
