// ? ---
// ?	Imports
// ? ---
import * as React from 'react'

import { compact } from 'lodash'

import { ELEMENT_VALIDATION, MFA_VALIDATION } from 'globals/constants/validations'

import { formatElement, formatMFA, sentenceElement, sentenceMFA } from 'hooks/useNode'

import { INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.action_input_setMFA,
	isLive: true,
	isMenuOption: true,
	isPro: false,
	isAccessibility: true,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.mfa',
			type: 'mfa',
			label: 'MFA Key',
			validation: MFA_VALIDATION,
		},
		{
			property: 'attributes.element',
			type: 'element',
			label: 'Element',
			validation: ELEMENT_VALIDATION,
		},
	],
	label: 'Set MFA',
	sentence: ({ element, isExample, mfa }: INodeRenderProps) => {
		return isExample ? `Set MFA` : `Set ${sentenceElement(element)} to ${sentenceMFA(mfa)} MFA Token`
	},
	family: INodeVariantTypes.action_input_setMFA.split('_')[0],
	type: INodeVariantTypes.action_input_setMFA.split('_')[1],
	subType: INodeVariantTypes.action_input_setMFA.split('_')[2],
	tags: compact([
		INodeVariantTypes.action_input_setMFA.split('_')[0],
		INodeVariantTypes.action_input_setMFA.split('_')[1],
		INodeVariantTypes.action_input_setMFA.split('_')[2],
		'fill',
		'type',
		'keyboard',
		'text',
		'set',
		'value',
		'passcode',
		'secret',
		'key',
		'mfa',
		'2fa',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ element, isExample, mfa }: INodeRenderProps) => {
			return (
				<>
					{isExample ? (
						<>Set MFA</>
					) : (
						<>
							Set <strong>{formatElement(element)}</strong> to <strong>{formatMFA(mfa)}</strong> MFA Token
						</>
					)}
				</>
			)
		},
	}
}
