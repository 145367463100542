// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import ReactPlayer from 'react-player/file'
import debug from 'debug'

import { ITestArtifact } from 'data/tests'
import { getSignedArtifact } from 'data/tests/helpers/getSignedArtifact'

// ? ---
// ?	Types
// ? ---
type Props = {
	data: ITestArtifact
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Dialogs-ArtifactDialogVideo'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function ArtifactDialogVideo({ data }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')

	// * ---
	// *	State
	// * ---
	const [signedUrl, $signedUrl] = React.useState<string | undefined>(undefined)

	// * ---
	// *	Effect: Get Signed URL
	// * ---
	React.useEffect(() => {
		const getSignedUrl = async (key: string) => {
			const temp = await getSignedArtifact(key)
			if (temp) {
				$signedUrl(temp)
			}
		}
		if (!signedUrl && data?.key) {
			getSignedUrl(data.key)
		}
	}, [data, signedUrl])

	// * ---
	// *	Return: Good
	// * ---
	return (
		<>
			{signedUrl && (
				<ReactPlayer
					controls
					pip={false}
					stopOnUnmount={true}
					playing={true}
					playsinline={true}
					playbackRate={1.5}
					muted={true}
					width={'100%'}
					height={'100%'}
					url={signedUrl}
					config={{
						attributes: {
							autoplay: true,
							preload: 'auto',
						},
					}}
				/>
			)}
		</>
	)
}
