// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import * as yup from 'yup'

import { compact, isEmpty, isString } from 'lodash'

import { VALUE_REGEX } from 'globals/constants/valueStore'
import { ACTION_GOTO_GROUP } from 'globals/constants/variantGroups'

import { formatValue, sentenceValue } from 'hooks/useNode'

import { INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.action_goTo_relative,
	isLive: true,
	isMenuOption: true,
	isPro: false,
	isAccessibility: true,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.variantType',
			type: 'variantType',
			label: 'Type',
			options: ACTION_GOTO_GROUP,
		},
		{
			property: 'attributes.value',
			type: 'text-with-tags',
			defaultValue: '/',
			label: 'Path',
			validation: yup.string().test('is-valid-path', 'Relative paths must start with "/"', (value: any) => {
				if (isString(value) && !isEmpty(value)) {
					return value.match(VALUE_REGEX) ? true : /^\//gim.test(value)
				}
				return false
			}),
		},
	],
	label: 'Go to relative path',
	sentence: ({ isExample, value }: INodeRenderProps) => {
		return isExample ? `Go to relative path` : `Go to ${sentenceValue(value)}`
	},
	family: 'browser',
	type: INodeVariantTypes.action_goTo_relative.split('_')[1],
	subType: INodeVariantTypes.action_goTo_relative.split('_')[2],
	tags: compact([
		INodeVariantTypes.action_goTo_relative.split('_')[0],
		INodeVariantTypes.action_goTo_relative.split('_')[1],
		INodeVariantTypes.action_goTo_relative.split('_')[2],
		'goto',
		'go',
		'to',
		'path',
		'browser',
		'open',
		'navigate',
		'website',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ isExample, value }: INodeRenderProps) => {
			return <>Go to {!isExample && <strong>{formatValue(value)}</strong>}</>
		},
	}
}
