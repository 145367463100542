// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'

import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Tab, Tabs, useTheme } from '@mui/material'
import Icon from '@mdi/react'
import { findIndex, map } from 'lodash'

import { icons } from 'globals/constants/icons'

import ArtifactDialogImage from 'components/_Global/Dialogs/ArtifactDialogImage'
import ArtifactDialogVideo from 'components/_Global/Dialogs/ArtifactDialogVideo'
import { Transition } from 'components/_Global/Dialogs/helpers/Transition'
import { ITestArtifactModal, ITestArtifactType } from 'data/tests'

// ? ---
// ?	Types
// ? ---
type Props = {
	state: boolean
	data?: ITestArtifactModal
	options?: {
		[key: string]: any
	}
	close: () => void
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Dialogs-ArtifactDialog'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function ArtifactDialog({ close, data, state }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const theme = useTheme()

	// * ---
	// *	State
	// * ---
	const [activeTab, $activeTab] = React.useState(findIndex(data, { isActive: true }) || 0)

	// * ---
	// *	Callback: Title
	// * ---
	const getTitle = React.useCallback(() => {
		if (data && data.length === 1 && data[0].artifact?.name) return data[0].artifact.name

		if (data && data[activeTab].artifact?.name !== undefined) return data[activeTab].artifact?.name

		return `Artifact`
	}, [activeTab, data])

	// ! ---
	// !	Return: No artifacts
	// ! ---
	if (!data || data.length < 1 || data[0].artifact?.name === undefined) {
		return <></>
	}

	// * ---
	// *	Return: Good
	// * ---
	return (
		<Dialog
			open={state}
			TransitionComponent={Transition}
			sx={{
				pb: 0,
				'& .MuiDialog-paperFullWidth': {
					maxWidth: '100%',
				},
			}}
			fullWidth={true}
			onClose={() => close()}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			aria-labelledby={namespace}
		>
			<DialogTitle id={namespace} className={'dragHandle'}>
				<Grid container direction='row' justifyContent='space-between' alignItems='center'>
					<Grid item xs>
						{getTitle()}
					</Grid>
					<Grid item>
						<IconButton
							data-test-id='PKlfKNx2fn0-KaP3uod4i'
							aria-label='close'
							onClick={close}
							sx={{
								color: theme.palette.text.secondary,
							}}
						>
							<Icon path={icons.closeIcon} size={1} />
						</IconButton>
					</Grid>
				</Grid>
			</DialogTitle>

			{/* Single Video */}
			{data.length === 1 && data[0].artifact?.type === ITestArtifactType.video && (
				<DialogContent
					sx={{
						backgroundColor: 'black',
						p: 0,
						height: '85vh',
						position: 'relative',
						overflowY: 'hidden',
					}}
				>
					<ArtifactDialogVideo data={data[0].artifact} />
				</DialogContent>
			)}

			{/* Single Image */}
			{data.length === 1 && data[0].artifact?.type === ITestArtifactType.image && (
				<DialogContent
					sx={{
						backgroundColor: theme.palette.background.default,
						p: 0,
						height: '85vh',
						position: 'relative',
					}}
				>
					<ArtifactDialogImage data={data[0].artifact} />
				</DialogContent>
			)}

			{/* Multiple Artifacts */}
			{data.length > 1 && (
				<DialogContent sx={{ p: 0 }}>
					<Tabs
						data-test-id={`${namespace}--tabs`}
						value={activeTab}
						onChange={(event: React.SyntheticEvent, newValue: number) => $activeTab(newValue)}
						indicatorColor='primary'
						textColor='inherit'
						variant='fullWidth'
						aria-label='artifact tabs'
						sx={{ backgroundColor: theme.palette.background.paper }}
					>
						{map(data, (item, index) => (
							<Tab
								label={item.label}
								id={`tab-${index}`}
								key={`tab-${index}`}
								value={index}
								aria-controls={`tabpanel-${index}`}
								sx={{
									backgroundColor:
										activeTab === index ? theme.palette.background.default : 'transparent',
								}}
							/>
						))}
					</Tabs>
					<Box
						sx={{
							height: '80vh',
							backgroundColor: theme.palette.background.paper,
							position: 'relative',
						}}
					>
						{map(data, (item, index) => (
							<div
								role='tabpanel'
								hidden={activeTab !== index}
								key={`tabpanel-${index}`}
								id={`tabpanel-${index}`}
								aria-labelledby={`simple-tab-${index}`}
							>
								{activeTab === index && item.artifact && <ArtifactDialogImage data={item.artifact} />}
							</div>
						))}
					</Box>
				</DialogContent>
			)}
		</Dialog>
	)
}
