// ? ---
// ?	Fragment
// ? ---
import gql from 'graphql-tag'

import { MAX_ITEMS_PER_PAGE, MAX_SELECT_ITEMS_PER_PAGE } from 'globals/constants/api'

export const MFA_FRAGMENT = `
	fragment mfaFragment on MfaEntity {
		id
		attributes {
			title
			nodes {
				data {
					id
				}
			}
		}
	}
`

// ? ---
// ?	Queries
// ? ---
export const QUERY_MFA = gql(/* GraphQL */ `
	${MFA_FRAGMENT}
	query query_mfas_main($filters: MfaFiltersInput!) {
		mfas(filters: $filters, pagination: { pageSize: ${MAX_ITEMS_PER_PAGE} }, sort: "title:desc") {
			data {
				...mfaFragment
			}
		}
	}
`)

export const QUERY_MFA_SELECT = gql(/* GraphQL */ `
    query query_mfas_main($filters: MfaFiltersInput!) {
        mfas(filters: $filters, pagination: { pageSize: ${MAX_SELECT_ITEMS_PER_PAGE} }, sort: "title:desc") {
            data {
                id
                attributes {
                    title
                    nodes {
                        data {
                            id
                        }
                    }
                }
            }
        }
    }
`)

// ? ---
// ?	Mutations
// ? --
export const ADD_MFA = gql(/* GraphQL */ `
	mutation create_mfa($data: MfaInput!) {
		createMfa(data: $data) {
			data {
				id
			}
		}
	}
`)

export const EDIT_MFA = gql(/* GraphQL */ `
	${MFA_FRAGMENT}
	mutation update_mfa($id: ID!, $data: MfaInput!) {
		updateMfa(id: $id, data: $data) {
			data {
				...mfaFragment
			}
		}
	}
`)

export const NUKE_MFA = gql(/* GraphQL */ `
	mutation delete_mfa($id: ID!) {
		deleteMfa(id: $id) {
			data {
				id
			}
		}
	}
`)
