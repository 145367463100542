// ? ---
// ?	Imports
// ? ---
import * as React from 'react'

import { compact } from 'lodash'

import { ELEMENT_VALIDATION } from 'globals/constants/validations'
import { RUNNER_STOP_GROUP } from 'globals/constants/variantGroups'

import { formatElement, sentenceElement } from 'hooks/useNode'

import { INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.runner_stop_elementDisplayed,
	isLive: true,
	isMenuOption: true,
	isPro: false,
	isAccessibility: false,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.variantType',
			type: 'variantType',
			label: 'Type',
			options: RUNNER_STOP_GROUP,
		},
		{
			property: 'attributes.element',
			type: 'element',
			label: 'Element',
			validation: ELEMENT_VALIDATION,
		},
	],
	label: 'Stop when element is displayed',
	sentence: ({ element, isExample }: INodeRenderProps) => {
		return isExample ? `Stop on element` : `Stop when ${sentenceElement(element)} is displayed`
	},
	family: INodeVariantTypes.runner_stop_elementDisplayed.split('_')[0],
	type: INodeVariantTypes.runner_stop_elementDisplayed.split('_')[1],
	subType: INodeVariantTypes.runner_stop_elementDisplayed.split('_')[2],
	tags: compact([
		INodeVariantTypes.runner_stop_elementDisplayed.split('_')[0],
		INodeVariantTypes.runner_stop_elementDisplayed.split('_')[1],
		INodeVariantTypes.runner_stop_elementDisplayed.split('_')[2],
		'state',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ element, isExample }: INodeRenderProps) => {
			return (
				<>
					{isExample ? (
						`Stop on element`
					) : (
						<>
							Stop when <strong>{formatElement(element)}</strong> is displayed
						</>
					)}
				</>
			)
		},
	}
}
