// ? ---
// ?	Imports
// ? ---
import * as React from 'react'

import { compact, constant } from 'lodash'

import { RUNNER_HEADER_GROUP } from 'globals/constants/variantGroups'

import { INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.runner_header_set,
	isLive: true,
	isMenuOption: true,
	isPro: false,
	isAccessibility: false,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.variantType',
			type: 'variantType',
			label: 'Type',
			options: RUNNER_HEADER_GROUP,
		},
		{
			property: 'attributes.value',
			type: 'json',
			asStringValue: true,
			label: 'Headers',
			helper: `Values can be used inside strings using the format: "{{headers}}".`,
		},
	],
	label: 'Set headers',
	sentence: constant('Set browser headers'),
	family: INodeVariantTypes.runner_header_set.split('_')[0],
	type: INodeVariantTypes.runner_header_set.split('_')[1],
	subType: INodeVariantTypes.runner_header_set.split('_')[2],
	tags: compact([
		INodeVariantTypes.runner_header_set.split('_')[0],
		INodeVariantTypes.runner_header_set.split('_')[1],
		INodeVariantTypes.runner_header_set.split('_')[2],
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: () => {
			return <>Set Headers</>
		},
	}
}
