// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'
import { useFormik } from 'formik'
import { useAnimation } from 'framer-motion'

import {
	Alert,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Fab,
	Grid,
	IconButton,
	Stack,
	useTheme,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import Icon from '@mdi/react'
import { isEmpty, omit } from 'lodash'

import { icons } from 'globals/constants/icons'
import { DIALOG_MEDIUM_WIDTH } from 'globals/constants/sizes'

import useMfas from 'hooks/useMfas'
import useThemeMode from 'hooks/useThemeMode'

import ShakeablePaperMedium from 'components/_Global/Dialogs/helpers/ShakeablePaperMedium'
import { Transition } from 'components/_Global/Dialogs/helpers/Transition'
import Field from 'components/_Global/Forms/Field'
import { IMfa, validationSchema, validationSchemaNew } from 'data/mfas'

import MfaCode from './MfaCode'

// ? ---
// ?	Types
// ? ---
type Props = {
	state: boolean
	data: undefined | Partial<IMfa>
	options?: {
		[key: string]: any
	}
	close: () => void
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-MFA-MfaDialog'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function MfaDialog({ close, data, options, state }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const theme = useTheme()
	const _theme = useThemeMode()
	const _mfas = useMfas()
	const controls = useAnimation()

	// * ---
	// *	State
	// * ---
	const [saving, $saving] = React.useState(false)
	const [busy, $busy] = React.useState(false)
	const [locked, $locked] = React.useState(!!data?.id)
	const [displayCode, $displayCode] = React.useState(false)

	// * ---
	// *	Methods
	// * ---
	const onSuccess = () => {
		log('onSuccess')
		$locked(true)
		$busy(false)
	}

	const onError = () => {
		log('onError')
		$locked(false)
		$busy(false)
	}

	// * ---
	// *	Formik
	// * ---
	const formik = useFormik({
		validationSchema: data?.id ? validationSchema : validationSchemaNew,
		initialValues: {
			...data,
			attributes: {
				...omit(data?.attributes, ['updatedAt']),
				title: data?.attributes?.title || '',
				secret: '',
			},
		},
		onSubmit: async (values) => {
			if (!locked) {
				$busy(true)
				$displayCode(true)
			} else {
				// * Save MFA Record
				$saving(true)
				log('onSubmit', values)
				await _mfas.upsert(
					{
						...values,
						attributes: {
							...omit(values.attributes, []),
						},
					},
					{
						onSuccess: (mfa) => {
							log('onSubmit onSuccess', { mfa, options })
							close()
							$saving(false)
							if (options?.onSuccess) options.onSuccess(mfa)
							if (options?.onComplete) options.onComplete(mfa)
						},
					}
				)
			}
		},
	})

	// * ---
	// *  Return: Good
	// * ---
	return (
		<Dialog
			open={state}
			TransitionComponent={Transition}
			sx={{
				'& .MuiDialog-paperFullWidth': {
					maxWidth: DIALOG_MEDIUM_WIDTH,
				},
			}}
			fullWidth={true}
			onClose={() => (formik.dirty ? controls.start('start') : close())}
			PaperComponent={ShakeablePaperMedium}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			PaperProps={{ controls }}
			aria-labelledby={namespace}
		>
			<form onSubmit={formik.handleSubmit}>
				<DialogTitle id={namespace} className={'dragHandle'}>
					{isEmpty(data?.id) ? 'Create MFA' : 'Update MFA'}
				</DialogTitle>
				<DialogContent>
					<Stack sx={{ pt: 2, maxHeight: '78vh' }}>
						<Field
							field={{
								property: 'attributes.title',
								type: 'text',
								label: 'Title',
							}}
							formik={formik}
						/>
						{!data?.id ? (
							<>
								<Field
									field={{
										property: 'attributes.secret',
										type: 'text',
										label: 'MFA Secret',
										autocomplete: 'off',
										disabled: locked,
										helper: 'You will not be able to view or edit the MFA secret after it is saved.',
									}}
									formik={formik}
								/>
								{displayCode && (
									<MfaCode
										secret={formik.values.attributes.secret}
										onError={onError}
										onSuccess={onSuccess}
									/>
								)}
							</>
						) : (
							<Alert
								severity='info'
								sx={{ border: _theme.conditional(`1px solid ${theme.palette.info.dark}`, undefined) }}
							>
								MFA Secret is not editable
							</Alert>
						)}
					</Stack>
				</DialogContent>
				<DialogActions>
					<Grid
						container
						direction='row'
						justifyContent='space-between'
						alignItems='center'
						spacing={1}
						sx={{ pl: 1, pr: locked ? 1 : 2.5 }}
					>
						<Grid item>
							{!isEmpty(data?.id) && (
								<IconButton
									data-test-id='tqrF3y2jv7JZ-7ekfmHP2'
									title={'Delete MFA'}
									sx={{ color: theme.palette.text.secondary }}
									onClick={() =>
										_mfas.remove(data as IMfa, {
											onSuccess: () => {
												close()
												if (options?.onDelete) {
													setTimeout(() => options.onDelete(), 0)
												}
											},
										})
									}
								>
									<Icon path={icons.removeIcon} size={1} />
								</IconButton>
							)}
						</Grid>
						<Grid item>
							<Button
								data-test-id='r_mymYaDHNrOkPrUgG9Mq'
								color={'secondary'}
								onClick={() => {
									close()
								}}
							>
								Cancel
							</Button>
						</Grid>
					</Grid>
					{!locked ? (
						<Button
							data-test-id={'verify-mfa'}
							color={busy ? 'secondary' : 'primary'}
							variant='contained'
							aria-label='Verify MFA'
							type='submit'
							disabled={busy || !formik.isValid}
							sx={{
								borderRadius: 100,
								position: 'absolute',
								mt: '-20px',
								right: '8px',
								py: 1,
								px: 1.5,
							}}
						>
							{busy ? <Icon path={icons.loadingIcon} size={1} spin={1} /> : `Verify`}
						</Button>
					) : (
						<Fab
							color='primary'
							aria-label='Save MFA'
							type='submit'
							disabled={saving}
							sx={{
								backgroundColor: saving || !formik.isValid ? grey[400] : undefined,
							}}
						>
							<Icon path={saving ? icons.loadingIcon : icons.agreeIcon} size={1} spin={saving ? 1 : 0} />
						</Fab>
					)}
				</DialogActions>
			</form>
		</Dialog>
	)
}
