// ? ---
// ?	Imports
// ? ---
import * as React from 'react'

import { compact } from 'lodash'

import { ELEMENT_VALIDATION, STORAGE_NAME_VALIDATION } from 'globals/constants/validations'
import { DATA_STORE_GROUP } from 'globals/constants/variantGroups'

import { formatElement, formatValue, sentenceElement, sentenceValue } from 'hooks/useNode'

import { INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.data_store_elementHTML,
	isLive: true,
	isMenuOption: true,
	isPro: false,
	isAccessibility: false,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.variantType',
			type: 'variantType',
			label: 'Type',
			options: DATA_STORE_GROUP,
		},
		{
			property: 'attributes.element',
			type: 'element',
			label: 'Element',
			validation: ELEMENT_VALIDATION,
		},
		{
			property: 'attributes.value',
			type: 'text',
			label: 'Storage Name',
			validation: STORAGE_NAME_VALIDATION,
			isValueStoreKey: true,
		},
	],
	label: 'Store element html',
	sentence: ({ element, isExample, value: storageName }: INodeRenderProps) => {
		return isExample
			? `Store element html`
			: `Store ${sentenceElement(element)} html as ${sentenceValue(storageName)}`
	},
	family: INodeVariantTypes.data_store_elementHTML.split('_')[0],
	type: INodeVariantTypes.data_store_elementHTML.split('_')[1],
	subType: INodeVariantTypes.data_store_elementHTML.split('_')[2],
	tags: compact([
		INodeVariantTypes.data_store_elementHTML.split('_')[0],
		INodeVariantTypes.data_store_elementHTML.split('_')[1],
		INodeVariantTypes.data_store_elementHTML.split('_')[2],
		'element',
		'html',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ element, isExample, value: storageName }: INodeRenderProps) => {
			return (
				<>
					{isExample ? (
						<>Store element html</>
					) : (
						<>
							Store <strong>{formatElement(element)}</strong> html as{' '}
							<strong>{formatValue(storageName)}</strong>
						</>
					)}
				</>
			)
		},
	}
}
