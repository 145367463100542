// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import debug from 'debug'

import { Dialog, DialogContent, DialogTitle, Grid, IconButton, useTheme } from '@mui/material'
import Icon from '@mdi/react'
import { find, findIndex, get, includes, max } from 'lodash'

import { icons } from 'globals/constants/icons'

import { Transition } from 'components/_Global/Dialogs/helpers/Transition'
import TestStepNode from 'components/Tests/TestStepNode'
import { INodeVariantTypes } from 'data/nodes'
import { ITest, ITestArtifact, ITestArtifactTopic, ITestArtifactType } from 'data/tests'

import ArtifactDialogImage from './ArtifactDialogImage'
import ArtifactScreenshotsDialogsChanges from './ArtifactScreenshotsDialogsChanges'

// ? ---
// ?	Types
// ? ---
type Props = {
	state: boolean
	data?: ITest
	options?: {
		[key: string]: any
		stepHash?: string
	}
	close: () => void
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Dialogs-ArtifactScreenshotsDialogs'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function ArtifactScreenshotsDialogs({ close, data, options, state }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.', options)
	const theme = useTheme()

	// * ---
	// *	State
	// * ---
	const [activeIndex, $activeIndex] = React.useState(
		max([findIndex(data?.attributes.stepData, { hash: options?.stepHash }), 0]) || 0
	)
	const [activeArtifact, $activeArtifact] = React.useState<ITestArtifact | undefined>(
		data &&
			(find(data.attributes.stepData[activeIndex].artifacts, {
				type: ITestArtifactType.image,
				topic: ITestArtifactTopic.viewport,
			}) as ITestArtifact)
	)

	// * ---
	// *	Effect: Update current screenshot
	// * ---
	React.useEffect(() => {
		if (!data) return
		$activeArtifact(
			find(data.attributes.stepData[activeIndex].artifacts, {
				type: ITestArtifactType.image,
				topic: ITestArtifactTopic.viewport,
			}) as ITestArtifact
		)
	}, [activeIndex, data])

	// * ---
	// *	Methods
	// * ---
	const next = () => {
		let temp = activeIndex + 1
		if (temp > get(data, 'attributes.stepData.length', 0) - 1) temp = 0
		$activeIndex(temp)
	}
	useHotkeys(
		['ArrowDown', 'ArrowRight'],
		() => activeIndex !== get(data, 'attributes.stepData.length', 0) - 1 && next(),
		{ preventDefault: true }
	)

	const prev = () => {
		let temp = activeIndex - 1
		if (temp < 0) temp = get(data, 'attributes.stepData.length', 0) - 1
		$activeIndex(temp)
	}
	useHotkeys(['ArrowUp', 'ArrowLeft'], () => activeIndex !== 0 && prev(), { preventDefault: true })

	// ! ---
	// !	Return: No test
	// ! ---
	if (!data) return <></>

	// * ---
	// *	Return: Good
	// * ---
	return (
		<Dialog
			open={state}
			TransitionComponent={Transition}
			sx={{
				pb: 0,
				'& .MuiDialog-paperFullWidth': {
					maxWidth: '100%',
				},
			}}
			fullWidth={true}
			onClose={() => close()}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			aria-labelledby={namespace}
		>
			<DialogTitle id={namespace} className={'dragHandle'}>
				<Grid container direction='row' justifyContent='space-between' alignItems='center'>
					<Grid item xs sx={{ lineHeight: 0 }}>
						<TestStepNode step={data.attributes.stepData[activeIndex]} />
					</Grid>
					<Grid item>
						<IconButton
							data-test-id='Kc3LTR3fRDWXdIvw3R4Vy'
							aria-label='previous'
							onClick={prev}
							disabled={activeIndex === 0}
							sx={{
								color: theme.palette.text.secondary,
							}}
						>
							<Icon path={icons.upIcon} size={1} />
						</IconButton>
						<IconButton
							data-test-id='bcHVS3PY6zRkxGOX8t6qf'
							aria-label='next'
							onClick={next}
							disabled={activeIndex === get(data, 'attributes.stepData.length', 0) - 1}
							sx={{
								color: theme.palette.text.secondary,
							}}
						>
							<Icon path={icons.downIcon} size={1} />
						</IconButton>
						<IconButton
							data-test-id='5rq614agTzqXHPOqYiQFe'
							aria-label='close'
							onClick={close}
							sx={{
								color: theme.palette.text.secondary,
							}}
						>
							<Icon path={icons.closeIcon} size={1} />
						</IconButton>
					</Grid>
				</Grid>
			</DialogTitle>

			<DialogContent
				sx={{
					backgroundColor: theme.palette.background.default,
					p: 0,
					height: '85vh',
					position: 'relative',
				}}
			>
				{includes(
					[INodeVariantTypes.change_element_position, INodeVariantTypes.change_element_snapshot],
					data.attributes.stepData[activeIndex].node.variantType
				) ? (
					<ArtifactScreenshotsDialogsChanges step={data.attributes.stepData[activeIndex]} />
				) : (
					<ArtifactDialogImage data={activeArtifact} />
				)}
			</DialogContent>
		</Dialog>
	)
}
