// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import { AppDataContext, IAppDataContext } from 'context/appData'
import debug from 'debug'
import { useFormik } from 'formik'
import { useAnimation } from 'framer-motion'

import Link from 'next/link'
import {
	Alert,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Fab,
	Grid,
	IconButton,
	useTheme,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import Icon from '@mdi/react'
import { includes, isEmpty } from 'lodash'

import { icons } from 'globals/constants/icons'
import { DIALOG_MEDIUM_WIDTH } from 'globals/constants/sizes'

import useElements from 'hooks/useElements'
import useThemeMode from 'hooks/useThemeMode'

import ShakeablePaperMedium from 'components/_Global/Dialogs/helpers/ShakeablePaperMedium'
import { Transition } from 'components/_Global/Dialogs/helpers/Transition'
import Field from 'components/_Global/Forms/Field'
import { IElement, IElementSelectorType, validationSchema } from 'data/elements'

// ? ---
// ?	Types
// ? ---
type Props = {
	state: boolean
	data: undefined | Partial<IElement>
	options?: {
		[key: string]: any
	}
	close: () => void
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Elements-ElementDialog'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function ElementDialog({ close, data, options, state }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const AppData = React.useContext<IAppDataContext>(AppDataContext)
	const _elements = useElements()
	const theme = useTheme()
	const _theme = useThemeMode()
	const controls = useAnimation()

	// * ---
	// *	State
	// * ---
	const [saving, $saving] = React.useState(false)

	// * ---
	// *	Formik
	// * ---
	const formik = useFormik({
		validationSchema,
		initialValues: {
			...data,
			attributes: {
				...data?.attributes,
				title: data?.attributes?.title || '',
				type: data?.attributes?.type || IElementSelectorType.css,
				selector: data?.attributes?.selector || '',
				isDynamic: data?.attributes?.isDynamic === true ? true : includes(data?.attributes?.selector, '-{{-'),
			},
		},
		onSubmit: async (values) => {
			$saving(true)
			log('onSubmit', values)
			await _elements.upsert(values, {
				onSuccess: (element) => {
					log('onSubmit onSuccess', { element, options })
					close()
					$saving(false)
					if (options?.onSuccess) options.onSuccess(element)
					if (options?.onComplete) options.onComplete(element)
				},
			})
		},
	})

	// * ---
	// *	Return: Good
	// * ---

	return (
		<Dialog
			open={state}
			TransitionComponent={Transition}
			sx={{
				'& .MuiDialog-paperFullWidth': {
					maxWidth: DIALOG_MEDIUM_WIDTH,
				},
			}}
			fullWidth={true}
			onClose={() => (formik.dirty ? controls.start('start') : close())}
			PaperComponent={ShakeablePaperMedium}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			PaperProps={{ controls }}
			aria-labelledby={namespace}
		>
			<form onSubmit={formik.handleSubmit}>
				<DialogTitle id={namespace} className={'dragHandle'}>
					{isEmpty(data?.id) ? 'Create Element' : 'Update Element'}
				</DialogTitle>
				<DialogContent>
					<Grid
						container
						direction='row'
						justifyContent='space-between'
						alignItems='flex-start'
						spacing={1}
						sx={{ pt: 2, maxHeight: '78vh' }}
					>
						<Grid item xs={12}>
							<Field
								field={{
									property: 'attributes.title',
									type: 'text',
									label: 'Title',
								}}
								formik={formik}
								pb={1}
							/>
						</Grid>
						<Grid item xs={6}>
							<Field
								field={{
									property: 'attributes.type',
									type: 'dropdown',
									label: 'Type',
									options: [
										{ label: 'CSS', value: 'css' },
										{ label: 'XPath', value: 'xpath' },
										{ label: 'Visible Text', value: 'text' },
										{ label: 'Test ID', value: 'testId' },
									],
								}}
								formik={formik}
								pb={1}
							/>
						</Grid>
						<Grid item xs={6}>
							<Field
								field={{
									property: 'attributes.isDynamic',
									type: 'button',
									label: 'Dynamic Selector',
									helper: 'Selector includes values',
									defaultValue: false,
								}}
								formik={formik}
								pb={1}
							/>
						</Grid>
						<Grid item xs={12}>
							<Field
								field={{
									property: 'attributes.selector',
									type: formik.values.attributes.isDynamic
										? 'text-with-tags'
										: formik.values.attributes.type === IElementSelectorType.css
										? 'css'
										: formik.values.attributes.type === IElementSelectorType.xpath
										? 'xpath'
										: 'text',
									label:
										formik.values.attributes.type === IElementSelectorType.text
											? 'Text'
											: formik.values.attributes.type === IElementSelectorType.testId
											? 'Test ID'
											: 'Selector',
								}}
								formik={formik}
								pb={1}
							/>
						</Grid>

						{formik.values.attributes.type === IElementSelectorType.css && (
							<Grid item xs={12}>
								<Alert
									severity='info'
									sx={{
										border: _theme.conditional(`1px solid ${theme.palette.info.main}`, undefined),
										fontWeight: 500,
									}}
								>
									<Link
										data-test-id={`${namespace}--css-guide`}
										href={
											'https://does.qa/blog/manual-tester-guide-to-css-selectors-for-test-automation'
										}
										target={'_blank'}
										style={{ color: 'inherit' }}
									>
										Guide to CSS Selectors for Test Automation
									</Link>
								</Alert>
							</Grid>
						)}
						{formik.values.attributes.type === IElementSelectorType.testId && (
							<Grid item xs={12}>
								<Alert
									severity='info'
									sx={{
										border: _theme.conditional(`1px solid ${theme.palette.info.main}`, undefined),
										fontWeight: 500,
									}}
								>
									<Link
										data-test-id={`${namespace}--test-id-guide`}
										href={'https://docs.does.qa/additional-items/elements#element-types'}
										target={'_blank'}
										style={{ color: 'inherit' }}
									>
										Supported Test IDs
									</Link>
								</Alert>
							</Grid>
						)}
					</Grid>
				</DialogContent>
				<DialogActions>
					<Grid
						container
						direction='row'
						justifyContent='space-between'
						alignItems='center'
						spacing={1}
						sx={{ pl: 1, pr: 1 }}
					>
						<Grid item>
							{!isEmpty(data?.id) && (
								<IconButton
									data-test-id='LEQBfqZE5t1evizgYa_T1'
									title={'Delete Element'}
									sx={{ color: theme.palette.text.secondary }}
									onClick={() =>
										_elements.remove(data as IElement, {
											onSuccess: () => {
												AppData.reload.current && AppData.reload.current()
												close()
											},
										})
									}
								>
									<Icon path={icons.removeIcon} size={1} />
								</IconButton>
							)}
						</Grid>
						<Grid item>
							<Button
								data-test-id='S_y0t6tpSlnht-lsxDt2p'
								color={'secondary'}
								onClick={() => {
									close()
								}}
							>
								Cancel
							</Button>
						</Grid>
					</Grid>
					<Fab
						color='primary'
						aria-label='Save Element'
						type='submit'
						disabled={saving}
						sx={{
							backgroundColor:
								saving || !formik.isValid ? _theme.conditional(grey[400], grey[800]) : undefined,
						}}
					>
						<Icon path={saving ? icons.loadingIcon : icons.agreeIcon} size={1} spin={saving ? 1 : 0} />
					</Fab>
				</DialogActions>
			</form>
		</Dialog>
	)
}
