// ? ---
// ?	Imports
// ? ---

import { Edge, Node } from 'reactflow'

import { IElement } from 'data/elements'
import { IMfa } from 'data/mfas'

// ? ---
// ?	Types & Enums
// ? ---
export enum INodeActionScrollOptions {
	nearest = 'Nearest Point',
	top_left = 'Top Left',
	center = 'Center',
	bottom_right = 'Bottom Right',
}

export enum INodeMailOpenFilterProperties {
	subject = 'Subject',
	from = 'From',
	replyTo = 'Reply To',
}

export enum INodeMailWaitFilterProperties {
	subject = 'Subject',
	from = 'From',
	replyTo = 'Reply To',
	htmlBody = 'HTML Body',
	plainTextBody = 'Plain Text Body',
	snippet = 'Snippet',
}

export enum INodeStarterThrottleOptions {
	offline = 'Offline',
	Regular2G = '2G',
	Regular3G = '3G',
	Regular4G = '4G',
	DSL = 'Slow Wifi',
	WiFi = 'Fast Wifi',
	online = 'No throttle',
}

export enum INodeStarterLanguageOptions {
	en_us = 'English (United States)',
	en_gb = 'English (United Kingdom)',
	zh_cn = 'Chinese (China, Simplified)',
	es_es = 'Spanish (Spain)',
	es_mx = 'Spanish (Mexico)',
	fr_fr = 'French (France)',
	de_de = 'German (Germany)',
	ja_jp = 'Japanese (Japan)',
	pl_pl = 'Polish (Poland)',
	it_it = 'Italian (Italy)',
	ar_AE = 'Arabic (United Arab Emirates)',
}

export enum INodeStarterTimezoneOptions {
	UTC = 'UTC',
	America___New_York = 'America/New_York',
	Europe___London = 'Europe/London',
	Europe___Paris = 'Europe/Paris',
	America___Los_Angeles = 'America/Los_Angeles',
	Europe___Berlin = 'Europe/Berlin',
	Asia___Tokyo = 'Asia/Tokyo',
	Australia___Sydney = 'Australia/Sydney',
	America___Sao_Paulo = 'America/Sao_Paulo',
	Asia___Dubai = 'Asia/Dubai',
}

export enum INodeStarterRunners {
	chrome = 'Chrome',
	firefox = 'Firefox',
	edge = 'Edge',
	emulated_device = 'Emulated Device',
}

export enum INodeStarterDevices {
	// * Edge
	edge__desktop_1920x1080 = 'Desktop',
	edge__desktop_2560x1080 = 'Desktop - Ultra-wide',
	edge__desktop_1080x1920 = 'Desktop - Portrait',
	edge__tablet_1024x768 = 'Tablet - Landscape',
	edge__tablet_768x1024 = 'Tablet - Portrait',

	// * Firefox
	firefox__desktop_1920x1080 = 'Desktop',
	firefox__desktop_2560x1080 = 'Desktop - Ultra-wide',
	firefox__desktop_1080x1920 = 'Desktop - Portrait',
	firefox__tablet_1024x768 = 'Tablet - Landscape',
	firefox__tablet_768x1024 = 'Tablet - Portrait',

	// * Chrome
	desktop_1920x1080 = 'Desktop',
	desktop_2560x1080 = 'Desktop - Ultra-wide',
	desktop_1080x1920 = 'Desktop - Portrait',
	tablet_1024x768 = 'Tablet - Landscape',
	tablet_768x1024 = 'Tablet - Portrait',

	// * Apple
	iphone_14_pro_max = 'iPhone 14 Pro Max',
	iphone_12_pro = 'iPhone 12 Pro',
	iphone_xr = 'iPhone XR',
	iphone_x = 'iPhone X',
	iphone_se = 'iPhone SE',
	iphone_4 = 'iPhone 4',
	ipad_pro = 'iPad Pro',
	ipad_mini = 'iPad Mini',
	ipad_air = 'iPad Air',

	// * Galaxy
	galaxy_a51_71 = 'Samsung Galaxy A51/71',
	galaxy_fold = 'Galaxy Z Fold 5',
	galaxy_s_3 = 'Samsung Galaxy S3',
	galaxy_s8 = 'Samsung Galaxy S8',
	galaxy_s8_plus = 'Samsung Galaxy S8+',
	galaxy_s9_plus = 'Samsung Galaxy S9+',
	galaxy_s20_ultra = 'Samsung Galaxy S20 Ultra',
	galaxy_note_2 = 'Samsung Galaxy Note 2',
	galaxy_note_3 = 'Samsung Galaxy Note 3',
	galaxy_tab_s4 = 'Samsung Galaxy Tab S4',

	// * Microsoft
	microsoft_lumia_550 = 'Microsoft Lumia 550',
	microsoft_lumia_950 = 'Microsoft Lumia 950',

	// * Moto
	moto_g4 = 'Moto G4',
	moto_g_power = 'Moto G Power',

	// * Nokia
	n9 = 'Nokia N9',

	// * Nexus
	nexus_4 = 'Nexus 4',
	nexus_5 = 'Nexus 5',
	nexus_5x = 'Nexus 5X',
	nexus_6 = 'Nexus 6',
	nexus_6p = 'Nexus 6P',
	nexus_7 = 'Nexus 7',
	nexus_10 = 'Nexus 10',

	// * Pixel
	pixel_2 = 'Pixel 2',
	pixel_2_xl = 'Pixel 2 XL',
	pixel_3 = 'Pixel 3',
	pixel_3_xl = 'Pixel 3 XL',
	pixel_4 = 'Pixel 4',
	pixel_7 = 'Pixel 7',

	// * Surface
	surface_duo = 'Surface Duo',
	surface_pro_7 = 'Surface Pro 7',

	// * Asus
	asus_zenbook_fold = 'Asus Zenbook Fold',

	// * Blackberry
	blackberry_playbook = 'Blackberry Playbook',
	blackberry_z30 = 'Blackberry Z30',

	// * Kindle
	kindle_fire_hdx = 'Kindle Fire HDX',

	// * LG
	lg_optimus_l70 = 'LG Optimus L70',

	// * Nest
	nest_hub = 'Nest Hub',
	nest_hub_max = 'Nest Hub Max',
}

export enum INodeDevOptions {
	option1 = 'Option 1',
	option2 = 'Option 2',
	option3 = 'Option 3',
	option4 = 'Option 4',
	option5 = 'Option 5',
}

export enum INodeStarterDeviceOrientation {
	portrait = 'Portrait',
	landscape = 'Landscape',
}

export enum INodeCheckComputedStyleOptions {
	alignContent = 'Align Content',
	alignItems = 'Align Items',
	alignSelf = 'Align Self',
	animation = 'Animation',
	animationDelay = 'Animation Delay',
	animationDirection = 'Animation Direction',
	animationDuration = 'Animation Duration',
	animationFillMode = 'Animation Fill Mode',
	animationIterationCount = 'Animation Iteration Count',
	animationName = 'Animation Name',
	animationTimingFunction = 'Animation Timing Function',
	animationPlayState = 'Animation Play State',
	background = 'Background',
	backgroundAttachment = 'Background Attachment',
	backgroundColor = 'Background Color',
	backgroundImage = 'Background Image',
	backgroundPosition = 'Background Position',
	backgroundRepeat = 'Background Repeat',
	backgroundClip = 'Background Clip',
	backgroundOrigin = 'Background Origin',
	backgroundSize = 'Background Size',
	backfaceVisibility = 'Backface Visibility',
	border = 'Border',
	borderBottom = 'Border Bottom',
	borderBottomColor = 'Border Bottom Color',
	borderBottomLeftRadius = 'Border Bottom Left Radius',
	borderBottomRightRadius = 'Border Bottom Right Radius',
	borderBottomStyle = 'Border Bottom Style',
	borderBottomWidth = 'Border Bottom Width',
	borderCollapse = 'Border Collapse',
	borderColor = 'Border Color',
	borderImage = 'Border Image',
	borderImageOutset = 'Border Image Outset',
	borderImageRepeat = 'Border Image Repeat',
	borderImageSlice = 'Border Image Slice',
	borderImageSource = 'Border Image Source',
	borderImageWidth = 'Border Image Width',
	borderLeft = 'Border Left',
	borderLeftColor = 'Border Left Color',
	borderLeftStyle = 'Border Left Style',
	borderLeftWidth = 'Border Left Width',
	borderRadius = 'Border Radius',
	borderRight = 'Border Right',
	borderRightColor = 'Border Right Color',
	borderRightStyle = 'Border Right Style',
	borderRightWidth = 'Border Right Width',
	borderSpacing = 'Border Spacing',
	borderStyle = 'Border Style',
	borderTop = 'Border Top',
	borderTopColor = 'Border Top Color',
	borderTopLeftRadius = 'Border Top Left Radius',
	borderTopRightRadius = 'Border Top Right Radius',
	borderTopStyle = 'Border Top Style',
	borderTopWidth = 'Border Top Width',
	borderWidth = 'Border Width',
	bottom = 'Bottom',
	boxDecorationBreak = 'Box Decoration Break',
	boxShadow = 'Box Shadow',
	boxSizing = 'Box Sizing',
	captionSide = 'Caption Side',
	caretColor = 'Caret Color',
	clear = 'Clear',
	clip = 'Clip',
	color = 'Color',
	columnCount = 'Column Count',
	columnFill = 'Column Fill',
	columnGap = 'Column Gap',
	columnRule = 'Column Rule',
	columnRuleColor = 'Column Rule Color',
	columnRuleStyle = 'Column Rule Style',
	columnRuleWidth = 'Column Rule Width',
	columns = 'Columns',
	columnSpan = 'Column Span',
	columnWidth = 'Column Width',
	content = 'Content',
	counterIncrement = 'Counter Increment',
	counterReset = 'Counter Reset',
	cursor = 'Cursor',
	direction = 'Direction',
	display = 'Display',
	emptyCells = 'Empty Cells',
	filter = 'Filter',
	flex = 'Flex',
	flexBasis = 'Flex Basis',
	flexDirection = 'Flex Direction',
	flexFlow = 'Flex Flow',
	flexGrow = 'Flex Grow',
	flexShrink = 'Flex Shrink',
	flexWrap = 'Flex Wrap',
	cssFloat = 'CSS Float',
	font = 'Font',
	fontFamily = 'Font Family',
	fontSize = 'Font Size',
	fontStyle = 'Font Style',
	fontVariant = 'Font Variant',
	fontWeight = 'Font Weight',
	fontSizeAdjust = 'Font Size Adjust',
	fontStretch = 'Font Stretch',
	hangingPunctuation = 'Hanging Punctuation',
	height = 'Height',
	hyphens = 'Hyphens',
	icon = 'Icon',
	imageOrientation = 'Image Orientation',
	isolation = 'Isolation',
	justifyContent = 'Justify Content',
	left = 'Left',
	letterSpacing = 'Letter Spacing',
	lineHeight = 'Line Height',
	listStyle = 'List Style',
	listStyleImage = 'List Style Image',
	listStylePosition = 'List Style Position',
	listStyleType = 'List Style Type',
	margin = 'Margin',
	marginBottom = 'Margin Bottom',
	marginLeft = 'Margin Left',
	marginRight = 'Margin Right',
	marginTop = 'Margin Top',
	maxHeight = 'Max Height',
	maxWidth = 'Max Width',
	minHeight = 'Min Height',
	minWidth = 'Min Width',
	navDown = 'Nav Down',
	navIndex = 'Nav Index',
	navLeft = 'Nav Left',
	navRight = 'Nav Right',
	navUp = 'Nav Up',
	objectFit = 'Object Fit',
	objectPosition = 'Object Position',
	opacity = 'Opacity',
	order = 'Order',
	orphans = 'Orphans',
	outline = 'Outline',
	outlineColor = 'Outline Color',
	outlineOffset = 'Outline Offset',
	outlineStyle = 'Outline Style',
	outlineWidth = 'Outline Width',
	overflow = 'Overflow',
	overflowX = 'Overflow-X',
	overflowY = 'Overflow-Y',
	padding = 'Padding',
	paddingBottom = 'Padding Bottom',
	paddingLeft = 'Padding Left',
	paddingRight = 'Padding Right',
	paddingTop = 'Padding Top',
	pageBreakAfter = 'Page Break After',
	pageBreakBefore = 'Page Break Before',
	pageBreakInside = 'Page Break Inside',
	perspective = 'Perspective',
	perspectiveOrigin = 'Perspective Origin',
	position = 'Position',
	quotes = 'Quotes',
	resize = 'Resize',
	right = 'Right',
	scrollBehavior = 'Scroll Behavior',
	tableLayout = 'Table Layout',
	tabSize = 'Tab Size',
	textAlign = 'Text Align',
	textAlignLast = 'Text Align Last',
	textDecoration = 'Text Decoration',
	textDecorationColor = 'Text Decoration Color',
	textDecorationLine = 'Text Decoration Line',
	textDecorationStyle = 'Text Decoration Style',
	textIndent = 'Text Indent',
	textJustify = 'Text Justify',
	textOverflow = 'Text Overflow',
	textShadow = 'Text Shadow',
	textTransform = 'Text Transform',
	top = 'Top',
	transform = 'Transform',
	transformOrigin = 'Transform Origin',
	transformStyle = 'Transform Style',
	transition = 'Transition',
	transitionProperty = 'Transition Property',
	transitionDuration = 'Transition Duration',
	transitionTimingFunction = 'Transition Timing Function',
	transitionDelay = 'Transition Delay',
	unicodeBidi = 'Unicode Bidi',
	userSelect = 'User Select',
	verticalAlign = 'Vertical Align',
	visibility = 'Visibility',
	whiteSpace = 'White Space',
	width = 'Width',
	wordBreak = 'Word Break',
	wordSpacing = 'Word Spacing',
	wordWrap = 'Word Wrap',
	widows = 'Widows',
	zIndex = 'Z-Index',
}

export enum INodeVariantTypes {
	// * Starters
	starter_open = 'starter_open',
	starter_open_advanced = 'starter_open_advanced',

	// * Actions
	action_goTo_relative = 'action_goTo_relative',
	action_goTo_absolute = 'action_goTo_absolute',
	action_goTo_reload = 'action_goTo_reload',
	action_goTo_back = 'action_goTo_back',
	action_goTo_forward = 'action_goTo_forward',
	action_touch_single = 'action_touch_single',
	action_touch_double = 'action_touch_double',
	action_touch_hover = 'action_touch_hover',
	action_input_sendKeys = 'action_input_sendKeys',
	action_input_setValue = 'action_input_setValue',
	action_input_setMFA = 'action_input_setMFA',
	action_input_clearValue = 'action_input_clearValue',
	action_input_selectOption = 'action_input_selectOption',
	action_input_file = 'action_input_file',
	action_input_setTinyMce = 'action_input_setTinyMce',
	action_wait_forSeconds = 'action_wait_forSeconds',
	action_wait_forElement = 'action_wait_forElement', // Displayed
	action_wait_forElementNotDisplayed = 'action_wait_forElementNotDisplayed',
	action_wait_forElementVisible = 'action_wait_forElementVisible',
	action_wait_forElementNotVisible = 'action_wait_forElementNotVisible',
	action_wait_forElementExist = 'action_wait_forElementExist',
	action_wait_forElementNotExist = 'action_wait_forElementNotExist',
	action_wait_forElementEnabled = 'action_wait_forElementEnabled',
	action_wait_forElementDisabled = 'action_wait_forElementDisabled',
	action_wait_forElementClickable = 'action_wait_forElementClickable',
	action_wait_forElementNotClickable = 'action_wait_forElementNotClickable',
	action_switch_window = 'action_switch_window',
	action_switch_frame = 'action_switch_frame',
	action_switch_parentFrame = 'action_switch_parentFrame',
	action_scroll_intoView = 'action_scroll_intoView',
	action_scroll_page = 'action_scroll_page',
	action_dragDrop = 'action_dragDrop',

	// * Checks
	check_is_displayed = 'check_is_displayed',
	check_is_notDisplayed = 'check_is_notDisplayed',
	check_is_visible = 'check_is_visible',
	check_is_notVisible = 'check_is_notVisible',
	check_is_exist = 'check_is_exist',
	check_is_notExist = 'check_is_notExist',
	check_is_enabled = 'check_is_enabled',
	check_is_disabled = 'check_is_disabled',
	check_is_clickable = 'check_is_clickable',
	check_is_notClickable = 'check_is_notClickable',
	check_is_focused = 'check_is_focused',
	check_is_notFocused = 'check_is_notFocused',
	check_text = 'check_text',
	check_value = 'check_value',
	check_valueStore = 'check_valueStore',
	check_attribute = 'check_attribute',
	check_computed_label = 'check_computed_label',
	check_computed_role = 'check_computed_role',
	check_computed_style = 'check_computed_style',
	check_count = 'check_count',
	check_browserTitle = 'check_browserTitle',
	check_browserAddress = 'check_browserAddress',
	check_cookie_value = 'check_cookie_value',
	check_localStorage_value = 'check_localStorage_value',
	check_sessionStorage_value = 'check_sessionStorage_value',
	check_input_tinyMce = 'check_input_tinyMce',
	check_links_valid = 'check_links_valid',
	check_sftp_fileExist = 'check_sftp_fileExist',
	check_sftp_fileNotExist = 'check_sftp_fileNotExist',
	check_sftp_fileCount = 'check_sftp_fileCount',

	// * Change Detection
	change_element_snapshot = 'change_element_snapshot',
	change_element_position = 'change_element_position',

	// * Validators
	validate_lighthouse_checkAccessibility = 'validate_lighthouse_checkAccessibility',
	validate_lighthouse_checkPerformance = 'validate_lighthouse_checkPerformance',
	validate_lighthouse_checkPractices = 'validate_lighthouse_checkPractices',
	validate_lighthouse_checkSeo = 'validate_lighthouse_checkSeo',
	validate_lighthouse_checkPwa = 'validate_lighthouse_checkPwa',
	validate_pa11y = 'validate_pa11y',

	// * Data
	data_store_alias = 'data_store_alias',
	data_store_set = 'data_store_set',
	data_store_elementText = 'data_store_elementText',
	data_store_elementHTML = 'data_store_elementHTML',
	data_store_elementValue = 'data_store_elementValue',
	data_store_elementCount = 'data_store_elementCount',
	data_store_elementAttribute = 'data_store_elementAttribute',
	data_cookie_set = 'data_cookie_set',
	data_cookie_delete = 'data_cookie_delete',
	data_cookie_deleteAll = 'data_cookie_deleteAll',
	data_localStorage_set = 'data_localStorage_set',
	data_localStorage_delete = 'data_localStorage_delete',
	data_localStorage_deleteAll = 'data_localStorage_deleteAll',
	data_sessionStorage_set = 'data_sessionStorage_set',
	data_sessionStorage_delete = 'data_sessionStorage_delete',
	data_sessionStorage_deleteAll = 'data_sessionStorage_deleteAll',
	data_process_calculate = 'data_process_calculate',
	data_process_replace = 'data_process_replace',

	// * Runner
	runner_header_add = 'runner_header_add',
	runner_header_set = 'runner_header_set',
	runner_header_removeAll = 'runner_header_removeAll',
	runner_stop_elementDisplayed = 'runner_stop_elementDisplayed',
	runner_stop_elementNotDisplayed = 'runner_stop_elementNotDisplayed',
	runner_stop_value = 'runner_stop_value',
	runner_generate_file = 'runner_generate_file',
	runner_generate_image = 'runner_generate_image',
	runner_wait_forElementText = 'runner_wait_forElementText',

	// * Integration
	integration_api_get = 'integration_api_get',
	integration_api_put = 'integration_api_put',
	integration_api_post = 'integration_api_post',
	integration_api_delete = 'integration_api_delete',
	integration_slack_send = 'integration_slack_send',
	integration_googleMaps_position = 'integration_googleMaps_position',
	integration_googleMaps_zoom = 'integration_googleMaps_zoom',
	integration_googleMaps_pan = 'integration_googleMaps_pan',
	integration_sftp_list = 'integration_sftp_list',
	integration_sftp_upload = 'integration_sftp_upload',

	// * Express
	express_doesqa_run = 'express_doesqa_run',
	express_adyen_card = 'express_adyen_card',
	express_adyen_klarna = 'express_adyen_klarna',
	express_adyen_afterpay = 'express_adyen_afterpay',
	express_adyen_paypal = 'express_adyen_paypal',
	express_adyen_googlepay = 'express_adyen_googlepay',

	// * Mail
	mail_inbox_open = 'mail_inbox_open',
	mail_inbox_close = 'mail_inbox_close',
	mail_message_select = 'mail_message_select',
	mail_message_open = 'mail_message_open',
	mail_wait_forMessage = 'mail_wait_forMessage',

	// * Utilities
	utility_comment_flow = 'utility_comment_flow',
	utility_comment_test = 'utility_comment_test',
	utility_name_test = 'utility_name_test',

	// * Special
	special_group = 'special_group',
}

export enum INodeComparisonTypes {
	// * Universal (+)
	equals = 'equals',
	contains = 'contains',
	startsWith = 'startsWith',
	endsWith = 'endsWith',
	hasValue = 'hasValue',

	// * Universal (-)
	notEquals = 'notEquals',
	notContains = 'notContains',
	notStartsWith = 'notStartsWith',
	notEndsWith = 'notEndsWith',
	notHasValue = 'notHasValue',

	// * Numbers
	greaterThan = 'greaterThan',
	greaterThanOrEqual = 'greaterThanOrEqual',
	lessThan = 'lessThan',
	lessThanOrEqual = 'lessThanOrEqual',

	// * Advanced
	regex = 'regex',
}

export enum INodeFamily {
	starter = 'starter',
	action = 'action',
	check = 'check',
	validate = 'validate',
	change = 'change',
	data = 'data',
	runner = 'runner',
	integration = 'integration',
	utility = 'utility',
}

// ? ---
// ?	Interfaces
// ? ---
export interface INodeConfig {
	failedStatus: 'failed' | 'passed_with_warning' | 'passed'
	timeoutOverride: 'fast' | 'slow' | 'very_slow'
}

export interface INode {
	__typename: 'NodeEntity'
	id: string
	attributes: {
		label: string
		nodes?: {
			data: INode[]
		}
		group?: {
			data: INode
		}
		element?: {
			data: IElement
		}
		element2?: {
			data: IElement
		}
		mfa?: {
			data: IMfa
		}
		value: string
		value2: string
		value3: string
		value4: string
		value5: string
		value6: string
		value7: string
		value8: string
		value9: string
		value10: string
		value11: string
		value12: string
		value13: string
		value14: string
		value15: string
		value16: string
		value17: string
		value18: string
		value19: string
		value20: string
		variantType: INodeVariantTypes
		comparisonType: INodeComparisonTypes
		config?: INodeConfig
	}
}

export interface INodeResponse {
	data: INode[]
}

export interface INodeQueryResponse {
	nodes: INodeResponse
}

export interface INodeInput {
	id?: string
	attributes?: {
		label?: string
		nodes?: string[]
		group?: string
		element?: string
		element2?: string
		mfa?: string
		value?: string
		value2?: string
		value3?: string
		value4?: string
		value5?: string
		value6?: string
		value7?: string
		value8?: string
		value9?: string
		value10?: string
		value11?: string
		value12?: string
		value13?: string
		value14?: string
		value15?: string
		value16?: string
		value17?: string
		value18?: string
		value19?: string
		value20?: string
		variantType?: INodeVariantTypes
		comparisonType?: INodeComparisonTypes
		config?: Partial<INodeConfig>
	}
}

export interface INodeContextOptions {
	force?: boolean
	showDelete?: boolean
	useDefaultValues?: boolean
	onSuccess?: (node?: INode) => any
	onError?: (error?: any) => any
	onComplete?: (node?: INode) => any
	onDelete?: (node?: INode) => any
}

export interface INodeContext {
	insert: (nodeInput: INodeInput, options?: INodeContextOptions) => Promise<INode>
	update: (nodeInput: INodeInput, options?: INodeContextOptions) => Promise<INode>
	upsert: (nodeInput: INodeInput, options?: INodeContextOptions) => Promise<INode>
	clone: (node: INode, options?: INodeContextOptions) => Promise<INode>
	groupWarnings: (seedNodes: Node[], edges: Edge[]) => string[]
	groupable: (seedNodes: Node[]) => Node[]
	group: (seedNodes: Node[], options?: INodeContextOptions) => Promise<INode | void>
	remove: (node: { id: string }, options?: INodeContextOptions) => Promise<void>
	openUpsert: (node?: Partial<INode>, options?: INodeContextOptions) => Promise<void>
	closeUpsert: (options?: INodeContextOptions) => Promise<void>
}

export interface INodeRenderProps {
	isExample?: boolean
	label?: string
	element?: string
	element2?: string
	mfa?: string
	comparisonType?: INodeComparisonTypes
	value?: string
	value2?: string
	value3?: string
	value4?: string
	value5?: string
	value6?: string
	value7?: string
	value8?: string
	value9?: string
	value10?: string
	value11?: string
	value12?: string
	value13?: string
	value14?: string
	value15?: string
	value16?: string
	value17?: string
	value18?: string
	value19?: string
	value20?: string
}

export interface IUseNodeField {
	property: string
	type: string
	label: string
	options?: any[]
	optionsFunc?: (values: { [key: string]: any }) => any[]
	defaultValue?: string | string[] | boolean | number
	validation?: any
	conditional?: (props: { values: { [key: string]: any } }) => boolean
	helper?: string
	asStringValue?: boolean
	isValueStoreKey?: boolean
	isFilename?: boolean
	labelTrue?: string
	labelFalse?: string
	valueTrue?: any
	valueFalse?: any
	config?: { [key: string]: any }
	box?: { [key: string]: any }
	grid?: number
	children?: JSX.Element
	isDev?: boolean
	autocomplete?: string
	disabled?: boolean
}

export interface IUseNodeConfig {
	key: INodeVariantTypes
	isLive: boolean
	isMenuOption: boolean
	isPro: boolean
	isAccessibility: boolean
	hasInput: boolean
	hasOutput: boolean
	isMultiLine?: boolean
	fields?: IUseNodeField[]
	label: string
	optionLabel?: string
	sentence: (props: INodeRenderProps) => string
	nodeStyle?: string
	family: string
	type: string
	subType?: string
	tags: string[]
}

export interface IUseNode extends IUseNodeConfig {
	render: (props: INodeRenderProps) => JSX.Element
}

// ? ---
// ?	Type Guards
// ? ---
export const isNode = (node: INode): boolean => {
	return node.__typename === 'NodeEntity'
}
