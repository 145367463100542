// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import * as yup from 'yup'

import { compact } from 'lodash'

import { ACTION_INPUT_GROUP } from 'globals/constants/variantGroups'

import { formatElement, formatValue, sentenceElement, sentenceValue } from 'hooks/useNode'

import { INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.action_input_sendKeys,
	isLive: true,
	isMenuOption: true,
	isPro: false,
	isAccessibility: true,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.variantType',
			type: 'variantType',
			label: 'Type',
			options: ACTION_INPUT_GROUP,
		},
		{
			property: 'attributes.value2',
			type: 'switch',
			label: 'Focus',
			defaultValue: 'element',
			valueFalse: 'current',
			labelFalse: 'Retain current',
			valueTrue: 'element',
			labelTrue: 'Element focus',
		},
		{
			property: 'attributes.element',
			type: 'element',
			label: 'Element',
			validation: yup.string().when('value2', {
				is: 'element',
				then: (schema) => schema.required('Element is required'),
				otherwise: (schema) => schema.notRequired(),
			}),
			conditional: ({ values }: { values: { [key: string]: any } }) => {
				return values.attributes.value2 === 'element'
			},
		},
		{
			property: 'attributes.value',
			type: 'text-with-keys',
			label: 'Keys',
			validation: yup.string().trim().required('Value is required'),
		},
	],
	label: 'Send keys',
	sentence: ({ element, isExample, value, value2 }: INodeRenderProps) => {
		return isExample
			? `Send keys`
			: value2 !== 'current'
			? `Send ${sentenceElement(element)} keys ${sentenceValue(value)}`
			: `Send keys ${sentenceValue(value)}`
	},
	family: INodeVariantTypes.action_input_sendKeys.split('_')[0],
	type: INodeVariantTypes.action_input_sendKeys.split('_')[1],
	subType: INodeVariantTypes.action_input_sendKeys.split('_')[2],
	tags: compact([
		INodeVariantTypes.action_input_sendKeys.split('_')[0],
		INodeVariantTypes.action_input_sendKeys.split('_')[1],
		INodeVariantTypes.action_input_sendKeys.split('_')[2],
		'fill',
		'type',
		'keyboard',
		'text',
		'keys',
		'set',
		'value',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ element, isExample, value, value2 }: INodeRenderProps) => {
			return (
				<>
					{isExample ? (
						<>Send keys</>
					) : (
						<>
							Send{' '}
							{value2 !== 'current' && (
								<>
									<strong>{formatElement(element)}</strong>{' '}
								</>
							)}
							keys <strong>{formatValue(value)}</strong>
						</>
					)}
				</>
			)
		},
	}
}
