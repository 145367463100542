// ? ---
// ?	Fragment
// ? ---
import gql from 'graphql-tag'

import { MAX_FLOW_NODES, MAX_GROUPED_NODES } from 'globals/constants/api'

export const NODE_FRAGMENT = `
fragment nodeFragment on NodeEntity {
	id
	attributes {
		element {
			data {
			  id 
			  attributes {
          selector
          title
          type
         }
			}
		}
		element2 {
			data {
			  id 
			  attributes {
          selector
          title
          type
         }
			}
		}
		mfa {
			data {
			  id 
			  attributes {
          title
         }
			}
		}
		label
		value
		value2
		value3
		value4
		value5
		value6
		value7
		value8
		value9
		value10
		value11
		value12
		value13
		value14
		value15
		value16
		value17
		value18
		value19
		value20
		variantType
		comparisonType
		config {
			failedStatus
			timeoutOverride
		}
		nodes {
			data {
				id
				attributes {
					element {
						data {
						  id 
						  attributes {
			          selector
			          title
			          type
			         }
						}
					}
					element2 {
						data {
						  id 
						  attributes {
			          selector
			          title
			          type
			         }
						}
					}
					mfa {
						data {
						  id
						  attributes {
			          title
			         }
						}
					}
					label
					value
					value2
					value3
					value4
					value5
					value6
					value7
					value8
					value9
					value10
					value11
					value12
					value13
					value14
					value15
					value16
					value17
					value18
					value19
					value20
					variantType
					comparisonType
					group {
						data {
							id
						}
					}
					config {
						failedStatus
						timeoutOverride
					}
				}
			}
		}
		group {
			data {
				id
				attributes {
					label
					variantType
				}
			}
		}
		flow {
			data {
				id
			}
		}
	}
}
`

// ? ---
// ?	Queries
// ? --
export const QUERY_NODES = gql(/* GraphQL */ `
    ${NODE_FRAGMENT}
    query query_nodes_main($filters: NodeFiltersInput!) {
        nodes(filters: $filters, pagination: { pageSize: ${MAX_FLOW_NODES} }) {
            data {
                ...nodeFragment
            }
        }
    }
`)

export const QUERY_NODES_GROUPED = gql(/* GraphQL */ `
    ${NODE_FRAGMENT}
    query query_nodes_grouped($filters: NodeFiltersInput!) {
        nodes(
            filters: $filters
	        	pagination: { pageSize: ${MAX_GROUPED_NODES} }
        ) {
		        data {
		            ...nodeFragment
		        }
        }
    }
`)

export const QUERY_NODES_IDS = gql(/* GraphQL */ `
	query query_nodes_ids($filters: NodeFiltersInput!) {
		nodes(filters: $filters, pagination: { pageSize: 1000 }) {
			data {
				id
			}
		}
	}
`)

export const QUERY_NODES_VARIANTS = gql(/* GraphQL */ `
	query query_nodes_variants($filters: NodeFiltersInput!) {
		nodes(filters: $filters, pagination: { pageSize: 5000 }) {
			data {
				id
				attributes {
					variantType
				}
			}
		}
	}
`)

// ? ---
// ?	Mutations
// ? --
export const ADD_NODE = gql(/* GraphQL */ `
	${NODE_FRAGMENT}
	mutation create_node($data: NodeInput!) {
		createNode(data: $data) {
			data {
				...nodeFragment
			}
		}
	}
`)

export const EDIT_NODE = gql(/* GraphQL */ `
	${NODE_FRAGMENT}
	mutation update_node($id: ID!, $data: NodeInput!) {
		updateNode(id: $id, data: $data) {
			data {
				...nodeFragment
			}
		}
	}
`)

export const NUKE_NODE = gql(/* GraphQL */ `
	${NODE_FRAGMENT}
	mutation delete_node($id: ID!) {
		deleteNode(id: $id) {
			data {
				...nodeFragment
			}
		}
	}
`)
