export const LIBRARY_DRAWER_WIDTH = 250
export const MAIL_MESSAGES_WIDTH = 300
export const MENU_DRAWER_WIDTH = 90
export const MENU_DRAWER_HEIGHT = 80
export const DIALOG_SMALL_WIDTH = 450
export const DIALOG_MEDIUM_WIDTH = 650
export const DIALOG_LARGE_WIDTH = 1000
export const STEP_THUMBNAIL_WIDTH = 200
export const TEST_STATUS_WIDTH = 90
export const CHILD_TEST_STATUS_WIDTH = 50
export const TEST_EXPAND_CONTROL_WIDTH = 60
export const NODE_ICON_AREA_WIDTH = 40
export const NODE_MAXIMUM_LABEL_WIDTH = 800
export const RUN_STATUS_COLUMN_WIDTH = 50
