// ? ---
// ?	Imports
// ? ---
import * as React from 'react'

import { compact, includes } from 'lodash'

import { STANDARD_COMPARISON_TYPE_CONDITION } from 'globals/client/conditions'
import { FAILED_STATUS_OPTIONS } from 'globals/constants/selectOptions'
import {
	COMPARISON_TYPE_VALIDATION,
	ELEMENT_VALIDATION,
	STANDARD_COMPARISON_TYPE_CONDITIONAL_VALIDATION,
} from 'globals/constants/validations'
import { RUNNER_WAIT_GROUP } from 'globals/constants/variantGroups'

import { formatComparisonType, formatElement, formatValue, sentenceElement, sentenceValue } from 'hooks/useNode'

import { INodeComparisonTypes, INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'
import { ITestStepStatus } from 'data/tests'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.runner_wait_forElementText,
	isLive: true,
	isMenuOption: true,
	isPro: false,
	isAccessibility: false,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.variantType',
			type: 'variantType',
			label: 'Type',
			options: RUNNER_WAIT_GROUP,
		},
		{
			property: 'attributes.element',
			type: 'element',
			label: 'Element',
			validation: ELEMENT_VALIDATION,
		},
		{
			property: 'attributes.comparisonType',
			type: 'comparisonType',
			defaultValue: 'equals',
			label: 'Comparison Type',
			validation: COMPARISON_TYPE_VALIDATION,
		},
		{
			property: 'attributes.value',
			type: 'text-with-tags',
			label: 'Value',
			conditional: STANDARD_COMPARISON_TYPE_CONDITION,
			validation: STANDARD_COMPARISON_TYPE_CONDITIONAL_VALIDATION,
		},
		{
			property: 'attributes.config.timeoutOverride',
			type: 'button-group',
			defaultValue: 'slow',
			label: 'How fast is this typically?',
			options: [
				{
					label: 'Fast',
					value: 'fast',
				},
				{
					label: 'Slow',
					value: 'slow',
				},
				{
					label: 'Very Slow',
					value: 'very_slow',
				},
			],
		},
		{
			property: 'attributes.config.failedStatus',
			type: 'button-group',
			label: 'Status on fail',
			defaultValue: ITestStepStatus.failed,
			options: FAILED_STATUS_OPTIONS,
		},
	],
	label: 'Wait for element text',
	sentence: ({ comparisonType, element, isExample, value }: INodeRenderProps) => {
		return isExample
			? `Wait for element text`
			: `Wait for when ${sentenceElement(element)} text ${formatComparisonType(comparisonType)} ${
					!includes([INodeComparisonTypes.hasValue, INodeComparisonTypes.notHasValue], comparisonType) &&
					sentenceValue(value)
			  }`
	},
	family: INodeVariantTypes.runner_wait_forElementText.split('_')[0],
	type: INodeVariantTypes.runner_wait_forElementText.split('_')[1],
	subType: INodeVariantTypes.runner_wait_forElementText.split('_')[2],
	tags: compact([
		INodeVariantTypes.runner_wait_forElementText.split('_')[0],
		INodeVariantTypes.runner_wait_forElementText.split('_')[1],
		INodeVariantTypes.runner_wait_forElementText.split('_')[2],
		'wait',
		'time',
		'sleep',
		'pause',
		'element',
		'delay',
		'text',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ comparisonType, element, isExample, value }: INodeRenderProps) => {
			return (
				<>
					{isExample ? (
						`Wait for text`
					) : (
						<>
							Wait for when <strong>{formatElement(element)}</strong> text{' '}
							<em>{formatComparisonType(comparisonType)}</em>
							{!includes(
								[INodeComparisonTypes.hasValue, INodeComparisonTypes.notHasValue],
								comparisonType
							) && <strong>{formatValue(value)}</strong>}
						</>
					)}
				</>
			)
		},
	}
}
