// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'

import { Alert, Box, Divider, useTheme } from '@mui/material'

import useCan from 'hooks/useCan'
import useThemeMode from 'hooks/useThemeMode'

import { IUseNodeField } from 'data/nodes'

import BooleanButton from './Fields/BooleanButton'
import BooleanSwitch from './Fields/BooleanSwitch'
import ButtonGroup from './Fields/ButtonGroup'
import ButtonGroupMulti from './Fields/ButtonGroupMulti'
import CalculatorOperation from './Fields/CalculatorOperation'
import ComparisonType from './Fields/ComparisonType'
import Css from './Fields/Css'
import Dropdown from './Fields/Dropdown'
import Flows from './Fields/Flows'
import Json from './Fields/Json'
import MultiSelect from './Fields/MultiSelect'
import ReplacementOperation from './Fields/ReplacementOperation'
import Schedule from './Fields/Schedule'
import SelectElement from './Fields/SelectElement'
import SelectFile from './Fields/SelectFile'
import SelectMFA from './Fields/SelectMFA'
import SelectNodes from './Fields/SelectNodes'
import SelectRecipe from './Fields/SelectRecipe'
import SelectSlackChannel from './Fields/SelectSlackChannel'
import SelectValue from './Fields/SelectValue'
import SliderPercentage from './Fields/SliderPercentage'
import SliderRange from './Fields/SliderRange'
import Tags from './Fields/Tags'
import Text from './Fields/Text'
import TextWithKeys from './Fields/TextWithKeys'
import TextWithTags from './Fields/TextWithTags'
import VariantType from './Fields/VariantType'
import VariantTypeButtonGroup from './Fields/VariantTypeButtonGroup'
import Xpath from './Fields/Xpath'

// ? ---
// ?	Types
// ? ---
type Props = {
	formik: any
	field: IUseNodeField
	subject?: any
	close?: () => void
	pb?: number
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Dialogs-UpsertFields-UpsertFields'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function Field({ close, field, formik, pb, subject }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const theme = useTheme()
	const _theme = useThemeMode()
	const Can = useCan()

	// ! ---
	// !	Return: Bad - Field isDev but account is not
	// ! ---
	if (field.isDev && !Can.access.devFeature()) return <></>

	// * ---
	// *	Return: Good
	// * ---
	return (
		<Box sx={{ pb: pb || 2, border: field.isDev ? `1px dotted red` : undefined }}>
			{field.type === 'element' ? (
				<SelectElement data-test-id={`${namespace}--${field.type}`} field={field} formik={formik} />
			) : field.type === 'mfa' ? (
				<SelectMFA data-test-id={`${namespace}--${field.type}`} field={field} formik={formik} />
			) : field.type === 'recipe' ? (
				<SelectRecipe data-test-id={`${namespace}--${field.type}`} field={field} formik={formik} />
			) : field.type === 'nodes' ? (
				<SelectNodes
					data-test-id={`${namespace}--${field.type}`}
					field={field}
					formik={formik}
					parentNode={subject}
				/>
			) : field.type === 'flows' ? (
				<Flows data-test-id={`${namespace}--${field.type}`} field={field} formik={formik} />
			) : field.type === 'tags' ? (
				<Tags data-test-id={`${namespace}--${field.type}`} field={field} formik={formik} />
			) : field.type === 'value' ? (
				<SelectValue data-test-id={`${namespace}--${field.type}`} field={field} formik={formik} />
			) : field.type === 'file' ? (
				<SelectFile data-test-id={`${namespace}--${field.type}`} field={field} formik={formik} />
			) : field.type === 'slackChannel' ? (
				<SelectSlackChannel
					data-test-id={`${namespace}--${field.type}`}
					field={field}
					formik={formik}
					close={close}
				/>
			) : field.type === 'comparisonType' ? (
				<ComparisonType data-test-id={`${namespace}--${field.type}`} field={field} formik={formik} />
			) : field.type === 'variantType' ? (
				<VariantType data-test-id={`${namespace}--${field.type}`} field={field} formik={formik} />
			) : field.type === 'variantTypeButtonGroup' ? (
				<VariantTypeButtonGroup data-test-id={`${namespace}--${field.type}`} field={field} formik={formik} />
			) : field.type === 'dropdown' ? (
				<Dropdown data-test-id={`${namespace}--${field.type}`} field={field} formik={formik} />
			) : field.type === 'button-group' ? (
				<ButtonGroup data-test-id={`${namespace}--${field.type}`} field={field} formik={formik} />
			) : field.type === 'button-group-multi' ? (
				<ButtonGroupMulti data-test-id={`${namespace}--${field.type}`} field={field} formik={formik} />
			) : field.type === 'text-with-tags' ? (
				<TextWithTags data-test-id={`${namespace}--${field.type}`} field={field} formik={formik} />
			) : field.type === 'text-with-keys' ? (
				<TextWithKeys data-test-id={`${namespace}--${field.type}`} field={field} formik={formik} />
			) : field.type === 'calculator-operation' ? (
				<CalculatorOperation data-test-id={`${namespace}--${field.type}`} field={field} formik={formik} />
			) : field.type === 'replace-operation' ? (
				<ReplacementOperation data-test-id={`${namespace}--${field.type}`} field={field} formik={formik} />
			) : field.type === 'json' ? (
				<Json data-test-id={`${namespace}--${field.type}`} field={field} formik={formik} />
			) : field.type === 'css' ? (
				<Css data-test-id={`${namespace}--${field.type}`} field={field} formik={formik} />
			) : field.type === 'xpath' ? (
				<Xpath data-test-id={`${namespace}--${field.type}`} field={field} formik={formik} />
			) : field.type === 'button' ? (
				<BooleanButton data-test-id={`${namespace}--${field.type}`} field={field} formik={formik} />
			) : field.type === 'switch' ? (
				<BooleanSwitch data-test-id={`${namespace}--${field.type}`} field={field} formik={formik} />
			) : field.type === 'multiSelect' ? (
				<MultiSelect data-test-id={`${namespace}--${field.type}`} field={field} formik={formik} />
			) : field.type === 'slider-percentage' ? (
				<SliderPercentage data-test-id={`${namespace}--${field.type}`} field={field} formik={formik} />
			) : field.type === 'schedule' ? (
				<Schedule data-test-id={`${namespace}--${field.type}`} field={field} formik={formik} />
			) : field.type === 'slider' ? (
				<SliderRange data-test-id={`${namespace}--${field.type}`} field={field} formik={formik} />
			) : field.type === 'textarea' ? (
				<Text data-test-id={`${namespace}--${field.type}`} field={field} formik={formik} multiline={true} />
			) : field.type === 'divider' ? (
				<Box
					data-test-id={`${namespace}--${field.type}`}
					sx={{
						'& *, & *:before, & *:after': {
							borderColor: `${_theme.conditional(
								theme.palette.grey[500],
								theme.palette.grey[700]
							)} !important`,
						},
					}}
				>
					<Divider sx={{ color: theme.palette.text.primary, fontSize: theme.typography.caption.fontSize }}>
						{field.label}
					</Divider>
				</Box>
			) : field.type === 'message' ? (
				<Box data-test-id={`${namespace}--${field.type}`}>
					<Alert
						severity='info'
						sx={{
							border: _theme.conditional(`1px solid ${theme.palette.info.dark}`, undefined),
							fontWeight: 500,
						}}
					>
						{field.children}
					</Alert>
				</Box>
			) : (
				<Text field={field} formik={formik} />
			)}
		</Box>
	)
}
