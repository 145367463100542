// ? ---
// ?	Imports
// ? ---
import { compact, filter, isEmpty, map } from 'lodash'

import { INodeVariantTypes } from 'data/nodes'
import { ITestInput } from 'data/tests'

// ? ---
// ?	Export
// ? ---
export const generateTestName = (test: ITestInput, index: number): string => {
	let title = ''
	const titleParts = compact(
		map(
			filter(test.attributes.stepData, {
				node: { variantType: INodeVariantTypes.utility_name_test },
			}),
			'node.value'
		)
	)
	if (isEmpty(titleParts)) {
		// ? Boring name
		title = `${test?.attributes?.flowData?.title || ''} - ${index}`
	} else {
		// ? Good name
		title = titleParts.join(' ➟ ')
	}
	return title
}
