// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import * as yup from 'yup'

import { compact } from 'lodash'

import { ELEMENT_VALIDATION } from 'globals/constants/validations'
import { INTEGRATION_GOOGLE_MAPS_GROUP } from 'globals/constants/variantGroups'

import { formatElement, formatValue, sentenceElement, sentenceValue } from 'hooks/useNode'

import { INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.integration_googleMaps_position,
	isLive: false,
	isMenuOption: true,
	isPro: false,
	isAccessibility: false,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.variantType',
			type: 'variantType',
			label: 'Method',
			options: INTEGRATION_GOOGLE_MAPS_GROUP,
		},
		{
			property: 'attributes.element',
			type: 'element',
			label: 'Element',
			validation: ELEMENT_VALIDATION,
		},
		{
			property: 'attributes.value',
			type: 'text-with-tags',
			label: 'Latitude',
			validation: yup.string().trim().required('Latitude is required'),
		},
		{
			property: 'attributes.value2',
			type: 'text-with-tags',
			label: 'Longitude',
			validation: yup.string().trim().required('Longitude is required'),
		},
		{
			property: 'attributes.value3',
			label: 'Zoom Level',
			type: 'slider',
			defaultValue: '7',
			config: {
				min: 1,
				max: 22,
				step: 1,
			},
		},
	],
	label: 'Position',
	sentence: ({ element, isExample, value, value2 }: INodeRenderProps) => {
		return isExample
			? `Position map`
			: `Position map ${sentenceElement(element)} at ${sentenceValue(value)}, ${sentenceValue(value2)}`
	},
	family: INodeVariantTypes.integration_googleMaps_position.split('_')[0],
	type: INodeVariantTypes.integration_googleMaps_position.split('_')[1],
	subType: INodeVariantTypes.integration_googleMaps_position.split('_')[2],
	tags: compact([
		INodeVariantTypes.integration_googleMaps_position.split('_')[0],
		INodeVariantTypes.integration_googleMaps_position.split('_')[1],
		INodeVariantTypes.integration_googleMaps_position.split('_')[2],
		'maps',
		'google',
		'latitude',
		'longitude',
		'zoom',
		'pan',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ element, isExample, value, value2 }: INodeRenderProps) => {
			return (
				<>
					{isExample ? (
						<>Position</>
					) : (
						<>
							Position <strong>{formatElement(element)}</strong> at <strong>{formatValue(value)}</strong>,{' '}
							<strong>{formatValue(value2)}</strong>
						</>
					)}
				</>
			)
		},
	}
}
