// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'
import { useFormik } from 'formik'
import { AnimatePresence, motion, useAnimation } from 'framer-motion'

import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Grid, Stack } from '@mui/material'
import { grey } from '@mui/material/colors'
import Icon from '@mdi/react'
import { isNil, map } from 'lodash'

import theme from 'globals/client/theme'
import { icons } from 'globals/constants/icons'
import { DIALOG_MEDIUM_WIDTH } from 'globals/constants/sizes'

import useAccount from 'hooks/useAccount'
import useThemeMode from 'hooks/useThemeMode'

import ShakeablePaperMedium from 'components/_Global/Dialogs/helpers/ShakeablePaperMedium'
import { Transition } from 'components/_Global/Dialogs/helpers/Transition'
import Field from 'components/_Global/Forms/Field'
import { IAccountValue, IAccountValueTypeOptions, valueValidationSchema } from 'data/accounts'

// ? ---
// ?	Types
// ? ---
type Props = {
	state: boolean
	data: undefined | Partial<IAccountValue>
	options?: {
		[key: string]: any
	}
	close: () => void
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-AccountValues-AccountValueDialog'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function ValueDialog({ close, data, options, state }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const _theme = useThemeMode()
	const _account = useAccount()
	const controls = useAnimation()

	// * ---
	// *	State
	// * ---
	const [saving, $saving] = React.useState(false)

	// * ---
	// *	Formik
	// * ---
	const formik = useFormik({
		validationSchema: valueValidationSchema,
		initialValues: {
			id: data?.id,
			name: data?.name || '',
			type: data?.type || 'string',
			stringValue: data?.stringValue || '',
			numberValue: data?.numberValue || 0,
			booleanValue: data?.booleanValue || false,
			jsonValue: isNil(data?.jsonValue) ? {} : data?.jsonValue,
			isPrivate: data?.isPrivate || false,
		} as IAccountValue,
		onSubmit: async (values) => {
			$saving(true)
			log('onSubmit', values)
			await _account.valueUpsert(values, {
				onSuccess: (account) => {
					log('onSubmit onSuccess', { account, options })
					close()
					$saving(false)
					if (options?.onSuccess) options.onSuccess(account)
					if (options?.onComplete) options.onComplete(account)
				},
			})
		},
	})

	// * ---
	// *	Return: Good
	// * ---
	return (
		<Dialog
			open={state}
			TransitionComponent={Transition}
			sx={{
				'& .MuiDialog-paperFullWidth': {
					maxWidth: DIALOG_MEDIUM_WIDTH,
				},
			}}
			fullWidth={true}
			onClose={() => (formik.dirty ? controls.start('start') : close())}
			PaperComponent={ShakeablePaperMedium}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			PaperProps={{ controls }}
			aria-labelledby={namespace}
		>
			<form onSubmit={formik.handleSubmit}>
				<DialogTitle id={namespace} className={'dragHandle'}>
					Value
				</DialogTitle>
				<DialogContent>
					<Stack sx={{ pt: 2 }}>
						<Field
							field={{
								property: 'name',
								type: 'text',
								label: 'Storage Name',
							}}
							formik={formik}
						/>
						{!data?.id || !data?.isPrivate ? (
							<>
								<Field
									field={{
										property: 'type',
										type: 'dropdown',
										label: 'Type',
										defaultValue: 'string',
										options: map(IAccountValueTypeOptions, (label, value) => {
											return {
												label,
												value,
											}
										}),
									}}
									formik={formik}
								/>
								<AnimatePresence>
									{formik.values.type === 'json' ? (
										<motion.div
											initial={{ opacity: 0, height: 0, overflow: 'hidden' }}
											animate={{
												opacity: 1,
												height: 'auto',
												overflow: 'visible',
											}}
											exit={{
												opacity: 0,
												height: 0,
												overflow: 'hidden',
											}}
										>
											<Field
												field={{
													property: 'jsonValue',
													type: 'json',
													label: 'Value',
												}}
												formik={formik}
											/>
										</motion.div>
									) : formik.values.type === 'boolean' ? (
										<motion.div
											initial={{ opacity: 0, height: 0, overflow: 'hidden' }}
											animate={{
												opacity: 1,
												height: 'auto',
												overflow: 'visible',
											}}
											exit={{
												opacity: 0,
												height: 0,
												overflow: 'hidden',
											}}
										>
											<Field
												field={{
													property: 'booleanValue',
													type: 'switch',
													label: 'Value',
													defaultValue: false,
													valueTrue: true,
													valueFalse: false,
												}}
												formik={formik}
											/>
										</motion.div>
									) : formik.values.type === 'number' ? (
										<motion.div
											initial={{ opacity: 0, height: 0, overflow: 'hidden' }}
											animate={{
												opacity: 1,
												height: 'auto',
												overflow: 'visible',
											}}
											exit={{
												opacity: 0,
												height: 0,
												overflow: 'hidden',
											}}
										>
											<Field
												field={{
													property: 'numberValue',
													type: 'text',
													label: 'Value',
												}}
												formik={formik}
											/>
										</motion.div>
									) : (
										<motion.div
											initial={{ opacity: 0, height: 0, overflow: 'hidden' }}
											animate={{
												opacity: 1,
												height: 'auto',
												overflow: 'visible',
											}}
											exit={{
												opacity: 0,
												height: 0,
												overflow: 'hidden',
											}}
										>
											<Field
												field={{
													property: 'stringValue',
													type: 'text',
													label: 'Value',
												}}
												formik={formik}
											/>
										</motion.div>
									)}
								</AnimatePresence>
								<AnimatePresence>
									<motion.div
										initial={{ opacity: 0, height: 0, overflow: 'hidden' }}
										animate={{
											opacity: 1,
											height: 'auto',
											overflow: 'visible',
										}}
										exit={{
											opacity: 0,
											height: 0,
											overflow: 'hidden',
										}}
									>
										<Field
											field={{
												property: 'isPrivate',
												type: 'switch',
												label: 'Private value',
												helper: 'Private values cannot be viewed or edited later',
											}}
											formik={formik}
										/>
									</motion.div>
								</AnimatePresence>
							</>
						) : (
							<Alert
								severity='warning'
								sx={{
									border: _theme.conditional(`1px solid ${theme.palette.warning.dark}`, undefined),
									mb: 0.5,
								}}
							>
								Private values cannot be viewed or edited
							</Alert>
						)}
					</Stack>
				</DialogContent>
				<DialogActions>
					<Grid
						container
						direction='row'
						justifyContent='space-between'
						alignItems='center'
						spacing={1}
						sx={{ pl: 1, pr: 1 }}
					>
						<Grid item></Grid>
						<Grid item>
							<Button
								data-test-id='Ao7AU2BkoH4GclU5gnjDQ'
								color={'secondary'}
								onClick={() => {
									close()
								}}
							>
								Cancel
							</Button>
						</Grid>
					</Grid>
					<Fab
						color='primary'
						aria-label='Save Flow'
						type='submit'
						disabled={saving}
						sx={{
							backgroundColor: saving || !formik.isValid ? grey[400] : undefined,
						}}
					>
						<Icon path={saving ? icons.loadingIcon : icons.agreeIcon} size={1} spin={saving ? 1 : 0} />
					</Fab>
				</DialogActions>
			</form>
		</Dialog>
	)
}
