import * as Sentry from '@sentry/nextjs'
import debug from 'debug'
import hash from 'object-hash'
import omitDeep from 'omit-deep-lodash'

import { compact, find, forEach, get, isArray, isNil, isNull, map, omit, toNumber } from 'lodash'

import * as nodes from 'components/Nodes/library'
import { IAccountOptions } from 'data/accounts'
import { IElementSelectorType } from 'data/elements'
import { IFlow } from 'data/flows'
import { INode, INodeComparisonTypes, INodeVariantTypes } from 'data/nodes'
import { IRunData } from 'data/runs'
import { ITestInput, ITestStatus, ITestStep, ITestStepStatus } from 'data/tests'
import { generateTestName } from 'data/tests/helpers/generateTestName'

import { extractTests } from './extractTests'

// ? ---
// ?	Types
// ? ---
type Props = {
	flow: IFlow
	account?: IAccountOptions
	previews?: boolean
	run: IRunData
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'data-flows-helpers-constructTests'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Export
// ? ---
export const constructTests = async ({ account, flow, previews, run }: Props): Promise<ITestInput[]> => {
	log('constructTests', { flow })

	// * ---
	// * Extract Tests
	// * ---
	const tests: any[] = []
	try {
		tests.push(...extractTests(flow))
	} catch (err) {
		log('!! Error extracting tests', err)
		Sentry.captureMessage(`${namespace} !! Error extracting tests ${err}`, 'error')
		return []
	}

	log(`${tests.length} test(s) found in flow`)

	// * ---
	// * Shape Step
	// * ---
	const formatTestStep = (node: INode, index: number, nodeInstanceId: string, isChild?: boolean) => {
		return {
			status: ITestStepStatus.pending,
			index,
			hash: '',
			url: '',
			isChild: !!isChild,
			node: {
				nodeId: toNumber(node.id),
				nodeInstanceId: nodeInstanceId,
				label: nodes[node.attributes.variantType].node.sentence({
					element: get(node, 'attributes.element.data.attributes.title'),
					element2: get(node, 'attributes.element2.data.attributes.title'),
					mfa: get(node, 'attributes.mfa.data.attributes.title'),
					comparisonType: get(node, 'attributes.comparisonType'),
					label: get(node, 'attributes.label'),
					value: get(node, 'attributes.value'),
					value2: get(node, 'attributes.value2'),
					value3: get(node, 'attributes.value3'),
					value4: get(node, 'attributes.value4'),
					value5: get(node, 'attributes.value5'),
					value6: get(node, 'attributes.value6'),
					value7: get(node, 'attributes.value7'),
					value8: get(node, 'attributes.value8'),
					value9: get(node, 'attributes.value9'),
					value10: get(node, 'attributes.value10'),
					value11: get(node, 'attributes.value11'),
					value12: get(node, 'attributes.value12'),
					value13: get(node, 'attributes.value13'),
					value14: get(node, 'attributes.value14'),
					value15: get(node, 'attributes.value15'),
					value16: get(node, 'attributes.value16'),
					value17: get(node, 'attributes.value17'),
					value18: get(node, 'attributes.value18'),
					value19: get(node, 'attributes.value19'),
					value20: get(node, 'attributes.value20'),
				}),
				variantType: node.attributes.variantType,
				comparisonType: node.attributes.comparisonType || INodeComparisonTypes.equals,
				value: node.attributes.value,
				value2: node.attributes.value2,
				value3: node.attributes.value3,
				value4: node.attributes.value4,
				value5: node.attributes.value5,
				value6: node.attributes.value6,
				value7: node.attributes.value7,
				value8: node.attributes.value8,
				value9: node.attributes.value9,
				value10: node.attributes.value10,
				value11: node.attributes.value11,
				value12: node.attributes.value12,
				value13: node.attributes.value13,
				value14: node.attributes.value14,
				value15: node.attributes.value15,
				value16: node.attributes.value16,
				value17: node.attributes.value17,
				value18: node.attributes.value18,
				value19: node.attributes.value19,
				value20: node.attributes.value20,
				elementId: toNumber(node.attributes.element?.data?.id || 0),
				elementTitle: node.attributes.element?.data?.attributes.title || '',
				elementType: node.attributes.element?.data?.attributes.type || IElementSelectorType.css,
				elementSelector: node.attributes.element?.data?.attributes.selector || '',
				element2Id: toNumber(node.attributes.element2?.data?.id || 0),
				element2Title: node.attributes.element2?.data?.attributes.title || '',
				element2Type: node.attributes.element2?.data?.attributes.type || IElementSelectorType.css,
				element2Selector: node.attributes.element2?.data?.attributes.selector || '',
				mfaId: toNumber(node.attributes.mfa?.data?.id || 0),
				mfaTitle: node.attributes.mfa?.data?.attributes.title || '',
				config: omitDeep(node.attributes.config || {}, ['__typename']),
			},
		} as ITestStep
	}

	// * ---
	// *	Lookup & shape node
	// * ---
	const formatNodeIntoTestSteps = (nodeId: string, index: number): undefined | ITestStep | ITestStep[] => {
		const nodeInstance = find(flow.attributes.nodeMeta, { id: nodeId })
		if (!isNull(get(nodeInstance, 'data.id', null))) {
			// * Lookup flow node (includes flow meta like position)
			const node = find(flow.attributes.nodes.data, { id: nodeInstance?.data.id })
			if (node && !isNull(get(node, 'id', null))) {
				// * Format Shape
				if (node.attributes.variantType === INodeVariantTypes.special_group) {
					// ? Node Group
					log('node group children', node.attributes)
					return compact([
						formatTestStep(node, index, nodeInstance?.id),
						...map(node.attributes.nodes?.data, (childNode: INode, childIndex: number) => {
							return formatTestStep(childNode, index + (childIndex + 1) / 1000, nodeInstance?.id, true)
						}),
					]) as unknown as ITestStep[]
				} else {
					// ? Normal Node
					return formatTestStep(node, index, nodeInstance?.id)
				}
			}
		}
	}

	// * ---
	// *	Construct Tests
	// * ---
	const constructedTests: ITestInput[] = tests.map((testOutline) => {
		// * Build step templates
		const stepTemplates: ITestStep[] = []
		forEach(testOutline, (nodeId: string) => {
			const steps = formatNodeIntoTestSteps(nodeId, stepTemplates.length)
			// ! Drop out if invalid step
			if (isNil(steps)) return

			// * Handle Node Type
			if (isArray(steps)) {
				// ? Node Group & child steps
				stepTemplates.push(...steps)
			} else {
				// ? Normal step
				stepTemplates.push(steps)
			}
		})

		// * Populate steps with step hash
		const precedingStepHashes: string[] = []
		const steps: ITestStep[] = map(stepTemplates, (step) => {
			const thisStepHash = hash([...precedingStepHashes, step.node])
			precedingStepHashes.push(thisStepHash)
			return {
				...step,
				hash: thisStepHash,
			}
		})

		// * Return
		return {
			attributes: {
				title: '',
				hash: hash([flow.id, ...map(steps, 'hash')]), // TODO: add edgeMeta but only for the edges connected to nodes in this test
				status: previews ? ITestStatus.preview : ITestStatus.pending,
				stepData: steps,
				accountData: account,
				additionalTriggers: [`/flows/${flow.id}`, `/flows/${flow.id}/tests`],
				runId: run.uid,
				runData: {
					...run,
					events: map(run.events, (event) => {
						return {
							...omit(event, ['attributes']),
							...event.attributes,
						}
					}),
				},
				flowId: flow.id,
				flowData: {
					flowId: flow.id,
					title: flow.attributes.title,
					video: true, // TODO: Video should be a toggle & option,
					lighthouse: flow.attributes.lighthouse,
					stepTimeoutSeconds: flow.attributes.stepTimeoutSeconds,
					stopTestAfterFirstError: flow.attributes.stopTestAfterFirstError,
					useLightningLane: flow.attributes.useLightningLane,
					isIsolated: flow.attributes.isIsolated,
				},
			},
		}
	})

	log('constructedTests', constructedTests)

	// * Return
	return map(constructedTests, (test, index) => {
		return {
			...test,
			attributes: {
				...test.attributes,
				title: generateTestName(test, index),
			},
		}
	})
}
