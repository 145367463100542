// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import * as yup from 'yup'

import { compact } from 'lodash'

import { formatValue, sentenceValue } from 'hooks/useNode'

import { INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.data_sessionStorage_set,
	isLive: true,
	isMenuOption: true,
	isPro: false,
	isAccessibility: false,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.value',
			type: 'text',
			label: 'Key Name',
			validation: yup.string().trim().required('sessionStorage key is required'),
		},
		{
			property: 'attributes.value2',
			type: 'text-with-tags',
			label: 'Key Value',
			validation: yup.string(),
		},
	],
	label: 'Set sessionStorage',
	sentence: ({ isExample, value: key, value2: value }: INodeRenderProps) => {
		return isExample ? `Set sessionStorage` : `Set ${sentenceValue(key)} sessionStorage to ${sentenceValue(value)}`
	},
	family: INodeVariantTypes.data_sessionStorage_set.split('_')[0],
	type: INodeVariantTypes.data_sessionStorage_set.split('_')[1],
	subType: INodeVariantTypes.data_sessionStorage_set.split('_')[2],
	tags: compact([
		INodeVariantTypes.data_sessionStorage_set.split('_')[0],
		INodeVariantTypes.data_sessionStorage_set.split('_')[1],
		INodeVariantTypes.data_sessionStorage_set.split('_')[2],
		'save',
		'add',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ isExample, value: key, value2: value }: INodeRenderProps) => {
			return (
				<>
					Set {!isExample && <strong>{formatValue(key)}</strong>} sessionStorage{` `}
					{!isExample && (
						<>
							to <strong>{formatValue(value)}</strong>
						</>
					)}
				</>
			)
		},
	}
}
