export const COMPARISON_TYPES = {
	hasValue: `Has Any Value`,
	equals: `Equals`,
	contains: `Contains`,
	startsWith: `Starts With`,
	endsWith: `Ends With`,
	notHasValue: `Has No Value`,
	notEquals: `Doesn't Equal`,
	notContains: `Doesn't Contain`,
	notStartsWith: `Doesn't Start With`,
	notEndsWith: `Doesn't End With`,
	greaterThan: `Is Greater Than`,
	greaterThanOrEqual: `Is Greater Than or Equal to`,
	lessThan: `Is Less Than`,
	lessThanOrEqual: `Is Less Than or Equal to`,
	regex: `Matches Regex`,
}

export const NODE_FAMILY = {
	starter: `Starters`,
	action: `Actions`,
	browser: `Browser`,
	check: `Checks`,
	mail: `Mail`,
	change: `Change Detection`,
	validate: `Validators`,
	data: `Data`,
	runner: `Runner`,
	integration: `Integrations`,
	express: `Express Nodes`,
	utility: `Utilities`,
	special: `Node Groups`,
}

export const NODE_FAMILY_SINGULAR = {
	special: `Node Group`,
	starter: `Starter`,
	action: `Action`,
	browser: `Browser`,
	check: `Check`,
	change: `Change Detection`,
	validate: `Validator`,
	data: `Data`,
	runner: `Runner`,
	integration: `Integration`,
	utility: `Utility`,
}

export const NODE_FAMILY_PLURAL = {
	special: `Node Groups`,
	starter: `Starters`,
	action: `Actions`,
	browser: `Browser`,
	check: `Checks`,
	change: `Change Detections`,
	validate: `Validators`,
	data: `Data`,
	runner: `Runner`,
	integration: `Integrations`,
	utility: `Utilities`,
}
