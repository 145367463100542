import * as yup from 'yup'

export const valueValidationSchema = yup.object({
	name: yup
		.string()
		.matches(/^[a-zA-Z]\w+$/gm, 'Must be a valid storage name')
		.required('Storage name is required'),
	numberValue: yup.mixed().when('type', {
		is: 'number',
		then: (schema) => schema.required('Value is required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	stringValue: yup.mixed().when('type', {
		is: 'string',
		then: (schema) => schema.required('Value is required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	booleanValue: yup.mixed().when('type', {
		is: 'boolean',
		then: (schema) => schema,
		otherwise: (schema) => schema.notRequired(),
	}),
	jsonValue: yup.mixed().when('type', {
		is: 'json',
		then: (schema) =>
			schema.test('test-json', 'Value must be valid json', (value) => {
				try {
					JSON.parse(JSON.stringify(value))
					return true
				} catch (err) {
					return false
				}
			}),
		otherwise: (schema) => schema.notRequired(),
	}),
})

export const webhookKeyValidationSchema = yup.object({
	name: yup.string().trim().required('name is required'),
})

export const tagValidationSchema = yup.object({
	label: yup
		.string()
		.matches(/^[a-zA-Z]\w+$/gm, `Must be a valid tag`)
		.required('Tag is required'),
})
