// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'
import { useAnimation } from 'framer-motion'

import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Stack } from '@mui/material'
import Icon from '@mdi/react'

import { colors } from 'globals/constants/colors'
import { icons } from 'globals/constants/icons'
import { DIALOG_MEDIUM_WIDTH } from 'globals/constants/sizes'

import ShakeablePaperMedium from 'components/_Global/Dialogs/helpers/ShakeablePaperMedium'
import { Transition } from 'components/_Global/Dialogs/helpers/Transition'
import { IMfa } from 'data/mfas'

import MfaCode from './MfaCode'

// ? ---
// ?	Types
// ? ---
type Props = {
	state: boolean
	data: undefined | Partial<IMfa>
	options?: {
		[key: string]: any
	}
	close: () => void
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-MFA-MfaDialog'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function MfaDialog({ close, data, state }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const controls = useAnimation()

	// * ---
	// *  Return: Good
	// * ---
	return (
		<Dialog
			open={state}
			TransitionComponent={Transition}
			sx={{
				pb: 0,
				'& .MuiDialog-paperFullWidth': {
					maxWidth: DIALOG_MEDIUM_WIDTH,
				},
			}}
			fullWidth={true}
			onClose={() => close()}
			PaperComponent={ShakeablePaperMedium}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			PaperProps={{ controls }}
			aria-labelledby={namespace}
		>
			<DialogTitle id={namespace} className={'dragHandle'}>
				<Grid container direction='row' justifyContent='space-between' alignItems='center'>
					<Grid item xs>
						{data?.attributes?.title}
					</Grid>
					<Grid item>
						<IconButton
							data-test-id='PKlfKNx2fn0-KaP3uod4i'
							aria-label='close'
							onClick={close}
							sx={{
								color: colors.grey[500],
							}}
						>
							<Icon path={icons.closeIcon} size={1} />
						</IconButton>
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent>
				<Stack sx={{ pt: 2, maxHeight: '78vh' }}>
					<MfaCode mfaId={data?.id} />
				</Stack>
			</DialogContent>
		</Dialog>
	)
}
