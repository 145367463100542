// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import * as yup from 'yup'

import { compact, includes, isEmpty } from 'lodash'

import { formatValue, sentenceValue } from 'hooks/useNode'

import { INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.runner_generate_file,
	isLive: true,
	isMenuOption: true,
	isPro: false,
	isAccessibility: false,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.value',
			type: 'text-with-tags',
			label: 'Filename',
			validation: yup.string().trim().required('Filename is required'),
			isFilename: true,
		},
		{
			property: 'attributes.value2',
			type: 'dropdown',
			label: 'Type',
			defaultValue: 'text/plain',
			options: [
				{ label: 'Plain Text', value: 'text/plain' },
				{ label: 'PDF', value: 'application/pdf' },
				{ label: 'JSON', value: 'application/json' },
				{ label: 'Excel (xlsx)', value: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
			],
		},
		{
			property: 'attributes.value3',
			type: 'text-with-tags',
			label: 'Contents',
			validation: yup.string().trim().required('Value is required'),
			conditional: ({ values }: { values: { [key: string]: any } }) => {
				return (
					isEmpty(values.attributes.value2) ||
					includes(
						[
							'text/plain',
							'application/pdf',
							'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
						],
						values.attributes.value2
					)
				)
			},
		},
		{
			property: 'attributes.value3',
			type: 'json',
			label: 'JSON Contents',
			asStringValue: true,
			helper: `Values can be used inside strings using the format: "{{runId}}".`,
			conditional: ({ values }: { values: { [key: string]: any } }) => {
				return includes(['application/json'], values.attributes.value2)
			},
		},
	],
	label: 'Generate file',
	sentence: ({ isExample, value }: INodeRenderProps) => {
		return isExample ? `Generate file` : `Generate file ${sentenceValue(value)}`
	},
	family: INodeVariantTypes.runner_generate_file.split('_')[0],
	type: INodeVariantTypes.runner_generate_file.split('_')[1],
	subType: INodeVariantTypes.runner_generate_file.split('_')[2],
	tags: compact([
		INodeVariantTypes.runner_generate_file.split('_')[0],
		INodeVariantTypes.runner_generate_file.split('_')[1],
		INodeVariantTypes.runner_generate_file.split('_')[2],
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ isExample, value }: INodeRenderProps) => {
			return (
				<>
					{isExample ? (
						<>Generate file</>
					) : (
						<>
							Generate file <strong>{formatValue(value)}</strong>
						</>
					)}
				</>
			)
		},
	}
}
