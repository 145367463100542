// ? ---
// ?	Imports
// ? ---
import * as React from 'react'

import Link from 'next/link'
import { compact } from 'lodash'

import { ADYEN_GROUP } from 'globals/constants/variantGroups'

import { formatValue, sentenceValue } from 'hooks/useNode'

import { INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.express_adyen_afterpay,
	isLive: false,
	isMenuOption: false,
	isPro: false,
	isAccessibility: false,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.variantType',
			type: 'variantTypeButtonGroup',
			label: 'Payment Option',
			options: ADYEN_GROUP,
		},
		{
			property: 'attributes.value7',
			type: 'text-with-tags',
			label: 'Afterpay / Clearpay Email',
		},
		{
			property: 'attributes.value8',
			type: 'text-with-tags',
			label: 'Afterpay / Clearpay Password',
		},
		{
			property: '',
			label: '',
			type: 'message',
			children: (
				<>
					View{' '}
					<Link
						data-test-id={`${INodeVariantTypes.express_adyen_afterpay}--docs`}
						href={'https://docs.adyen.com/development-resources/testing/test-other-payment-methods/'}
						target={'_blank'}
						style={{ color: 'inherit' }}
					>
						Adyen documentation for test credentials
					</Link>
				</>
			),
		},
	],
	label: 'Afterpay',
	sentence: ({ isExample, value7 }: INodeRenderProps) => {
		return isExample
			? `Complete Afterpay / Clearpay payment`
			: `Complete Afterpay / Clearpay payment with email: ${sentenceValue(value7)}`
	},
	// nodeStyle: 'adyen',
	family: INodeVariantTypes.express_adyen_afterpay.split('_')[0],
	type: INodeVariantTypes.express_adyen_afterpay.split('_')[1],
	subType: INodeVariantTypes.express_adyen_afterpay.split('_')[2],
	tags: compact([
		INodeVariantTypes.express_adyen_afterpay.split('_')[0],
		INodeVariantTypes.express_adyen_afterpay.split('_')[1],
		INodeVariantTypes.express_adyen_afterpay.split('_')[2],
		'rest',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ isExample, value7 }: INodeRenderProps) => {
			return isExample ? (
				<>{'Adyen Widget'}</>
			) : (
				<>
					{'Adyen Afterpay / Clearpay: '}
					<strong>{formatValue(value7)}</strong>
				</>
			)
		},
	}
}
