// ? ---
// ?	Imports
// ? ---
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

// ? ---
// ?	Constants
// ? ---
dayjs.extend(utc)

// ? ---
// ?	Export
// ? ---
export const VALUE_REGEX = /-\{\{-(\{.+?\})-\}\}-/gi

export const GLOBAL_VALUES = [
	// * Current Values
	{ value: 'currentUrl' },
	{ value: 'currentOrigin' },
	{ value: 'currentPath' },
	{ value: 'currentUnix', example: `${dayjs().unix()}` },

	// * Test Special Values
	{ value: 'runId' },
	{ value: 'runLabel' },
	{ value: 'flowName' },

	// * Test Values
	{ value: 'testName' },
	{ value: 'testStartedAt', example: dayjs().utc().format('YYYYMMDDHHmm') },
	{ value: 'testStartedAtDay', example: dayjs().utc().format('DD') },
	{ value: 'testStartedAtMonth', example: dayjs().utc().format('MM') },
	{ value: 'testStartedAtYear', example: dayjs().utc().format('YYYY') },
	{ value: 'testStartedAtUnix', example: `${dayjs().unix()}` },
	{ value: 'testNumber', example: '54321' },
	{ value: 'testEmail' },
	{ value: 'testFirstName' },
	{ value: 'testLastName' },
	{ value: 'testFullName' },
	{ value: 'testUsername' },
	{ value: 'testZipCode' },
	{ value: 'testPhoneUS' },
	{ value: 'testPhoneUK' },
	{ value: 'testSentence' },

	// * Account Values
	{ value: 'accountEmail' },

	// * Random Values
	{ value: 'randomNumber', example: '54321' },
	{ value: 'randomEmail' },
	{ value: 'randomShortEmail' },
	{ value: 'randomFirstName' },
	{ value: 'randomLastName' },
	{ value: 'randomFullName' },
	{ value: 'randomUsername' },
	{ value: 'randomZipCode' },
	{ value: 'randomPhoneUS' },
	{ value: 'randomPhoneUK' },
	{ value: 'randomSentence' },
]
