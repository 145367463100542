// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import { useLazyQuery } from '@apollo/client'
import debug from 'debug'

import { useRouter } from 'next/router'
import { Autocomplete, Box, Grid, TextField } from '@mui/material'
import { find, get, isArray, isEmpty, isEqual, map } from 'lodash'

import useFlow from 'hooks/useFlow'

import { IFlowQueryResponse, QUERY_FLOWS } from 'data/flows'
import { IUseNodeField } from 'data/nodes'

// ? ---
// ?	Types
// ? ---
type Props = {
	formik: any
	field: IUseNodeField

	close?: () => void
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Dialogs-UpsertFields-SelectValue'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function SelectValue({ field, formik }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const _flow = useFlow()
	const router = useRouter()

	// * ---
	// *	State
	// * ---
	const [loaded, $loaded] = React.useState(false)
	const [values, $values] = React.useState<{ label: string; value: string }[]>([])

	// * ---
	// *	Current Flow
	// * ---
	const [loadFlow] = useLazyQuery<IFlowQueryResponse>(QUERY_FLOWS, {
		fetchPolicy: 'network-only',
	})

	// * ---
	// *	Effect: Load Flow Values
	// * ---
	React.useEffect(() => {
		let abort = false
		log('..')

		const load = async (flowId: string) => {
			const flowResponse = await loadFlow({
				variables: {
					filters: {
						id: { eq: flowId },
					},
				},
			})
			log('loadFlow flowResponse', flowResponse)
			if (flowResponse.data?.flows.data[0] && !abort) {
				const tempValues = map(
					_flow.valuesInStore(flowResponse.data?.flows.data[0], { excludeGlobal: true }),
					(item) => {
						return {
							label: item.value,
							...item,
						}
					}
				)
				if (!isEqual(tempValues, values) && !abort) {
					$loaded(true)
					$values(tempValues)
				}
			}
		}

		if (router.query.id && !loaded) {
			const flowId = isArray(router.query?.id) ? router.query.id[0] : router.query?.id
			load(flowId)
		}

		return () => {
			abort = true
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// * ---
	// *	Helpers
	// * ---
	const valueLabelFromValue = (value: string) => `${get(find(values, { value }), 'label', '')}`

	// * ---
	// *	Return
	// * ---
	return (
		<>
			<Grid container direction='row' justifyContent='space-between' alignItems='flex-start'>
				<Grid item xs={12}>
					{!isEmpty(values) && (
						<Autocomplete
							data-test-id={`${namespace}--${field.property}`}
							fullWidth
							id={field.property}
							value={
								get(formik?.values, field.property) !== '' ? get(formik?.values, field.property) : null
							}
							onChange={(event, value) => formik.setFieldValue(field.property, value)}
							isOptionEqualToValue={(optionValue, selectedValue) => {
								return optionValue === selectedValue
							}}
							getOptionLabel={(value) => valueLabelFromValue(value)}
							options={map(values, 'value')}
							renderInput={(params) => (
								<TextField
									{...(params as any)}
									label={field.label}
									error={
										get(formik, `touched[${field.property}]`) &&
										Boolean(get(formik, `errors[${field.property}]`))
									}
									helperText={
										get(formik, `touched[${field.property}]`) &&
										get(formik, `errors[${field.property}]`)
									}
								/>
							)}
							renderOption={(props, value) => (
								<Box component='li' {...(props as any)}>
									{valueLabelFromValue(value)}
								</Box>
							)}
						/>
					)}
				</Grid>
			</Grid>
		</>
	)
}
