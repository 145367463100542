// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import * as yup from 'yup'

import { compact, get, includes, isEmpty, isString, map, startsWith } from 'lodash'

import { VALUE_REGEX } from 'globals/constants/valueStore'
import { STARTER_OPEN_GROUP } from 'globals/constants/variantGroups'

import { formatEnumValue, formatValue, sentenceValue } from 'hooks/useNode'

import {
	INodeRenderProps,
	INodeStarterDevices,
	INodeStarterLanguageOptions,
	INodeStarterRunners,
	INodeStarterThrottleOptions,
	INodeStarterTimezoneOptions,
	INodeVariantTypes,
	IUseNode,
	IUseNodeConfig,
} from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.starter_open_advanced,
	isLive: true,
	isMenuOption: true,
	isPro: false,
	isAccessibility: false,
	hasInput: false,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.variantType',
			type: 'variantType',
			label: 'Type',
			options: STARTER_OPEN_GROUP,
		},
		{
			property: 'attributes.value',
			type: 'text-with-tags',
			defaultValue: 'https://',
			label: 'URL',
			validation: yup.string().test('is-valid-url', 'Must be a fully qualified URL', (value: any) => {
				if (isString(value) && !isEmpty(value)) {
					return value.match(VALUE_REGEX)
						? true
						: /^((https?|ftp):)?\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(
								value
						  )
				}
				return false
			}),
		},
		{
			property: 'divider-0',
			type: 'divider',
			label: 'Runner & Browser',
		},
		{
			property: 'attributes.value5',
			type: 'button-group',
			asStringValue: true,
			defaultValue: 'chrome',
			label: 'Runner',
			options: map(INodeStarterRunners, (label, value) => {
				return {
					label,
					value,
					icon: `${value}Icon`,
				}
			}),
		},
		{
			property: 'attributes.value2',
			type: 'dropdown',
			label: 'Variant',
			defaultValue: 'desktop_1920x1080',
			optionsFunc: (values: { [key: string]: any }) => {
				const runner = get(values, 'attributes.value5', '')
				switch (runner) {
					case 'edge':
					case 'firefox':
						return map(INodeStarterDevices, (label, value) => {
							if (startsWith(value, `${runner}__`)) {
								return {
									label,
									value,
								}
							}
						})
					case 'emulated_device':
						return map(INodeStarterDevices, (label, value) => {
							if (
								!startsWith(value, `desktop_`) &&
								!startsWith(value, `tablet_`) &&
								!startsWith(value, `mobile_`) &&
								!includes(value, `__`)
							) {
								return {
									label,
									value,
								}
							}
						})
					case 'chrome':
					default:
						return map(INodeStarterDevices, (label, value) => {
							if (
								startsWith(value, `desktop_`) ||
								startsWith(value, `tablet_`) ||
								startsWith(value, `mobile_`)
							) {
								return {
									label,
									value,
								}
							}
						})
				}
			},
		},
		{
			property: 'attributes.value3',
			type: 'dropdown',
			label: 'Throttle',
			defaultValue: 'online',
			conditional: ({ values }: { values: { [key: string]: any } }) => {
				return values.attributes.value5 !== 'firefox'
			},
			options: map(INodeStarterThrottleOptions, (label, value) => {
				return {
					label,
					value,
				}
			}),
		},
		{
			property: 'divider-1',
			type: 'divider',
			label: 'Authentication & Custom Headers',
		},
		{
			property: 'attributes.value6',
			type: 'switch',
			label: 'Basic Auth',
			defaultValue: 'false',
			valueFalse: 'false',
			valueTrue: 'true',
			labelTrue: 'Use Basic Auth',
		},
		{
			property: 'attributes.value7',
			type: 'text-with-tags',
			defaultValue: '',
			label: 'Username',
			conditional: ({ values }: { values: { [key: string]: any } }) => {
				return values.attributes.value6 === 'true'
			},
		},
		{
			property: 'attributes.value8',
			type: 'text-with-tags',
			defaultValue: '',
			label: 'Password',
			conditional: ({ values }: { values: { [key: string]: any } }) => {
				return values.attributes.value6 === 'true'
			},
		},
		{
			property: 'attributes.value4',
			type: 'json',
			asStringValue: true,
			label: 'Headers',
			helper: `Values can be used inside strings using the format: "{{headers}}".`,
		},

		{
			property: 'divider-2',
			type: 'divider',
			label: 'Language & Timezone',
		},
		{
			property: 'attributes.value9',
			type: 'dropdown',
			label: 'Language',
			defaultValue: 'en_us',
			conditional: ({ values }: { values: { [key: string]: any } }) => {
				return !includes(['firefox', 'edge'], values.attributes.value5)
			},
			options: map(INodeStarterLanguageOptions, (label, value) => {
				return {
					label,
					value,
				}
			}),
		},
		{
			property: 'attributes.value10',
			type: 'dropdown',
			label: 'Timezone',
			defaultValue: 'UTC',
			options: map(INodeStarterTimezoneOptions, (label, value) => {
				return {
					label,
					value,
				}
			}),
		},
	],
	label: 'Advanced Open',
	sentence: ({ isExample, value: url, value2: variant, value5: runner }: INodeRenderProps) => {
		return isExample
			? `Advanced Open`
			: runner === 'emulated_device'
			? `Open ${sentenceValue(url)} on ${formatEnumValue(INodeStarterDevices, variant)}`
			: `Open ${sentenceValue(url)} on ${formatEnumValue(INodeStarterRunners, runner)} (${formatEnumValue(
					INodeStarterDevices,
					variant
			  )})`
	},
	family: INodeVariantTypes.starter_open_advanced.split('_')[0],
	type: INodeVariantTypes.starter_open_advanced.split('_')[1],
	subType: INodeVariantTypes.starter_open_advanced.split('_')[2],
	tags: compact([
		INodeVariantTypes.starter_open_advanced.split('_')[0],
		INodeVariantTypes.starter_open_advanced.split('_')[1],
		INodeVariantTypes.starter_open_advanced.split('_')[2],
		'goto',
		'go',
		'to',
		'path',
		'browser',
		'open',
		'navigate',
		'website',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ isExample, value: url, value2: variant, value5: runner }: INodeRenderProps) => {
			return (
				<>
					{isExample ? (
						`Advanced Open`
					) : (
						<>
							Open <strong>{formatValue(url)}</strong>
							{' on '}
							{runner === 'emulated_device' ? (
								<strong>{formatEnumValue(INodeStarterDevices, variant)}</strong>
							) : (
								<strong>
									{formatEnumValue(INodeStarterRunners, runner)} (
									{formatEnumValue(INodeStarterDevices, variant)})
								</strong>
							)}
						</>
					)}
				</>
			)
		},
	}
}
