// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'
import { useFormik } from 'formik'
import { useAnimation } from 'framer-motion'

import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Fab,
	Grid,
	IconButton,
	Stack,
	useTheme,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import Icon from '@mdi/react'
import { compact, get, isEmpty, map, omit, uniq, uniqBy, upperFirst } from 'lodash'

import { icons } from 'globals/constants/icons'
import { DIALOG_MEDIUM_WIDTH } from 'globals/constants/sizes'

import useAccount from 'hooks/useAccount'
import useSchedules from 'hooks/useSchedules'

import ShakeablePaperMedium from 'components/_Global/Dialogs/helpers/ShakeablePaperMedium'
import { Transition } from 'components/_Global/Dialogs/helpers/Transition'
import Field from 'components/_Global/Forms/Field'
import { ISchedule, validationSchema } from 'data/schedules'

// ? ---
// ?	Types
// ? ---
type Props = {
	state: boolean
	data: undefined | Partial<ISchedule>
	options?: {
		[key: string]: any
	}
	close: () => void
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Schedules-ScheduleDialog'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Helper: Get Select
// ? ---
const getSelection = (data: ISchedule) => {
	if (get(data, 'attributes.selection', false)) {
		// ? Selection
		return get(data, 'attributes.selection', 'all')
	} else if (get(data, 'attributes.withAll', false) || get(data, 'attributes.withoutAny', false)) {
		// ? Tag
		return 'tag'
	} else if (get(data, 'attributes.recipe.data.id', false)) {
		// ? Recipe
		return 'recipe'
	} else {
		// ? All
		return 'all'
	}
}

// ? ---
// ?	Component
// ? ---
export default function ScheduleDialog({ close, data, options, state }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const theme = useTheme()
	const _account = useAccount()
	const _schedules = useSchedules()
	const controls = useAnimation()

	// * ---
	// *	State
	// * ---
	const [saving, $saving] = React.useState(false)

	// * ---
	// *	Formik
	// * ---
	const formik = useFormik({
		validationSchema,
		initialValues: {
			...data,
			attributes: {
				...omit(data?.attributes, ['updatedAt']),
				title: data?.attributes?.title || '',
				selection: getSelection(data as ISchedule),
				recipe: get(data, 'attributes.recipe.data.id', ''),
				withAll: compact(uniq((data?.attributes?.withAll || '').split('|'))),
				withoutAny: compact(uniq((data?.attributes?.withoutAny || '').split('|'))),
				failureCount: data?.attributes?.failureCount || '3',
				status: data?.attributes?.status || 'active',
			},
		},
		onSubmit: async (values) => {
			$saving(true)
			log('onSubmit', values)
			await _schedules.upsert(
				{
					...values,
					attributes: {
						...omit(values.attributes, []),
						withAll: `|${values.attributes.withAll.join('|')}|`,
						withoutAny: `|${values.attributes.withoutAny.join('|')}|`,
					},
				},
				{
					onSuccess: (schedule) => {
						log('onSubmit onSuccess', { schedule, options })
						close()
						$saving(false)
						if (options?.onSuccess) options.onSuccess(schedule)
						if (options?.onComplete) options.onComplete(schedule)
					},
				}
			)
		},
	})

	// * ---
	// *	Return: Good
	// * ---
	return (
		<Dialog
			open={state}
			TransitionComponent={Transition}
			sx={{
				'& .MuiDialog-paperFullWidth': {
					maxWidth: DIALOG_MEDIUM_WIDTH,
				},
			}}
			fullWidth={true}
			onClose={() => (formik.dirty ? controls.start('start') : close())}
			PaperComponent={ShakeablePaperMedium}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			PaperProps={{ controls }}
			aria-labelledby={namespace}
		>
			<form onSubmit={formik.handleSubmit}>
				<DialogTitle id={namespace} className={'dragHandle'}>
					{isEmpty(data?.id) ? 'Create Schedule' : 'Update Schedule'}
				</DialogTitle>
				<DialogContent>
					<Stack sx={{ pt: 2, maxHeight: '78vh' }}>
						<Stack direction={'row'} spacing={1}>
							<Box sx={{ width: '50%' }}>
								<Field
									field={{
										property: 'attributes.title',
										type: 'text',
										label: 'Title',
									}}
									formik={formik}
								/>
							</Box>
							<Box sx={{ width: '50%' }}>
								<Field
									field={{
										property: 'attributes.status',
										type: 'switch',
										label: 'State',
										defaultValue: 'active',
										valueFalse: 'paused',
										labelFalse: 'Paused',
										valueTrue: 'active',
										labelTrue: 'Active',
									}}
									formik={formik}
								/>
							</Box>
						</Stack>
						<Field
							field={{
								property: 'attributes.selection',
								type: 'button-group',
								label: 'Flow Selection',
								defaultValue: 'all',
								options: [
									{ label: 'All', value: 'all' },
									{ label: 'Tags', value: 'tag' },
									{ label: 'Recipe', value: 'recipe' },
								],
							}}
							formik={formik}
						/>
						{formik.values.attributes.selection === 'tag' && (
							<Stack direction={'row'} spacing={1}>
								<Box sx={{ width: '50%' }}>
									<Field
										field={{
											property: 'attributes.withAll',
											type: 'multiSelect',
											label: 'Include Tags',
											options: uniqBy(
												[
													..._account.tags(),
													...map(formik.values.attributes.withAll, (tag) => {
														return { label: upperFirst(tag), value: tag }
													}),
												],
												'value'
											),
											helper: 'Must have all selected',
										}}
										formik={formik}
									/>
								</Box>
								<Box sx={{ width: '50%' }}>
									<Field
										field={{
											property: 'attributes.withoutAny',
											type: 'multiSelect',
											label: `Exclude Tags`,
											options: uniqBy(
												[
													..._account.tags(),
													...map(formik.values.attributes.withoutAny, (tag) => {
														return { label: upperFirst(tag), value: tag }
													}),
												],
												'value'
											),
											helper: `Can't have any selected`,
										}}
										formik={formik}
									/>
								</Box>
							</Stack>
						)}
						{formik.values.attributes.selection === 'recipe' && (
							<Field
								field={{
									property: 'attributes.recipe',
									type: 'recipe',
									label: 'Recipe',
								}}
								formik={formik}
							/>
						)}
						<Field
							field={{
								property: 'attributes.schedule',
								type: 'schedule',
								label: 'Schedule',
								helper: 'Times in UTC, 23:00 - 02:59 reserved for maintenance',
								config: {
									minTime: 3,
									maxTime: 23,
								},
							}}
							formik={formik}
						/>
						<Field
							field={{
								property: 'attributes.failureCount',
								type: 'slider',
								label: 'Consecutive Failures Before Pausing',
								defaultValue: '3',
								config: {
									min: 1,
									max: 5,
									step: 1,
								},
							}}
							formik={formik}
						/>
					</Stack>
				</DialogContent>
				<DialogActions>
					<Grid
						container
						direction='row'
						justifyContent='space-between'
						alignItems='center'
						spacing={1}
						sx={{ pl: 1, pr: 1 }}
					>
						<Grid item>
							{!isEmpty(data?.id) && (
								<IconButton
									data-test-id='xBPMetwDL6aUHKDA6-Qua'
									title={'Delete Schedule'}
									sx={{ color: theme.palette.text.secondary }}
									onClick={() =>
										_schedules.remove(data as ISchedule, {
											onSuccess: () => {
												close()
												if (options?.onDelete) {
													setTimeout(() => options.onDelete(), 0)
												}
											},
										})
									}
								>
									<Icon path={icons.removeIcon} size={1} />
								</IconButton>
							)}
						</Grid>
						<Grid item>
							<Button
								data-test-id='YeYDJCJCGbTIjHbqm9_sC'
								color={'secondary'}
								onClick={() => {
									close()
								}}
							>
								Cancel
							</Button>
						</Grid>
					</Grid>
					<Fab
						color='primary'
						aria-label='Save Schedule'
						type='submit'
						disabled={saving}
						sx={{
							backgroundColor: saving || !formik.isValid ? grey[400] : undefined,
						}}
					>
						<Icon path={saving ? icons.loadingIcon : icons.agreeIcon} size={1} spin={saving ? 1 : 0} />
					</Fab>
				</DialogActions>
			</form>
		</Dialog>
	)
}
