// ? ---
// ?	Imports
// ? ---
import * as React from 'react'

import { compact } from 'lodash'

import { ELEMENT_VALIDATION } from 'globals/constants/validations'

import { formatElement, sentenceElement } from 'hooks/useNode'

import { INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.action_switch_frame,
	isLive: true,
	isMenuOption: true,
	isPro: false,
	isAccessibility: false,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.variantType',
			type: 'variantType',
			label: 'Type',
			options: [INodeVariantTypes.action_switch_frame, INodeVariantTypes.action_switch_parentFrame],
		},
		{
			property: 'attributes.element',
			type: 'element',
			label: 'Element',
			validation: ELEMENT_VALIDATION,
		},
	],
	label: 'Switch frame',
	sentence: ({ element, isExample }: INodeRenderProps) => {
		return isExample ? `Switch frame` : `Switch frame to ${sentenceElement(element)}`
	},
	family: 'runner',
	type: INodeVariantTypes.action_switch_frame.split('_')[1],
	subType: INodeVariantTypes.action_switch_frame.split('_')[2],
	tags: compact([
		INodeVariantTypes.action_switch_frame.split('_')[0],
		INodeVariantTypes.action_switch_frame.split('_')[1],
		INodeVariantTypes.action_switch_frame.split('_')[2],
		'iframe',
		'inner',
		'child',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ element, isExample }: INodeRenderProps) => {
			return (
				<>
					Switch frame{' '}
					{!isExample && (
						<>
							to <strong>{formatElement(element)}</strong>
						</>
					)}
				</>
			)
		},
	}
}
