// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'

import { TextField } from '@mui/material'
import { get } from 'lodash'

import { IUseNodeField } from 'data/nodes'

// ? ---
// ?	Types
// ? ---
type Props = {
	formik: any
	field: IUseNodeField
	multiline?: boolean
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Dialogs-UpsertFields-Text'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function Text({ field, formik, multiline }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')

	// * ---
	// *	Return
	// * ---
	return (
		<TextField
			data-test-id={`${namespace}--${field.property}`}
			fullWidth
			id={field.property}
			name={field.property}
			label={field.label}
			autoComplete={field.autocomplete}
			disabled={field.disabled}
			value={get(formik?.values, field.property)}
			onChange={(event) => formik.setFieldValue(field.property, event.target.value)}
			variant='outlined'
			error={get(formik, `touched.${field.property}`) && Boolean(get(formik, `errors.${field.property}`))}
			helperText={
				get(formik, `touched.${field.property}`) && get(formik, `errors.${field.property}`)
					? get(formik, `errors.${field.property}`)
					: get(field, `helper`)
			}
			multiline={!!multiline}
		/>
	)
}
