// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'
import { useDebouncedCallback } from 'use-debounce'

import '@yaireo/tagify/dist/tagify.css'

import { get } from 'lodash'

import { IUseNodeField } from 'data/nodes'

import TextWithTagsInput from './Partials/TextWithTagsInput'

// ? ---
// ?	Types
// ? ---
type Props = {
	formik: any
	field: IUseNodeField
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Dialogs-UpsertFields-TextWithTags'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function TextWithTags({ field, formik }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')

	// * ---
	// *	Debounced: On Change
	// * ---
	const onChange = useDebouncedCallback(
		(event) => {
			log('onChange', formik, field.property, event)
			formik.setFieldValue(field.property, event.detail.value)
		},
		100,
		{ leading: false, trailing: true }
	)

	// * ---
	// *	Return
	// * ---
	return (
		<TextWithTagsInput
			data-test-id={`${namespace}--${field.property}`}
			name={field.property}
			label={field.label}
			value={get(formik?.values, field.property)}
			onChange={onChange}
			hasError={get(formik, `touched[${field.property}]`) && Boolean(get(formik, `errors[${field.property}]`))}
			guide={true}
			helperMessage={
				get(formik, `touched.${field.property}`) && get(formik, `errors.${field.property}`)
					? get(formik, `errors.${field.property}`)
					: get(field, `helper`)
			}
		/>
	)
}
