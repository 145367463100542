// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import { useEffect } from 'react'
import debug from 'debug'
import { useFormik } from 'formik'
import { useAnimation } from 'framer-motion'

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Fab,
	Grid,
	IconButton,
	Stack,
	useTheme,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import Icon from '@mdi/react'
import { isEmpty, isEqual, map, omit, omitBy, uniqBy } from 'lodash'

import { icons } from 'globals/constants/icons'
import { DIALOG_MEDIUM_WIDTH } from 'globals/constants/sizes'

import useEvents from 'hooks/useEvents'
import useThemeMode from 'hooks/useThemeMode'
import useUsers from 'hooks/useUsers'

import ShakeablePaperMedium from 'components/_Global/Dialogs/helpers/ShakeablePaperMedium'
import { Transition } from 'components/_Global/Dialogs/helpers/Transition'
import Field from 'components/_Global/Forms/Field'
import { IEvent, validationSchema } from 'data/events'
import { ITestRunTriggerType, ITestStatus } from 'data/tests'
import { IUser } from 'data/users'

// ? ---
// ?	Types
// ? ---
type Props = {
	state: boolean
	data: undefined | Partial<IEvent>
	options?: {
		[key: string]: any
	}
	close: () => void
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Events-EventDialog'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function EventDialog({ close, data, options, state }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const theme = useTheme()
	const _theme = useThemeMode()
	const _events = useEvents()
	const _users = useUsers()
	const controls = useAnimation()

	// * ---
	// *	State
	// * ---
	const [saving, $saving] = React.useState(false)
	const [users, $users] = React.useState<IUser[]>([])
	const [isLoading, $isLoading] = React.useState(true)

	// * ---
	// *	Formik
	// * ---
	const formik = useFormik({
		validationSchema,
		initialValues: {
			...data,
			__eventType: data?.attributes?.webhook ? 'hook' : 'email',
			attributes: {
				...omit(data?.attributes, ['updatedAt']),
				title: data?.attributes?.title || '',
				status: data?.attributes?.status || 'active',

				email: data?.attributes?.email || true,
				emailRecipients: data?.attributes?.emailRecipients || [],

				webhook: data?.attributes?.webhook || false,
				webhookUrl: data?.attributes?.webhookUrl || 'https://',
				webhookHeaders: data?.attributes?.webhookHeaders || {},

				runStatuses: data?.attributes?.runStatuses || ['passed'],
				triggerTypes: data?.attributes?.triggerTypes || ['user', 'hook', 'schedule', 'child'],
			},
		},
		onSubmit: async (values) => {
			$saving(true)
			log('onSubmit', values)
			await _events.upsert(
				{
					...values,
					attributes: {
						...omit(values.attributes, []),
						runStatuses: values.attributes.runStatuses as ITestStatus[],
						triggerTypes: values.attributes.triggerTypes as ITestRunTriggerType[],
						email: values.__eventType === 'email',
						webhook: values.__eventType === 'hook',
					},
				},
				{
					onSuccess: (event) => {
						log('onSubmit onSuccess', { event, options })
						close()
						$saving(false)
						if (options?.onSuccess) options.onSuccess(event)
						if (options?.onComplete) options.onComplete(event)
					},
				}
			)
		},
	})

	// * ---
	// *	Effect: User Data
	// * ---
	useEffect(() => {
		let abort = false
		const doLoad = async () => {
			$isLoading(true)
			log('doLoad')
			const tempUsers: IUser[] = await _users.getAll()
			if (!isEqual(users, tempUsers) && !abort) {
				log('!changed', tempUsers)
				$users(tempUsers)
			}
			$isLoading(false)
		}

		if (isEmpty(users) || !isLoading) doLoad()

		return () => {
			abort = true
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// * ---
	// *	Return: Good
	// * ---
	return (
		<Dialog
			open={state}
			TransitionComponent={Transition}
			sx={{
				'& .MuiDialog-paperFullWidth': {
					maxWidth: DIALOG_MEDIUM_WIDTH,
				},
			}}
			fullWidth={true}
			onClose={() => (formik.dirty ? controls.start('start') : close())}
			PaperComponent={ShakeablePaperMedium}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			PaperProps={{ controls }}
			aria-labelledby={namespace}
		>
			<form onSubmit={formik.handleSubmit}>
				<DialogTitle id={namespace} className={'dragHandle'}>
					{!isEmpty(data?.id)
						? 'Update Notification'
						: isEqual(data?.attributes?.runStatuses, ['passed'])
						? 'New Passed Run Notification'
						: isEqual(data?.attributes?.runStatuses, ['failed'])
						? 'New Failed Run Notification'
						: 'Create Notification'}
				</DialogTitle>
				<DialogContent>
					<Stack sx={{ pt: 2 }}>
						<Field
							field={{
								property: 'attributes.title',
								type: 'text',
								label: 'Title',
							}}
							formik={formik}
						/>
						{formik.values.attributes.runStatuses.length > 1 && (
							<Field
								field={{
									property: 'attributes.runStatuses',
									type: 'multiSelect',
									label: 'Run Statuses',
									options: [
										{ label: 'Passed', value: 'passed' },
										{ label: 'Failed', value: 'failed' },
										{ label: 'Rejected', value: 'rejected' },
									],
								}}
								formik={formik}
							/>
						)}
						<Field
							field={{
								property: 'attributes.triggerTypes',
								type: 'multiSelect',
								label: 'Run Types',
								options: [
									{ label: 'User', value: 'user' },
									{ label: 'CI/CD Webhook', value: 'hook' },
									{ label: 'Schedule', value: 'schedule' },
									{ label: 'Child Runs', value: 'child' },
								],
							}}
							formik={formik}
						/>
						<Field
							field={{
								property: '__eventType',
								type: 'button-group',
								label: 'Type',
								defaultValue: 'email',
								options: [
									{
										label: 'Email',
										value: 'email',
									},
									{
										label: 'Webhook',
										value: 'hook',
									},
								],
							}}
							formik={formik}
						/>

						{formik.values.__eventType === 'email' && (
							<>
								<Field
									field={{
										property: 'divider-email-config',
										type: 'divider',
										label: 'Email Configuration',
									}}
									formik={formik}
								/>
								<Field
									field={{
										property: 'attributes.emailRecipients',
										type: 'multiSelect',
										label: 'Recipients',
										options: omitBy(
											uniqBy(
												[
													...map(users, (user) => {
														return { label: user.fullName, value: user.email }
													}),
													...map(formik.values.attributes.emailRecipients, (email) => {
														return { label: email, value: email }
													}),
												],
												'value'
											),
											(item) => isEmpty(item.value)
										) as { value: string; label: string }[],
									}}
									formik={formik}
								/>
							</>
						)}

						{formik.values.__eventType === 'hook' && (
							<>
								<Field
									field={{
										property: 'divider-hook-config',
										type: 'divider',
										label: 'Webhook Configuration',
									}}
									formik={formik}
								/>
								<Field
									field={{
										property: 'attributes.webhookUrl',
										type: 'text',
										label: 'Webhook URL',
									}}
									formik={formik}
								/>
								<Field
									field={{
										property: 'attributes.webhookHeaders',
										type: 'json',
										label: 'Webhook Headers',
									}}
									formik={formik}
								/>
							</>
						)}
					</Stack>
				</DialogContent>
				<DialogActions>
					<Grid
						container
						direction='row'
						justifyContent='space-between'
						alignItems='center'
						spacing={1}
						sx={{ pl: 1, pr: 1 }}
					>
						<Grid item>
							{!isEmpty(data?.id) && (
								<IconButton
									data-test-id='G0OBWBrYwkg-HFNeoUcbu'
									title={'Delete Event'}
									sx={{ color: theme.palette.text.secondary }}
									onClick={() =>
										_events.remove(data as IEvent, {
											onSuccess: () => {
												close()
												if (options?.onDelete) {
													setTimeout(() => options.onDelete(), 0)
												}
											},
										})
									}
								>
									<Icon path={icons.removeIcon} size={1} />
								</IconButton>
							)}
						</Grid>
						<Grid item>
							<Button
								data-test-id='78VKLj1TPa6V8fD00ZryQ'
								color={'secondary'}
								onClick={() => {
									close()
								}}
							>
								Cancel
							</Button>
						</Grid>
					</Grid>
					<Fab
						color='primary'
						aria-label='Save Event'
						type='submit'
						disabled={saving}
						sx={{
							backgroundColor:
								saving || !formik.isValid ? _theme.conditional(grey[400], grey[800]) : undefined,
						}}
					>
						<Icon path={saving ? icons.loadingIcon : icons.agreeIcon} size={1} spin={saving ? 1 : 0} />
					</Fab>
				</DialogActions>
			</form>
		</Dialog>
	)
}
