// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'
import { useFormik } from 'formik'
import { useAnimation } from 'framer-motion'
import hash from 'object-hash'

import {
	Alert,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Fab,
	FormControl,
	Grid,
	InputLabel,
	Stack,
	Typography,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import Icon from '@mdi/react'
import { random } from 'lodash'

import theme from 'globals/client/theme'
import { icons } from 'globals/constants/icons'
import { DIALOG_SMALL_WIDTH } from 'globals/constants/sizes'

import useAccount from 'hooks/useAccount'
import useThemeMode from 'hooks/useThemeMode'

import ShakeablePaperSmall from 'components/_Global/Dialogs/helpers/ShakeablePaperSmall'
import { Transition } from 'components/_Global/Dialogs/helpers/Transition'
import Field from 'components/_Global/Forms/Field'
import { IAccountWebhookKey, webhookKeyValidationSchema } from 'data/accounts'

// ? ---
// ?	Types
// ? ---
type Props = {
	state: boolean
	data: undefined | Partial<IAccountWebhookKey>
	options?: {
		[key: string]: any
	}
	close: () => void
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Webhooks-WebhookDialog'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function WebhookDialog({ close, data, options, state }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const _theme = useThemeMode()
	const _account = useAccount()
	const controls = useAnimation()

	// * ---
	// *	State
	// * ---
	const [saving, $saving] = React.useState(false)

	// * ---
	// *	Formik
	// * ---
	const formik = useFormik({
		validationSchema: webhookKeyValidationSchema,
		initialValues: {
			id: data?.id,
			name: data?.name || '',
			slackChannelId: data?.slackChannelId || '',
			key: data?.id ? undefined : hash([random(10000, 99999)]).substring(0, 28),
		} as IAccountWebhookKey,
		onSubmit: async (values) => {
			$saving(true)
			log('onSubmit', values)
			await _account.webhookKeyUpsert(values, {
				onSuccess: (account) => {
					log('onSubmit onSuccess', { account, options })
					close()
					$saving(false)
					if (options?.onSuccess) options.onSuccess(account)
					if (options?.onComplete) options.onComplete(account)
				},
			})
		},
	})

	// * ---
	// *	Return: Good
	// * ---
	return (
		<Dialog
			open={state}
			TransitionComponent={Transition}
			sx={{
				'& .MuiDialog-paperFullWidth': {
					maxWidth: DIALOG_SMALL_WIDTH,
				},
			}}
			fullWidth={true}
			onClose={() => (formik.dirty ? controls.start('start') : close())}
			PaperComponent={ShakeablePaperSmall}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			PaperProps={{ controls }}
			aria-labelledby={namespace}
		>
			<form onSubmit={formik.handleSubmit}>
				<DialogTitle id={namespace} className={'dragHandle'}>
					Webhook Key
				</DialogTitle>
				<DialogContent>
					<Stack sx={{ pt: 2 }}>
						<Field
							field={{
								property: 'name',
								type: 'text',
								label: 'Name',
							}}
							formik={formik}
						/>
						<Field
							field={{
								property: 'slackChannelId',
								type: 'slackChannel',
								label: 'Slack Channel',
							}}
							formik={formik}
							close={close}
						/>
						{!data?.id && (
							<Box>
								<FormControl fullWidth>
									<InputLabel shrink={true}>Webhook Key</InputLabel>
									<Typography
										variant={'body1'}
										sx={{
											fontFamily: 'Monospace',
											fontWeight: 'bold !important',
											textAlign: 'center',
											color: theme.palette.text.secondary,
											pt: 1.5,
											pb: 1,
										}}
									>
										{formik.values.key}
									</Typography>
									<Alert
										severity='info'
										sx={{
											border: _theme.conditional(
												`1px solid ${theme.palette.info.dark}`,
												undefined
											),
										}}
									>
										This will not be displayed again
									</Alert>
								</FormControl>
							</Box>
						)}
					</Stack>
				</DialogContent>
				<DialogActions>
					<Grid
						container
						direction='row'
						justifyContent='space-between'
						alignItems='center'
						spacing={1}
						sx={{ pl: 1, pr: 1 }}
					>
						<Grid item></Grid>
						<Grid item>
							<Button
								data-test-id='IubzOENnkizr-4et-l_bI'
								color={'secondary'}
								onClick={() => {
									close()
								}}
							>
								Cancel
							</Button>
						</Grid>
					</Grid>
					<Fab
						color='primary'
						aria-label='Save Webhook'
						type='submit'
						disabled={saving}
						sx={{
							backgroundColor: saving || !formik.isValid ? grey[400] : undefined,
						}}
					>
						<Icon path={saving ? icons.loadingIcon : icons.agreeIcon} size={1} spin={saving ? 1 : 0} />
					</Fab>
				</DialogActions>
			</form>
		</Dialog>
	)
}
