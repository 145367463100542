// ? ---
// ?	Imports
// ? ---
import * as React from 'react'

import { compact } from 'lodash'

import { ELEMENT_VALIDATION } from 'globals/constants/validations'

import { formatElement, formatValue, sentenceElement, sentenceValue } from 'hooks/useNode'

import { INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.action_input_file,
	isLive: true,
	isMenuOption: true,
	isPro: false,
	isAccessibility: false,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.element',
			type: 'element',
			label: 'Element',
			validation: ELEMENT_VALIDATION,
		},
		{
			property: 'attributes.value2',
			type: 'switch',
			label: 'File',
			defaultValue: 'generated',
			valueFalse: 'last_download',
			labelFalse: 'Last Downloaded File',
			valueTrue: 'generated',
			labelTrue: 'Generated File',
		},
		{
			property: 'attributes.value',
			type: 'file',
			label: 'Generated File',
			conditional: ({ values }: { values: { [key: string]: any } }) => {
				return values.attributes.value2 !== 'last_download'
			},
		},
	],
	label: 'File',
	sentence: ({ element, isExample, value, value2 }: INodeRenderProps) => {
		return isExample
			? `Set file input`
			: value2 === 'last_download'
			? `Set file input ${sentenceElement(element)} to last downloaded file`
			: `Set file input ${sentenceElement(element)} to ${sentenceValue(value)}`
	},
	family: INodeVariantTypes.action_input_setValue.split('_')[0],
	type: INodeVariantTypes.action_input_setValue.split('_')[1],
	subType: INodeVariantTypes.action_input_setValue.split('_')[2],
	tags: compact([
		INodeVariantTypes.action_input_setValue.split('_')[0],
		INodeVariantTypes.action_input_setValue.split('_')[1],
		INodeVariantTypes.action_input_setValue.split('_')[2],
		'fill',
		'set',
		'upload',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ element, isExample, value, value2 }: INodeRenderProps) => {
			return (
				<>
					{isExample ? (
						<>File</>
					) : (
						<>
							{value2 === 'last_download' ? (
								<>
									Set <strong>{formatElement(element)}</strong> to last downloaded file
								</>
							) : (
								<>
									Set <strong>{formatElement(element)}</strong> to{' '}
									<strong>{formatValue(value)}</strong>
								</>
							)}
						</>
					)}
				</>
			)
		},
	}
}
