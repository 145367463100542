// ? ---
// ?	Imports
// ? ---
import * as React from 'react'

import { compact } from 'lodash'

import { INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.action_switch_parentFrame,
	isLive: true,
	isMenuOption: false,
	isPro: false,
	isAccessibility: false,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.variantType',
			type: 'variantType',
			label: 'Type',
			options: [INodeVariantTypes.action_switch_frame, INodeVariantTypes.action_switch_parentFrame],
		},
	],
	label: 'Switch to parent frame',
	sentence: () => {
		return `Switch to parent frame`
	},
	family: 'runner',
	type: INodeVariantTypes.action_switch_parentFrame.split('_')[1],
	subType: INodeVariantTypes.action_switch_parentFrame.split('_')[2],
	tags: compact([
		INodeVariantTypes.action_switch_parentFrame.split('_')[0],
		INodeVariantTypes.action_switch_parentFrame.split('_')[1],
		INodeVariantTypes.action_switch_parentFrame.split('_')[2],
		'iframe',
		'parent',
		'frame',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: () => {
			return <>Switch to parent frame</>
		},
	}
}
