// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import * as yup from 'yup'

import { compact, includes, inRange, isEmpty, isString, toNumber } from 'lodash'

import { VALUE_REGEX } from 'globals/constants/valueStore'
import { RUNNER_WAIT_GROUP } from 'globals/constants/variantGroups'

import { formatValue, sentenceValue } from 'hooks/useNode'

import { INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.action_wait_forSeconds,
	isLive: true,
	isMenuOption: true,
	isPro: false,
	isAccessibility: true,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.variantType',
			type: 'variantType',
			label: 'Type',
			options: RUNNER_WAIT_GROUP,
		},
		{
			property: 'attributes.value2',
			type: 'button-group',
			asStringValue: true,
			defaultValue: 'fixed',
			label: 'Type',
			options: [
				{
					label: 'Fixed',
					value: 'fixed',
				},
				{
					label: 'Dynamic',
					value: 'input',
				},
			],
		},
		{
			property: 'attributes.value',
			type: 'slider',
			defaultValue: '5',
			label: 'Seconds',
			config: {
				min: 1,
				max: 60,
				step: 1,
			},
			conditional: ({ values }: { values: { [key: string]: any } }) => {
				return !includes(['input'], values.attributes.value2)
			},
		},
		{
			property: 'attributes.value3',
			type: 'text-with-tags',
			defaultValue: '5',
			label: 'Seconds',
			validation: yup.string().when('value2', {
				is: 'input',
				then: (schema) =>
					schema.test('is-in-range', 'Outside of allowed range (0-600)', (value: any) => {
						if (isString(value) && !isEmpty(value)) {
							return value.match(VALUE_REGEX) ? true : inRange(toNumber(value), 600)
						}
						return false
					}),
				otherwise: (schema) => schema.notRequired(),
			}),
			conditional: ({ values }: { values: { [key: string]: any } }) => {
				return includes(['input'], values.attributes.value2)
			},
		},
	],
	label: 'Pause',
	sentence: ({ isExample, value, value2, value3 }: INodeRenderProps) => {
		return isExample
			? `Pause`
			: value2 === 'input'
			? `Pause for ${sentenceValue(value3)} seconds`
			: `Pause for ${sentenceValue(value)} seconds`
	},
	family: 'runner',
	type: INodeVariantTypes.action_wait_forSeconds.split('_')[1],
	subType: INodeVariantTypes.action_wait_forSeconds.split('_')[2],
	tags: compact([
		INodeVariantTypes.action_wait_forSeconds.split('_')[0],
		INodeVariantTypes.action_wait_forSeconds.split('_')[1],
		INodeVariantTypes.action_wait_forSeconds.split('_')[2],
		'wait',
		'time',
		'sleep',
		'pause',
		'delay',
		'state',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ isExample, value, value2, value3 }: INodeRenderProps) => {
			return (
				<>
					{isExample ? (
						`Pause`
					) : (
						<>
							{value2 === 'input' ? (
								<>
									Pause for <strong>{formatValue(value3)}</strong> seconds
								</>
							) : (
								<>
									Pause for <strong>{formatValue(value)}</strong> seconds
								</>
							)}
						</>
					)}
				</>
			)
		},
	}
}
