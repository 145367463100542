// ? ---
// ?	Imports
// ? ---
import { Theme } from '@mui/material'
import { blue, green, pink, purple } from '@mui/material/colors'
import { get } from 'lodash'

const style = (theme: Theme) => {
	return {
		hljs: {
			display: 'block',
			overflowX: 'auto',
			padding: '0.5em',
			color: get(theme, 'palette.text.secondary'),
			background: get(theme, 'palette.background.default'),
			fontWeight: 500,
		},
		'hljs-comment': {
			color: '#656e77',
		},
		'hljs-keyword': {
			color: blue[700],
		},
		'hljs-selector-tag': {
			color: blue[700],
		},
		'hljs-meta-keyword': {
			color: blue[700],
		},
		'hljs-doctag': {
			color: blue[700],
		},
		'hljs-section': {
			color: blue[700],
		},
		'hljs-selector-class': {
			color: blue[700],
		},
		'hljs-meta': {
			color: blue[700],
		},
		'hljs-selector-pseudo': {
			color: blue[700],
		},
		'hljs-attr': {
			color: blue[700],
		},
		'hljs-attribute': {
			color: pink[600],
		},
		'hljs-name': {
			color: purple[600],
		},
		'hljs-type': {
			color: purple[600],
		},
		'hljs-number': {
			color: purple[600],
		},
		'hljs-selector-id': {
			color: purple[600],
		},
		'hljs-quote': {
			color: purple[600],
		},
		'hljs-template-tag': {
			color: purple[600],
		},
		'hljs-built_in': {
			color: purple[600],
		},
		'hljs-title': {
			color: purple[600],
		},
		'hljs-literal': {
			color: purple[600],
		},
		'hljs-string': {
			color: green[700],
		},
		'hljs-regexp': {
			color: green[700],
		},
		'hljs-symbol': {
			color: green[700],
		},
		'hljs-variable': {
			color: green[700],
		},
		'hljs-template-variable': {
			color: green[700],
		},
		'hljs-link': {
			color: green[700],
		},
		'hljs-selector-attr': {
			color: green[700],
		},
		'hljs-meta-string': {
			color: green[700],
		},
		'hljs-bullet': {
			color: '#535a60',
		},
		'hljs-code': {
			color: '#535a60',
		},
		'hljs-deletion': {
			color: pink[600],
		},
		'hljs-addition': {
			color: pink[600],
		},
		'hljs-emphasis': {
			fontStyle: 'italic',
		},
		'hljs-strong': {
			fontWeight: 'bold',
		},
	}
}

export default style
