// ? ---
// ?	Imports
// ? ---
// import * as React from 'react'
import axios from 'axios'
import debug from 'debug'

import { CUSTOM_ACTIVITY_TYPES } from 'globals/constants/activity'

import { INodeVariantTypes } from 'data/nodes'

// ? ---
// ?	Constants
// ? ---
const namespace = 'hooks-useActivity'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Hook
// ? ---
export default function useActivity() {
	// * ---
	// *	Setup
	// * ---
	// log('.')

	// * ---
	// *	Send Tracking Event
	// * ---
	const sendTrackingEvent = async ({ message, type }: { type: string; message: string }) => {
		const title = CUSTOM_ACTIVITY_TYPES[type]

		// ! Drop out if activity type is not recognised
		if (!title) {
			log('!! Activity type not recognised', type)
			return
		}

		// * Send event to Outseta
		await axios
			.post(`/api/accounts/activities/custom-activity`, {
				Title: title,
				Description: message,
			})
			.then(() => {
				log('Custom activity event sent to outseta', type, message)
			})
			.catch((err) => {
				log('!! Failed to send custom activity event to outseta', err)
			})
	}

	// * ---
	// *	Track Flow Created
	// * ---
	const trackFlowCreated = (title: string) => {
		sendTrackingEvent({
			type: 'FLOW_CREATED',
			message: `Flow created: ${title}`,
		})
	}

	// * ---
	// *	Track Node Inserted
	// * ---
	const trackNodeInserted = (variantType: INodeVariantTypes) => {
		sendTrackingEvent({
			type: 'NODE_INSERTED',
			message: `Node inserted: ${variantType || 'UNKNOWN_VARIANT'}`,
		})
	}

	// * ---
	// *	Track Element Created
	// * ---
	const trackElementCreated = (title: string) => {
		sendTrackingEvent({
			type: 'ELEMENT_CREATED',
			message: `Element created: ${title}`,
		})
	}

	// * ---
	// *	Track Event Created
	// * ---
	const trackEventCreated = (title: string) => {
		sendTrackingEvent({
			type: 'EVENT_CREATED',
			message: `Event created: ${title}`,
		})
	}

	// * ---
	// *	Track Schedule Created
	// * ---
	const trackScheduleCreated = (title: string) => {
		sendTrackingEvent({
			type: 'SCHEDULE_CREATED',
			message: `Schedule created: ${title}`,
		})
	}

	// * ---
	// *	Track MFA Created
	// * ---
	const trackMfaCreated = (title: string) => {
		sendTrackingEvent({
			type: 'MFA_CREATED',
			message: `MFA created: ${title}`,
		})
	}

	// * ---
	// *	Track Value Created
	// * ---
	const trackValueCreated = (title: string) => {
		sendTrackingEvent({
			type: 'VAlUE_CREATED',
			message: `Value created: ${title}`,
		})
	}

	// * ---
	// *	Track Webhook Created
	// * ---
	const trackWebhookCreated = (title: string) => {
		sendTrackingEvent({
			type: 'WEBHOOK_CREATED',
			message: `Webhook created: ${title}`,
		})
	}

	// * ---
	// *	Track Video Viewed
	// * ---
	const trackVideoViewed = (title: string) => {
		sendTrackingEvent({
			type: 'VIDEO_VIEWED',
			message: `Video viewed: ${title}`,
		})
	}

	// * ---
	// *	Track Screenshot Viewed
	// * ---
	const trackScreenshotViewed = (title: string) => {
		sendTrackingEvent({
			type: 'SCREENSHOT_VIEWED',
			message: `Screenshot viewed: ${title}`,
		})
	}

	// * ---
	// *	Track Test Data Viewed
	// * ---
	const trackTestDataViewed = (title: string) => {
		sendTrackingEvent({
			type: 'TEST_DATA_VIEWED',
			message: `Test data viewed: ${title}`,
		})
	}

	// * ---
	// *	Track Plan Upgrade
	// * ---
	const trackPlanUpgradeViewed = (location: string) => {
		sendTrackingEvent({
			type: 'PLAN_UPGRADE_VIEWED',
			message: `Plan upgrade viewed: ${location}`,
		})
	}

	// * ---
	// *	Return
	// * ---
	return {
		trackFlowCreated,
		trackNodeInserted,
		trackElementCreated,
		trackEventCreated,
		trackScheduleCreated,
		trackMfaCreated,
		trackValueCreated,
		trackWebhookCreated,
		trackVideoViewed,
		trackScreenshotViewed,
		trackTestDataViewed,
		trackPlanUpgradeViewed,
	}
}
