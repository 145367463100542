// ? ---
// ?	Imports
// ? ---
import * as React from 'react'

import { compact } from 'lodash'

import { FAILED_STATUS_OPTIONS } from 'globals/constants/selectOptions'
import { ELEMENT_VALIDATION } from 'globals/constants/validations'
import { RUNNER_WAIT_GROUP } from 'globals/constants/variantGroups'

import { formatElement, sentenceElement } from 'hooks/useNode'

import { INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'
import { ITestStepStatus } from 'data/tests'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.action_wait_forElementNotDisplayed,
	isLive: true,
	isMenuOption: false,
	isPro: false,
	isAccessibility: true,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.variantType',
			type: 'variantType',
			label: 'Type',
			options: RUNNER_WAIT_GROUP,
		},
		{
			property: 'attributes.element',
			type: 'element',
			label: 'Element',
			validation: ELEMENT_VALIDATION,
		},
		{
			property: 'attributes.config.timeoutOverride',
			type: 'button-group',
			defaultValue: 'slow',
			label: 'How fast is this typically?',
			options: [
				{
					label: 'Fast',
					value: 'fast',
				},
				{
					label: 'Slow',
					value: 'slow',
				},
				{
					label: 'Very Slow',
					value: 'very_slow',
				},
			],
		},
		{
			property: 'attributes.config.failedStatus',
			type: 'button-group',
			label: 'Status on fail',
			defaultValue: ITestStepStatus.failed,
			options: FAILED_STATUS_OPTIONS,
		},
	],
	label: 'Wait for element to not be displayed',
	sentence: ({ element, isExample }: INodeRenderProps) => {
		return isExample ? `Wait for element not displayed` : `Wait for ${sentenceElement(element)} to not be displayed`
	},
	family: 'runner',
	type: INodeVariantTypes.action_wait_forElementNotDisplayed.split('_')[1],
	subType: INodeVariantTypes.action_wait_forElementNotDisplayed.split('_')[2],
	tags: compact([
		INodeVariantTypes.action_wait_forElementNotDisplayed.split('_')[0],
		INodeVariantTypes.action_wait_forElementNotDisplayed.split('_')[1],
		INodeVariantTypes.action_wait_forElementNotDisplayed.split('_')[2],
		'wait',
		'time',
		'sleep',
		'pause',
		'element',
		'delay',
		'state',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ element, isExample }: INodeRenderProps) => {
			return (
				<>
					{isExample ? (
						`Wait for not displayed`
					) : (
						<>
							Wait for <strong>{formatElement(element)}</strong> to not be displayed
						</>
					)}
				</>
			)
		},
	}
}
