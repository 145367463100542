// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import ScheduleSelector from 'react-schedule-selector'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import debug from 'debug'

import { Box, FormControl, FormHelperText, Typography, useTheme } from '@mui/material'
import { compact, forEach, get, map, take, union } from 'lodash'

import { colors } from 'globals/constants/colors'

import useThemeMode from 'hooks/useThemeMode'

import { IUseNodeField } from 'data/nodes'

// ? ---
// ?	Types
// ? ---
type Props = {
	formik: any
	field: IUseNodeField
}

// ? ---
// ?	Constants
// ? ---
dayjs.extend(utc)
const namespace = 'components-Global-Dialogs-UpsertFields-Schedule'
const log = debug(`app:${namespace}`)

const BASE_YEAR = 2017 // Jan 1st is a Sunday

// ? ---
// ?	Component
// ? ---
export default function Schedule({ field, formik }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	const theme = useTheme()
	const _theme = useThemeMode()
	log('.')

	const maxSelection = get(field, 'config.maxSelection', -1)

	// * ---
	// *	Callback: Helper Text
	// * ---
	const helperText = React.useCallback(() => {
		// ? Touched & Error
		if (get(formik, `touched.${field.property}`) && get(formik, `errors.${field.property}`)) {
			return {
				active: true,
				isError: true,
				message: get(formik, `errors.${field.property}`),
			}
		}
		// ? Helper Text
		if (get(field, `helper`)) {
			return {
				active: true,
				isError: false,
				message: field.helper,
			}
		}
		return {
			active: false,
			isError: false,
			message: field.helper,
		}
	}, [formik, field])

	// * ---
	// *	Callback: Get Value
	// * ---
	const getValue = React.useCallback(() => {
		const valueMatrix = get(formik?.values, field.property)
		const dateArray = map(valueMatrix, (day, dayIndex) =>
			map(day, (value, hourIndex) => {
				if (value) {
					return dayjs(`${BASE_YEAR}-01-${dayIndex + 1} ${hourIndex}:00`)
						.utc()
						.toDate()
				}
			})
		)
		const response = compact(union(...dateArray))
		log('getValue', response)
		return response
	}, [formik, field])

	// * ---
	// *	Callback: Max Selection Style
	// * ---
	const getMaxSelectionStyle = React.useCallback(() => {
		if (maxSelection <= 0) return {}
		const currentSize = getValue().length
		if (currentSize >= maxSelection) return { color: colors.error[600] }
		if (currentSize >= maxSelection * 0.85) return { color: colors.warning[800] }
		return {}
	}, [getValue, maxSelection])

	// * ---
	// *	Method: onChange
	// * ---
	const onChange = (schedule: Date[]) => {
		const valueMatrix: (1 | 0)[][] = [
			[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // Sunday
			[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // Monday
			[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // Tuesday
			[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // Wednesday
			[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // Thursday
			[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // Friday
			[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // Saturday
		]
		const scheduleSubset = maxSelection > 0 ? take(schedule, maxSelection) : schedule

		forEach(scheduleSubset, (timestamp) => {
			const day = dayjs(timestamp).day()
			const hour = dayjs(timestamp).hour()
			log('day, hour', day, hour)
			valueMatrix[day][hour] = 1
		})
		log('onChange', schedule, valueMatrix)
		formik.setFieldValue(field.property, valueMatrix)
	}

	// * ---
	// *	Return
	// * ---
	return (
		<FormControl
			variant='outlined'
			fullWidth
			sx={{
				'[class*="ScheduleSelector__Grid"] > div > div': {
					height: '15px !important',
				},
				'[class*="ScheduleSelector__Grid"] > span': {
					lineHeight: '1.5 !important',
					fontSize: '0.6em !important',
				},
			}}
		>
			<Typography component='legend' variant='caption' sx={{ ml: 1, mr: 1, color: theme.palette.text.secondary }}>
				{field.label}{' '}
				{maxSelection > 0 && (
					<Box sx={{ display: 'inline-block', ...getMaxSelectionStyle() }}>
						({getValue().length} / {maxSelection})
					</Box>
				)}
			</Typography>
			{/* @ts-expect-error Server Component */}
			<ScheduleSelector
				data-test-id={`${namespace}--${field.property}`}
				selection={getValue()}
				onChange={onChange}
				numDays={7}
				minTime={get(field, 'config.minTime', 0)}
				maxTime={get(field, 'config.maxTime', 24)}
				hourlyChunks={1}
				selectionScheme={'square'}
				rowGap={'2px'}
				columnGap={'2px'}
				unselectedColor={_theme.conditional(colors.grey[300], colors.grey[800])}
				hoveredColor={colors.primary[50]}
				selectedColor={colors.primary[500]}
				startDate={dayjs(`${BASE_YEAR}-01-01`).toDate()}
				renderDateLabel={(date) => (
					<Typography
						variant='caption'
						sx={{ mt: 1, textAlign: 'center', color: theme.palette.text.secondary }}
					>
						{dayjs(date).utc().format('ddd')}
					</Typography>
				)}
				renderTimeLabel={(time) => (
					<Typography
						variant='caption'
						sx={{ mr: 0.5, textAlign: 'right', color: theme.palette.text.secondary }}
					>
						{dayjs(time).utc().format('ha')}
					</Typography>
				)}
			/>
			{helperText().active && (
				<FormHelperText error={helperText().isError} sx={{ textAlign: 'right' }}>
					{helperText().message}
				</FormHelperText>
			)}
		</FormControl>
	)
}
