import {
	amber,
	blueGrey,
	cyan,
	deepOrange,
	deepPurple,
	green,
	grey,
	indigo,
	lightBlue,
	lime,
	orange,
	pink,
	purple,
	teal,
	yellow,
} from '@mui/material/colors'

import GREY from './colors/brandGrey'
import BRAND_PRIMARY from './colors/brandPrimary'

export const colors: {
	[key: string]: { [key: string]: string }
} = {
	special: blueGrey,
	starter: lightBlue,
	info: lightBlue,
	check: green,
	change: cyan,
	action: orange,
	browser: {
		...yellow,
		[600]: yellow[700],
		[700]: yellow[800],
		[800]: yellow[900],
	},
	validate: teal,
	data: purple,
	runner: deepPurple,
	integration: deepOrange,
	express: pink,
	mail: indigo,
	adyen: {
		50: '#e7f5e8',
		100: '#c5e7c6',
		200: '#9fd7a2',
		300: '#77c87c',
		400: '#57bc5f',
		500: '#35b042',
		600: '#2ca139',
		700: '#1e8f2e',
		800: '#0f7e23',
		900: '#005f11',
	},
	utility: {
		...grey,
		[100]: grey[200],
	},
	passed: green,
	passed_with_warning: orange,
	success: green,
	passedAlt: lime,
	warning: amber,
	failed: deepOrange,
	stopped: orange,
	failedAlt: pink,
	rejected: deepOrange,
	error: deepOrange,
	preview: blueGrey,
	never_run: blueGrey,
	pending: blueGrey,
	queued: blueGrey,
	accepted: blueGrey,
	ignored: orange,
	skipped: orange,
	continued: green,
	running: lightBlue,
	primary: BRAND_PRIMARY,
	grey: {
		...grey,
		...GREY,
		[550]: '#8A8A8A',
	},
}
