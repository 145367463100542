// ? ---
// ?	Imports
// ? ---
import * as React from 'react'

import { compact } from 'lodash'

import { FAILED_STATUS_OPTIONS } from 'globals/constants/selectOptions'
import { ELEMENT_VALIDATION } from 'globals/constants/validations'

import { formatElement, sentenceElement } from 'hooks/useNode'

import { INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'
import { ITestStepStatus } from 'data/tests'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.action_touch_hover,
	isLive: true,
	isMenuOption: false,
	isPro: false,
	isAccessibility: true,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.variantType',
			type: 'variantType',
			label: 'Type',
			options: [
				INodeVariantTypes.action_touch_single,
				INodeVariantTypes.action_touch_double,
				INodeVariantTypes.action_touch_hover,
			],
		},
		{
			property: 'attributes.element',
			type: 'element',
			label: 'Element',
			validation: ELEMENT_VALIDATION,
		},
		{
			property: 'attributes.config.failedStatus',
			type: 'button-group',
			label: 'Status on fail',
			defaultValue: ITestStepStatus.failed,
			options: FAILED_STATUS_OPTIONS,
		},
	],
	label: 'Hover',
	sentence: ({ element, isExample }: INodeRenderProps) => {
		return isExample ? `Hover` : `Hover over ${sentenceElement(element)}`
	},
	family: INodeVariantTypes.action_touch_hover.split('_')[0],
	type: INodeVariantTypes.action_touch_hover.split('_')[1],
	subType: INodeVariantTypes.action_touch_hover.split('_')[2],
	tags: compact([
		INodeVariantTypes.action_touch_hover.split('_')[0],
		INodeVariantTypes.action_touch_hover.split('_')[1],
		INodeVariantTypes.action_touch_hover.split('_')[2],
		'move',
		'mouse',
		'moveto',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ element, isExample }: INodeRenderProps) => {
			return <>Hover {!isExample && <strong>{formatElement(element)}</strong>}</>
		},
	}
}
