// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import Color from 'color'
import debug from 'debug'
import { useAnimation } from 'framer-motion'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, useTheme } from '@mui/material'
import Icon from '@mdi/react'
import { includes } from 'lodash'

import { IOpenMessageDetails, IOpenMessageOptions } from 'globals/client/types'
import { colors } from 'globals/constants/colors'
import { icons } from 'globals/constants/icons'
import { DIALOG_SMALL_WIDTH } from 'globals/constants/sizes'

import useThemeMode from 'hooks/useThemeMode'

import ShakeablePaperSmall from 'components/_Global/Dialogs/helpers/ShakeablePaperSmall'
import { Transition } from 'components/_Global/Dialogs/helpers/Transition'

// ? ---
// ?	Types
// ? ---
type Props = {
	state: boolean
	data?: IOpenMessageDetails
	options?: IOpenMessageOptions
	close: () => void
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Dialogs-MessageDialog'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function MessageDialog({ close, data, options, state }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const theme = useTheme()
	const _theme = useThemeMode()
	const controls = useAnimation()

	// * ---
	// *	Return: Good
	// * ---

	return (
		<Dialog
			open={state}
			TransitionComponent={Transition}
			sx={{
				'& .MuiDialog-paperFullWidth': {
					maxWidth: DIALOG_SMALL_WIDTH,
				},
			}}
			fullWidth={true}
			onClose={() => {
				close()
				if (options?.onComplete) options.onComplete()
			}}
			PaperComponent={ShakeablePaperSmall}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			PaperProps={{ controls }}
			aria-labelledby={namespace}
		>
			<DialogTitle
				id={namespace}
				className={'dragHandle'}
				sx={{
					backgroundColor:
						data?.level && includes(['error', 'warning', 'info'], data?.level)
							? _theme.conditional(
									colors[data.level][100],
									Color(colors[data.level][800]).alpha(0.2).string()
							  )
							: undefined,
				}}
			>
				{data?.title}
			</DialogTitle>
			{data?.body && (
				<DialogContent
					sx={{
						borderTop: 'none',
						borderColor: 'none !important',
						backgroundColor:
							data?.level && includes(['error', 'warning', 'info'], data?.level)
								? _theme.conditional(
										colors[data.level][100],
										Color(colors[data.level][800]).alpha(0.2).string()
								  )
								: undefined,
						paddingTop:
							data?.level && includes(['error', 'warning', 'info'], data?.level)
								? `0 !important`
								: `${theme.spacing(1.5)} !important`,
						fontWeight: 500,
						whiteSpace: 'pre-wrap',
					}}
				>
					{data?.body}
					{/*<pre>{JSON.stringify(data?.related, null, 2)}</pre>*/}
				</DialogContent>
			)}
			<DialogActions>
				<Button
					data-test-id='upx2a77BI9MxM7j3xtZCx'
					color={'secondary'}
					onClick={() => {
						close()
						if (options?.onCancel) options.onCancel()
						if (options?.onComplete) options.onComplete()
					}}
				>
					Cancel
				</Button>
				<Fab
					data-test-id='SDdwIZJYBNpntAZYEIOzr'
					color='primary'
					aria-label='Delete'
					onClick={() => {
						if (options?.onSuccess) options.onSuccess()
						if (options?.onComplete) options.onComplete()
						close()
					}}
					sx={{
						backgroundColor:
							data?.level && includes(['error', 'warning', 'info'], data?.level)
								? _theme.conditional(colors[data.level][400], colors[data.level][700])
								: undefined,

						'&:hover': {
							backgroundColor:
								data?.level && includes(['error', 'warning', 'info'], data?.level)
									? colors[data.level][900]
									: undefined,
						},
					}}
				>
					<Icon path={icons[data?.icon || 'agreeIcon']} size={1} />
				</Fab>
			</DialogActions>
		</Dialog>
	)
}
