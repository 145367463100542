// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'

import { FormControl, FormControlLabel, FormHelperText, Slider, Typography, useTheme } from '@mui/material'
import { floor, get } from 'lodash'

import { helperText } from 'components/_Global/Forms/helperHelper'
import { IUseNodeField } from 'data/nodes'

// ? ---
// ?	Types
// ? ---
type Props = {
	formik: any
	field: IUseNodeField
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Dialogs-UpsertFields-SliderPercentage'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function SliderPercentage({ field, formik }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const theme = useTheme()
	const helper = helperText({ field, formik })

	// * ---
	// *	Return
	// * ---
	return (
		<FormControl variant='outlined' fullWidth>
			<Typography component='legend' variant='caption' sx={{ ml: 1, mr: 1, color: theme.palette.text.secondary }}>
				{field.label}
			</Typography>
			<FormControlLabel
				control={
					<Slider
						data-test-id={`${namespace}--${field.property}`}
						id={field.property}
						name={field.property}
						value={parseFloat(
							get(formik?.values, field.property) === '' ? '0' : get(formik?.values, field.property)
						)}
						onChange={(event, value) => formik.setFieldValue(field.property, `${value}`)}
						// valueLabelDisplay='auto'
						valueLabelFormat={(value) => floor(value * 100)}
						step={0.05}
						min={0}
						max={1}
						marks
					/>
				}
				label={
					<Typography sx={{ minWidth: '50px', textAlign: 'right' }} variant={'caption'}>
						{floor(get(formik?.values, field.property, 0) * 100)}%
					</Typography>
				}
				sx={{ ml: 0.5, mr: 0.5 }}
			/>
			{helper.active && <FormHelperText error={helper.isError}>{helper.message}</FormHelperText>}
		</FormControl>
	)
}
