// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import * as yup from 'yup'

import { compact } from 'lodash'

import { ACTION_SCROLL_GROUP } from 'globals/constants/variantGroups'

import { formatValue, sentenceValue } from 'hooks/useNode'

import { INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.action_scroll_page,
	isLive: true,
	isMenuOption: false,
	isPro: false,
	isAccessibility: true,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.variantType',
			type: 'variantType',
			label: 'Type',
			options: ACTION_SCROLL_GROUP,
		},
		{
			property: 'attributes.value',
			type: 'dropdown',
			label: 'Destination',
			defaultValue: 'top',
			options: [
				{ label: 'Top', value: 'top' },
				{ label: 'Bottom', value: 'bottom' },
				{ label: 'Left', value: 'left' },
				{ label: 'Right', value: 'right' },
				// { label: 'Top-Left', value: 'top_left' },
				// { label: 'Top-Right', value: 'top_right' },
				// { label: 'Bottom-Left', value: 'bottom_left' },
				// { label: 'Bottom-Right', value: 'bottom_right' },
			],
		},
		{
			property: '',
			type: 'divider',
			label: 'Target',
			isDev: true,
		},
		{
			property: 'attributes.value2',
			type: 'button-group',
			asStringValue: true,
			defaultValue: 'page',
			label: 'Scrollable Target',
			options: [
				{
					label: 'Page',
					value: 'page',
				},
				{
					label: 'Element',
					value: 'element',
				},
			],
			isDev: true,
		},
		{
			property: 'attributes.element2',
			type: 'element',
			label: 'Parent Element',
			validation: yup.string().when('value', {
				is: 'true',
				then: (schema) => schema.required('Element is required'),
				otherwise: (schema) => schema.notRequired(),
			}),
			conditional: ({ values }: { values: { [key: string]: any } }) => {
				return values.attributes.value2 === 'element'
			},
		},
	],
	label: 'Scroll to edge',
	sentence: ({ element2, isExample, value, value2 }: INodeRenderProps) => {
		return isExample
			? `Scroll to edge`
			: value2 === 'element'
			? `Scroll to ${sentenceValue(value)} edge within ${element2}`
			: `Scroll to ${sentenceValue(value)} edge of page`
	},
	family: INodeVariantTypes.action_scroll_page.split('_')[0],
	type: INodeVariantTypes.action_scroll_page.split('_')[1],
	subType: INodeVariantTypes.action_scroll_page.split('_')[2],
	tags: compact([
		INodeVariantTypes.action_scroll_page.split('_')[0],
		INodeVariantTypes.action_scroll_page.split('_')[1],
		INodeVariantTypes.action_scroll_page.split('_')[2],
		'view',
		'target',
		'focus',
		'move',
		'viewport',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ isExample, value }: INodeRenderProps) => {
			return (
				<>
					{isExample ? (
						'Scroll to edge'
					) : (
						<>
							Scroll to <strong>{formatValue(value)}</strong> edge
						</>
					)}
				</>
			)
		},
	}
}
