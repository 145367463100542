// ? ---
// ?	Imports
// ? ---
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import debug from 'debug'
import { nanoid } from 'nanoid'
import omitDeep from 'omit-deep-lodash'

import { difference, get, isEqual, map } from 'lodash'

import { RUN_UID_LENGTH } from 'globals/constants/misc'

import useAccount from 'hooks/useAccount'
import useTests from 'hooks/useTests'

import { useAuth } from 'components/_Global/_____AuthProvider'
import { IAccountOptions, IAccountQueryResponse } from 'data/accounts'
import { formatRunValues } from 'data/accounts/helpers/formatRunValues'
import { IFlow, IFlowSaveOptions } from 'data/flows'
import { constructTests } from 'data/flows/helpers/constructTests'
import { ITestInput, ITestRunTriggerType, ITestStatus } from 'data/tests'

// ? ---
// ?	Constants
// ? ---
const namespace = 'hooks-useTestGenerator'
const log = debug(`app:${namespace}`)

dayjs.extend(utc)

// ? ---
// ?	Hook
// ? ---
export default function useTestGenerator() {
	// * ---
	// *	Hooks
	// * ---
	const _tests = useTests()
	const _account = useAccount()
	const { user } = useAuth()

	// * ---
	// *	Create Flow Tests
	// * ---
	const createFlowTests = async ({
		constructedTests,
		flow,
		options,
		runId,
	}: {
		flow: IFlow
		runId?: string
		constructedTests?: ITestInput[]
		options?: IFlowSaveOptions
	}): Promise<void> => {
		// * Setup
		log('updateTests', { flow, constructedTests, options })
		runId = runId || nanoid(RUN_UID_LENGTH)

		// ? Construct Tests (if needed)
		if (!constructedTests) {
			const accountData = (await _account.fetchAccount()) as { data: IAccountQueryResponse }
			const account = accountData.data.accounts.data[0]

			constructedTests = await constructTests({
				flow,
				account: omitDeep(account.attributes.options, ['id', '__typename']) as IAccountOptions,
				run: {
					runId,
					triggerType: ITestRunTriggerType.user,
					triggerName: user?.FullName || user?.Email || '',
					triggerUserId: user?.Uid,
					triggerEmail: user?.Email,
					values: formatRunValues(get(account, 'attributes.values', [])),
				},
			})
		}

		// * Hash info
		const previousHashes = flow.attributes.currentHashes
		const newHashes = map(constructedTests, 'attributes.hash')
		const hashesMatch = isEqual(previousHashes, newHashes)
		const hashesToRemove = difference(previousHashes, newHashes)
		const hashesToAdd = difference(newHashes, previousHashes)
		log({ previousHashes, newHashes, hashesMatch, hashesToRemove, hashesToAdd })

		// * Create preview/pending tests
		for await (const newTest of constructedTests) {
			log(
				`+ create ${options?.andRunImmediately ? 'and run' : 'preview'} test for hash: ${
					newTest.attributes.hash
				}`
			)

			await _tests.insert({
				...newTest,
				attributes: {
					...newTest.attributes,
					status: options?.andRunImmediately ? ITestStatus.pending : ITestStatus.preview,
				},
			})
		}
	}

	// * ---
	// *	Return
	// * ---
	return {
		constructTests,
		createFlowTests,
	}
}
