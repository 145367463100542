//
// ? ---
// ?	Imports
// ? ---
import * as React from 'react'

import { ISocketSubscribe, ISocketSubscribeState, ISocketUnsubscribe } from 'components/_Global/__SocketHandler'

// ? ---
// ?	Types
// ? ---
export interface ISocketHandlerContext {
	connected: boolean
	subscribe: ISocketSubscribe
	unsubscribe: ISocketUnsubscribe
	unsubscribeAll: () => void
	subscriptions: ISocketSubscribeState
}

// ? ---
// ?	Constants
// ? ---
const socketHandlerInitialContext: ISocketHandlerContext = {} as ISocketHandlerContext

// ? ---
// ?	Export
// ? ---
export const SocketHandlerContext = React.createContext(socketHandlerInitialContext)
