// ? ---
// ?	Imports
// ? ---
import * as React from 'react'

import { compact } from 'lodash'

import { INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.mail_inbox_open,
	isLive: true,
	isMenuOption: true,
	isPro: false,
	isAccessibility: false,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.value',
			type: 'button-group',
			defaultValue: 'testcase',
			label: 'Inbox',
			options: [
				{
					label: 'Test Case',
					value: 'testcase',
				},
				{
					label: 'Account',
					value: 'account',
				},
			],
		},
	],
	label: 'Open inbox',
	sentence: ({ isExample, value }: INodeRenderProps) => {
		return isExample ? `Open inbox` : value === 'account' ? `Open account inbox` : `Scroll test case inbox`
	},
	family: INodeVariantTypes.mail_inbox_open.split('_')[0],
	type: INodeVariantTypes.mail_inbox_open.split('_')[1],
	subType: INodeVariantTypes.mail_inbox_open.split('_')[2],
	tags: compact([
		INodeVariantTypes.mail_inbox_open.split('_')[0],
		INodeVariantTypes.mail_inbox_open.split('_')[1],
		INodeVariantTypes.mail_inbox_open.split('_')[2],
		'email',
		'messages',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ isExample, value }: INodeRenderProps) => {
			return (
				<>
					{isExample ? (
						'Open inbox'
					) : value === 'account' ? (
						<>
							Open <strong>Account</strong> inbox
						</>
					) : (
						<>
							Open <strong>Test Case</strong> inbox
						</>
					)}
				</>
			)
		},
	}
}
