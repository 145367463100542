import * as yup from 'yup'

export const validationSchema = yup.object({
	attributes: yup.object({
		title: yup.string().trim().required('name is required'),
		stepTimeoutSeconds: yup
			.number()
			.min(1, 'must be 1 or more')
			.max(120, 'must be 120 or less')
			.required('timeout is required'),
	}),
})
