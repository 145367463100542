// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import * as yup from 'yup'

import { compact, includes, map } from 'lodash'

import {
	COMPARISON_TYPE_VALIDATION,
	STANDARD_COMPARISON_TYPE_CONDITIONAL_VALIDATION,
} from 'globals/constants/validations'
import { MAIL_MESSAGE_GROUP } from 'globals/constants/variantGroups'

import { formatComparisonType, formatEnumValue, formatValue, sentenceValue } from 'hooks/useNode'

import {
	INodeComparisonTypes,
	INodeMailOpenFilterProperties,
	INodeRenderProps,
	INodeVariantTypes,
	IUseNode,
	IUseNodeConfig,
} from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.mail_message_select,
	isLive: true,
	isMenuOption: true,
	isPro: false,
	isAccessibility: false,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.variantType',
			type: 'variantType',
			label: 'Type',
			options: MAIL_MESSAGE_GROUP,
		},
		{
			property: 'attributes.value',
			type: 'button-group',
			defaultValue: 'testcase',
			label: 'Inbox',
			options: [
				{
					label: 'Test Case',
					value: 'testcase',
				},
				{
					label: 'Account',
					value: 'account',
				},
			],
		},
		{
			property: 'attributes.value2',
			type: 'button-group',
			defaultValue: 'latest',
			label: 'Message',
			options: [
				{
					label: 'Latest',
					value: 'latest',
				},
				{
					label: 'Previous',
					value: 'previous',
				},
				{
					label: 'By Index',
					value: 'index',
				},
				{
					label: 'Custom',
					value: 'custom',
				},
			],
		},
		{
			property: 'attributes.value3',
			type: 'dropdown',
			label: 'Property',
			defaultValue: 'subject',
			options: map(INodeMailOpenFilterProperties, (label, value) => {
				return {
					label,
					value,
				}
			}),
			conditional: ({ values }: { values: { [key: string]: any } }) => {
				return values.attributes.value2 === 'custom'
			},
		},
		{
			property: 'attributes.comparisonType',
			type: 'comparisonType',
			defaultValue: 'equals',
			label: 'Comparison Type',
			validation: COMPARISON_TYPE_VALIDATION,
			conditional: ({ values }: { values: { [key: string]: any } }) => {
				return values.attributes.value2 === 'custom'
			},
		},
		{
			property: 'attributes.value4',
			type: 'text-with-tags',
			label: 'Value',
			conditional: ({ values }: { values: { [key: string]: any } }) => {
				return (
					values.attributes.value2 === 'custom' &&
					!includes(
						[INodeComparisonTypes.hasValue, INodeComparisonTypes.notHasValue],
						values.attributes.comparisonType
					)
				)
			},
			validation: yup.mixed().when('value2', {
				is: 'custom',
				then: () => STANDARD_COMPARISON_TYPE_CONDITIONAL_VALIDATION,
				otherwise: (schema) => schema.notRequired(),
			}),
		},
		{
			property: 'attributes.value5',
			type: 'slider',
			label: 'Message Index',
			helper: 'Latest Message = 0, Previous Message = 1, etc',
			defaultValue: '2',
			config: {
				min: 0,
				max: 30,
				step: 1,
			},
			conditional: ({ values }: { values: { [key: string]: any } }) => {
				return values.attributes.value2 === 'index'
			},
		},
	],
	label: 'Select email',
	sentence: ({ comparisonType, isExample, value2, value3, value4, value5 }: INodeRenderProps) => {
		if (isExample) return `Select email`
		switch (value2) {
			case 'previous':
				return `Select previous email`
			case 'index':
				return `Select email at index ${value5}`
			case 'custom':
				return `Select email with ${value3} ${formatComparisonType(comparisonType)} ${
					!includes([INodeComparisonTypes.hasValue, INodeComparisonTypes.notHasValue], comparisonType) &&
					sentenceValue(value4)
				}`
			case 'latest':
			default:
				return `Select latest email`
		}
	},
	family: INodeVariantTypes.mail_message_select.split('_')[0],
	type: INodeVariantTypes.mail_message_select.split('_')[1],
	subType: INodeVariantTypes.mail_message_select.split('_')[2],
	tags: compact([
		INodeVariantTypes.mail_message_select.split('_')[0],
		INodeVariantTypes.mail_message_select.split('_')[1],
		INodeVariantTypes.mail_message_select.split('_')[2],
		'email',
		'messages',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ comparisonType, isExample, value2, value3, value4, value5 }: INodeRenderProps) => {
			return (
				<>
					{isExample ? (
						'Select email'
					) : value2 === 'previous' ? (
						<>
							Select <strong>previous</strong> email
						</>
					) : value2 === 'index' ? (
						<>
							Select email at <strong>index</strong> <strong>{formatValue(value5)}</strong>
						</>
					) : value2 === 'custom' ? (
						<>
							Select email where <strong>{formatEnumValue(INodeMailOpenFilterProperties, value3)}</strong>{' '}
							<em>{formatComparisonType(comparisonType)}</em>{' '}
							{!includes(
								[INodeComparisonTypes.hasValue, INodeComparisonTypes.notHasValue],
								comparisonType
							) && <strong>{formatValue(value4)}</strong>}
						</>
					) : (
						<>
							Select <strong>latest</strong> email
						</>
					)}
				</>
			)
		},
	}
}
