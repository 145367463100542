// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'

import { FormControl, InputLabel, ListSubheader, MenuItem, Select } from '@mui/material'
import { get } from 'lodash'

import { COMPARISON_TYPES } from 'globals/constants/labels'

import { IUseNodeField } from 'data/nodes'

// ? ---
// ?	Types
// ? ---
type Props = {
	formik: any
	field: IUseNodeField
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Dialogs-UpsertFields-ComparisonType'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function ComparisonType({ field, formik }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')

	// * ---
	// *	Return
	// * ---
	return (
		<FormControl variant='outlined' fullWidth>
			<InputLabel id={`${field.property}-label`}>{field.label}</InputLabel>
			<Select
				data-test-id={`${namespace}--${field.property}`}
				labelId={`${field.property}-label`}
				id={field.property}
				name={field.property}
				value={get(formik?.values, field.property)}
				label={field.label}
				onChange={(event) => formik.setFieldValue(field.property, event.target.value)}
			>
				<ListSubheader>Basic</ListSubheader>
				<MenuItem value={'hasValue'}>{COMPARISON_TYPES['hasValue']}</MenuItem>
				<MenuItem value={'equals'}>{COMPARISON_TYPES['equals']}</MenuItem>
				<MenuItem value={'contains'}>{COMPARISON_TYPES['contains']}</MenuItem>
				<MenuItem value={'startsWith'}>{COMPARISON_TYPES['startsWith']}</MenuItem>
				<MenuItem value={'endsWith'}>{COMPARISON_TYPES['endsWith']}</MenuItem>

				<ListSubheader>Negative</ListSubheader>
				<MenuItem value={'notHasValue'}>{COMPARISON_TYPES['notHasValue']}</MenuItem>
				<MenuItem value={'notEquals'}>{COMPARISON_TYPES['notEquals']}</MenuItem>
				<MenuItem value={'notContains'}>{COMPARISON_TYPES['notContains']}</MenuItem>
				<MenuItem value={'notStartsWith'}>{COMPARISON_TYPES['notStartsWith']}</MenuItem>
				<MenuItem value={'notEndsWith'}>{COMPARISON_TYPES['notEndsWith']}</MenuItem>

				<ListSubheader>Numbers</ListSubheader>
				<MenuItem value={'greaterThan'}>{COMPARISON_TYPES['greaterThan']}</MenuItem>
				<MenuItem value={'greaterThanOrEqual'}>{COMPARISON_TYPES['greaterThanOrEqual']}</MenuItem>
				<MenuItem value={'lessThan'}>{COMPARISON_TYPES['lessThan']}</MenuItem>
				<MenuItem value={'lessThanOrEqual'}>{COMPARISON_TYPES['lessThanOrEqual']}</MenuItem>

				<ListSubheader>Advanced</ListSubheader>
				<MenuItem value={'regex'}>Custom Regex</MenuItem>
			</Select>
		</FormControl>
	)
}
