// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import { useQuery } from '@apollo/client'
import debug from 'debug'

import {
	Box,
	Checkbox,
	Chip,
	FormControl,
	FormHelperText,
	InputLabel,
	ListItemText,
	MenuItem,
	Select,
	Skeleton,
} from '@mui/material'
import { compact, find, get, includes, isEqual, map, reject, sortBy, unionBy, uniq } from 'lodash'

import { IFlow, IFlowQueryResponse, QUERY_FLOWS_SELECT } from 'data/flows'
import { IUseNodeField } from 'data/nodes'

// ? ---
// ?	Types
// ? ---
type Props = {
	formik: any
	field: IUseNodeField
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Dialogs-UpsertFields-Flows'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function Flows({ field, formik }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')

	const inputValue = field.asStringValue
		? compact(uniq((get(formik?.values, field.property, field.defaultValue || '') as string).split('|')))
		: get(formik?.values, field.property, [])

	// * ---
	// *	State
	// * ---
	const [busy, $busy] = React.useState(true)
	const [internalValue, $internalValue] = React.useState<string[]>(inputValue)
	const [combinedFlows, $combinedFlows] = React.useState<{ label: string; value: string }[]>([])

	// * ---
	// *	Data
	// * ---
	const { data: flows, loading } = useQuery<IFlowQueryResponse>(QUERY_FLOWS_SELECT, {
		variables: {
			filters: {},
		},
		fetchPolicy: 'no-cache',
	})

	// * ---
	// *	Effect: Update combinedFlows
	// * ---
	React.useEffect(() => {
		log('..')
		const temp = sortBy(
			reject(
				unionBy(
					map(get(flows, 'flows.data', []), (flow: IFlow) => {
						return {
							label: flow.attributes.title,
							value: flow.id,
						}
					}),
					combinedFlows,
					'value'
				),
				{ value: '' }
			),
			'label'
		)
		if (!isEqual(temp, combinedFlows)) {
			$combinedFlows(temp)
			$busy(false)
		}
	}, [flows, combinedFlows])

	// * ---
	// *	Effect: Update Value
	// * ---
	React.useEffect(() => {
		if (field.asStringValue) {
			formik.setFieldValue(field.property, `|${internalValue.join('|')}|`)
		} else {
			formik.setFieldValue(field.property, internalValue)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [internalValue])

	// * ---
	// *	Return
	// * ---
	return (
		<FormControl variant='outlined' fullWidth>
			{loading || busy ? (
				<Skeleton sx={{ height: '100%', transform: 'none' }} />
			) : (
				<>
					<InputLabel id={`${field.property}-label`}>{field.label}</InputLabel>
					<Select
						data-test-id={`${namespace}--${field.property}`}
						labelId={`${field.property}-label`}
						id={field.property}
						name={field.property}
						multiple
						value={internalValue}
						label={field.label}
						renderValue={(selected: string[]) => (
							<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
								{map(selected, (value: string) => (
									<Chip
										key={value}
										label={get(find(combinedFlows, { value }), 'label', '')}
										size='small'
										sx={{ textTransform: 'uppercase', fontSize: '80%', fontWeight: 600 }}
									/>
								))}
							</Box>
						)}
						onChange={(event) => $internalValue(event.target.value as string[])}
					>
						{map(combinedFlows, (option) => (
							<MenuItem key={option.value} value={option.value} dense={true}>
								<Checkbox checked={includes(get(formik?.values, field.property, []), option.value)} />
								<ListItemText primary={option.label} />
							</MenuItem>
						))}
					</Select>
					{get(field, 'helper') && <FormHelperText>{get(field, 'helper')}</FormHelperText>}
				</>
			)}
		</FormControl>
	)
}
