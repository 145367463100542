// ? ---
// ?	Imports
// ? ---
import reactStringReplace from 'react-string-replace'
import * as Sentry from '@sentry/nextjs'

import { compact, filter, forEach, get, includes, isEmpty, map, pick, union, values } from 'lodash'

import { VALUE_REGEX } from 'globals/constants/valueStore'

import { IAccount } from 'data/accounts'
import { ITestStep } from 'data/tests/steps'
import { ITest } from 'data/tests/type'

// ? ---
// ?	Export
// ? ---
export const getTestCaseValues = (testCase: ITest, account: IAccount): { [key: string]: any } => {
	// * Setup
	const runValues = get(testCase, 'attributes.runData.values', {})

	// ! Drop out: No run values
	if (isEmpty(runValues)) return {}

	// * Used Values
	let usedValues: any[] = []
	forEach(get(testCase, 'attributes.stepData', {}), (step: ITestStep) => {
		const fieldValues = compact(
			values(
				pick(step?.node, [
					'value',
					'value2',
					'value3',
					'value4',
					'value5',
					'value6',
					'value7',
					'value8',
					'value9',
					'value10',
					'value11',
					'value12',
					'value13',
					'value14',
					'value15',
					'value16',
					'value17',
					'value18',
					'value19',
					'value20',
					'elementSelector',
					'elementSelector2',
					'mfaTitle',
				])
			)
		)
		const fieldsUsingValueStore = filter(fieldValues, (value) => includes(`${value}`, '-{{-'))
		const valueStoreKeys = map(fieldsUsingValueStore, (fieldValue) =>
			reactStringReplace(`${fieldValue}`, VALUE_REGEX, (match) => {
				try {
					const tag = JSON.parse(match)
					return tag.value
				} catch (e) {
					Sentry.captureMessage(`getTestCaseValues !! value could not be parsed, match: ${match}`, 'warning')
				}
			})
		)
		usedValues = union(usedValues, ...valueStoreKeys)
	})

	// * Raw Test Values
	const testValues = pick(runValues, compact(usedValues))

	// ! Drop out: No test values
	if (isEmpty(testValues)) return {}

	// * Handle Private Values
	const privateKeys = map(filter(account.attributes.values, { isPrivate: true }), 'name')
	const response: { [key: string]: any } = {}
	forEach(testValues, (value, key) => {
		response[key] = includes(privateKeys, key) ? '/* PRIVATE VALUE */' : value
	})

	// * Return
	return response
}
