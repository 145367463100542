// ? ---
// ?	Imports
// ? ---
import * as yup from 'yup'

import { includes, values } from 'lodash'

import { INodeComparisonTypes } from 'data/nodes'

// ? ---
// ?	Exports
// ? ---
export const STORAGE_NAME_VALIDATION = yup
	.string()
	.matches(/^[a-zA-Z]\w+$/gm, 'Must be a valid storage name')
	.required('Storage name is required')

export const RESPONSE_PATH_VALIDATION = yup.string().matches(/^(?:\S+?\.)+\S+\b/g, 'Must be a valid json path')

export const ELEMENT_VALIDATION = yup.string().trim().required('Element is required')
export const MFA_VALIDATION = yup.string().trim().required('MFA is required')
export const COMPARISON_TYPE_VALIDATION = yup.string().oneOf(values(INodeComparisonTypes))

export const STANDARD_COMPARISON_TYPE_CONDITIONAL_VALIDATION = yup
	.string()
	.when(['comparisonType'], ([comparisonType], schema) => {
		return !includes([INodeComparisonTypes.hasValue, INodeComparisonTypes.notHasValue], comparisonType)
			? schema.required('Value is required')
			: schema
	})

export const ACCOUNT_NAME_VALIDATION = yup.string().min(3, 'Minimum 3 characters').required('Email is required')
export const EMAIL_VALIDATION = yup.string().email('Must be a valid email address').required('Email is required')
