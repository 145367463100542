export const STARTER_PLAN: { [key: string]: boolean } = {
	NmdEj5Q0: true, // XQ3 - Explorer
}

export const PLAN_MAX_CONCURRENCY: { [key: string]: number } = {
	// Current Plans
	NmdEj5Q0: 1, // XQ3 - Explorer
	rQVBKqQ6: 5, // XQ3 - 5 Runners (with discount)
	jW7jk09q: 5, // XQ3 - 5 Runners
	rmknjKWg: 20, // XQ3 - 20 Runners
	vWyVj1mb: 100, // XQ3 - 100 Runners
	y9qrd0WA: 0, // XQ3 - Unlimited Runners
	ZmN83792: 0, // Enterprise (Unlimited)

	// Historical
	xmeb6aQV: 1, // XQ2 - Starter (1,000)
	jW7v5l9q: 20, // XQ2 - Team
	EWBNaEWr: 100, // XQ2 - Pro
	Rm87EdW4: 10, // XQ1 - Evaluation Plan
	DQ2E0ymV: 0, // XQ1 - Enterprise Evaluation
	rmkaGk9g: 10, // XQ1 - Starter Plan
	vWyz8Mmb: 20, // XQ1 - Team Plan
	zWZrkqWp: 50, // XQ1 - Plus Plan
	LmJbMEmP: 0, // XQ1 - Pro Plan (Unlimited)
	A93VnGQ0: 10, // Free
	xmebbMQV: 10, // Freebie
	pWrNb2Wn: 10, // Evaluation
	nmD6rX9y: 50, // Starter
	BWzNywWE: 0, // Pro (Unlimited)
	BWzkMwmE: 100, // Founders
}

export const PLAN_PROMOTE_UPGRADE: { [key: string]: boolean } = {
	NmdEj5Q0: true, // XQ3 - Explorer
	rQVBKqQ6: true, // XQ3 - 5 Runners (with discount)
	jW7jk09q: true, // XQ3 - 5 Runners
}

// ? Outseta Docs: https://documenter.getpostman.com/view/3613332/outseta-rest-api-v1/7TNfr6k#05e0dfbe-4413-6d3a-5e13-0f2f0f72fc55
export const ACTIVE_ACCOUNT_STAGES = [2, 3, 4, 7] // 2 = Trialing, 3 = Subscribing, 4= Cancelling, 7 = Past Due
