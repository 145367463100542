// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import * as yup from 'yup'

import { compact } from 'lodash'

import { FAILED_STATUS_OPTIONS } from 'globals/constants/selectOptions'

import { formatElement, sentenceElement } from 'hooks/useNode'

import { INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'
import { ITestStepStatus } from 'data/tests'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.check_links_valid,
	isLive: false,
	isMenuOption: true,
	isPro: false,
	isAccessibility: false,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.value3',
			type: 'dropdown',
			label: 'Links Types',
			defaultValue: 'all',
			options: [
				{
					value: 'all',
					label: 'All Links',
				},
				{
					value: 'internal',
					label: 'Internal',
				},
				{
					value: 'external',
					label: 'External',
				},
			],
		},
		{
			property: 'attributes.value2',
			type: 'switch',
			label: 'Target Area',
			defaultValue: 'page',
			valueFalse: 'page',
			labelFalse: 'Entire Page',
			valueTrue: 'element',
			labelTrue: 'Inside Element',
		},
		{
			property: 'attributes.element',
			type: 'element',
			label: 'Element',
			validation: yup.string().when('value2', {
				is: 'element',
				then: (schema) => schema.required('Element is required'),
				otherwise: (schema) => schema.notRequired(),
			}),
			conditional: ({ values }: { values: { [key: string]: any } }) => {
				return values.attributes.value2 === 'element'
			},
		},
		{
			property: 'attributes.value',
			type: 'dropdown',
			label: 'Status',
			defaultValue: 'not-400-599',
			options: [
				{
					value: '200',
					label: 'All OK (200)',
				},
				{
					value: '200-299',
					label: 'All Successful (200-299)',
				},
				{
					value: 'not-404',
					label: 'No 404 Errors',
				},
				{
					value: 'not-400-499',
					label: 'No Client Errors (400-499)',
				},
				{
					value: 'not-500-599',
					label: 'No Server Errors (500-599)',
				},
				{
					value: 'not-400-599',
					label: 'No Errors (400-599)',
				},
			],
		},
		{
			property: 'attributes.config.failedStatus',
			type: 'button-group',
			label: 'Status on fail',
			defaultValue: ITestStepStatus.failed,
			options: FAILED_STATUS_OPTIONS,
		},
	],
	label: 'Check links',
	sentence: ({ element, isExample, value2 }: INodeRenderProps) => {
		return isExample
			? `Check links`
			: value2 === 'element'
			? `Check ${sentenceElement(element)} links`
			: `Check page links`
	},
	family: INodeVariantTypes.check_links_valid.split('_')[0],
	type: INodeVariantTypes.check_links_valid.split('_')[1],
	subType: INodeVariantTypes.check_links_valid.split('_')[2],
	tags: compact([
		INodeVariantTypes.check_links_valid.split('_')[0],
		INodeVariantTypes.check_links_valid.split('_')[1],
		INodeVariantTypes.check_links_valid.split('_')[2],
		'test',
		'expect',
		'assert',
		'links',
		'valid',
		'404s',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ element, isExample, value2 }: INodeRenderProps) => {
			return (
				<>
					{isExample ? (
						<>Check links</>
					) : value2 === 'element' ? (
						<>
							Check <strong>{formatElement(element)}</strong> links
						</>
					) : (
						<>Check page links</>
					)}
				</>
			)
		},
	}
}
