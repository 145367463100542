// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import { useLazyQuery } from '@apollo/client'
import debug from 'debug'

import { useRouter } from 'next/router'
import { Alert, Autocomplete, Box, Grid, Skeleton, TextField, useTheme } from '@mui/material'
import { filter, find, forEach, get, isArray, isEmpty, isEqual, map, union } from 'lodash'

import { colors } from 'globals/constants/colors'

import useFlow from 'hooks/useFlow'
import useNode, { formatValue, formatValueAsString } from 'hooks/useNode'

import { IFlowQueryResponse, QUERY_FLOWS } from 'data/flows'
import { INodeQueryResponse, IUseNodeField, QUERY_NODES_GROUPED } from 'data/nodes'

// ? ---
// ?	Types
// ? ---
type Props = {
	formik: any
	field: IUseNodeField

	close?: () => void
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Dialogs-UpsertFields-SelectFile'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function SelectFile({ field, formik }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const _flow = useFlow()
	const _node = useNode()
	const router = useRouter()
	const theme = useTheme()

	// * ---
	// *	State
	// * ---
	const [loaded, $loaded] = React.useState(false)
	const [files, $files] = React.useState<{ label: string; value: string }[]>([])

	// * ---
	// *	Current Data
	// * ---
	const [loadFlow] = useLazyQuery<IFlowQueryResponse>(QUERY_FLOWS, {
		fetchPolicy: 'network-only',
	})

	const [loadGroup] = useLazyQuery<INodeQueryResponse>(QUERY_NODES_GROUPED, {
		fetchPolicy: 'network-only',
	})

	// * ---
	// *	Effect: Load Flow Files
	// * ---
	React.useEffect(() => {
		let abort = false
		log('..')

		const load = async (flowId: string | undefined, groupId: string | undefined) => {
			log('.. load')
			let filesFromFlow: { label: string; value: string }[] = []
			const filesFromGroup: { label: string; value: string }[] = []

			// * File found in flow
			if (flowId) {
				log('.. Find files in flow')
				const flowResponse = await loadFlow({
					variables: {
						filters: {
							id: { eq: flowId },
						},
					},
				})
				if (flowResponse.data?.flows.data[0]) {
					filesFromFlow = map(_flow.filesInStore(flowResponse.data?.flows.data[0]), (item) => {
						return {
							label: item.value,
							...item,
						}
					})
				}
			}

			// * Files found in group
			if (groupId) {
				log('.. Find files in group')
				const groupResponse = await loadGroup({
					variables: {
						filters: {
							group: { id: { eq: groupId } },
						},
					},
				})
				if (groupResponse.data?.nodes.data) {
					forEach(groupResponse.data?.nodes.data, (node) => {
						forEach(
							filter(_node[get(node, 'attributes.variantType')].fields, { isFilename: true }),
							(field) => {
								const filename = get(node, `[${field.property}]`) as string | undefined
								if (filename !== undefined) {
									filesFromGroup.push({ label: filename, value: filename })
								}
							}
						)
					})
				}
			}

			// * Update file options
			const tempFiles = union(filesFromFlow, filesFromGroup)
			if (!isEqual(tempFiles, files) && !abort) {
				$files(tempFiles)
			}
			$loaded(true)
		}

		log(router.query.id, formik.initialValues.__groupId, loaded, formik)
		if ((router.query.id || formik.initialValues.__groupId) && !loaded) {
			const flowId = isArray(router.query?.id) ? router.query.id[0] : router.query?.id
			const groupId = get(formik, 'initialValues.__groupId')
			load(flowId, groupId)
		}

		return () => {
			abort = true
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// * ---
	// *	Helpers
	// * ---
	const fileLabelFromValue = (value: string) => `${get(find(files, { value }), 'label', '')}`

	// * ---
	// *	Return
	// * ---
	return (
		<>
			<Grid container direction='row' justifyContent='space-between' alignItems='flex-start'>
				<Grid item xs={12}>
					{!isEmpty(files) ? (
						<Autocomplete
							data-test-id={`${namespace}--${field.property}`}
							fullWidth
							id={field.property}
							value={
								get(formik?.values, field.property) !== '' ? get(formik?.values, field.property) : null
							}
							onChange={(event, value) => formik.setFieldValue(field.property, value)}
							isOptionEqualToValue={(optionValue, selectedValue) => {
								return optionValue === selectedValue
							}}
							getOptionLabel={(value) => formatValueAsString(fileLabelFromValue(value))}
							options={map(files, 'value')}
							renderInput={(params) => (
								<TextField
									{...(params as any)}
									label={field.label}
									error={
										get(formik, `touched[${field.property}]`) &&
										Boolean(get(formik, `errors[${field.property}]`))
									}
									helperText={
										get(formik, `touched[${field.property}]`) &&
										get(formik, `errors[${field.property}]`)
									}
								/>
							)}
							renderOption={(props, value) => (
								<Box
									component='li'
									{...(props as any)}
									sx={{
										'& .node-value-tag': {
											p: `1px 3px 2px`,
											ml: `2px`,
											borderRadius: '4px',
											color: 'white',
											backgroundColor: colors['grey'][700],
										},
									}}
								>
									{formatValue(fileLabelFromValue(value))}
								</Box>
							)}
						/>
					) : !loaded ? (
						<Skeleton sx={{ height: '56px', transform: 'none' }} />
					) : (
						<Alert severity='warning' sx={{ mt: 1, border: `1px solid ${theme.palette.warning.dark}` }}>
							No files found
						</Alert>
					)}
				</Grid>
			</Grid>
		</>
	)
}
