// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import Color from 'color'
import debug from 'debug'

import { Box, FormControl, FormHelperText, Grid, Typography, useTheme } from '@mui/material'
import Icon from '@mdi/react'
import { get } from 'lodash'

import { icons } from 'globals/constants/icons'

import useThemeMode from 'hooks/useThemeMode'

import { helperText } from 'components/_Global/Forms/helperHelper'
import { IUseNodeField } from 'data/nodes'

// ? ---
// ?	Types
// ? ---
type Props = {
	formik: any
	field: IUseNodeField
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Dialogs-UpsertFields-BooleanButton'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function BooleanButton({ field, formik }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const theme = useTheme()
	const _theme = useThemeMode()
	const helper = helperText({ field, formik })
	const valueTrue = field.valueTrue || true
	const valueFalse = field.valueFalse || false

	const currentState = get(formik?.values, field.property, field.defaultValue) !== valueFalse

	// * ---
	// *	Return
	// * ---
	return (
		<FormControl variant='outlined' fullWidth>
			<Box
				data-test-id='BrHWrIP73xPoWLyMCuGTo'
				sx={{
					borderRadius: `${theme.shape.borderRadius * 2}px`,
					pt: 0.38,
					pb: 0.38,
					pl: 0.8,
					pr: 0.7,
					color: currentState ? theme.palette.primary.main : undefined,
					backgroundColor: currentState ? Color(theme.palette.primary.main).alpha(0.1).string() : undefined,
					border: `1px solid ${
						currentState
							? theme.palette.primary.main
							: _theme.conditional(theme.palette.grey[500], theme.palette.grey[700])
					}`,
					cursor: 'pointer',
					boxShadow: currentState
						? `0 0 5px 0 ${Color(theme.palette.primary.main).alpha(0.5).string()}`
						: undefined,
				}}
				onClick={() => formik.setFieldValue(field.property, currentState ? valueFalse : valueTrue)}
			>
				<Grid container direction='row' justifyContent='space-between' alignItems='center' spacing={0.7}>
					<Grid item>
						<Icon
							color={
								currentState
									? theme.palette.primary.main
									: _theme.conditional(theme.palette.grey[500], theme.palette.grey[700])
							}
							style={{ verticalAlign: 'middle' }}
							path={currentState ? icons.checkedIcon : icons.uncheckedIcon}
							size={1.4}
						/>
					</Grid>
					<Grid item xs>
						<Typography
							variant={'body2'}
							fontWeight={'bold'}
							color={
								currentState
									? _theme.conditional(theme.palette.primary.main, theme.palette.primary.light)
									: theme.palette.text.secondary
							}
						>
							{field.label}
						</Typography>
						{field.helper && (
							<Typography
								variant={'body2'}
								fontWeight={500}
								color={
									currentState
										? _theme.conditional(theme.palette.primary.main, theme.palette.primary.light)
										: theme.palette.text.secondary
								}
							>
								{field.helper}
							</Typography>
						)}
					</Grid>
				</Grid>
			</Box>
			{helper.active && helper.isError && (
				<FormHelperText error={helper.isError}>{helper.message}</FormHelperText>
			)}
		</FormControl>
	)
}
