// ? ---
// ?	Imports
// ? ---
import debug from 'debug'

import { forEach } from 'lodash'

import { IAccountValue } from 'data/accounts'

// ? ---
// ?	Constants
// ? ---
const namespace = 'data-accounts-helpers-formatRunValues'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Export
// ? ---
export const formatRunValues = (values?: IAccountValue[]) => {
	log('.')
	const result: { [key: string]: any } = {}
	forEach(values, (value) => {
		switch (value.type) {
			case 'json':
				result[value.name] = value.isPrivate ? value.jsonValue_private : value.jsonValue
				break
			case 'number':
				result[value.name] = value.isPrivate ? value.numberValue_private : value.numberValue
				break
			case 'boolean':
				result[value.name] = value.isPrivate ? value.booleanValue_private : value.booleanValue
				break
			case 'string':
			default:
				result[value.name] = value.isPrivate ? value.stringValue_private : value.stringValue
				break
		}
	})
	return result
}
