// ? ---
// ?	Imports
// ? ---
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import debug from 'debug'

import { filter, forEach, get, isEqual, map, pick, reject, uniq, uniqBy } from 'lodash'

import { GLOBAL_FILES } from 'globals/constants/fileStore'
import { GLOBAL_VALUES } from 'globals/constants/valueStore'

import useAccount from 'hooks/useAccount'
import useFlows from 'hooks/useFlows'
import useNode from 'hooks/useNode'
import useTestGenerator from 'hooks/useTestGenerator'

import { IFlowContext } from 'data/flows'
import { getCheckRatio } from 'data/flows/helpers/getCheckRatio'
import { ITestRunTriggerType } from 'data/tests'

// ? ---
// ?	Constants
// ? ---
const namespace = 'hooks-useFlow'
const log = debug(`app:${namespace}`)

dayjs.extend(utc)

// ? ---
// ?	Hook
// ? ---
export default function useFlow() {
	// * ---
	// *	Hooks
	// * ---
	const _node = useNode()
	const _flows = useFlows()
	const _account = useAccount()
	const _testGenerator = useTestGenerator()

	// * ---
	// *	Values In Store
	// * ---
	const valuesInStore: IFlowContext['valuesInStore'] = (item, options) => {
		// * Global Values
		const globalValues = GLOBAL_VALUES

		// * Account values
		const accountValues = map(_account.data?.attributes.values, (value) => {
			return {
				value: value.name,
			}
		})

		// * Flow values
		const flowValues: { value: string }[] = []
		forEach(item.attributes.nodes.data, (node) => {
			forEach(filter(_node[get(node, 'attributes.variantType')].fields, { isValueStoreKey: true }), (field) => {
				const value = get(node, `[${field.property}]`)
				if (value !== undefined) {
					flowValues.push({ value })
				}
			})
		})

		// * Return Values
		const temp = []
		if (!get(options, 'excludeFlow', false)) {
			temp.push(...flowValues)
		}
		if (!get(options, 'excludeAccount', false)) {
			temp.push(...accountValues)
		}
		if (!get(options, 'excludeGlobal', false)) {
			temp.push(...globalValues)
		}
		const values = reject(uniqBy(temp, 'value'), { value: '' })
		log('valuesInStore', values)
		return values
	}

	// * ---
	// *	Files In Store
	// * ---
	const filesInStore: IFlowContext['filesInStore'] = (item, options) => {
		// * Global Values
		const globalFiles = GLOBAL_FILES

		// * Account values
		const accountFiles: never[] = []

		// * Flow values
		const flowFiles: { value: string }[] = []
		forEach(item.attributes.nodes.data, (node) => {
			forEach(filter(_node[get(node, 'attributes.variantType')].fields, { isFilename: true }), (field) => {
				const filename = get(node, `[${field.property}]`)
				if (filename !== undefined) {
					flowFiles.push({ value: filename })
				}
			})
		})

		// * Return Values
		const temp = []
		if (!get(options, 'excludeFlow', false)) {
			temp.push(...flowFiles)
		}
		if (!get(options, 'excludeAccount', false)) {
			temp.push(...accountFiles)
		}
		if (!get(options, 'excludeGlobal', false)) {
			temp.push(...globalFiles)
		}
		const files = uniqBy(temp, 'value')
		log('filesInStore', files)
		return files
	}

	// * ---
	// *	Save
	// * ---
	const save: IFlowContext['save'] = async ({ edges, flow, nodes }, options) => {
		log('save', { flow, nodes, edges, options })

		// * Setup
		const nodeMeta = map(nodes, (node) => pick(node, ['id', 'position', 'data.id', 'data.attributes.variantType']))
		const nodeIds: string[] = uniq(map(nodeMeta, 'data.id'))
		const edgeMeta = uniqBy(
			map(edges, (edge) => pick(edge, ['id', 'source', 'target'])),
			'id'
		)
		const checkRatio = getCheckRatio(map(nodes, 'data'), nodeMeta, edgeMeta)
		log('checkRatio', checkRatio)

		// * Build Flow Input
		const flowInput = {
			id: flow.id,
			attributes: {
				checksum: flow.attributes.checksum,
				nodes: nodeIds,
				nodeMeta,
				edgeMeta,
				checkRatio,
				useLightningLane: false, // <-- Disables LightningLane runner after any change, runner can re-enable after run if still compatible
			},
		}

		// * Update Flow with user changes
		let updatedFlow = await _flows.update(flowInput)

		// * Construct Tests
		const constructedTests = await _testGenerator.constructTests({
			flow: updatedFlow,
			run: {
				triggerType: ITestRunTriggerType.user,
				triggerName: '',
				triggerUserId: '',
				triggerEmail: '',
				values: {},
			},
		})

		// ? Update Flow (again) with new hashes (if needed)
		const currentHashes = map(constructedTests, 'attributes.hash')
		if (!isEqual(updatedFlow.attributes.currentHashes, currentHashes)) {
			updatedFlow = await _flows.update({
				id: updatedFlow.id,
				attributes: {
					currentHashes,
				},
			})

			// * Update Peak Flow Branches
			if (
				!get(flow, 'attributes.isFixture', false) &&
				currentHashes.length > get(_account, 'outseta.DqaPeakFlowBranches', 0)
			) {
				_account.update({
					DqaPeakFlowBranches: currentHashes.length,
				})
			}
		}

		// * Callbacks
		if (options?.onSuccess) options.onSuccess(updatedFlow)
		if (options?.onComplete) options.onComplete(updatedFlow)

		// * Return FLow
		return updatedFlow
	}

	// * ---
	// *	Run
	// * ---
	const run: IFlowContext['run'] = async (item, options) => {
		log('run', { item, options })
		await _flows.runHook([item.id], { ...(options || {}) })
	}

	// * ---
	// *	Preview
	// * ---
	const preview: IFlowContext['preview'] = async (item, options) => {
		log('preview', { item })
		await _flows.runHook([item.id], { ...(options || {}), preview: true })
	}

	// * ---
	// *	Return
	// * ---
	return {
		save,
		run,
		preview,
		valuesInStore,
		filesInStore,
	}
}
