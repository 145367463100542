// ? ---
// ?	Fragment
// ? ---
import gql from 'graphql-tag'

// ? ---
// ?	Queries
// ? --
export const QUERY_ACCOUNT = gql(/* GraphQL */ `
	query query_accounts_main($filters: AccountFiltersInput!) {
		accounts(filters: $filters, pagination: { pageSize: 1 }) {
			data {
				id
				attributes {
					updatedAt
					values {
						id
						name
						type
						stringValue
						numberValue
						booleanValue
						jsonValue
						isPrivate
					}
					keys {
						id
						name
						isActive
						userId
						activeAt
						inactiveAt
						slackChannelId
					}
					integrations {
						id
						type
						isActive
					}
					options {
						id
						maxConcurrency
						blockedHostnames
						abandonQueuedOnFirstFail
						stepTimeoutSeconds
					}
					tags {
						id
						label
						value
					}
				}
			}
		}
	}
`)

export const QUERY_ACCOUNT_SERVER = gql(/* GraphQL */ `
	query query_accounts_server($filters: AccountFiltersInput!) {
		accounts(filters: $filters, pagination: { pageSize: 1 }) {
			data {
				id
				attributes {
					updatedAt
					values {
						id
						name
						type
						stringValue
						numberValue
						booleanValue
						jsonValue
						stringValue_private
						numberValue_private
						booleanValue_private
						jsonValue_private
						isPrivate
					}
					keys {
						id
						name
						key
						isActive
						userId
						activeAt
						inactiveAt
						slackChannelId
					}
					integrations {
						id
						type
						isActive
					}
					options {
						id
						maxConcurrency
						blockedHostnames
						abandonQueuedOnFirstFail
						stepTimeoutSeconds
					}
					tags {
						id
						label
						value
					}
				}
			}
		}
	}
`)

export const QUERY_ACCOUNT_MAILBOX = gql(/* GraphQL */ `
	query query_accounts_server($filters: AccountFiltersInput!) {
		accounts(filters: $filters, pagination: { pageSize: 1 }) {
			data {
				id
			}
		}
	}
`)

// ? ---
// ?	Mutations
// ? --
export const ADD_ACCOUNT = gql(/* GraphQL */ `
	mutation create_account($data: AccountInput!) {
		createAccount(data: $data) {
			data {
				id
				attributes {
					updatedAt
				}
			}
		}
	}
`)

export const EDIT_ACCOUNT = gql(/* GraphQL */ `
	mutation update_account($id: ID!, $data: AccountInput!) {
		updateAccount(id: $id, data: $data) {
			data {
				id
				attributes {
					updatedAt
				}
			}
		}
	}
`)
