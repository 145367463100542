// ? ---
// ?	Imports
// ? ---
import { useTheme } from '@mui/material'

// ? ---
// ?	Constants
// ? ---

// ? ---
// ?	Hook
// ? ---
export default function useThemeMode() {
	// * ---
	// *	Setup
	// * ---
	const theme = useTheme()

	// * ---
	// *	Switch
	// * ---
	const conditional = (light: string | undefined, dark: string | undefined) => {
		return theme.palette.mode === 'dark' ? dark : light
	}

	// * ---
	// *	Return
	// * ---
	return {
		conditional,
	}
}
