// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import * as yup from 'yup'

import { compact, includes } from 'lodash'

import { STANDARD_COMPARISON_TYPE_CONDITION } from 'globals/client/conditions'
import { FAILED_STATUS_OPTIONS } from 'globals/constants/selectOptions'
import {
	COMPARISON_TYPE_VALIDATION,
	STANDARD_COMPARISON_TYPE_CONDITIONAL_VALIDATION,
} from 'globals/constants/validations'

import { formatComparisonType, formatValue, sentenceValue } from 'hooks/useNode'

import { INodeComparisonTypes, INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'
import { ITestStepStatus } from 'data/tests'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.check_localStorage_value,
	isLive: true,
	isMenuOption: true,
	isPro: false,
	isAccessibility: false,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.value',
			type: 'text',
			label: 'Key Name',
			validation: yup.string().trim().required('Key name is required'),
		},
		{
			property: 'attributes.comparisonType',
			type: 'comparisonType',
			defaultValue: 'equals',
			label: 'Comparison Type',
			validation: COMPARISON_TYPE_VALIDATION,
		},
		{
			property: 'attributes.value2',
			type: 'text-with-tags',
			label: 'Value',
			conditional: STANDARD_COMPARISON_TYPE_CONDITION,
			validation: STANDARD_COMPARISON_TYPE_CONDITIONAL_VALIDATION,
		},
		{
			property: 'attributes.config.failedStatus',
			type: 'button-group',
			label: 'Status on fail',
			defaultValue: ITestStepStatus.failed,
			options: FAILED_STATUS_OPTIONS,
		},
	],
	label: 'Check localStorage',
	sentence: ({ comparisonType, isExample, value: key, value2: value }: INodeRenderProps) => {
		return isExample
			? `Check localStorage`
			: `Check localStorage ${sentenceValue(key)} ${formatComparisonType(comparisonType)} ${
					!includes([INodeComparisonTypes.hasValue, INodeComparisonTypes.notHasValue], comparisonType) &&
					sentenceValue(value)
			  }`
	},
	family: INodeVariantTypes.check_localStorage_value.split('_')[0],
	type: INodeVariantTypes.check_localStorage_value.split('_')[1],
	subType: INodeVariantTypes.check_localStorage_value.split('_')[2],
	tags: compact([
		INodeVariantTypes.check_localStorage_value.split('_')[0],
		INodeVariantTypes.check_localStorage_value.split('_')[1],
		INodeVariantTypes.check_localStorage_value.split('_')[2],
		'test',
		'expect',
		'assert',
		'data',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ comparisonType, isExample, value: key, value2: value }: INodeRenderProps) => {
			return (
				<>
					Check localStorage{' '}
					{!isExample && (
						<>
							<strong>{formatValue(key)}</strong>
							<em>{formatComparisonType(comparisonType)}</em>
							{!includes(
								[INodeComparisonTypes.hasValue, INodeComparisonTypes.notHasValue],
								comparisonType
							) && <strong>{formatValue(value)}</strong>}
						</>
					)}
				</>
			)
		},
	}
}
