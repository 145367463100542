// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'
import { useAnimation } from 'framer-motion'

import { Dialog, DialogActions, DialogContent, DialogTitle, Fab, Typography } from '@mui/material'
import Icon from '@mdi/react'

import { icons } from 'globals/constants/icons'
import { DIALOG_SMALL_WIDTH } from 'globals/constants/sizes'

import ShakeablePaperSmall from 'components/_Global/Dialogs/helpers/ShakeablePaperSmall'
import { Transition } from 'components/_Global/Dialogs/helpers/Transition'

// ? ---
// ?	Types
// ? ---
type Props = {
	state: boolean
	data?: {
		title: string
		body: string
	}
	options?: {
		onCancel?: () => void
		onConfirm: () => void
	}
	close: () => void
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Dialogs-SuggestReloadDialog'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function SuggestReloadDialog({ close, data, options, state }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const controls = useAnimation()

	// * ---
	// *	Return: Good
	// * ---

	return (
		<Dialog
			open={state}
			TransitionComponent={Transition}
			sx={{
				'& .MuiDialog-paperFullWidth': {
					maxWidth: DIALOG_SMALL_WIDTH,
				},
			}}
			fullWidth={true}
			onClose={() => {
				if (options?.onConfirm) options.onConfirm()
				close()
			}}
			PaperComponent={ShakeablePaperSmall}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			PaperProps={{ controls }}
			aria-labelledby={namespace}
		>
			<DialogTitle id={namespace} className={'dragHandle'}>
				{data?.title || 'View has been updated'}
			</DialogTitle>
			<DialogContent>
				<Typography variant={'body1'} sx={{ mt: 1, mb: 1 }}>
					{data?.body || 'Reload view to get the updates'}
				</Typography>
			</DialogContent>
			<DialogActions sx={{ minHeight: '56px' }}>
				<Fab
					data-test-id='anrwknnxAxxD3d-OcHI6m'
					color='secondary'
					aria-label='Reload'
					onClick={() => {
						if (options?.onConfirm) options.onConfirm()
						close()
					}}
				>
					<Icon path={icons.reloadIcon} size={1} />
				</Fab>
			</DialogActions>
		</Dialog>
	)
}
