// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'
import { useFormik } from 'formik'
import { useAnimation } from 'framer-motion'

import { useRouter } from 'next/router'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Fab,
	Grid,
	IconButton,
	Stack,
	useTheme,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import Icon from '@mdi/react'
import { compact, get, isEmpty, isEqual, map, omit, toNumber, uniq, uniqBy, upperFirst } from 'lodash'

import { icons } from 'globals/constants/icons'
import { DIALOG_MEDIUM_WIDTH } from 'globals/constants/sizes'

import useAccount from 'hooks/useAccount'
import useFlows from 'hooks/useFlows'

import ShakeablePaperMedium from 'components/_Global/Dialogs/helpers/ShakeablePaperMedium'
import { Transition } from 'components/_Global/Dialogs/helpers/Transition'
import Field from 'components/_Global/Forms/Field'
import { IFlow, validationSchema } from 'data/flows'

// ? ---
// ?	Types
// ? ---
type Props = {
	state: boolean
	data: undefined | Partial<IFlow>
	options?: {
		[key: string]: any
	}
	close: () => void
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Flows-FlowDialog'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function FlowDialog({ close, data, options, state }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const theme = useTheme()
	const _account = useAccount()
	const _flows = useFlows()
	const controls = useAnimation()
	const router = useRouter()

	// * ---
	// *	State
	// * ---
	const [saving, $saving] = React.useState(false)

	// * ---
	// *	Formik
	// * ---
	const formik = useFormik({
		validationSchema,
		initialValues: {
			...data,
			attributes: {
				...omit(data?.attributes, ['nodeMeta', 'edgeMeta', 'nodes', 'tests', 'updatedAt']),
				title: data?.attributes?.title || '',
				tags: compact(uniq((data?.attributes?.tags || '').split('|'))),
				stopTestAfterFirstError: get(data, 'attributes.stopTestAfterFirstError', true),
				stepTimeoutSeconds: data?.attributes?.stepTimeoutSeconds || 5,
				lighthouse: data?.attributes?.lighthouse || false,
				isFixture: data?.attributes?.isFixture || false,
				isIsolated: data?.attributes?.isIsolated || false,
				isAccessibility: data?.attributes?.isAccessibility || false,
			},
		},
		onSubmit: async (values) => {
			$saving(true)
			log('onSubmit', values)

			// ? If Flow isFixture & user changes Flow's title, remove the fixture flag
			if (values.attributes.isFixture && !isEqual(data?.attributes?.title, values.attributes.title)) {
				values.attributes.isFixture = false
			}

			await _flows.upsert(
				{
					...values,
					attributes: {
						...omit(values.attributes, ['nodes', 'currentHashes', 'runs']),
						stepTimeoutSeconds: toNumber(values.attributes.stepTimeoutSeconds),
						tags: `|${values.attributes.tags.join('|')}|`,
					},
				},
				{
					onSuccess: (flow) => {
						log('onSubmit onSuccess', { flow, options })
						close()
						$saving(false)
						if (options?.onSuccess) options.onSuccess(flow)
						if (options?.onComplete) options.onComplete(flow)
					},
				}
			)
		},
	})

	// * ---
	// *	Return: Good
	// * ---
	return (
		<Dialog
			open={state}
			TransitionComponent={Transition}
			sx={{
				'& .MuiDialog-paperFullWidth': {
					maxWidth: DIALOG_MEDIUM_WIDTH,
				},
			}}
			fullWidth={true}
			onClose={() => {
				if (formik.dirty) {
					controls.start('start')
				} else {
					close()
					if (options?.onDismiss) options.onDismiss(data)
				}
			}}
			PaperComponent={ShakeablePaperMedium}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			PaperProps={{ controls }}
			aria-labelledby={namespace}
		>
			<form onSubmit={formik.handleSubmit}>
				<DialogTitle id={namespace} className={'dragHandle'}>
					{options?.title ? options.title : isEmpty(data?.id) ? 'Create Flow' : 'Update Flow'}
				</DialogTitle>
				<DialogContent>
					<Stack sx={{ pt: 2 }}>
						{/*{_account.accountPlanId === EXPLORER_PLAN && options?.title && (*/}
						{/*	<Field*/}
						{/*		field={{*/}
						{/*			property: 'attributes.isAccessibility',*/}
						{/*			type: 'button-group',*/}
						{/*			label: 'Flow Type',*/}
						{/*			options: [*/}
						{/*				{ label: 'Explore', value: false },*/}
						{/*				{ label: 'Accessibility', value: true },*/}
						{/*			],*/}
						{/*			helper: 'Accessibility Flows have limited Nodes but are free to run.',*/}
						{/*		}}*/}
						{/*		formik={formik}*/}
						{/*	/>*/}
						{/*)}*/}

						{!options?.title && data?.attributes?.isAccessibility && (
							<Field
								field={{
									property: 'attributes.isAccessibility',
									type: 'button',
									label: 'Accessibility Flow',
									helper: 'Disable to convert to Full Flow. This cannot be reversed.',
								}}
								formik={formik}
							/>
						)}

						<Field
							field={{
								property: 'attributes.title',
								type: 'text',
								label: 'Title',
							}}
							formik={formik}
						/>
						<Field
							field={{
								property: 'attributes.tags',
								type: 'multiSelect',
								label: 'Tags',
								options: uniqBy(
									[
										..._account.tags(),
										...map(formik.values.attributes.tags, (tag) => {
											return { label: upperFirst(tag), value: tag }
										}),
									],
									'value'
								),
							}}
							formik={formik}
						/>
						{/*<Box sx={{ pb: 2 }}>*/}
						{/*	<TextField*/}
						{/*		fullWidth*/}
						{/*		id='attributes.stepTimeoutSeconds'*/}
						{/*		name='attributes.stepTimeoutSeconds'*/}
						{/*		label='Test Step Timeout'*/}
						{/*		value={formik?.values?.attributes?.stepTimeoutSeconds}*/}
						{/*		onChange={formik.handleChange}*/}
						{/*		variant='outlined'*/}
						{/*		inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}*/}
						{/*		InputProps={{ endAdornment: <InputAdornment position='end'>seconds</InputAdornment> }}*/}
						{/*		error={*/}
						{/*			get(formik, 'touched.attributes.stepTimeoutSeconds') &&*/}
						{/*			Boolean(get(formik, 'errors.attributes.stepTimeoutSeconds'))*/}
						{/*		}*/}
						{/*		helperText={*/}
						{/*			get(formik, 'touched.attributes.stepTimeoutSeconds') &&*/}
						{/*			get(formik, 'errors.attributes.stepTimeoutSeconds')*/}
						{/*		}*/}
						{/*	/>*/}
						{/*</Box>*/}
						{/*<Field*/}
						{/*	field={{*/}
						{/*		property: 'attributes.lighthouse',*/}
						{/*		type: 'switch',*/}
						{/*		label: 'Record Lighthouse Flow across test',*/}
						{/*		helper: 'Increases the length of your tests',*/}
						{/*	}}*/}
						{/*	formik={formik}*/}
						{/*/>*/}
						<Field
							field={{
								property: 'attributes.stopTestAfterFirstError',
								type: 'button',
								label: 'Stop on first fail',
								helper: 'Reduces the length of failing tests',
							}}
							formik={formik}
						/>
						<Field
							field={{
								property: 'attributes.isIsolated',
								type: 'button',
								label: 'Run in isolation',
								helper: 'Test cases run consecutively in isolation.',
							}}
							formik={formik}
						/>
					</Stack>
				</DialogContent>
				<DialogActions>
					<Grid
						container
						direction='row'
						justifyContent='space-between'
						alignItems='center'
						spacing={1}
						sx={{ pl: 1, pr: 1 }}
					>
						<Grid item>
							{!isEmpty(data?.id) && (
								<IconButton
									data-test-id='EbR6zgqU_neNflMi4b49I'
									title={'Delete Flow'}
									sx={{ color: theme.palette.text.secondary }}
									onClick={() =>
										_flows.remove(data as IFlow, {
											onSuccess: () => {
												close()
												router.push('/app/flows')
												if (options?.onDelete) {
													setTimeout(() => options.onDelete(), 0)
												}
											},
										})
									}
								>
									<Icon path={icons.removeIcon} size={1} />
								</IconButton>
							)}
						</Grid>
						<Grid item>
							<Button
								data-test-id='jzHhA2zDByY6hQOLoUusE'
								color={'secondary'}
								onClick={() => {
									close()
									if (options?.onCancel) options.onCancel(data)
									if (options?.onDismiss) options.onDismiss(data)
								}}
							>
								Cancel
							</Button>
						</Grid>
					</Grid>
					<Fab
						color='primary'
						aria-label='Save Flow'
						type='submit'
						disabled={saving}
						sx={{
							backgroundColor: saving || !formik.isValid ? grey[400] : undefined,
						}}
					>
						<Icon path={saving ? icons.loadingIcon : icons.agreeIcon} size={1} spin={saving ? 1 : 0} />
					</Fab>
				</DialogActions>
			</form>
		</Dialog>
	)
}
