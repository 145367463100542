// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import axios from 'axios'
import { AppDataContext, IAppDataContext } from 'context/appData'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import debug from 'debug'

import { event as gaEvent } from 'nextjs-google-analytics/dist/interactions/event'
import { filter, find, get, isArray, isEmpty, map, omit, uniq, values } from 'lodash'

import { gaEventName } from 'globals/constants/integrations'

import { useAuth } from 'components/_Global/_____AuthProvider'
import { ADD_FLOW, EDIT_FLOW, IFlow, IFlowContext, IFlowQueryResponse, NUKE_FLOW, QUERY_FLOWS } from 'data/flows'
import { INodeVariantTypes } from 'data/nodes'

import useAccount from './useAccount'
import useActivity from './useActivity'
import useNodes from './useNodes'

// ? ---
// ?	Constants
// ? ---
const namespace = 'hooks-useFlows'
const log = debug(`app:${namespace}`)
const type = 'Flow'
const model = 'flows'

dayjs.extend(utc)

// ? ---
// ?	Hook
// ? ---
export default function useFlows() {
	// * ---
	// *	Context
	// * ---
	const AppData = React.useContext<IAppDataContext>(AppDataContext)
	const { openUpgrade, user } = useAuth()
	const _account = useAccount()
	const _nodes = useNodes()
	const _activity = useActivity()

	// * ---
	// *	Queries
	// * ---
	const [queryFlow] = useLazyQuery<IFlowQueryResponse>(QUERY_FLOWS)

	// * ---
	// *	Mutation
	// * ---
	const [add] = useMutation(ADD_FLOW)
	const [edit] = useMutation(EDIT_FLOW)
	const [nuke] = useMutation(NUKE_FLOW)

	// * ---
	// *	Populate
	// * ---
	const populate: IFlowContext['populate'] = async (input, options) => {
		log('populate', { input, options })
		const responseData = await queryFlow({
			variables: {
				filters: {
					id: { eq: input.id },
				},
				pagination: { pageSize: 1 },
				sort: 'updatedAt:desc',
			},
			fetchPolicy: 'no-cache',
		})

		const response = get(responseData, 'data.flows.data[0]', {}) as IFlow
		if (options?.onSuccess) options.onSuccess(response)
		if (options?.onComplete) options.onComplete(response)
		return response
	}

	// * ---
	// *	Insert
	// * ---
	const insert: IFlowContext['insert'] = async (input, options) => {
		log('insert', { input, options })
		const response = await add({
			variables: {
				data: {
					...omit(input.attributes, ['__typename', 'createdAt', 'updatedAt']),
					title: input?.attributes?.title || undefined,
					userId: user?.Uid,
				},
			},
		})
		_activity.trackFlowCreated(input?.attributes?.title || 'UNKNOWN_NAME')
		if (options?.onSuccess) options.onSuccess(response.data[`create${type}`].data)
		if (options?.onComplete) options.onComplete(response.data[`create${type}`].data)
		return response.data[`create${type}`].data
	}

	// * ---
	// *	Update
	// * ---
	const update: IFlowContext['update'] = async (input, options) => {
		log('update', { input, options })

		// * Stops broken nodes from being saved
		const nodeMeta = !input.attributes?.nodeMeta ? undefined : filter(input.attributes.nodeMeta, 'data.id')

		// * Removed broken edges (when the source and/or target for the edge is missing)
		const edgeMeta = !input.attributes?.edgeMeta
			? undefined
			: !input.attributes?.nodeMeta
			? input.attributes.edgeMeta
			: filter(input.attributes?.edgeMeta, (edge) =>
					find(input.attributes?.nodeMeta, (node) => edge.source === node.id || edge.target === node.id)
			  )

		// * Verify nodes
		let nodes = input.attributes?.nodes
		if (isArray(nodes) && !isEmpty(nodes)) {
			const nodesResponse = await _nodes.getValidatedNodes({
				variables: {
					filters: {
						id: { in: nodes },
					},
				},
			})
			if (nodesResponse.data?.nodes.data) {
				nodes = uniq(map(nodesResponse.data.nodes.data, 'id'))
			}
		}

		const response = await edit({
			variables: {
				id: input.id,
				data: {
					...omit(input.attributes, ['__typename', 'createdAt', 'updatedAt']),
					title: input?.attributes?.title || undefined,
					nodes,
					nodeMeta,
					edgeMeta,
					userId: user?.Uid,
				},
			},
		})
		if (options?.onSuccess) options.onSuccess(response.data[`update${type}`].data)
		if (options?.onComplete) options.onComplete(response.data[`update${type}`].data)
		return response.data[`update${type}`].data
	}

	// * ---
	// *	Remove
	// * ---
	const remove: IFlowContext['remove'] = async (item, options) => {
		log('remove', { item, options })
		if (options?.force) {
			await nuke({
				variables: {
					id: item.id,
				},
			})
			if (options?.onSuccess) options.onSuccess()
			if (options?.onComplete) options.onComplete()
		} else {
			await AppData.openDeleteConfirmation(
				{},
				{
					onSuccess: async () => {
						await nuke({
							variables: {
								id: item.id,
							},
						})
						if (options?.onSuccess) options.onSuccess()
						if (options?.onComplete) options.onComplete()
					},
				}
			)
		}
	}

	// * ---
	// *	Upsert
	// * ---
	const upsert: IFlowContext['upsert'] = async (input, options) => {
		log('upsert', { input, options })
		if (input.id === undefined || input.id === '') {
			return await insert(input, options)
		} else {
			return await update(input, options)
		}
	}

	// * ---
	// *	Clone
	// * ---
	const clone: IFlowContext['clone'] = async (item, options) => {
		log('clone', { item, options })

		// * Populate if needed
		if (get(item, 'attributes.nodes.data', []).length === 0) {
			item = await populate(item, options)
		}

		// * Clone nodes
		const nodes: { [key: string]: string } = {}
		for (const node of item.attributes.nodes.data) {
			if (node.attributes.variantType === INodeVariantTypes.special_group) {
				// ? Node Group should link to the same node
				nodes[node.id] = node.id
			} else {
				// ? Normal Node should be cloned
				const newNode = await _nodes.clone(node)
				nodes[node.id] = newNode.id
			}
		}

		// * Update nodeMeta to use new node ids
		item.attributes.nodeMeta = map(item.attributes.nodeMeta, (nodeMeta) => {
			return {
				...nodeMeta,
				data: {
					...nodeMeta.data,
					id: nodes[nodeMeta.data.id],
				},
			}
		})

		// * Create new flow
		const response = await insert(
			{
				id: item.id,
				attributes: {
					...omit(item.attributes, [
						'__typename',
						'uid',
						'nodes',
						'currentHashes',
						'checksum',
						'runs',
						'updatedAt',
						'createdAt',
					]),
					title: `${item.attributes?.title} (copy)`,
					nodes: isEmpty(nodes) ? undefined : values(nodes),
					userId: user?.Uid || undefined,
					checkRatio: -1,
					runs: undefined,
					isFixture: false,
					isHidden: false,
					...(options?.data?.attributes || {}),
				},
			},
			options
		)
		if (options?.onSuccess) options.onSuccess(response)
		if (options?.onComplete) options.onComplete(response)
		return response
	}

	// * ---
	// *	Run Hook
	// * ---
	const runHook: IFlowContext['runHook'] = async (flowIds, options) => {
		log('runHook', { flowIds, options })

		const flowIdsQS = flowIds.length > 0 ? `&ids=${flowIds.join(',')}` : ''
		const previewQS = options?.preview ? '&previews' : ''
		const runStatus = await axios
			.post(
				`/api/hook/${_account.accountId}?key=app${flowIdsQS}&email=${user?.Email}&userId=${user?.Uid}&name=${
					user?.FullName || user?.Email
				}${previewQS}`
			)
			.then((response) => {
				return response.status
			})
			.catch((err) => {
				log('!! runHook failed', err)
				if (get(err, 'response.status') === 402) {
					log('!! runHook: upgrade required')
					openUpgrade()
					_activity.trackPlanUpgradeViewed('webhook-response')
					gaEvent(gaEventName('upgrade', 'webhook-response', 'flow'))
				}
				if (options?.onError) options.onError(err)
			})

		if (runStatus === 200 && options?.onSuccess) options.onSuccess()
		if (options?.onComplete) options.onComplete()
	}

	// * ---
	// *	Return
	// * ---
	return {
		populate,
		insert,
		update,
		upsert,
		clone,
		remove,
		runHook,
		openUpsert: AppData[model].openUpsert,
		closeUpsert: AppData[model].closeUpsert,
	}
}
