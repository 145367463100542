// ? ---
// ?	Imports
// ? ---
import * as React from 'react'

import { compact } from 'lodash'

import { ELEMENT_VALIDATION } from 'globals/constants/validations'

import { formatElement, sentenceElement } from 'hooks/useNode'

import { INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.action_dragDrop,
	isLive: true,
	isMenuOption: true,
	isPro: false,
	isAccessibility: false,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.element',
			type: 'element',
			label: 'Element',
			validation: ELEMENT_VALIDATION,
		},
		{
			property: 'attributes.element2',
			type: 'element',
			label: 'Dropzone',
			validation: ELEMENT_VALIDATION,
		},
	],
	label: 'Drag and drop',
	sentence: ({ element, element2, isExample }: INodeRenderProps) => {
		return isExample ? `Drag and drop` : `Drag and drop ${sentenceElement(element)} to ${sentenceElement(element2)}`
	},
	family: INodeVariantTypes.action_dragDrop.split('_')[0],
	type: INodeVariantTypes.action_dragDrop.split('_')[1],
	subType: INodeVariantTypes.action_dragDrop.split('_')[2],
	tags: compact([
		INodeVariantTypes.action_dragDrop.split('_')[0],
		INodeVariantTypes.action_dragDrop.split('_')[1],
		INodeVariantTypes.action_dragDrop.split('_')[2],
		'move',
		'drag',
		'drop',
		'dnd',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ element, element2, isExample }: INodeRenderProps) => {
			return (
				<>
					Drag and drop{' '}
					{!isExample && (
						<>
							<strong>{formatElement(element)}</strong> to <strong>{formatElement(element2)}</strong>
						</>
					)}
				</>
			)
		},
	}
}
