// ? ---
// ?	Imports
// ? ---
import * as React from 'react'

import { compact } from 'lodash'

import { ELEMENT_VALIDATION } from 'globals/constants/validations'
import { INTEGRATION_GOOGLE_MAPS_GROUP } from 'globals/constants/variantGroups'

import { formatElement, formatValue, sentenceElement, sentenceValue } from 'hooks/useNode'

import { INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.integration_googleMaps_pan,
	isLive: false,
	isMenuOption: false,
	isPro: false,
	isAccessibility: false,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.variantType',
			type: 'variantType',
			label: 'Method',
			options: INTEGRATION_GOOGLE_MAPS_GROUP,
		},
		{
			property: 'attributes.element',
			type: 'element',
			label: 'Element',
			validation: ELEMENT_VALIDATION,
		},
		{
			property: 'attributes.value',
			label: 'Latitude',
			type: 'slider',
			defaultValue: '0',
			config: {
				min: -500,
				max: 500,
				step: 1,
			},
		},
		{
			property: 'attributes.value2',
			label: 'Longitude',
			type: 'slider',
			defaultValue: '0',
			config: {
				min: -500,
				max: 500,
				step: 1,
			},
		},
	],
	label: 'Pan',
	sentence: ({ element, isExample, value, value2 }: INodeRenderProps) => {
		return isExample
			? `Pan map`
			: `Pan map ${sentenceElement(element)} by ${sentenceValue(value)}, ${sentenceValue(value2)}`
	},
	family: INodeVariantTypes.integration_googleMaps_pan.split('_')[0],
	type: INodeVariantTypes.integration_googleMaps_pan.split('_')[1],
	subType: INodeVariantTypes.integration_googleMaps_pan.split('_')[2],
	tags: compact([
		INodeVariantTypes.integration_googleMaps_pan.split('_')[0],
		INodeVariantTypes.integration_googleMaps_pan.split('_')[1],
		INodeVariantTypes.integration_googleMaps_pan.split('_')[2],
		'maps',
		'google',
		'latitude',
		'longitude',
		'pan',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ element, isExample, value, value2 }: INodeRenderProps) => {
			return (
				<>
					{isExample ? (
						<>Pan</>
					) : (
						<>
							Pan <strong>{formatElement(element)}</strong> by <strong>{formatValue(value)}</strong>,{' '}
							<strong>{formatValue(value2)}</strong>
						</>
					)}
				</>
			)
		},
	}
}
