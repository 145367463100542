// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import { Node } from 'reactflow'
import { useLazyQuery, useMutation } from '@apollo/client'
import { AppDataContext, IAppDataContext } from 'context/appData'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import debug from 'debug'

import {
	defaultsDeep,
	filter,
	flatMap,
	forEach,
	get,
	groupBy,
	isEmpty,
	map,
	omit,
	pickBy,
	set,
	some,
	sortBy,
	unionBy,
	uniq,
} from 'lodash'

import { NODE_FAMILY_PLURAL, NODE_FAMILY_SINGULAR } from 'globals/constants/labels'

import * as nodes from 'components/Nodes/library'
import {
	ADD_NODE,
	EDIT_NODE,
	INodeContext,
	INodeFamily,
	INodeInput,
	INodeQueryResponse,
	INodeVariantTypes,
	IUseNodeConfig,
	NUKE_NODE,
	QUERY_NODES_IDS,
} from 'data/nodes'
import { ITestStatus } from 'data/tests'

import useActivity from './useActivity'
import useCan from './useCan'
import useNode from './useNode'

// ? ---
// ?	Constants
// ? ---
const namespace = 'hooks-useNodes'
const log = debug(`app:${namespace}`)
const type = 'Node'
const model = 'nodes'

dayjs.extend(utc)

// ? ---
// ?	Hook
// ? ---
export default function useNodes() {
	// * ---
	// *	Setup
	// * ---
	const AppData = React.useContext<IAppDataContext>(AppDataContext)
	const _node = useNode()
	const _activity = useActivity()
	const Can = useCan()

	// * ---
	// *	Node options
	// * ---
	const allMenuNodes = pickBy(_node, (node) => node.isMenuOption && Can.see.node(node))

	// * ---
	// *	Mutation
	// * ---
	const [add] = useMutation(ADD_NODE)
	const [edit] = useMutation(EDIT_NODE)
	const [nuke] = useMutation(NUKE_NODE)

	// * ---
	// *	Get nodes by ID
	// * ---
	const [getValidatedNodes] = useLazyQuery<INodeQueryResponse>(QUERY_NODES_IDS)

	// * ---
	// *	Insert
	// * ---
	const insert: INodeContext['insert'] = async (input, options) => {
		log('insert', { input, options })

		// * Node Default Values
		if (options?.useDefaultValues && input.attributes?.variantType) {
			const nodeConfig: IUseNodeConfig = nodes[input.attributes.variantType].node
			const fields = nodeConfig.fields
			const defaultValues: INodeInput = {}
			forEach(
				filter(fields, (field) => !isEmpty(field.defaultValue)),
				(field) => {
					set(defaultValues, field.property, field.defaultValue)
				}
			)
			input = defaultsDeep(input, defaultValues)
		}

		const response = await add({
			variables: {
				data: {
					...omit(input.attributes, ['__typename', 'flow', 'updatedAt']),
					element: input?.attributes?.element || undefined,
					element2: input?.attributes?.element2 || undefined,
					mfa: input?.attributes?.mfa || undefined,
					nodes: input?.attributes?.nodes || undefined,
					group: input?.attributes?.group || undefined,
					label: `${input?.attributes?.label || ''}`,
					value: `${input?.attributes?.value || ''}`,
					value2: `${input?.attributes?.value2 || ''}`,
					value3: `${input?.attributes?.value3 || ''}`,
					value4: `${input?.attributes?.value4 || ''}`,
					value5: `${input?.attributes?.value5 || ''}`,
					value6: `${input?.attributes?.value6 || ''}`,
					value7: `${input?.attributes?.value7 || ''}`,
					value8: `${input?.attributes?.value8 || ''}`,
					value9: `${input?.attributes?.value9 || ''}`,
					value10: `${input?.attributes?.value10 || ''}`,
					value11: `${input?.attributes?.value11 || ''}`,
					value12: `${input?.attributes?.value12 || ''}`,
					value13: `${input?.attributes?.value13 || ''}`,
					value14: `${input?.attributes?.value14 || ''}`,
					value15: `${input?.attributes?.value15 || ''}`,
					value16: `${input?.attributes?.value16 || ''}`,
					value17: `${input?.attributes?.value17 || ''}`,
					value18: `${input?.attributes?.value18 || ''}`,
					value19: `${input?.attributes?.value19 || ''}`,
					value20: `${input?.attributes?.value20 || ''}`,
					config: {
						failedStatus: `${input?.attributes?.config?.failedStatus || ITestStatus.failed}`,
						timeoutOverride: `${input?.attributes?.config?.timeoutOverride || 0}`,
					},
				},
			},
		})
		_activity.trackNodeInserted(input?.attributes?.variantType as INodeVariantTypes)
		if (options?.onSuccess) options.onSuccess(response.data[`create${type}`].data)
		if (options?.onComplete) options.onComplete(response.data[`create${type}`].data)
		return response.data[`create${type}`].data
	}

	// * ---
	// *	Update
	// * ---
	const update: INodeContext['update'] = async (input, options) => {
		log('update', { input, options })
		const response = await edit({
			variables: {
				id: input.id,
				data: {
					...omit(input.attributes, ['__typename', 'flow']),
					element: input?.attributes?.element || undefined,
					element2: input?.attributes?.element2 || undefined,
					mfa: input?.attributes?.mfa || undefined,
					nodes: input?.attributes?.nodes || undefined,
					group: input?.attributes?.group || undefined,
					label: `${input?.attributes?.label || ''}`,
					value: `${input?.attributes?.value || ''}`,
					value2: `${input?.attributes?.value2 || ''}`,
					value3: `${input?.attributes?.value3 || ''}`,
					value4: `${input?.attributes?.value4 || ''}`,
					value5: `${input?.attributes?.value5 || ''}`,
					value6: `${input?.attributes?.value6 || ''}`,
					value7: `${input?.attributes?.value7 || ''}`,
					value8: `${input?.attributes?.value8 || ''}`,
					value9: `${input?.attributes?.value9 || ''}`,
					value10: `${input?.attributes?.value10 || ''}`,
					value11: `${input?.attributes?.value11 || ''}`,
					value12: `${input?.attributes?.value12 || ''}`,
					value13: `${input?.attributes?.value13 || ''}`,
					value14: `${input?.attributes?.value14 || ''}`,
					value15: `${input?.attributes?.value15 || ''}`,
					value16: `${input?.attributes?.value16 || ''}`,
					value17: `${input?.attributes?.value17 || ''}`,
					value18: `${input?.attributes?.value18 || ''}`,
					value19: `${input?.attributes?.value19 || ''}`,
					value20: `${input?.attributes?.value20 || ''}`,
					config: {
						failedStatus: `${input?.attributes?.config?.failedStatus || ITestStatus.failed}`,
						timeoutOverride: `${input?.attributes?.config?.timeoutOverride || 0}`,
					},
				},
			},
		})
		if (options?.onSuccess) options.onSuccess(response.data[`update${type}`].data)
		if (options?.onComplete) options.onComplete(response.data[`update${type}`].data)
		return response.data[`update${type}`].data
	}

	// * ---
	// *	Remove
	// * ---
	const remove: INodeContext['remove'] = async (item, options) => {
		const linkedFlows = unionBy(get(item, 'attributes.flow.data', []), 'id')
		log('remove', { item, options })
		if (options?.force) {
			await nuke({
				variables: {
					id: item.id,
				},
			})
			if (options?.onSuccess) options.onSuccess()
			if (options?.onComplete) options.onComplete()
		} else {
			await AppData.openDeleteConfirmation(
				{
					body:
						linkedFlows.length === 1
							? 'A Flow is using this Node Group.'
							: linkedFlows.length > 1
							? `${linkedFlows.length} Flows are using this Node Group.`
							: undefined,
					related: linkedFlows.length > 0 ? linkedFlows : undefined,
				},
				{
					onSuccess: async () => {
						await nuke({
							variables: {
								id: item.id,
							},
						})
						if (options?.onSuccess) options.onSuccess()
						if (options?.onComplete) options.onComplete()
					},
				}
			)
		}
	}

	// * ---
	// *	Upsert
	// * ---
	const upsert: INodeContext['upsert'] = async (input, options) => {
		log('upsert', { input, options })
		if (input.id === undefined || input.id === '') {
			return await insert(input, options)
		} else {
			return await update(input, options)
		}
	}

	// * ---
	// *	Clone
	// * ---
	const clone: INodeContext['clone'] = async (item, options) => {
		log('clone', { item, options })

		// ? Node Group - First Clone all child nodes
		const childNodeIds: string[] = []
		if (item.attributes.variantType === INodeVariantTypes.special_group) {
			log(
				'Cloning node group, step over and clone all child nodes',
				get(item, 'attributes.nodes.data', []).length
			)
			for (const childNode of get(item, 'attributes.nodes.data', [])) {
				const clonedChildNode = await clone(childNode, options)
				childNodeIds.push(clonedChildNode.id)
			}
		}

		const response = await insert(
			{
				...item,
				attributes: {
					...omit(item.attributes, ['__typename', 'flow']),
					label: `${item.attributes?.label} (copy)`,
					nodes: childNodeIds.length > 0 ? childNodeIds : undefined,
					group: undefined,
					element: get(item, 'attributes.element.data.id', undefined),
					element2: get(item, 'attributes.element2.data.id', undefined),
					mfa: get(item, 'attributes.mfa.data.id', undefined),
				},
			},
			options
		)
		if (options?.onSuccess) options.onSuccess(response)
		if (options?.onComplete) options.onComplete(response)
		return response
	}

	// * ---
	// *	Group Errors
	// * ---
	const groupWarnings: INodeContext['groupWarnings'] = (items, edges) => {
		log('groupWarnings', { items, edges })
		const errors = []

		// * Check for Groups
		if (some(items, { data: { attributes: { variantType: INodeVariantTypes.special_group } } }))
			errors.push('Node Groups cannot be grouped, and will be excluded.')

		// * Check for Branches
		const ids = map(sortBy(groupable(items), ['position.y', 'position.x']), 'id')
		const inboundToGroup = flatMap(ids, (target) => map(filter(edges, { target }), 'target'))
		const outboundFromGroup = flatMap(ids, (source) => map(filter(edges, { source }), 'source'))
		if (
			inboundToGroup.length > uniq(inboundToGroup).length ||
			outboundFromGroup.length > uniq(outboundFromGroup).length
		)
			errors.push('Node Groups cannot include branches and will be flattened into a linear sequence.')

		log('groupWarnings', { errors, ids, inboundToGroup, outboundFromGroup })
		return errors
	}

	// * ---
	// *	Groupable
	// * ---
	const groupable: INodeContext['groupable'] = (items) => {
		log('groupable', { items })
		const groupableNodes = filter(items, (item) => {
			const variantType = get(item, 'data.attributes.variantType')
			log('variantType', variantType)
			if (variantType) {
				const nodeConfig: IUseNodeConfig = nodes[variantType as INodeVariantTypes].node
				return nodeConfig.hasInput && nodeConfig.hasOutput && nodeConfig.family !== 'special'
			}
		}) as Node[]

		log('groupable groupableNodes', groupableNodes)

		return groupableNodes ? groupableNodes : []
	}

	// * ---
	// *	Group
	// * ---
	const group: INodeContext['group'] = async (items, options) => {
		log('group', { items, options })

		// ? Select only groupable nodes
		const groupableNodes = groupable(items)
		log('group groupableNodes', items, groupableNodes)
		if (groupableNodes.length === 0) return
		log('groupableNode Ids', map(groupableNodes, 'data.id'))

		// * Node Group Name
		const nodeTypes = groupBy(groupableNodes, (node) => _node[get(node, 'data.attributes.variantType')].family)
		const labelParts: string[] = []
		forEach(nodeTypes, (nodes, type) => {
			const family: INodeFamily = type as INodeFamily
			labelParts.push(
				`${
					nodes.length > 1
						? `${nodes.length} ${NODE_FAMILY_PLURAL[family]}`
						: `1 ${NODE_FAMILY_SINGULAR[family]}`
				}`
			)
			labelParts.sort().reverse()
		})

		let label = ''
		if (labelParts.length > 1) {
			const lastPart = labelParts.pop()
			label = `${labelParts.join(', ')} & ${lastPart}`
		} else {
			label = labelParts[0] || 'New Node Group'
		}

		// * Create Group
		const response = await insert(
			{
				attributes: {
					label,
					variantType: INodeVariantTypes.special_group,
					nodes: map(sortBy(groupableNodes, ['position.y', 'position.x']), 'data.id'),
				},
			},
			options
		)
		if (options?.onSuccess) options.onSuccess(response)
		if (options?.onComplete) options.onComplete(response)
		return response
	}

	// * ---
	// *	Return
	// * ---
	return {
		allMenuNodes,
		insert,
		update,
		upsert,
		clone,
		group,
		groupable,
		groupWarnings,
		remove,
		getValidatedNodes,
		openUpsert: AppData[model].openUpsert,
		closeUpsert: AppData[model].closeUpsert,
		openUpsertChild: AppData[model].openUpsertChild,
		closeUpsertChild: AppData[model].closeUpsertChild,
	}
}
