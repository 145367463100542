// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'

import { Box, Tab, Tabs, useTheme } from '@mui/material'
import { find, map } from 'lodash'

import ArtifactDialogImage from 'components/_Global/Dialogs/ArtifactDialogImage'
import { INodeVariantTypes } from 'data/nodes'
import { ITestArtifact, ITestArtifactModal, ITestArtifactTopic, ITestArtifactType, ITestStep } from 'data/tests'

// ? ---
// ?	Types
// ? ---
type Props = {
	step?: ITestStep
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Dialogs-ArtifactScreenshotsDialogsChanges'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function ArtifactScreenshotsDialogsChanges({ step }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const theme = useTheme()

	// * ---
	// *	State
	// * ---
	const [activeTab, $activeTab] = React.useState(0)

	// * ---
	// *	Helper: Get snapshot change detection artifacts
	// * ---
	const getChangeSnapshotArtifacts = () => {
		if (!step) return []

		const control = find(step.artifacts, {
			type: ITestArtifactType.image,
			topic: ITestArtifactTopic.control,
		}) as ITestArtifact
		const diffCtoA = find(step.artifacts, {
			type: ITestArtifactType.image,
			topic: ITestArtifactTopic.diffCtoA,
		}) as ITestArtifact
		const diffAtoC = find(step.artifacts, {
			type: ITestArtifactType.image,
			topic: ITestArtifactTopic.diffAtoC,
		}) as ITestArtifact
		const actual = find(step.artifacts, {
			type: ITestArtifactType.image,
			topic: ITestArtifactTopic.actual,
		}) as ITestArtifact

		const result: ITestArtifactModal = []
		if (control) {
			result.push({
				isActive: !actual,
				label: 'Control',
				artifact: control,
			})
		}
		if (diffCtoA) {
			result.push({
				label: 'Control compared to Actual',
				artifact: diffCtoA,
			})
		}
		if (diffAtoC) {
			result.push({
				label: 'Actual compared to Control',
				artifact: diffAtoC,
			})
		}
		if (actual) {
			result.push({
				isActive: true,
				label: 'Actual',
				artifact: actual,
			})
		}
		return result
	}

	// * ---
	// *	Helper: Get position change detection artifacts
	// * ---
	const getChangePositionArtifacts = () => {
		if (!step) return []

		const viewport = find(step.artifacts, {
			type: ITestArtifactType.image,
			topic: ITestArtifactTopic.viewport,
		}) as ITestArtifact
		const diff = find(step.artifacts, {
			type: ITestArtifactType.image,
			topic: ITestArtifactTopic.diff,
		}) as ITestArtifact

		const result: ITestArtifactModal = []
		if (diff) {
			result.push({
				isActive: true,
				label: 'Diff',
				artifact: diff,
			})
		}
		if (viewport) {
			result.push({
				isActive: !diff,
				label: 'Now',
				artifact: viewport,
			})
		}
		return result
	}

	const getData = () => {
		if (!step) return []
		return step.node.variantType === INodeVariantTypes.change_element_snapshot
			? getChangeSnapshotArtifacts()
			: getChangePositionArtifacts()
	}

	// ! ---
	// !	Return: No artifacts
	// ! ---
	if (!step) {
		return <></>
	}

	// * ---
	// *	Return: Good
	// * ---
	return (
		<>
			<Tabs
				data-test-id={`${namespace}--tabs`}
				value={activeTab}
				onChange={(event: React.SyntheticEvent, newValue: number) => $activeTab(newValue)}
				indicatorColor='primary'
				textColor='inherit'
				variant='fullWidth'
				aria-label='artifact tabs'
				sx={{ backgroundColor: theme.palette.background.paper }}
			>
				{map(getData(), (item, index) => (
					<Tab
						label={item.label}
						id={`tab-${index}`}
						key={`tab-${index}`}
						value={index}
						aria-controls={`tabpanel-${index}`}
						sx={{
							backgroundColor: activeTab === index ? theme.palette.background.default : 'transparent',
						}}
					/>
				))}
			</Tabs>
			<Box
				sx={{
					height: '80vh',
					backgroundColor: theme.palette.background.paper,
					position: 'relative',
				}}
			>
				{map(getData(), (item, index) => (
					<div
						role='tabpanel'
						hidden={activeTab !== index}
						key={`tabpanel-${index}`}
						id={`tabpanel-${index}`}
						aria-labelledby={`simple-tab-${index}`}
					>
						{activeTab === index && item.artifact && <ArtifactDialogImage data={item.artifact} />}
					</div>
				))}
			</Box>
		</>
	)
}
