// ? ---
// ?	Types & Enums
// ? ---
import { IMeta } from 'globals/client/types'

import { INodeResponse } from 'data/nodes'

export enum IElementSelectorType {
	css = 'css',
	testId = 'testId',
	xpath = 'xpath',
	text = 'text',
}

// ? ---
// ?	Interfaces
// ? ---
export interface IElement {
	__typename: 'ElementEntity'
	id: string
	attributes: {
		title: string
		type: IElementSelectorType
		selector: string
		nodes: Partial<INodeResponse>
		nodes2: Partial<INodeResponse>
		isDynamic?: boolean
	}
}

export interface IElementResponse {
	meta: IMeta
	data: IElement[]
}

export interface IElementQueryResponse {
	elements: IElementResponse
}

export interface IElementInput {
	id?: string
	attributes?: {
		title?: string
		type?: IElementSelectorType
		selector?: string
		isDynamic?: boolean
	}
}

export interface IElementContextOptions {
	force?: boolean
	onSuccess?: (element?: IElement) => any
	onError?: (error?: any) => any
	onComplete?: (element?: IElement) => any
}

export interface IElementContext {
	insert: (elementInput: IElementInput, options?: IElementContextOptions) => Promise<IElement>
	update: (elementInput: IElementInput, options?: IElementContextOptions) => Promise<IElement>
	upsert: (elementInput: IElementInput, options?: IElementContextOptions) => Promise<IElement>
	clone: (element: IElement, options?: IElementContextOptions) => Promise<IElement>
	remove: (element: IElement, options?: IElementContextOptions) => Promise<void>
	openUpsert: (element?: Partial<IElement>, options?: IElementContextOptions) => Promise<void>
	closeUpsert: (options?: IElementContextOptions) => Promise<void>
}

// ? ---
// ?	Type Guards
// ? ---
export const isElement = (element: IElement): boolean => {
	return element.__typename === 'ElementEntity'
}
