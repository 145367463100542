// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import { useMutation, useQuery } from '@apollo/client'
import * as Sentry from '@sentry/nextjs'
import axios from 'axios'
import { AppDataContext } from 'context/appData'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import debug from 'debug'

import { isEmpty, isNil, some, values } from 'lodash'

import theme from 'globals/client/theme'
import { IOpenMessageDetails, IOpenMessageOptions, IOpenSuggestReload } from 'globals/client/types'
import { MAXIMUM_BUSY_TASK_LENGTH_SECONDS } from 'globals/constants/time'

import ArtifactDialog from 'components/_Global/Dialogs/ArtifactDialog'
import DeleteConfirmation from 'components/_Global/Dialogs/DeleteConfirmationDialog'
import SuggestReloadDialog from 'components/_Global/Dialogs/SuggestReloadDialog'
import ElementsDialog from 'components/Elements/ElementDialog'
import EventDialog from 'components/Events/EventDialog'
import FlowsDialog from 'components/Flows/FlowDialog'
import MfaCodeDialog from 'components/MFAs/MfaCodeDialog'
import MfaDialog from 'components/MFAs/MfaDialog'
import NodesDialog from 'components/Nodes/NodeDialog'
import RecipeDialog from 'components/Recipes/RecipeDialog'
import ScheduleDialog from 'components/Schedules/ScheduleDialog'
import TagDialog from 'components/Tags/TagDialog'
import ValueDialog from 'components/Values/ValueDialog'
import WebhookDialog from 'components/Webhooks/WebhookDialog'
import {
	ADD_ACCOUNT,
	IAccount,
	IAccountContext,
	IAccountQueryResponse,
	IAccountTag,
	IAccountTagContext,
	IAccountUsers,
	IAccountValue,
	IAccountValueContext,
	IAccountWebhookKey,
	IAccountWebhookKeyContext,
	QUERY_ACCOUNT,
} from 'data/accounts'
import { IElement, IElementContext } from 'data/elements'
import { IEvent, IEventContext } from 'data/events'
import { IFlow, IFlowContext } from 'data/flows'
import { IMfa, IMfaContext } from 'data/mfas'
import { INode, INodeContext } from 'data/nodes'
import { IRecipe, IRecipeContext } from 'data/recipes'
import { ISchedule, IScheduleContext } from 'data/schedules'
import { ITest, ITestArtifactModal, ITestContext } from 'data/tests'

import ArtifactScreenshotsDialogs from './Dialogs/ArtifactScreenshotsDialogs'
import MessageDialog from './Dialogs/MessageDialog'
import MinutesRequiredDialog from './Dialogs/MinutesRequiredDialog'
import OnlyAccountOwnersDialog from './Dialogs/OnlyAccountOwnersDialog'
import TestInformationDialog from './Dialogs/TestInformationDialog'
import LoadingOverlay from './LoadingOverlay'

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-AppData'
const log = debug(`app:${namespace}`)

dayjs.extend(utc)

// ? ---
// ?	Types
// ? ---
type Props = {
	children: JSX.Element
}

// ? ---
// ?	Component
// ? ---
export default function AppData({ children }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')

	// * ---
	// *	Refs
	// * ---
	const reload = React.useRef()
	const reloadNodesMenu = React.useRef()
	const save = React.useRef()
	const run = React.useRef()
	const preview = React.useRef()

	// * ---
	// *	Generic States
	// * ---
	const [title, $title] = React.useState('')
	const [menuLibrary, $menuLibrary] = React.useState(false)

	// * ---
	// *	Busy
	// * ---
	const [busy, $busy] = React.useState<{
		[key: string]: { startedAt: string; isBlocking: boolean; label: string } | undefined
	}>({})

	// * Add busy task
	const addBusyTask = (task: string, options?: { isBlocking?: boolean; label?: string }) => {
		$busy((currentValue) => {
			return {
				...currentValue,
				[task]: {
					startedAt: dayjs().utc().toISOString(),
					isBlocking: options?.isBlocking || false,
					label: options?.label || task,
				},
			}
		})
	}

	// * Remove busy task
	const removeBusyTask = (task: string) => {
		$busy((currentValue) => {
			return {
				...currentValue,
				[task]: undefined,
			}
		})
	}

	// * Has Task
	const hasBusyTask = (task: string) => {
		if (isEmpty(busy) || busy[task] === undefined) return false
		const currentTaskDuration = dayjs().diff(dayjs(busy[task]?.startedAt).utc(), 'seconds')
		if (currentTaskDuration > MAXIMUM_BUSY_TASK_LENGTH_SECONDS) {
			// ! Slow Task
			log(`!! hasBusyTask: Task (${task}) has been running for too long (${currentTaskDuration}s), removing task`)
			Sentry.captureMessage(
				`${namespace} hasBusyTask: Task (${task}) has been running for too long (${currentTaskDuration}s), removing task`,
				'warning'
			)
			removeBusyTask(task)
			return false
		} else {
			// * Valid Task
			return true
		}
	}

	// * ---
	// *	Strapi Account
	// * ---
	const [cmsAccount, $cmsAccount] = React.useState<IAccount | null>(null)
	const [createStrapiAccountRecord] = useMutation(ADD_ACCOUNT)
	const { data: cmsAccountData, refetch: cmsAccountRefetch } = useQuery<IAccountQueryResponse>(QUERY_ACCOUNT, {
		variables: {
			filters: {},
		},
		fetchPolicy: 'network-only',
		onCompleted: async (data) => {
			if (data.accounts.data.length === 0) {
				// ! Account record missing, create one
				Sentry.captureMessage(`${namespace} !! Account did not have record, create one`, 'warning')
				const response = await createStrapiAccountRecord({
					variables: {
						data: {
							slackOptions: {},
						},
					},
				})
				$cmsAccount(response.data['createAccount'].data[0])
			}
		},
	})
	React.useEffect(() => {
		if (!isNil(cmsAccountData)) {
			$cmsAccount(cmsAccountData.accounts.data[0])
		}
	}, [cmsAccountData])

	// * ---
	// *	Account Users
	// * ---
	const [users, $users] = React.useState<IAccountUsers>({})
	React.useEffect(() => {
		let abort = false
		const load = async () => {
			const accountUsers = await axios
				.get(`/api/accounts/users`)
				.then((response) => {
					return response.data
				})
				.catch((err) => {
					log('!! load users failed, continuing anyway', err)
				})
			if (abort) return
			$users(accountUsers)
		}
		if (isEmpty(users)) {
			load()
		}
		return () => {
			abort = true
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// * ---
	// *	Generic Message Dialog Controls
	// * ---
	const [messageDialogData, $messageDialogData] = React.useState<
		undefined | { title: string; body?: string; level?: 'error' | 'warning' | 'info' }
	>(undefined)
	const [messageDialogState, $messageDialogState] = React.useState<boolean>(false)
	const [messageDialogOptions, $messageDialogOptions] = React.useState<
		| undefined
		| {
				[key: string]: any
		  }
	>(undefined)

	const openMessage = async (details?: IOpenMessageDetails, options?: IOpenMessageOptions) => {
		log('openMessage', { details, options })
		$messageDialogOptions(options)
		$messageDialogData(details)
		$messageDialogState(true)
	}

	const closeMessage = async (options?: {
		onSuccess?: () => void | Promise<any>
		onError?: (error?: any) => void | Promise<any>
		onComplete?: () => void | Promise<any>
	}) => {
		log('closeMessage', { options })
		$messageDialogState(false)
		setTimeout(() => {
			$messageDialogData(undefined)
			$messageDialogOptions(undefined)
		}, theme.transitions.duration.leavingScreen)
		if (options?.onSuccess) await options.onSuccess()
		if (options?.onComplete) await options.onComplete()
	}

	// * ---
	// *	Delete Confirmation
	// * ---
	const [deleteConfirmationDialogData, $deleteConfirmationDialogData] = React.useState<
		undefined | { title?: string; body?: string; related?: string[] }
	>(undefined)
	const [deleteConfirmationDialogState, $deleteConfirmationDialogState] = React.useState<boolean>(false)
	const [deleteConfirmationDialogOptions, $deleteConfirmationDialogOptions] = React.useState<
		| undefined
		| {
				[key: string]: any
		  }
	>(undefined)

	const openDeleteConfirmation = async (
		details?: { title?: string; body?: string; related?: string[] },
		options?: {
			onSuccess?: () => void | Promise<any>
			onError?: (error?: any) => void | Promise<any>
			onComplete?: () => void | Promise<any>
		}
	) => {
		log('openDeleteConfirmation', { details, options })
		$deleteConfirmationDialogOptions(options)
		$deleteConfirmationDialogData(details)
		$deleteConfirmationDialogState(true)
	}

	const closeDeleteConfirmation = async (options?: {
		onSuccess?: () => void | Promise<any>
		onError?: (error?: any) => void | Promise<any>
		onComplete?: () => void | Promise<any>
	}) => {
		log('closeDeleteConfirmation', { options })
		$deleteConfirmationDialogState(false)
		setTimeout(() => {
			$deleteConfirmationDialogData(undefined)
			$deleteConfirmationDialogOptions(undefined)
		}, theme.transitions.duration.leavingScreen)
		if (options?.onSuccess) await options.onSuccess()
		if (options?.onComplete) await options.onComplete()
	}

	// * ---
	// *	Suggest Reload
	// * ---
	const [suggestReloadDialogData, $suggestReloadDialogData] = React.useState<
		undefined | { title: string; body: string }
	>(undefined)
	const [suggestReloadDialogState, $suggestReloadDialogState] = React.useState<boolean>(false)
	const [suggestReloadDialogOptions, $suggestReloadDialogOptions] = React.useState<
		| undefined
		| {
				onCancel?: () => void | Promise<void>
				onConfirm: () => void | Promise<void>
		  }
	>(undefined)

	const openSuggestReload: IOpenSuggestReload = (details, options) => {
		log('openSuggestReload', { details, options })
		$suggestReloadDialogOptions(options)
		$suggestReloadDialogData(details)
		$suggestReloadDialogState(true)
	}

	const closeSuggestReload = async () => {
		log('closeSuggestReload')
		$suggestReloadDialogState(false)
		setTimeout(() => {
			$suggestReloadDialogData(undefined)
			$suggestReloadDialogOptions(undefined)
		}, theme.transitions.duration.leavingScreen)
	}

	// * ---
	// *	Element Dialog Controls
	// * ---

	// * State
	const [upsertElementDialogData, $upsertElementDialogData] = React.useState<undefined | Partial<IElement>>(undefined)
	const [upsertElementDialogState, $upsertElementDialogState] = React.useState<boolean>(false)
	const [upsertElementDialogOptions, $upsertElementDialogOptions] = React.useState<
		| undefined
		| {
				[key: string]: any
		  }
	>(undefined)
	const openElementUpsert: IElementContext['openUpsert'] = async (item, options) => {
		log('openUpsert', { item })
		$upsertElementDialogOptions(options)
		$upsertElementDialogData(item)
		$upsertElementDialogState(true)
	}

	// * Methods
	const closeElementUpsert: IElementContext['closeUpsert'] = async (options) => {
		log('closeUpsert', { options })
		$upsertElementDialogState(false)
		setTimeout(() => {
			$upsertElementDialogData(undefined)
			$upsertElementDialogOptions(undefined)
		}, theme.transitions.duration.leavingScreen)
		if (options?.onSuccess) await options.onSuccess()
		if (options?.onComplete) await options.onComplete()
	}

	// * ---
	// *	Flow Dialog Controls
	// * ---

	// * State
	const [upsertFlowDialogData, $upsertFlowDialogData] = React.useState<undefined | Partial<IFlow>>(undefined)
	const [upsertFlowDialogState, $upsertFlowDialogState] = React.useState<boolean>(false)
	const [upsertFlowDialogOptions, $upsertFlowDialogOptions] = React.useState<
		| undefined
		| {
				[key: string]: any
		  }
	>(undefined)
	const openFlowUpsert: IFlowContext['openUpsert'] = async (item, options) => {
		log('openUpsert', { item })
		$upsertFlowDialogOptions(options)
		$upsertFlowDialogData(item)
		$upsertFlowDialogState(true)
	}

	// * Methods
	const closeFlowUpsert: IFlowContext['closeUpsert'] = async (options) => {
		log('closeUpsert', { options })
		$upsertFlowDialogState(false)
		setTimeout(() => {
			$upsertFlowDialogData(undefined)
			$upsertFlowDialogOptions(undefined)
		}, theme.transitions.duration.leavingScreen)
		if (options?.onSuccess) await options.onSuccess()
		if (options?.onComplete) await options.onComplete()
	}

	// * ---
	// *	Event Dialog Controls
	// * ---

	// * State
	const [upsertEventDialogData, $upsertEventDialogData] = React.useState<undefined | Partial<IEvent>>(undefined)
	const [upsertEventDialogState, $upsertEventDialogState] = React.useState<boolean>(false)
	const [upsertEventDialogOptions, $upsertEventDialogOptions] = React.useState<
		| undefined
		| {
				[key: string]: any
		  }
	>(undefined)
	const openEventUpsert: IEventContext['openUpsert'] = async (item, options) => {
		log('openUpsert', { item })
		$upsertEventDialogOptions(options)
		$upsertEventDialogData(item)
		$upsertEventDialogState(true)
	}

	// * Methods
	const closeEventUpsert: IEventContext['closeUpsert'] = async (options) => {
		log('closeUpsert', { options })
		$upsertEventDialogState(false)
		setTimeout(() => {
			$upsertEventDialogData(undefined)
			$upsertEventDialogOptions(undefined)
		}, theme.transitions.duration.leavingScreen)
		if (options?.onSuccess) await options.onSuccess()
		if (options?.onComplete) await options.onComplete()
	}

	// * ---
	// *	Schedule Dialog Controls
	// * ---

	// * State
	const [upsertScheduleDialogData, $upsertScheduleDialogData] = React.useState<undefined | Partial<ISchedule>>(
		undefined
	)
	const [upsertScheduleDialogState, $upsertScheduleDialogState] = React.useState<boolean>(false)
	const [upsertScheduleDialogOptions, $upsertScheduleDialogOptions] = React.useState<
		| undefined
		| {
				[key: string]: any
		  }
	>(undefined)
	const openScheduleUpsert: IScheduleContext['openUpsert'] = async (item, options) => {
		log('openUpsert', { item })
		$upsertScheduleDialogOptions(options)
		$upsertScheduleDialogData(item)
		$upsertScheduleDialogState(true)
	}

	// * Methods
	const closeScheduleUpsert: IScheduleContext['closeUpsert'] = async (options) => {
		log('closeUpsert', { options })
		$upsertScheduleDialogState(false)
		setTimeout(() => {
			$upsertScheduleDialogData(undefined)
			$upsertScheduleDialogOptions(undefined)
		}, theme.transitions.duration.leavingScreen)
		if (options?.onSuccess) await options.onSuccess()
		if (options?.onComplete) await options.onComplete()
	}

	// * ---
	// *	Recipe Dialog Controls
	// * ---

	// * State
	const [upsertRecipeDialogData, $upsertRecipeDialogData] = React.useState<undefined | Partial<IRecipe>>(undefined)
	const [upsertRecipeDialogState, $upsertRecipeDialogState] = React.useState<boolean>(false)
	const [upsertRecipeDialogOptions, $upsertRecipeDialogOptions] = React.useState<
		| undefined
		| {
				[key: string]: any
		  }
	>(undefined)
	const openRecipeUpsert: IRecipeContext['openUpsert'] = async (item, options) => {
		log('openUpsert', { item })
		$upsertRecipeDialogOptions(options)
		$upsertRecipeDialogData(item)
		$upsertRecipeDialogState(true)
	}

	// * Methods
	const closeRecipeUpsert: IRecipeContext['closeUpsert'] = async (options) => {
		log('closeUpsert', { options })
		$upsertRecipeDialogState(false)
		setTimeout(() => {
			$upsertRecipeDialogData(undefined)
			$upsertRecipeDialogOptions(undefined)
		}, theme.transitions.duration.leavingScreen)
		if (options?.onSuccess) await options.onSuccess()
		if (options?.onComplete) await options.onComplete()
	}

	// * ---
	// *	MFA Dialog Controls
	// * ---

	// * State
	const [upsertMfaDialogData, $upsertMfaDialogData] = React.useState<undefined | Partial<IMfa>>(undefined)
	const [upsertMfaDialogState, $upsertMfaDialogState] = React.useState<boolean>(false)
	const [upsertMfaDialogOptions, $upsertMfaDialogOptions] = React.useState<
		| undefined
		| {
				[key: string]: any
		  }
	>(undefined)
	const openMfaUpsert: IMfaContext['openUpsert'] = async (item, options) => {
		log('openUpsert', { item })
		$upsertMfaDialogOptions(options)
		$upsertMfaDialogData(item)
		$upsertMfaDialogState(true)
	}

	// * Methods
	const closeMfaUpsert: IMfaContext['closeUpsert'] = async (options) => {
		log('closeUpsert', { options })
		$upsertMfaDialogState(false)
		setTimeout(() => {
			$upsertMfaDialogData(undefined)
			$upsertMfaDialogOptions(undefined)
		}, theme.transitions.duration.leavingScreen)
		if (options?.onSuccess) await options.onSuccess()
		if (options?.onComplete) await options.onComplete()
	}

	// * ---
	// *	MFA Code Dialog Controls
	// * ---

	// * State
	const [mfaCodeDialogData, $mfaCodeDialogData] = React.useState<undefined | Partial<IMfa>>(undefined)
	const [mfaCodeDialogState, $mfaCodeDialogState] = React.useState<boolean>(false)

	const openMfaCode: IMfaContext['openCode'] = async (item) => {
		log('openUpsert', { item })
		$mfaCodeDialogData(item)
		$mfaCodeDialogState(true)
	}

	// * Methods
	const closeMfaCode: IMfaContext['closeCode'] = async () => {
		log('closeUpsert')
		$mfaCodeDialogState(false)
		setTimeout(() => {
			$mfaCodeDialogData(undefined)
		}, theme.transitions.duration.leavingScreen)
	}

	// * ---
	// *	Tag Dialog Controls
	// * ---

	// * State
	const [upsertAccountTagDialogData, $upsertAccountTagDialogData] = React.useState<undefined | Partial<IAccountTag>>(
		undefined
	)
	const [upsertAccountTagDialogState, $upsertAccountTagDialogState] = React.useState<boolean>(false)
	const [upsertAccountTagDialogOptions, $upsertAccountTagDialogOptions] = React.useState<
		| undefined
		| {
				[key: string]: any
		  }
	>(undefined)
	const openAccountTagUpsert: IAccountTagContext['openUpsert'] = async (item, options) => {
		log('openUpsert', { item })
		$upsertAccountTagDialogOptions(options)
		$upsertAccountTagDialogData(item)
		$upsertAccountTagDialogState(true)
	}

	// * Methods
	const closeAccountTagUpsert: IAccountTagContext['closeUpsert'] = async (options) => {
		log('closeUpsert', { options })
		$upsertAccountTagDialogState(false)
		setTimeout(() => {
			$upsertAccountTagDialogData(undefined)
			$upsertAccountTagDialogOptions(undefined)
		}, theme.transitions.duration.leavingScreen)
		if (options?.onSuccess) await options.onSuccess()
		if (options?.onComplete) await options.onComplete()
	}

	// * ---
	// *	Node Clone
	// * ---
	const cloneNode = React.useRef()

	// * ---
	// *	Node Dialog Controls
	// * ---

	// * State
	const [upsertNodeDialogData, $upsertNodeDialogData] = React.useState<undefined | Partial<INode>>(undefined)
	const [upsertNodeDialogState, $upsertNodeDialogState] = React.useState<boolean>(false)
	const [upsertNodeDialogOptions, $upsertNodeDialogOptions] = React.useState<
		| undefined
		| {
				[key: string]: any
		  }
	>(undefined)
	const openNodeUpsert: INodeContext['openUpsert'] = async (item, options) => {
		log('openUpsert', { item })
		$upsertNodeDialogOptions(options)
		$upsertNodeDialogData(item)
		$upsertNodeDialogState(true)
	}

	// * Methods
	const closeNodeUpsert: INodeContext['closeUpsert'] = async (options) => {
		log('closeUpsert', { options })
		$upsertNodeDialogState(false)
		setTimeout(() => {
			$upsertNodeDialogData(undefined)
			$upsertNodeDialogOptions(undefined)
		}, theme.transitions.duration.leavingScreen)
		if (options?.onSuccess) await options.onSuccess()
		if (options?.onComplete) await options.onComplete()
	}

	// * ---
	// *	Child Node Dialog Controls
	// * ---

	// * State
	const [upsertChildNodeDialogData, $upsertChildNodeDialogData] = React.useState<undefined | Partial<INode>>(
		undefined
	)
	const [upsertChildNodeDialogState, $upsertChildNodeDialogState] = React.useState<boolean>(false)
	const [upsertChildNodeDialogOptions, $upsertChildNodeDialogOptions] = React.useState<
		| undefined
		| {
				[key: string]: any
		  }
	>(undefined)
	const openChildNodeUpsert: INodeContext['openUpsert'] = async (item, options) => {
		log('openUpsert', { item })
		$upsertChildNodeDialogOptions(options)
		$upsertChildNodeDialogData(item)
		$upsertChildNodeDialogState(true)
	}

	// * Methods
	const closeChildNodeUpsert: INodeContext['closeUpsert'] = async (options) => {
		log('closeUpsert', { options })
		$upsertChildNodeDialogState(false)
		setTimeout(() => {
			$upsertChildNodeDialogData(undefined)
			$upsertChildNodeDialogOptions(undefined)
		}, theme.transitions.duration.leavingScreen)
		if (options?.onSuccess) await options.onSuccess()
		if (options?.onComplete) await options.onComplete()
	}

	// * ---
	// *	Test: Artifact Dialog Controls
	// * ---

	// * State
	const [artifactDialogData, $artifactDialogData] = React.useState<undefined | ITestArtifactModal>(undefined)
	const [artifactDialogState, $artifactDialogState] = React.useState<boolean>(false)
	const openViewArtifact: ITestContext['openViewArtifact'] = async (data) => {
		log('openViewArtifact', { data })
		$artifactDialogData(data)
		$artifactDialogState(true)
	}

	// * Methods
	const closeViewArtifact: ITestContext['closeViewArtifact'] = async () => {
		log('closeViewArtifact')
		$artifactDialogState(false)
		setTimeout(() => {
			$artifactDialogData(undefined)
		}, theme.transitions.duration.leavingScreen)
	}

	// * ---
	// *	Test: Artifact Screenshots Dialog Controls
	// * ---

	// * State
	const [artifactScreenshotsDialogData, $artifactScreenshotsDialogData] = React.useState<undefined | ITest>(undefined)
	const [artifactScreenshotsDialogState, $artifactScreenshotsDialogState] = React.useState<boolean>(false)
	const [artifactScreenshotsDialogOptions, $artifactScreenshotsDialogOptions] = React.useState<
		| undefined
		| {
				[key: string]: any
		  }
	>(undefined)
	const openViewArtifactScreenshots: ITestContext['openViewArtifactScreenshots'] = async (data, options) => {
		log('openViewArtifactScreenshots', { data })
		$artifactScreenshotsDialogOptions(options)
		$artifactScreenshotsDialogData(data)
		$artifactScreenshotsDialogState(true)
	}

	// * Methods
	const closeViewArtifactScreenshots: ITestContext['closeViewArtifactScreenshots'] = async () => {
		log('closeViewArtifactScreenshots')
		$artifactScreenshotsDialogState(false)
		setTimeout(() => {
			$artifactScreenshotsDialogOptions(undefined)
			$artifactScreenshotsDialogData(undefined)
		}, theme.transitions.duration.leavingScreen)
	}

	// * ---
	// *	Test: Values Dialog Controls
	// * ---

	// * State
	const [testInformationDialogData, $testInformationDialogData] = React.useState<undefined | ITest>(undefined)
	const [testInformationDialogState, $testInformationDialogState] = React.useState<boolean>(false)
	const openViewInformation: ITestContext['openViewInformation'] = async (data) => {
		log('openViewValues', { data })
		$testInformationDialogData(data)
		$testInformationDialogState(true)
	}

	// * Methods
	const closeViewInformation: ITestContext['closeViewInformation'] = async () => {
		log('closeViewValues')
		$testInformationDialogState(false)
		setTimeout(() => {
			$testInformationDialogData(undefined)
		}, theme.transitions.duration.leavingScreen)
	}

	// * ---
	// *	Minutes Required Dialog Controls
	// * ---

	// * State
	const [minutesRequiredDialogState, $minutesRequiredDialogState] = React.useState<boolean>(false)
	const openMinutesRequired: IAccountContext['openMinutesRequired'] = async () => {
		log('openMinutesRequired')
		$minutesRequiredDialogState(true)
	}

	// * Methods
	const closeMinutesRequired: IAccountContext['closeMinutesRequired'] = async () => {
		log('closeMinutesRequired')
		$minutesRequiredDialogState(false)
	}

	// * ---
	// *	Only Account Owners Dialog Controls
	// * ---

	// * State
	const [onlyAccountOwnersDialogState, $onlyAccountOwnersDialogState] = React.useState<boolean>(false)
	const openOnlyAccountOwners: IAccountContext['openOnlyAccountOwners'] = async () => {
		log('openOnlyAccountOwners')
		$onlyAccountOwnersDialogState(true)
	}

	// * Methods
	const closeOnlyAccountOwners: IAccountContext['closeOnlyAccountOwners'] = async () => {
		log('closeOnlyAccountOwners')
		$onlyAccountOwnersDialogState(false)
	}

	// * ---
	// *	Account Value Dialog Controls
	// * ---

	// * State
	const [upsertAccountValueDialogData, $upsertAccountValueDialogData] = React.useState<
		undefined | Partial<IAccountValue>
	>(undefined)
	const [upsertAccountValueDialogState, $upsertAccountValueDialogState] = React.useState<boolean>(false)
	const [upsertAccountValueDialogOptions, $upsertAccountValueDialogOptions] = React.useState<
		| undefined
		| {
				[key: string]: any
		  }
	>(undefined)
	const openAccountValueUpsert: IAccountValueContext['openUpsert'] = async (item, options) => {
		log('openUpsert', { item })
		$upsertAccountValueDialogOptions(options)
		$upsertAccountValueDialogData(item)
		$upsertAccountValueDialogState(true)
	}

	// * Methods
	const closeAccountValueUpsert: IAccountValueContext['closeUpsert'] = async (options) => {
		log('closeUpsert', { options })
		$upsertAccountValueDialogState(false)
		setTimeout(() => {
			$upsertAccountValueDialogData(undefined)
			$upsertAccountValueDialogOptions(undefined)
		}, theme.transitions.duration.leavingScreen)
		if (options?.onSuccess) await options.onSuccess()
		if (options?.onComplete) await options.onComplete()
	}

	// * ---
	// *	Account Webhook Keys Dialog Controls
	// * ---

	// * State
	const [upsertAccountWebhookKeysDialogData, $upsertAccountWebhookKeysDialogData] = React.useState<
		undefined | Partial<IAccountWebhookKey>
	>(undefined)
	const [upsertAccountWebhookKeysDialogState, $upsertAccountWebhookKeysDialogState] = React.useState<boolean>(false)
	const [upsertAccountWebhookKeysDialogOptions, $upsertAccountWebhookKeysDialogOptions] = React.useState<
		| undefined
		| {
				[key: string]: any
		  }
	>(undefined)
	const openAccountWebhookKeysUpsert: IAccountWebhookKeyContext['openUpsert'] = async (item, options) => {
		log('openUpsert', { item })
		$upsertAccountWebhookKeysDialogOptions(options)
		$upsertAccountWebhookKeysDialogData(item)
		$upsertAccountWebhookKeysDialogState(true)
	}

	// * Methods
	const closeAccountWebhookKeysUpsert: IAccountWebhookKeyContext['closeUpsert'] = async (options) => {
		log('closeUpsert', { options })
		$upsertAccountWebhookKeysDialogState(false)
		setTimeout(() => {
			$upsertAccountWebhookKeysDialogData(undefined)
			$upsertAccountWebhookKeysDialogOptions(undefined)
		}, theme.transitions.duration.leavingScreen)
		if (options?.onSuccess) await options.onSuccess()
		if (options?.onComplete) await options.onComplete()
	}

	// * ---
	// *	Return: Good
	// * ---
	return (
		<AppDataContext.Provider
			value={{
				version: '18.55.0',
				title,
				$title,
				menuLibrary,
				$menuLibrary,
				reload,
				reloadNodesMenu,
				save,
				// saving,
				// $saving,
				run,
				preview,
				openMessage,
				openDeleteConfirmation,
				openSuggestReload,
				busy: {
					tasks: busy,
					hasTask: hasBusyTask,
					addTask: addBusyTask,
					removeTask: removeBusyTask,
				},
				account: cmsAccount,
				accountUsers: users || {},
				accountRefetch: cmsAccountRefetch,
				accountValues: {
					openUpsert: openAccountValueUpsert,
					closeUpsert: closeAccountValueUpsert,
				},
				accountWebhookKeys: {
					openUpsert: openAccountWebhookKeysUpsert,
					closeUpsert: closeAccountWebhookKeysUpsert,
				},
				tags: {
					openUpsert: openAccountTagUpsert,
					closeUpsert: closeAccountTagUpsert,
				},
				accountMinutesRequired: {
					openUpsert: openMinutesRequired,
					closeUpsert: closeMinutesRequired,
				},
				onlyAccountOwners: {
					open: openOnlyAccountOwners,
					close: closeOnlyAccountOwners,
				},
				elements: {
					openUpsert: openElementUpsert,
					closeUpsert: closeElementUpsert,
				},
				flows: {
					openUpsert: openFlowUpsert,
					closeUpsert: closeFlowUpsert,
				},
				events: {
					openUpsert: openEventUpsert,
					closeUpsert: closeEventUpsert,
				},
				schedules: {
					openUpsert: openScheduleUpsert,
					closeUpsert: closeScheduleUpsert,
				},
				recipes: {
					openUpsert: openRecipeUpsert,
					closeUpsert: closeRecipeUpsert,
				},
				mfas: {
					openUpsert: openMfaUpsert,
					closeUpsert: closeMfaUpsert,
					openCode: openMfaCode,
					closeCode: closeMfaCode,
				},
				nodes: {
					clone: cloneNode,
					openUpsert: openNodeUpsert,
					closeUpsert: closeNodeUpsert,
					openUpsertChild: openChildNodeUpsert,
					closeUpsertChild: closeChildNodeUpsert,
				},
				tests: {
					openViewArtifact,
					closeViewArtifact,
					openViewArtifactScreenshots,
					closeViewArtifactScreenshots,
					openViewInformation,
					closeViewInformation,
				},
			}}
		>
			{children}

			{/* BLOCKING BUSY TASKS */}
			{some(values(busy), { isBlocking: true }) && <LoadingOverlay />}

			{/* DIALOGS */}
			{(messageDialogState || messageDialogData) && (
				<MessageDialog
					state={messageDialogState}
					data={messageDialogData}
					options={messageDialogOptions}
					close={closeMessage}
				/>
			)}
			{(deleteConfirmationDialogState || deleteConfirmationDialogData) && (
				<DeleteConfirmation
					state={deleteConfirmationDialogState}
					data={deleteConfirmationDialogData}
					options={deleteConfirmationDialogOptions}
					close={closeDeleteConfirmation}
				/>
			)}
			{(suggestReloadDialogState || suggestReloadDialogData) && (
				<SuggestReloadDialog
					state={suggestReloadDialogState}
					data={suggestReloadDialogData}
					options={suggestReloadDialogOptions}
					close={closeSuggestReload}
				/>
			)}
			{(upsertElementDialogState || upsertElementDialogData) && (
				<ElementsDialog
					state={upsertElementDialogState}
					data={upsertElementDialogData}
					options={upsertElementDialogOptions}
					close={closeElementUpsert}
				/>
			)}
			{(upsertNodeDialogState || upsertNodeDialogData) && (
				<NodesDialog
					state={upsertNodeDialogState}
					data={upsertNodeDialogData}
					options={upsertNodeDialogOptions}
					close={closeNodeUpsert}
				/>
			)}
			{(upsertChildNodeDialogState || upsertChildNodeDialogData) && (
				<NodesDialog
					state={upsertChildNodeDialogState}
					data={upsertChildNodeDialogData}
					options={upsertChildNodeDialogOptions}
					close={closeChildNodeUpsert}
				/>
			)}
			{(upsertFlowDialogState || upsertFlowDialogData) && (
				<FlowsDialog
					state={upsertFlowDialogState}
					data={upsertFlowDialogData}
					options={upsertFlowDialogOptions}
					close={closeFlowUpsert}
				/>
			)}
			{(upsertEventDialogState || upsertEventDialogData) && (
				<EventDialog
					state={upsertEventDialogState}
					data={upsertEventDialogData}
					options={upsertEventDialogOptions}
					close={closeEventUpsert}
				/>
			)}
			{(upsertScheduleDialogState || upsertScheduleDialogData) && (
				<ScheduleDialog
					state={upsertScheduleDialogState}
					data={upsertScheduleDialogData}
					options={upsertScheduleDialogOptions}
					close={closeScheduleUpsert}
				/>
			)}
			{(upsertRecipeDialogState || upsertRecipeDialogData) && (
				<RecipeDialog
					state={upsertRecipeDialogState}
					data={upsertRecipeDialogData}
					options={upsertRecipeDialogOptions}
					close={closeRecipeUpsert}
				/>
			)}
			{(upsertMfaDialogState || upsertMfaDialogData) && (
				<MfaDialog
					state={upsertMfaDialogState}
					data={upsertMfaDialogData}
					options={upsertMfaDialogOptions}
					close={closeMfaUpsert}
				/>
			)}
			{(mfaCodeDialogState || mfaCodeDialogData) && (
				<MfaCodeDialog state={mfaCodeDialogState} data={mfaCodeDialogData} close={closeMfaCode} />
			)}
			{(artifactDialogState || artifactDialogData) && (
				<ArtifactDialog state={artifactDialogState} data={artifactDialogData} close={closeViewArtifact} />
			)}
			{(artifactScreenshotsDialogState || artifactScreenshotsDialogData) && (
				<ArtifactScreenshotsDialogs
					state={artifactScreenshotsDialogState}
					data={artifactScreenshotsDialogData}
					options={artifactScreenshotsDialogOptions}
					close={closeViewArtifactScreenshots}
				/>
			)}
			{(testInformationDialogState || testInformationDialogData) && (
				<TestInformationDialog
					state={testInformationDialogState}
					data={testInformationDialogData}
					close={closeViewInformation}
				/>
			)}
			{minutesRequiredDialogState && (
				<MinutesRequiredDialog state={minutesRequiredDialogState} close={closeMinutesRequired} />
			)}
			{onlyAccountOwnersDialogState && (
				<OnlyAccountOwnersDialog state={onlyAccountOwnersDialogState} close={closeOnlyAccountOwners} />
			)}
			{(upsertAccountValueDialogState || upsertAccountValueDialogData) && (
				<ValueDialog
					state={upsertAccountValueDialogState}
					data={upsertAccountValueDialogData}
					options={upsertAccountValueDialogOptions}
					close={closeAccountValueUpsert}
				/>
			)}
			{(upsertAccountWebhookKeysDialogState || upsertAccountWebhookKeysDialogData) && (
				<WebhookDialog
					state={upsertAccountWebhookKeysDialogState}
					data={upsertAccountWebhookKeysDialogData}
					options={upsertAccountWebhookKeysDialogOptions}
					close={closeAccountWebhookKeysUpsert}
				/>
			)}
			{(upsertAccountTagDialogState || upsertAccountTagDialogData) && (
				<TagDialog
					state={upsertAccountTagDialogState}
					data={upsertAccountTagDialogData}
					options={upsertAccountTagDialogOptions}
					close={closeAccountTagUpsert}
				/>
			)}
		</AppDataContext.Provider>
	)
}
