// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import * as yup from 'yup'

import { compact, includes, map } from 'lodash'

import {
	COMPARISON_TYPE_VALIDATION,
	STANDARD_COMPARISON_TYPE_CONDITIONAL_VALIDATION,
} from 'globals/constants/validations'

import { formatComparisonType, formatEnumValue, formatValue, sentenceValue } from 'hooks/useNode'

import {
	INodeComparisonTypes,
	INodeMailWaitFilterProperties,
	INodeRenderProps,
	INodeVariantTypes,
	IUseNode,
	IUseNodeConfig,
} from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.mail_wait_forMessage,
	isLive: true,
	isMenuOption: true,
	isPro: false,
	isAccessibility: false,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.value',
			type: 'button-group',
			defaultValue: 'testcase',
			label: 'Inbox',
			options: [
				{
					label: 'Test Case',
					value: 'testcase',
				},
				{
					label: 'Account',
					value: 'account',
				},
			],
		},
		{
			property: 'attributes.value2',
			type: 'button-group',
			defaultValue: 'new',
			label: 'Type',
			options: [
				{
					label: 'New',
					value: 'new',
				},
				{
					label: 'Custom',
					value: 'custom',
				},
			],
		},
		{
			property: 'attributes.value3',
			type: 'dropdown',
			label: 'Property',
			defaultValue: 'subject',
			options: map(INodeMailWaitFilterProperties, (label, value) => {
				return {
					label,
					value,
				}
			}),
			conditional: ({ values }: { values: { [key: string]: any } }) => {
				return values.attributes.value2 === 'custom'
			},
		},
		{
			property: 'attributes.comparisonType',
			type: 'comparisonType',
			defaultValue: 'equals',
			label: 'Comparison Type',
			validation: COMPARISON_TYPE_VALIDATION,
			conditional: ({ values }: { values: { [key: string]: any } }) => {
				return values.attributes.value2 === 'custom'
			},
		},
		{
			property: 'attributes.value4',
			type: 'text-with-tags',
			label: 'Value',
			conditional: ({ values }: { values: { [key: string]: any } }) => {
				return (
					values.attributes.value2 === 'custom' &&
					!includes(
						[INodeComparisonTypes.hasValue, INodeComparisonTypes.notHasValue],
						values.attributes.comparisonType
					)
				)
			},
			validation: yup.mixed().when('value2', {
				is: 'custom',
				then: () => STANDARD_COMPARISON_TYPE_CONDITIONAL_VALIDATION,
				otherwise: (schema) => schema.notRequired(),
			}),
		},
	],
	label: 'Wait for email',
	sentence: ({ comparisonType, isExample, value2, value3, value4 }: INodeRenderProps) => {
		if (isExample) return `Wait for email`
		switch (value2) {
			case 'custom':
				return `Wait for email with ${value3} ${formatComparisonType(comparisonType)} ${
					!includes([INodeComparisonTypes.hasValue, INodeComparisonTypes.notHasValue], comparisonType) &&
					sentenceValue(value4)
				}`
			case 'new':
			default:
				return `Wait for new email`
		}
	},
	family: INodeVariantTypes.mail_wait_forMessage.split('_')[0],
	type: INodeVariantTypes.mail_wait_forMessage.split('_')[1],
	subType: INodeVariantTypes.mail_wait_forMessage.split('_')[2],
	tags: compact([
		INodeVariantTypes.mail_wait_forMessage.split('_')[0],
		INodeVariantTypes.mail_wait_forMessage.split('_')[1],
		INodeVariantTypes.mail_wait_forMessage.split('_')[2],
		'emails',
		'messages',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ comparisonType, isExample, value2, value3, value4 }: INodeRenderProps) => {
			return (
				<>
					{isExample ? (
						'Wait for email'
					) : value2 === 'custom' ? (
						<>
							Wait for email where{' '}
							<strong>{formatEnumValue(INodeMailWaitFilterProperties, value3)}</strong>{' '}
							<em>{formatComparisonType(comparisonType)}</em>{' '}
							{!includes(
								[INodeComparisonTypes.hasValue, INodeComparisonTypes.notHasValue],
								comparisonType
							) && <strong>{formatValue(value4)}</strong>}
						</>
					) : (
						<>
							Wait for <strong>new</strong> email
						</>
					)}
				</>
			)
		},
	}
}
