// ? ---
// ?	Fragment
// ? ---
import gql from 'graphql-tag'

// ? ---
// ?	Queries
// ? --
export const QUERY_TESTS_FULL = gql(/* GraphQL */ `
	query query_tests_full($filters: TestFiltersInput!, $pagination: PaginationArg!) {
		tests(filters: $filters, pagination: $pagination, sort: "createdAt:desc") {
			data {
				id
				attributes {
					uid
					title
					hash
					status
					createdAt
					updatedAt
					startedAt
					completedAt
					hasEmail
					runId
					runData
					flowId
					flowData
					stepData
					artifacts {
						key
						type
						topic
						name
						width
						height
					}
					response {
						type
						timestamp
						message
					}
				}
			}
		}
	}
`)

export const QUERY_TESTS_VIEW = gql(/* GraphQL */ `
	query query_tests_view($filters: TestFiltersInput!, $pagination: PaginationArg!) {
		tests(filters: $filters, pagination: $pagination, sort: "updatedAt:desc") {
			meta {
				pagination {
					total
				}
			}
			data {
				id
				attributes {
					uid
					title
					hash
					status
					createdAt
					updatedAt
					startedAt
					completedAt
					hasEmail
					runId
					runData
					flowId
					flowData
					stepData
					artifacts {
						key
						type
						topic
						name
						width
						height
					}
					response {
						type
						timestamp
						message
					}
				}
			}
		}
	}
`)

export const QUERY_TESTS_MAILBOX = gql(/* GraphQL */ `
	query query_tests_mailbox($filters: TestFiltersInput!) {
		tests(filters: $filters, pagination: { pageSize: 1 }, sort: "updatedAt:desc") {
			data {
				id
				attributes {
					uid
					title
					status
					completedAt
					runId
					flowId
					flowData
					hasEmail
				}
			}
		}
	}
`)

export const QUERY_RUN_TESTS_VIEW = gql(/* GraphQL */ `
	query query_tests_view($filters: TestFiltersInput!, $pagination: PaginationArg!) {
		tests(filters: $filters, pagination: $pagination, sort: "title:asc") {
			meta {
				pagination {
					total
				}
			}
			data {
				id
				attributes {
					uid
					title
					hash
					status
					createdAt
					updatedAt
					startedAt
					completedAt
					runId
					runData
					flowId
					flowData
					stepData
					artifacts {
						key
						type
						topic
						name
						width
						height
					}
					response {
						type
						timestamp
						message
					}
				}
			}
		}
	}
`)

export const QUERY_TESTS_STATUS = gql(/* GraphQL */ `
	query query_tests_status($filters: TestFiltersInput!, $pagination: PaginationArg!) {
		tests(filters: $filters, pagination: $pagination, sort: "updatedAt:desc") {
			data {
				id
				attributes {
					uid
					status
					updatedAt
					runId
					flowId
				}
			}
		}
	}
`)

export const QUERY_TESTS_INBOXES = gql(/* GraphQL */ `
	query query_tests_inboxes($filters: TestFiltersInput!, $pagination: PaginationArg!) {
		tests(filters: $filters, pagination: $pagination, sort: "updatedAt:desc") {
			data {
				id
				attributes {
					title
					createdAt
				}
			}
		}
	}
`)

export const QUERY_TESTS_UPDATED = gql(/* GraphQL */ `
	query query_tests_updated($filters: TestFiltersInput!) {
		tests(filters: $filters, pagination: { pageSize: 1 }, sort: "updatedAt:desc") {
			data {
				id
				attributes {
					updatedAt
				}
			}
		}
	}
`)

export const QUERY_TESTS_META = gql(/* GraphQL */ `
	query query_tests_meta($filters: TestFiltersInput!) {
		tests(filters: $filters) {
			meta {
				pagination {
					total
				}
			}
		}
	}
`)

// ? ---
// ?	Mutations
// ? --
export const ADD_TEST = gql(/* GraphQL */ `
	mutation add_test($data: TestInput!) {
		createTest(data: $data) {
			data {
				id
				attributes {
					updatedAt
				}
			}
		}
	}
`)

export const ADD_TEST_WEBHOOK = gql(/* GraphQL */ `
	mutation create_test_webhook($data: TestInput!) {
		createTest(data: $data) {
			data {
				id
			}
		}
	}
`)

export const EDIT_TEST = gql(/* GraphQL */ `
	mutation update_test($id: ID!, $data: TestInput!) {
		updateTest(id: $id, data: $data) {
			data {
				id
				attributes {
					updatedAt
				}
			}
		}
	}
`)

export const NUKE_TEST = gql(/* GraphQL */ `
	mutation delete_test($id: ID!) {
		deleteTest(id: $id) {
			data {
				id
				attributes {
					updatedAt
				}
			}
		}
	}
`)
