// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import { useMutation } from '@apollo/client'
import axios from 'axios'
import { AppDataContext, IAppDataContext } from 'context/appData'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import debug from 'debug'
import omitDeep from 'omit-deep-lodash'

import { get, isEqual, map, reject, sortBy, toLower, toNumber, unionBy, uniqBy } from 'lodash'

import { PLAN_MAX_CONCURRENCY } from 'globals/constants/account'
import { FLOW_TAG_OPTIONS } from 'globals/constants/selectOptions'

import { useAuth } from 'components/_Global/_____AuthProvider'
import {
	EDIT_ACCOUNT,
	IAccount,
	IAccountOptions,
	IAccountSlackChannel,
	IAccountTag,
	IAccountTagContext,
	IAccountValue,
	IAccountValueContext,
	IAccountWebhookKey,
	IAccountWebhookKeyContext,
} from 'data/accounts'

import useActivity from './useActivity'

// ? ---
// ?	Constants
// ? ---
const namespace = 'hooks-useAccount'
const log = debug(`app:${namespace}`)

dayjs.extend(utc)

// ? ---
// ?	Hook
// ? ---
export default function useAccount() {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const AppData = React.useContext<IAppDataContext>(AppDataContext)
	const data = AppData.account
	const { getToken, user } = useAuth()
	const _activity = useActivity()

	// * ---
	// *	Mutation
	// * ---
	const [edit] = useMutation(EDIT_ACCOUNT)

	// * ---
	// *	State
	// * ---
	const [outsetaAccount, $outsetaAccount] = React.useState(user?.Account)

	// * ---
	// *	Static
	// * ---
	const accountId = user?.Account.Uid
	const accountName = user?.Account.Name
	const planId = get(outsetaAccount, 'CurrentSubscription.Plan.Uid')
	const planName = get(outsetaAccount, 'CurrentSubscription.Plan.Name')
	const periodEnd = dayjs(get(outsetaAccount, 'CurrentSubscription.RenewalDate')).utc().toISOString()
	const periodStart = dayjs(periodEnd).utc().subtract(30, 'days').toISOString()
	const infraAccountId = `o-${accountId}`
	const stackType = user?.Account.DqaStackType
	const stack = user?.Account.DqaStackType == 'community' ? 'community' : accountId
	const maxConcurrency = get(PLAN_MAX_CONCURRENCY, get(outsetaAccount, 'CurrentSubscription.Plan.Uid', 'null'), 0)

	// * ---
	// *	Update Outseta Account (fire & forget)
	// * ---
	const update = (body: { [key: string]: any }): void => {
		axios
			.put(`/api/v2/${stackType}/accounts/${accountId}`, body, {
				headers: {
					'Content-Type': 'application/json',
					'x-auth': getToken(),
					'x-account': accountId,
				},
			})
			.then(() => {
				$outsetaAccount((account) => {
					if (!account) return account
					return {
						...account,
						...body,
					}
				})
			})
			.catch((err) => {
				log('!! Update outseta account failed', err)
			})
	}

	// * ---
	// *	Slack Channels & Users
	// * ---
	const [slackChannels, $slackChannels] = React.useState<IAccountSlackChannel[]>([])
	const fetchSlackChannels = async (force?: boolean) => {
		let tempChannels = [...slackChannels]
		if (tempChannels.length === 0 || force) {
			const response = await fetch(`/api/slack/channels`).catch(log)
			if (response && response.status === 200) {
				const responseData = await response.json().catch(log)
				tempChannels = responseData.channels
			}
			log('fetchSlackChannels', tempChannels)
			if (!isEqual(tempChannels, slackChannels)) {
				$slackChannels(tempChannels)
			}
		}
		return tempChannels
	}

	// * ---
	// *	Save Options
	// * ---
	const saveOptions = async (input: Partial<IAccountOptions>): Promise<IAccount> => {
		log('saveOptions', { input })
		const response = await edit({
			variables: {
				id: data?.id,
				data: {
					options: {
						...input,
						maxConcurrency: parseInt(`${input.maxConcurrency}`),
						stepTimeoutSeconds: parseInt(`${input.stepTimeoutSeconds}`),
						blockedHostnames: input?.blockedHostnames ? input.blockedHostnames : [],
					},
				},
			},
		})
		AppData.accountRefetch()
		return response.data[`updateAccount`].data
	}

	// * ---
	// *	Value: Upsert
	// * ---
	const valueUpsert: IAccountValueContext['upsert'] = async (input, options) => {
		log('valueUpsert', { input, options })

		const values = map(
			omitDeep(unionBy([input], data?.attributes.values || [], 'id'), '__typename'),
			(value: IAccountValue) => {
				if (value.id === input.id || value.id === undefined) {
					if (value.isPrivate) {
						switch (value.type) {
							case 'number':
								return {
									...value,
									numberValue_private: toNumber(value.numberValue),
									numberValue: undefined,
								}
							case 'boolean':
								return {
									...value,
									booleanValue_private: !!value.booleanValue,
									booleanValue: undefined,
								}
							case 'json':
								return {
									...value,
									jsonValue_private: value.jsonValue,
									jsonValue: undefined,
								}
							case 'string':
							default:
								return {
									...value,
									stringValue_private: value.stringValue,
									stringValue: undefined,
								}
						}
					} else {
						switch (value.type) {
							case 'number':
								return {
									...value,
									numberValue: toNumber(value.numberValue),
								}
							case 'boolean':
								return {
									...value,
									booleanValue: !!value.booleanValue,
								}
							default:
								return value
						}
					}
				} else {
					return { id: value.id }
				}
			}
		)

		const response = await edit({
			variables: {
				id: data?.id,
				data: {
					values,
				},
			},
		})
		if (!input.id) {
			_activity.trackValueCreated(input.name || 'UNKNOWN_NAME')
		}
		if (options?.onSuccess) options.onSuccess(response.data[`updateAccount`].data)
		if (options?.onComplete) options.onComplete(response.data[`updateAccount`].data)
		return response.data[`updateAccount`].data
	}

	// * ---
	// *	Value: Remove
	// * ---
	const valueRemove: IAccountValueContext['remove'] = async (input, options) => {
		log('valueRemove', { input, options })
		await AppData.openDeleteConfirmation(
			{
				body: 'Deleting a value can cause flows to fail.',
			},
			{
				onSuccess: async () => {
					const response = await edit({
						variables: {
							id: data?.id,
							data: {
								values: map(
									omitDeep(reject(data?.attributes.values, { id: input.id }), '__typename'),
									(value: IAccountValue) => {
										return { id: value.id }
									}
								),
							},
						},
					})
					if (options?.onSuccess) options.onSuccess(response.data[`updateAccount`].data)
					if (options?.onComplete) options.onComplete(response.data[`updateAccount`].data)
					return response.data[`updateAccount`].data
				},
			}
		)
	}

	// * ---
	// *	Webhook Key: Upsert
	// * ---
	const webhookKeyUpsert: IAccountWebhookKeyContext['upsert'] = async (input, options) => {
		log('webhookKeyUpsert', { input, options })

		const keys = map(
			omitDeep(unionBy([input], data?.attributes.keys || [], 'id'), '__typename'),
			(key: IAccountWebhookKey) => {
				if (key.id === input.id || key.id === undefined) {
					return {
						...key,
						slackChannelId: key.slackChannelId !== '' ? key.slackChannelId : null,
						isActive: true,
						activeAt: dayjs().toISOString(),
						userId: user?.Uid,
					}
				} else {
					return { id: key.id }
				}
			}
		)
		const response = await edit({
			variables: {
				id: data?.id,
				data: {
					keys,
				},
			},
		})
		if (!input.id) {
			_activity.trackWebhookCreated(input.name || 'UNKNOWN_NAME')
		}
		if (options?.onSuccess) options.onSuccess(response.data[`updateAccount`].data)
		if (options?.onComplete) options.onComplete(response.data[`updateAccount`].data)
		return response.data[`updateAccount`].data
	}

	// * ---
	// *	Webhook Key: Remove
	// * ---
	const webhookKeyRemove: IAccountWebhookKeyContext['remove'] = async (input, options) => {
		log('webhookKeyRemove', { input, options })
		await AppData.openDeleteConfirmation(
			{
				body: 'Webhook integrations using this key will stop working.',
			},
			{
				onSuccess: async () => {
					const response = await edit({
						variables: {
							id: data?.id,
							data: {
								keys: map(
									omitDeep(reject(data?.attributes.keys, { id: input.id }), '__typename'),
									(value: IAccountValue) => {
										return { id: value.id }
									}
								),
							},
						},
					})
					if (options?.onSuccess) options.onSuccess(response.data[`updateAccount`].data)
					if (options?.onComplete) options.onComplete(response.data[`updateAccount`].data)
					return response.data[`updateAccount`].data
				},
			}
		)
	}

	// * ---
	// *	Tags
	// * ---
	const tags = (): IAccountTag[] => {
		return sortBy(uniqBy([...(data?.attributes?.tags || []), ...FLOW_TAG_OPTIONS], 'value'), ['value'])
	}

	// * ---
	// *	Tag: Upsert
	// * ---
	const tagUpsert: IAccountTagContext['upsert'] = async (input, options) => {
		log('tagUpsert', { input, options })

		const tags = map(
			omitDeep(unionBy([input], data?.attributes.tags || [], 'id'), '__typename'),
			(tag: IAccountTag) => {
				if (tag.id === input.id || tag.id === undefined) {
					return {
						...tag,
						value: toLower(tag.label),
					}
				} else {
					return { id: tag.id }
				}
			}
		)
		const response = await edit({
			variables: {
				id: data?.id,
				data: {
					tags,
				},
			},
		})
		if (options?.onSuccess) options.onSuccess(response.data[`updateAccount`].data)
		if (options?.onComplete) options.onComplete(response.data[`updateAccount`].data)
		return response.data[`updateAccount`].data
	}

	// * ---
	// *	Tag: Remove
	// * ---
	const tagRemove: IAccountTagContext['remove'] = async (input, options) => {
		log('tagRemove', { input, options })
		await AppData.openDeleteConfirmation(
			{
				body: 'Deleting a tag does not remove it from flows.',
			},
			{
				onSuccess: async () => {
					const response = await edit({
						variables: {
							id: data?.id,
							data: {
								tags: map(
									omitDeep(reject(data?.attributes.tags, { id: input.id }), '__typename'),
									(value: IAccountTag) => {
										return { id: value.id }
									}
								),
							},
						},
					})
					if (options?.onSuccess) options.onSuccess(response.data[`updateAccount`].data)
					if (options?.onComplete) options.onComplete(response.data[`updateAccount`].data)
					return response.data[`updateAccount`].data
				},
			}
		)
	}

	// * ---
	// *	Return
	// * ---
	return {
		stack,
		stackType,
		accountId,
		accountName,
		accountPlanId: planId,
		accountPlan: planName,
		periodStart,
		periodEnd,
		maxConcurrency,
		hasUnlimitedConcurrency: maxConcurrency === 0,
		infraAccountId,
		outseta: outsetaAccount,
		$outsetaAccount,
		update,
		fetchSlackChannels,
		slackChannels,
		fetchAccount: AppData.accountRefetch,
		saveOptions,
		data,
		tags,
		openValueUpsert: AppData.accountValues.openUpsert,
		valueUpsert,
		valueRemove,
		webhookKeyUpsert,
		webhookKeyRemove,
		tagUpsert,
		tagRemove,
		stats: {
			nodes: {
				total: 0,
				action: 0,
				check: 0,
				validate: 0,
				data: 0,
			},
			tests: {
				total: 0,
				passed: 0,
				failed: 0,
			},
		},
		limit: {
			nodes: 100,
			runMinutes: 1000,
		},
	}
}
