// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import { useMutation } from '@apollo/client'
import axios from 'axios'
import { AppDataContext, IAppDataContext } from 'context/appData'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import debug from 'debug'

import { get, map, omit } from 'lodash'

import { useAuth } from 'components/_Global/_____AuthProvider'
import { ADD_MFA, EDIT_MFA, IMfaContext, NUKE_MFA } from 'data/mfas'

import useAccount from './useAccount'
import useActivity from './useActivity'

// ? ---
// ?	Constants
// ? ---
const namespace = 'hooks-useMfas'
const log = debug(`app:${namespace}`)
const type = 'Mfa'
const model = 'mfas'

dayjs.extend(utc)

// ? ---
// ?	Hook
// ? ---
export default function useMfas() {
	// * ---
	// *	Context
	// * ---
	const AppData = React.useContext<IAppDataContext>(AppDataContext)
	const _activity = useActivity()
	const _account = useAccount()
	const { getToken } = useAuth()

	// * ---
	// *	Mutation
	// * ---
	const [add] = useMutation(ADD_MFA)
	const [edit] = useMutation(EDIT_MFA)
	const [nuke] = useMutation(NUKE_MFA)

	// * ---
	// *	Insert
	// * ---
	const insert: IMfaContext['insert'] = async (input, options) => {
		log('insert', { input, options })
		const response = await add({
			variables: {
				data: {
					...omit(input.attributes, ['__typename']),
				},
			},
		})
		_activity.trackMfaCreated(input?.attributes?.title || 'UNKNOWN_NAME')
		if (options?.onSuccess) options.onSuccess(response.data[`create${type}`].data)
		if (options?.onComplete) options.onComplete(response.data[`create${type}`].data)
		return response.data[`create${type}`].data
	}

	// * ---
	// *	Update
	// * ---
	const update: IMfaContext['update'] = async (input, options) => {
		log('update', { input, options })
		const response = await edit({
			variables: {
				id: input.id,
				data: {
					...omit(input.attributes, ['__typename', 'updatedAt', 'nodes', 'secret']),
				},
			},
		})
		if (options?.onSuccess) options.onSuccess(response.data[`update${type}`].data)
		if (options?.onComplete) options.onComplete(response.data[`update${type}`].data)
		return response.data[`update${type}`].data
	}

	// * ---
	// *	Remove
	// * ---
	const remove: IMfaContext['remove'] = async (item, options) => {
		const linkedNodes = map(get(item, 'attributes.nodes.data', []), 'id')
		log('remove', { item, options })
		if (options?.force) {
			await nuke({
				variables: {
					id: item.id,
				},
			})
			if (options?.onSuccess) options.onSuccess()
			if (options?.onComplete) options.onComplete()
		} else {
			await AppData.openDeleteConfirmation(
				{
					body:
						linkedNodes.length === 1
							? 'A Node is connected to this MFA.'
							: linkedNodes.length > 1
							? `${linkedNodes.length} Nodes are connected to this MFA.`
							: undefined,
					related: linkedNodes.length > 0 ? linkedNodes : undefined,
				},
				{
					onSuccess: async () => {
						await nuke({
							variables: {
								id: item.id,
							},
						})
						if (options?.onSuccess) options.onSuccess()
						if (options?.onComplete) options.onComplete()
					},
				}
			)
		}
	}

	// * ---
	// *	Upsert
	// * ---
	const upsert: IMfaContext['upsert'] = async (input, options) => {
		log('upsert', { input, options })
		if (input.id === undefined || input.id === '') {
			return await insert(input, options)
		} else {
			return await update(input, options)
		}
	}

	// * ---
	// *	Clone
	// * ---
	const clone: IMfaContext['clone'] = async (item, options) => {
		log('clone', { item, options })
		const response = await insert(
			{
				...item,
				attributes: {
					...omit(item.attributes, ['__typename']),
					title: `${item.attributes?.title} (copy)`,
				},
			},
			options
		)
		if (options?.onSuccess) options.onSuccess(response)
		if (options?.onComplete) options.onComplete(response)
		return response
	}

	// * ---
	// *	Clone
	// * ---
	const generateCode: IMfaContext['generateCode'] = async ({ id, secret }) => {
		log('generateCode', { secret })
		return await axios
			.post(
				`/api/v2/${_account.stackType}/accounts/${_account.accountId}/mfa/generate`,
				id ? { id } : { secret },
				{
					headers: {
						'Content-Type': 'application/json',
						'x-auth': getToken(),
						'x-account': _account.accountId,
					},
				}
			)
			.then((response) => {
				return response.data
			})
			.catch((err) => {
				log('!! generateCode failed', err)
				return null
			})
	}

	// * ---
	// *	Return
	// * ---
	return {
		insert,
		update,
		upsert,
		clone,
		remove,
		generateCode,
		openUpsert: AppData[model].openUpsert,
		closeUpsert: AppData[model].closeUpsert,
		openCode: AppData[model].openCode,
		closeCode: AppData[model].closeCode,
	}
}
