// ? ---
// ?	Fragment
// ? ---
import gql from 'graphql-tag'

import { MAX_FLOW_NODES, MAX_FLOWS_PER_PAGE, MAX_ITEMS_PER_PAGE, MAX_RUN_TEST_ITEMS } from 'globals/constants/api'

import { NODE_FRAGMENT } from 'data/nodes'

export const FLOW_FRAGMENT = `
fragment flowFragment on FlowEntity {
	id
	attributes {
		uid
		title
		tags
		stopTestAfterFirstError
		stepTimeoutSeconds
		lighthouse
		nodes(pagination: { pageSize: ${MAX_FLOW_NODES} }) {
			data {
				...nodeFragment
			}
		}
		edgeMeta
		nodeMeta
		createdAt
		updatedAt
		currentHashes
		checksum
		userId
		useLightningLane
		isFixture
		isHidden
		isIsolated
		isAccessibility
	}
}
`

export const FLOW_FRAGMENT_USER = `
fragment flowFragmentUser on FlowEntity {
	id
	attributes {
		updatedAt
		userId
	}
}
`

export const FLOW_FRAGMENT_WEBHOOK = `
fragment flowFragmentWebhook on FlowEntity {
	id
	attributes {
		uid
		title
		tags
		stopTestAfterFirstError
		stepTimeoutSeconds
		lighthouse
		nodes(pagination: { pageSize: ${MAX_FLOW_NODES} }) {
			data {
				...nodeFragment
			}
		}
		edgeMeta
		nodeMeta
		updatedAt
		currentHashes
		checksum
		userId
		runHistory
		useLightningLane
		isFixture
		isHidden
		isIsolated
		isAccessibility
		runs(sort: "createdAt:desc", pagination: {pageSize: 4}) {
			data {
			  id
			}
		}
	}
}
`

// ? ---
// ?	Queries
// ? --
export const QUERY_FLOWS = gql(/* GraphQL */ `
    ${FLOW_FRAGMENT}
    ${NODE_FRAGMENT}
    query query_flows_main($filters: FlowFiltersInput!) {
        flows(filters: $filters, pagination: { pageSize: ${MAX_ITEMS_PER_PAGE} }, sort: "title:asc") {
            data {
                ...flowFragment
            }
        }
    }
`)

export const QUERY_FLOWS_USER = gql(/* GraphQL */ `
	${FLOW_FRAGMENT_USER}
	query query_flows_user($filters: FlowFiltersInput!) {
		flows(filters: $filters, pagination: { pageSize: 1 }, sort: "updatedAt:desc") {
			data {
				...flowFragmentUser
			}
		}
	}
`)

export const QUERY_FLOWS_WEBHOOK = gql(/* GraphQL */ `
    ${FLOW_FRAGMENT_WEBHOOK}
    ${NODE_FRAGMENT}
    query query_flows_webhook($filters: FlowFiltersInput!) {
        flows(filters: $filters, pagination: { pageSize: ${MAX_FLOWS_PER_PAGE} }, sort: "title:asc") {
            data {
                ...flowFragmentWebhook
            }
        }
    }
`)

export const QUERY_FLOWS_VIEW = gql(/* GraphQL */ `
		query query_flows_view($filters: FlowFiltersInput!) {
		    flows(filters: $filters, pagination: { pageSize: ${MAX_RUN_TEST_ITEMS} }) {
		        data {
		            id
		            attributes {
		                title
		                tags
		                stopTestAfterFirstError
		                stepTimeoutSeconds
		                lighthouse
		                updatedAt
		                userId
		                checkRatio
		                runHistory
                    isFixture
				            isHidden
                    isIsolated
                    isAccessibility
		            }
		        }
		    }
		}
`)

export const QUERY_FLOWS_STAT = gql(/* GraphQL */ `
    query query_flows_view($filters: FlowFiltersInput!) {
        flows(filters: $filters, pagination: { pageSize: ${MAX_RUN_TEST_ITEMS} }) {
            data {
                id
                attributes {
                    title
                    checkRatio
                }
            }
        }
    }
`)

export const QUERY_FLOWS_SELECT = gql(/* GraphQL */ `
    query query_flows_view($filters: FlowFiltersInput!) {
        flows(filters: $filters, pagination: { pageSize: ${MAX_RUN_TEST_ITEMS} }) {
            data {
                id
                attributes {
                    title
                }
            }
        }
    }
`)

// ? ---
// ?	Mutations
// ? --
export const ADD_FLOW = gql(/* GraphQL */ `
	${FLOW_FRAGMENT}
	${NODE_FRAGMENT}
	mutation create_flow($data: FlowInput!) {
		createFlow(data: $data) {
			data {
				...flowFragment
			}
		}
	}
`)

export const EDIT_FLOW = gql(/* GraphQL */ `
	${FLOW_FRAGMENT}
	${NODE_FRAGMENT}
	mutation update_flow($id: ID!, $data: FlowInput!) {
		updateFlow(id: $id, data: $data) {
			data {
				...flowFragment
			}
		}
	}
`)

export const EDIT_FLOW_WEBHOOK = gql(/* GraphQL */ `
	mutation update_flow($id: ID!, $data: FlowInput!) {
		updateFlow(id: $id, data: $data) {
			data {
				id
			}
		}
	}
`)

export const NUKE_FLOW = gql(/* GraphQL */ `
	${FLOW_FRAGMENT}
	${NODE_FRAGMENT}
	mutation delete_flow($id: ID!) {
		deleteFlow(id: $id) {
			data {
				...flowFragment
			}
		}
	}
`)
