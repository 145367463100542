// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'
import { useDebounce } from 'use-debounce'

import { useAuth } from 'components/_Global/_____AuthProvider'
import E403 from 'components/_Global/E403'
import Loading from 'components/_Global/Loading'

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-WaitForAuth'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Types
// ? ---
type Props = {
	children: JSX.Element
}

// ? ---
// ?	Component
// ? ---
export default function WaitForAuth({ children }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const { isAuth, isLoading, isLoggingIn } = useAuth()

	// * ---
	// *	State
	// * ---
	const [appReady, $appReady] = React.useState(false)

	// * ---
	// *	Loading State
	// * ---
	const [debouncedIsLoading] = useDebounce(isLoading || isLoggingIn, 250, {
		leading: true,
		trailing: true,
	})

	// * ---
	// *	Effect: App Ready
	// * ---
	React.useEffect(() => {
		if (!isLoading && !isLoggingIn) {
			setTimeout(() => {
				$appReady(true)
			}, 1000)
		}
	}, [isLoading, isLoggingIn])

	// * ---
	// *	Return: No logged in user
	// * ---
	if (!appReady) return <></>
	if (!isAuth) return <E403 />
	if (debouncedIsLoading) return <Loading />

	// * ---
	// *	Return: Good
	// * ---
	return <>{children}</>
}
