/* eslint-disable @typescript-eslint/no-unused-vars */

// ? ---
// ?	Imports
// ? ---
import { Theme } from '@mui/material'
import { grey } from '@mui/material/colors'
import { defaultsDeep } from 'lodash'

// ? ---
// ?	Exports
// ? ---
export const PLAIN_LINK_STYLE = (theme: Theme) => {
	return {
		color: 'inherit',
		textDecoration: 'none',
		textTransform: 'none',
	}
}

export const LINK_STYLE = (theme: Theme) => {
	return {
		color: theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.primary.light,
		textDecoration: 'none',
		textTransform: 'none',

		'&:hover': {
			textDecoration: 'underline !important',
		},
	}
}

export const IDE_STYLE = (theme: Theme) => {
	return {
		'& fieldset': {
			borderRadius: `6px`,
			p: 0,
			ml: 0,
			mr: 0,
			mb: 0,
			mt: '-3px',
			border: `1px solid ${theme.palette.mode === 'light' ? grey[300] : grey[800]}`,
			backgroundColor: theme.palette.background.default,
		},
		'& fieldset:hover': {
			border: `1px solid ${theme.palette.mode === 'light' ? grey[500] : grey[700]}`,
		},
		'& fieldset:focus-within': {
			border: `1px solid ${theme.palette.mode === 'light' ? grey[500] : grey[700]}`,
		},
		'& legend': {
			p: `2px 3px 3px`,
			ml: `10px`,
			fontSize: `12px`,
			lineHeight: '2px',
			color: theme.palette.text.secondary,
			backgroundColor: `transparent !important`,
		},
		'& .ace_editor': {
			backgroundColor: 'transparent !important',
		},
	}
}

export const TAGIFY_STYLE = (theme: Theme) => {
	return {
		'& fieldset': {
			borderRadius: `6px`,
			p: 0,
			ml: 0,
			mr: 0,
			mb: 0,
			mt: '-3px',
			border: `1px solid ${theme.palette.mode === 'light' ? grey[300] : grey[800]}`,
			backgroundColor: theme.palette.background.default,
		},
		'& fieldset:hover': {
			border: `1px solid ${theme.palette.mode === 'light' ? grey[500] : grey[700]}`,
		},
		'& fieldset:focus-within': {
			border: `1px solid ${theme.palette.mode === 'light' ? grey[500] : grey[700]}`,
		},
		'& legend': {
			p: `2px 3px 3px`,
			ml: `10px`,
			fontSize: `12px`,
			lineHeight: '2px',
			color: theme.palette.text.secondary,
			backgroundColor: `transparent !important`,
		},
		'& .tagify': {
			border: `none`,
			borderRadius: 0,
			padding: 0,
			backgroundColor: `transparent`,
		},
		'& .tagify__input': {
			padding: `8px 13px 11px`,
			lineHeight: 2,
			backgroundColor: `transparent`,
		},
		'& .tagify--focus': {
			border: `none`,
			padding: 0,
		},
		'& .tagify__tag': {
			backgroundColor: `${theme.palette.background.default} !important`,
			color: `${theme.palette.text.primary} !important`,
		},
	}
}

export const TAGIFY_STYLE_WITH_HELPER = (theme: Theme) => {
	return defaultsDeep(
		{
			'& .tagify__input': {
				padding: `8px 0 11px 0`,
			},
		},
		TAGIFY_STYLE(theme)
	)
}
