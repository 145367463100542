// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'

import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Tab, Tabs, useTheme } from '@mui/material'
import Icon from '@mdi/react'
import { filter, get, map, startsWith } from 'lodash'

import { icons } from 'globals/constants/icons'
import { DIALOG_MEDIUM_WIDTH } from 'globals/constants/sizes'

import { Transition } from 'components/_Global/Dialogs/helpers/Transition'
import { ITest } from 'data/tests'

import TestInformationDialogElements from './TestInformationDialogElements'
import TestInformationDialogValues from './TestInformationDialogValues'

// ? ---
// ?	Types
// ? ---
type Props = {
	state: boolean
	data?: ITest
	options?: {
		[key: string]: any
	}
	close: () => void
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Dialogs-TestInformationDialog'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function TestInformationDialog({ close, data, state }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const theme = useTheme()

	// * ---
	// *	State
	// * ---
	const [activeTab, $activeTab] = React.useState('values')

	// * ---
	// *	Return: Good
	// * ---
	return (
		<Dialog
			open={state}
			TransitionComponent={Transition}
			sx={{
				pb: 0,
				'& .MuiDialog-paperFullWidth': {
					maxWidth: DIALOG_MEDIUM_WIDTH,
				},
			}}
			fullWidth={true}
			onClose={() => close()}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			aria-labelledby={namespace}
		>
			<DialogTitle id={namespace} className={'dragHandle'}>
				<Grid container direction='row' justifyContent='space-between' alignItems='center'>
					<Grid item xs>
						Test Information
					</Grid>
					<Grid item>
						<IconButton
							data-test-id='i7yy8vzpUUfpB02MpHb5n'
							aria-label='close'
							onClick={close}
							sx={{
								color: theme.palette.text.secondary,
							}}
						>
							<Icon path={icons.closeIcon} size={1} />
						</IconButton>
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent
				sx={{
					p: 0,
					maxHeight: '85vh',
					position: 'relative',
				}}
			>
				<Tabs
					data-test-id={`${namespace}--tabs`}
					value={activeTab}
					onChange={(event: React.SyntheticEvent, newValue: string) => $activeTab(newValue)}
					indicatorColor='primary'
					textColor='inherit'
					variant='fullWidth'
					aria-label='artifact tabs'
					sx={{ backgroundColor: theme.palette.background.paper }}
				>
					<Tab
						label={'Values'}
						id={`tab-values`}
						key={`tab-values`}
						value={'values'}
						aria-controls={`tabpanel-values`}
						sx={{
							backgroundColor: activeTab === 'values' ? theme.palette.background.default : 'transparent',
						}}
					/>
					<Tab
						label={'Elements'}
						id={`tab-elements`}
						key={`tab-elements`}
						value={'elements'}
						aria-controls={`tabpanel-elements`}
						sx={{
							backgroundColor:
								activeTab === 'elements' ? theme.palette.background.default : 'transparent',
						}}
					/>
					<Tab
						label={'Steps'}
						id={`tab-steps`}
						key={`tab-steps`}
						value={'steps'}
						aria-controls={`tabpanel-steps`}
						sx={{
							backgroundColor: activeTab === 'steps' ? theme.palette.background.default : 'transparent',
						}}
					/>
				</Tabs>
				<Box
					sx={{
						height: '80vh',
						backgroundColor: theme.palette.background.default,
						position: 'relative',
					}}
				>
					{/* VALUES */}
					<div
						role='tabpanel'
						hidden={activeTab !== 'values'}
						key={`tabpanel-values`}
						id={`tabpanel-values`}
						aria-labelledby={`simple-tab-values`}
						style={{ height: '100%' }}
					>
						<TestInformationDialogValues data={data} />
					</div>

					{/* ELEMENTS */}
					<div
						role='tabpanel'
						hidden={activeTab !== 'elements'}
						key={`tabpanel-elements`}
						id={`tabpanel-elements`}
						aria-labelledby={`simple-tab-elements`}
						style={{ height: '100%' }}
					>
						<TestInformationDialogElements data={data} />
					</div>

					{/* STEPS */}
					<div
						role='tabpanel'
						hidden={activeTab !== 'steps'}
						key={`tabpanel-steps`}
						id={`tabpanel-steps`}
						aria-labelledby={`simple-tab-steps`}
						style={{
							minHeight: '100%',
						}}
					>
						<Box
							sx={{
								p: 1,
								color: theme.palette.text.secondary,
								fontFamily: 'monospace',
								fontWeight: 500,
								fontSize: '0.8rem',
							}}
						>
							<Box>{data?.attributes.title}</Box>
							<ol>
								{map(
									filter(
										get(data, 'attributes.stepData', []),
										(step) =>
											!startsWith(step.node.variantType, 'utility_') &&
											!startsWith(step.node.variantType, 'special_')
									),
									(step, index) => (
										<li key={`step-${index}`} style={{ marginBottom: theme.spacing(0.7) }}>
											{step.node.label}
										</li>
									)
								)}
							</ol>
						</Box>
					</div>
				</Box>
			</DialogContent>
		</Dialog>
	)
}
