// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'

import { FormControl, FormControlLabel, FormHelperText, Stack, Switch, Typography, useTheme } from '@mui/material'
import { get } from 'lodash'

import { helperText } from 'components/_Global/Forms/helperHelper'
import { IUseNodeField } from 'data/nodes'

// ? ---
// ?	Types
// ? ---
type Props = {
	formik: any
	field: IUseNodeField
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Dialogs-UpsertFields-BooleanSwitch'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function BooleanSwitch({ field, formik }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const theme = useTheme()
	const helper = helperText({ field, formik })
	const valueTrue = field.valueTrue || true
	const valueFalse = field.valueFalse || false
	const isChecked = get(formik?.values, field.property, field.defaultValue) === valueTrue

	// * ---
	// *	Return
	// * ---
	return (
		<FormControl variant='outlined' fullWidth>
			<Typography component='legend' variant='caption' sx={{ ml: 1, mr: 1, color: theme.palette.text.secondary }}>
				{field.label}
			</Typography>
			<Stack direction='row' justifyContent='flex-start' alignItems='center'>
				{field.labelFalse && (
					<Typography
						sx={{
							display: 'inline-block',
							ml: 1,
							fontSize: theme.typography.body2.fontSize,
							fontWeight: isChecked ? 500 : 600,
							color: isChecked ? theme.palette.text.secondary : theme.palette.text.primary,
						}}
					>
						{field.labelFalse}
					</Typography>
				)}
				<FormControlLabel
					control={
						<Switch
							data-test-id={`${namespace}--${field.property}`}
							id={field.property}
							name={field.property}
							checked={isChecked}
							onChange={(event) =>
								formik.setFieldValue(field.property, event.target.checked ? valueTrue : valueFalse)
							}
						/>
					}
					label={field.labelTrue || 'True'}
					sx={{ ml: 0.5, mr: 0.5 }}
					slotProps={{
						typography: {
							sx: {
								fontSize: theme.typography.body2,
								fontWeight: isChecked ? 600 : 500,
								color: isChecked ? theme.palette.text.primary : theme.palette.text.secondary,
							},
						},
					}}
				/>
			</Stack>
			{helper.active && <FormHelperText error={helper.isError}>{helper.message}</FormHelperText>}
		</FormControl>
	)
}
