// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import { useQuery } from '@apollo/client'
import debug from 'debug'

import { Autocomplete, Box, Button, Grid, Skeleton, TextField } from '@mui/material'
import Icon from '@mdi/react'
import { find, get, isEqual, map, union, unionBy, uniq } from 'lodash'

import { icons } from 'globals/constants/icons'

import useMfas from 'hooks/useMfas'

import { IMfa, QUERY_MFA_SELECT } from 'data/mfas'
import { IUseNodeField } from 'data/nodes'

// ? ---
// ?	Types
// ? ---
type Props = {
	formik: any
	field: IUseNodeField
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Dialogs-UpsertFields-SelectMFA'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function SelectMFA({ field, formik }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const _mfas = useMfas()

	// * ---
	// *	State
	// * ---
	const [combinedMfas, $combinedMfas] = React.useState<IMfa[]>([])

	// * ---
	// *	Data
	// * ---
	const {
		data: mfas,
		loading,
		refetch,
	} = useQuery(QUERY_MFA_SELECT, {
		variables: {
			filters: {},
		},
		fetchPolicy: 'no-cache',
	})

	// * ---
	// *	Effect: Update combinedMfas
	// * ---
	React.useEffect(() => {
		log('..')
		const temp = unionBy(get(mfas, 'mfas.data', []), combinedMfas, 'id')
		if (!isEqual(temp, combinedMfas)) {
			$combinedMfas(temp)
		}
	}, [mfas, combinedMfas])

	// * ---
	// *	Helpers
	// * ---
	const mfaTitleFromId = (mfaId: string) => get(find(combinedMfas, { id: mfaId }), 'attributes.title', '')

	// * ---
	// *	Return
	// * ---
	return (
		<>
			<Grid container direction='row' justifyContent='space-between' alignItems='stretch' spacing={1}>
				<Grid item xs>
					{loading ? (
						<Skeleton sx={{ height: '100%', transform: 'none' }} />
					) : (
						<Autocomplete
							data-test-id={`${namespace}--${field.property}`}
							fullWidth
							loading={loading}
							id={field.property}
							value={
								get(formik?.values, field.property) !== '' ? get(formik?.values, field.property) : null
							}
							onChange={(event, value) => formik.setFieldValue(field.property, value)}
							isOptionEqualToValue={(elementId, value) => {
								// log('isOptionEqualToValue', { elementId, value })
								return elementId === value
							}}
							getOptionLabel={(mfaId: string) => mfaTitleFromId(mfaId)}
							options={uniq(map(combinedMfas, 'id'))}
							renderInput={(params) => (
								<TextField
									{...(params as any)}
									label={field.label}
									error={
										get(formik, `touched[${field.property}]`) &&
										Boolean(get(formik, `errors[${field.property}]`))
									}
									helperText={
										get(formik, `touched[${field.property}]`) &&
										get(formik, `errors[${field.property}]`)
									}
								/>
							)}
							renderOption={(props, mfaId) => (
								<Box component='li' {...(props as any)} key={mfaId}>
									{mfaTitleFromId(mfaId)}
								</Box>
							)}
						/>
					)}
				</Grid>
				<Grid
					item
					xs={3}
					sx={{
						pt: `3px`,
						pb: `3px`,
					}}
				>
					<Button
						data-test-id='Bv3iOaSkMakAidP2R9sUD'
						variant='outlined'
						color='secondary'
						sx={{ height: `55px`, width: '100%' }}
						onClick={() =>
							_mfas.openUpsert(
								{},
								{
									onSuccess: async (mfa) => {
										log('Create new MFA stacked modal callback', mfa)
										$combinedMfas(union(combinedMfas, [mfa]) as IMfa[])
										await formik.setFieldValue(field.property, mfa?.id)
										setTimeout(async () => {
											await refetch()
										}, 1000)
									},
								}
							)
						}
					>
						<Icon path={icons.addIcon} size={1} />
					</Button>
				</Grid>
			</Grid>
		</>
	)
}
