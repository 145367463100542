// ? ---
// ?	Imports
// ? ---
import { get } from 'lodash'

import { IUseNodeField } from 'data/nodes'

// ? ---
// ?	Types
// ? ---
type Props = {
	formik: any
	field: IUseNodeField
}

// ? ---
// ?	Export
// ? ---
export const helperText = ({ field, formik }: Props) => {
	// ? Touched & Error
	if (field?.property && get(formik, `touched.${field.property}`) && get(formik, `errors.${field.property}`)) {
		return {
			active: true,
			isError: true,
			message: get(formik, `errors.${field.property}`),
		}
	}
	// ? Helper Text
	if (get(field, `helper`)) {
		return {
			active: true,
			isError: false,
			message: field?.helper,
		}
	}
	return {
		active: false,
		isError: false,
		message: field?.helper,
	}
}
