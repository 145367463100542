// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import * as yup from 'yup'

import { compact } from 'lodash'

import { FAILED_STATUS_OPTIONS } from 'globals/constants/selectOptions'
import { ELEMENT_VALIDATION } from 'globals/constants/validations'
import { ACTION_INPUT_GROUP } from 'globals/constants/variantGroups'

import { formatElement, formatValue, sentenceElement, sentenceValue } from 'hooks/useNode'

import { INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'
import { ITestStepStatus } from 'data/tests'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.action_input_setValue,
	isLive: true,
	isMenuOption: true,
	isPro: false,
	isAccessibility: true,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.variantType',
			type: 'variantType',
			label: 'Type',
			options: ACTION_INPUT_GROUP,
		},
		{
			property: 'attributes.element',
			type: 'element',
			label: 'Element',
			validation: ELEMENT_VALIDATION,
		},
		{
			property: 'attributes.value',
			type: 'text-with-tags',
			label: 'Value',
			validation: yup.string().trim().required('Value is required'),
		},
		{
			property: 'attributes.config.failedStatus',
			type: 'button-group',
			label: 'Status on fail',
			defaultValue: ITestStepStatus.failed,
			options: FAILED_STATUS_OPTIONS,
		},
	],
	label: 'Set value',
	sentence: ({ element, isExample, value }: INodeRenderProps) => {
		return isExample ? `Set input` : `Set input ${sentenceElement(element)} to ${sentenceValue(value)}`
	},
	family: INodeVariantTypes.action_input_setValue.split('_')[0],
	type: INodeVariantTypes.action_input_setValue.split('_')[1],
	subType: INodeVariantTypes.action_input_setValue.split('_')[2],
	tags: compact([
		INodeVariantTypes.action_input_setValue.split('_')[0],
		INodeVariantTypes.action_input_setValue.split('_')[1],
		INodeVariantTypes.action_input_setValue.split('_')[2],
		'fill',
		'type',
		'keyboard',
		'text',
		'keys',
		'set',
		'value',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ element, isExample, value }: INodeRenderProps) => {
			return (
				<>
					{isExample ? (
						<>Set input</>
					) : (
						<>
							Set <strong>{formatElement(element)}</strong> to <strong>{formatValue(value)}</strong>
						</>
					)}
				</>
			)
		},
	}
}
