// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import * as yup from 'yup'

import { compact } from 'lodash'

import { STORAGE_NAME_VALIDATION } from 'globals/constants/validations'
import { DATA_PROCESS_GROUP } from 'globals/constants/variantGroups'

import { formatValue, sentenceValue } from 'hooks/useNode'

import { INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.data_process_calculate,
	isLive: true,
	isMenuOption: true,
	isPro: false,
	isAccessibility: false,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.variantType',
			type: 'variantType',
			label: 'Type',
			options: DATA_PROCESS_GROUP,
		},
		{
			property: 'attributes.value',
			type: 'text-with-tags',
			label: 'Input Value',
			validation: yup.string().trim().required('Input Value is required'),
		},
		{
			property: 'attributes.value3',
			type: 'calculator-operation',
			label: 'Operation',
			asStringValue: true,
		},
		{
			property: 'attributes.value2',
			type: 'text',
			label: 'Output Storage Name',
			validation: STORAGE_NAME_VALIDATION,
			isValueStoreKey: true,
		},
	],
	label: 'Calculate value',
	sentence: ({ isExample, value2: outputValue }: INodeRenderProps) => {
		return isExample ? `Calculate value` : `Calculate ${sentenceValue(outputValue)}`
	},
	family: INodeVariantTypes.data_process_calculate.split('_')[0],
	type: INodeVariantTypes.data_process_calculate.split('_')[1],
	subType: INodeVariantTypes.data_process_calculate.split('_')[2],
	tags: compact([
		INodeVariantTypes.data_process_calculate.split('_')[0],
		INodeVariantTypes.data_process_calculate.split('_')[1],
		INodeVariantTypes.data_process_calculate.split('_')[2],
		'maths',
		'sum',
		'round',
		'floor',
		'ceil',
		'add',
		'subtract',
		'multiply',
		'divide',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ isExample, value2: outputValue }: INodeRenderProps) => {
			return (
				<>
					{isExample ? (
						<>Calculate value</>
					) : (
						<>
							Calculate <strong>{formatValue(outputValue)}</strong>
						</>
					)}
				</>
			)
		},
	}
}
