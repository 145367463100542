// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import * as yup from 'yup'

import { compact, isEmpty, isString } from 'lodash'

import { API_STATUS_OPTIONS } from 'globals/constants/selectOptions'
import { STORAGE_NAME_VALIDATION } from 'globals/constants/validations'
import { VALUE_REGEX } from 'globals/constants/valueStore'

import { formatValue, sentenceValue } from 'hooks/useNode'

import { INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.integration_api_delete,
	isLive: true,
	isMenuOption: true,
	isPro: false,
	isAccessibility: false,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.variantType',
			type: 'variantType',
			label: 'Method',
			options: [
				INodeVariantTypes.integration_api_get,
				INodeVariantTypes.integration_api_put,
				INodeVariantTypes.integration_api_post,
				INodeVariantTypes.integration_api_delete,
			],
		},
		{
			property: 'attributes.value',
			type: 'text-with-tags',
			defaultValue: 'https://',
			label: 'URL',
			validation: yup.string().test('is-valid-url', 'Must be a fully qualified URL', (value: any) => {
				if (isString(value) && !isEmpty(value)) {
					return value.match(VALUE_REGEX)
						? true
						: /^((https?|ftp):)?\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(
								value
						  )
				}
				return false
			}),
		},
		{
			property: 'attributes.value2',
			type: 'json',
			asStringValue: true,
			label: 'Headers',
			helper: `Values can be used inside strings using the format: "{{runId}}".`,
		},
		{
			property: 'attributes.value4',
			type: 'multiSelect',
			asStringValue: true,
			defaultValue: '|2xx|',
			label: 'Expected Response Status Codes',
			options: API_STATUS_OPTIONS,
			helper: `5xx codes are considered errors.`,
		},
		{
			property: 'attributes.value5',
			type: 'text',
			label: 'Response Storage Name',
			validation: STORAGE_NAME_VALIDATION,
			isValueStoreKey: true,
		},
	],
	label: 'DELETE',
	sentence: ({ isExample, value2 }: INodeRenderProps) => {
		return isExample ? `API DELETE` : `API DELETE: ${sentenceValue(value2)}`
	},
	family: INodeVariantTypes.integration_api_delete.split('_')[0],
	type: INodeVariantTypes.integration_api_delete.split('_')[1],
	subType: INodeVariantTypes.integration_api_delete.split('_')[2],
	tags: compact([
		INodeVariantTypes.integration_api_delete.split('_')[0],
		INodeVariantTypes.integration_api_delete.split('_')[1],
		INodeVariantTypes.integration_api_delete.split('_')[2],
		'rest',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ isExample, value }: INodeRenderProps) => {
			return (
				<>
					DELETE
					{!isExample && (
						<>
							{': '}
							<strong>{formatValue(value)}</strong>
						</>
					)}
				</>
			)
		},
	}
}
