// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import * as yup from 'yup'

import { compact, includes, map } from 'lodash'

import { STORAGE_NAME_VALIDATION } from 'globals/constants/validations'
import { DATA_PROCESS_GROUP } from 'globals/constants/variantGroups'

import { formatValue, sentenceValue } from 'hooks/useNode'

import { IAccountValueTypeOptions } from 'data/accounts'
import { INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.data_store_set,
	isLive: true,
	isMenuOption: true,
	isPro: false,
	isAccessibility: false,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.variantType',
			type: 'variantType',
			label: 'Type',
			options: DATA_PROCESS_GROUP,
		},
		{
			property: 'attributes.value2',
			type: 'text',
			label: 'Storage Name',
			validation: STORAGE_NAME_VALIDATION,
			isValueStoreKey: true,
		},
		{
			property: '',
			type: 'divider',
			label: 'Value',
		},
		{
			property: 'attributes.value3',
			type: 'dropdown',
			label: 'Type',
			defaultValue: 'string',
			options: map(IAccountValueTypeOptions, (label, value) => {
				return {
					label,
					value,
				}
			}),
		},
		{
			property: 'attributes.value',
			type: 'text-with-tags',
			label: 'Value',
			conditional: ({ values }: { values: { [key: string]: any } }) => {
				return includes([null, 'string'], values.attributes.value3)
			},
			validation: yup.mixed().when('value3', {
				is: 'string',
				then: (schema) => schema.required('Value is required'),
				otherwise: (schema) => schema.notRequired(),
			}),
		},
		{
			property: 'attributes.value4',
			type: 'json',
			label: 'Value',
			asStringValue: true,
			conditional: ({ values }: { values: { [key: string]: any } }) => {
				return includes(['json'], values.attributes.value3)
			},
			validation: yup.mixed().when('value3', {
				is: 'json',
				then: (schema) =>
					schema.test('test-json', 'Value must be valid json', (value) => {
						try {
							JSON.parse(JSON.stringify(value))
							return true
						} catch (err) {
							return false
						}
					}),
				otherwise: (schema) => schema.notRequired(),
			}),
		},
		{
			property: 'attributes.value5',
			type: 'switch',
			label: 'Value',
			asStringValue: true,
			defaultValue: 'false',
			valueTrue: 'true',
			valueFalse: 'false',
			conditional: ({ values }: { values: { [key: string]: any } }) => {
				return includes(['boolean'], values.attributes.value3)
			},
		},
		{
			property: 'attributes.value6',
			type: 'text',
			label: 'Value',
			conditional: ({ values }: { values: { [key: string]: any } }) => {
				return includes(['number'], values.attributes.value3)
			},
			validation: yup.mixed().when('value3', {
				is: 'number',
				then: (schema) => schema.required('Value is required'),
				otherwise: (schema) => schema.notRequired(),
			}),
		},
	],
	label: 'Set value',
	sentence: ({ isExample, value, value2: storageName, value3, value5, value6 }: INodeRenderProps) => {
		if (isExample) return `Store value`
		switch (value3) {
			case 'json':
				return `Set ${sentenceValue(storageName)} to JSON value`
			case 'number':
				return `Set ${sentenceValue(storageName)} to ${sentenceValue(value6)}`
			case 'boolean':
				return `Set ${sentenceValue(storageName)} to ${sentenceValue(value5)}`
			case 'string':
			default:
				return `Set ${sentenceValue(storageName)} to ${sentenceValue(value)}`
		}
	},
	family: INodeVariantTypes.data_store_set.split('_')[0],
	type: INodeVariantTypes.data_store_set.split('_')[1],
	subType: INodeVariantTypes.data_store_set.split('_')[2],
	tags: compact([
		INodeVariantTypes.data_store_set.split('_')[0],
		INodeVariantTypes.data_store_set.split('_')[1],
		INodeVariantTypes.data_store_set.split('_')[2],
		'rename',
		'value',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ isExample, value, value2: storageName, value3, value5, value6 }: INodeRenderProps) => {
			return (
				<>
					{isExample ? (
						<>Set value</>
					) : (
						<>
							{value3 === 'json' ? (
								<>
									Set <strong>{formatValue(storageName)}</strong> to <strong>JSON</strong>
								</>
							) : value3 === 'number' ? (
								<>
									Set <strong>{formatValue(storageName)}</strong> to{' '}
									<strong>{formatValue(value6)}</strong>
								</>
							) : value3 === 'boolean' ? (
								<>
									Set <strong>{formatValue(storageName)}</strong> to{' '}
									<strong>{formatValue(value5)}</strong>
								</>
							) : (
								<>
									Set <strong>{formatValue(storageName)}</strong> to{' '}
									<strong>{formatValue(value)}</strong>
								</>
							)}
						</>
					)}
				</>
			)
		},
	}
}
