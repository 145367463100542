import {
	mdiAccount as userIcon,
	mdiAccountCircle as userSettingsIcons,
	mdiAccountMultiple as usersIcon,
	mdiAlert as warningIcon,
	mdiAlertCircle as errorIcon,
	mdiAlignHorizontalLeft as alignLeftIcon,
	mdiAlignHorizontalRight as alignRightIcon,
	mdiAlignVerticalBottom as alignBottomIcon,
	mdiAlignVerticalTop as alignTopIcon,
	mdiAlphaDBoxOutline as keyDIcon,
	mdiAlphaGBoxOutline as keyGIcon,
	mdiAlphaUBoxOutline as keyUIcon,
	mdiApi as apiCallIcon,
	mdiApi as apiIcon,
	mdiApplicationArrayOutline as iframeIcon,
	mdiApplicationCog as openAdvancedIcon,
	mdiApplicationExport as goToIcon,
	mdiApplicationOutline as check_browserTitleIcon,
	mdiApplicationOutline as openIcon,
	mdiArrowDown as ascIcon,
	mdiArrowLeft as action_goTo_backIcon,
	mdiArrowRight as action_goTo_forwardIcon,
	mdiArrowUp as descIcon,
	mdiBackspace as action_input_clearValueIcon,
	mdiBackspaceOutline as keyBackspaceIcon,
	mdiBookOpenVariant as docsIcon,
	mdiBorderAllVariant as existIcon,
	mdiBorderNoneVariant as notExistIcon,
	mdiBrush as styleIcon,
	mdiCalculator as calculatorIcon,
	mdiCalendarClock as scheduleIcon,
	mdiCardMultiple as elementIcon,
	mdiCardTextOutline as selectByTextIcon,
	mdiCellphoneArrowDownVariant as scrollIcon,
	mdiChartBox as dashboardIcon,
	mdiChartGantt as roadmapIcon,
	mdiCheck as agreeIcon,
	mdiCheckboxBlankCircle as resultsIcon,
	mdiCheckboxBlankCircleOutline as mixedResultsIcon,
	mdiCheckboxBlankCircleOutline as uncheckedIcon,
	mdiCheckboxMarkedCircle as approvalIcon,
	mdiCheckboxMultipleBlank as copyIcon,
	mdiCheckCircle as checkedIcon,
	mdiCheckCircle as passedIcon,
	mdiCheckCircleOutline as passed_with_warningIcon,
	mdiChevronDown as downIcon,
	mdiChevronLeft as leftIcon,
	mdiChevronRight as rightIcon,
	mdiChevronUp as upIcon,
	mdiCircleSlice1 as progress1Icon,
	mdiCircleSlice2 as progress2Icon,
	mdiCircleSlice3 as progress3Icon,
	mdiCircleSlice4 as progress4Icon,
	mdiCircleSlice5 as progress5Icon,
	mdiCircleSlice6 as progress6Icon,
	mdiCircleSlice7 as progress7Icon,
	mdiCircleSlice8 as progress8Icon,
	mdiClockOutline as clockIcon,
	mdiClockPlusOutline as scheduleAddIcon,
	mdiClose as closeIcon,
	mdiCloudArrowUp as saveIcon,
	mdiCodeBlockTags as tinyMceIcon,
	mdiCodeTags as attributeIcon,
	mdiCog as accountSettingsIcon,
	mdiCommentOutline as commentIcon,
	mdiCompare as change_element_snapshotIcon,
	mdiConnection as integrationIcon,
	mdiCookie as cookieIcon,
	mdiCounter as elementValueIcon,
	mdiCreditCardOutline as adyenIcon,
	mdiCurrencyUsd as valueStoreIcon,
	mdiCursorMove as action_dragDropIcon,
	mdiDatabase as storageIcon,
	mdiDatabaseEdit as data_store_setIcon,
	mdiDatabaseOutline as sessionStorageIcon,
	mdiDelete as removeIcon,
	mdiDeleteCircle as removeAltIcon,
	mdiDesktopClassic as computedIcon,
	mdiDotsVertical as submenuIcon,
	mdiDrag as dragIcon,
	mdiEmail as emailIcon,
	mdiEmail as mailIcon,
	mdiEmailArrowLeft as mail_message_selectIcon,
	mdiEmailNewsletter as checkEmailIcon,
	mdiEmailNewsletter as mail_message_openIcon,
	mdiEmailRemove as mail_inbox_closeIcon,
	mdiEmailSync as mail_wait_forMessageIcon,
	mdiEmoticonHappyOutline as smileIcon,
	mdiEye as check_is_visibleIcon,
	mdiEyeOff as check_is_notVisibleIcon,
	mdiEyeOffOutline as notDisplayedIcon,
	mdiEyeOutline as displayedIcon,
	mdiFastForward as skippedIcon,
	mdiFilePlus as runner_generate_fileIcon,
	mdiFileUpload as action_input_fileIcon,
	mdiFilter as filterIcon,
	mdiFindReplace as replaceIcon,
	mdiFirefox as firefoxIcon,
	mdiFlaskOutline as recipeIcon,
	mdiFormDropdown as action_input_selectOptionIcon,
	mdiFormTextbox as enabledIcon,
	mdiFormTextbox as inputIcon,
	mdiFormTextboxLock as disabledIcon,
	mdiGestureDoubleTap as action_touch_doubleIcon,
	mdiGestureTap as touchIcon,
	mdiGestureTapButton as clickableIcon,
	mdiGithub as githubIcon,
	mdiGoogleChrome as chromeIcon,
	mdiGoogleMaps as googleMapsIcon,
	mdiHandshake as supportIcon,
	mdiHistory as runsIcon,
	mdiHome as homeIcon,
	mdiHuman as accessibilityIcon,
	mdiImageFilterCenterFocusStrong as focusedIcon,
	mdiImageFilterCenterFocusWeak as notFocusedIcon,
	mdiImagePlus as runner_generate_imageIcon,
	mdiInformation as infoAltIcon,
	mdiInformationSlabCircleOutline as infoIcon,
	mdiKeyboard as keyboardIcon,
	mdiLighthouseOn as lighthouseIcon,
	mdiLink as check_browserAddressIcon,
	mdiLinkBoxVariantOutline as check_links_validIcon,
	mdiLoading as runningIcon,
	mdiLock as passwordIcon,
	mdiLogoutVariant as logoutIcon,
	mdiMenu as menuIcon,
	mdiMicrosoftEdge as edgeIcon,
	mdiMinusCircle as minusAltIcon,
	mdiMotionPause as pauseTimeIcon,
	mdiOpenInNew as openInNewIcon,
	mdiPageNextOutline as eventIcon,
	mdiPencil as editIcon,
	mdiPlay as startTestIcon,
	mdiPlayBoxMultiple as triggerIcon,
	mdiPlayCircle as startPackIcon,
	mdiPlayOutline as previewTestIcon,
	mdiPlus as addIcon,
	mdiPlusCircle as addAltIcon,
	mdiPrinter as printIcon,
	mdiPuzzle as nodeAltIcon,
	mdiPuzzleOutline as nodeIcon,
	mdiRayStartArrow as data_store_aliasIcon,
	mdiRefresh as reloadIcon,
	mdiReload as action_goTo_reloadIcon,
	mdiRename as utility_name_testIcon,
	mdiReplay as rerunTestIcon,
	mdiRocketLaunch as launchIcon,
	mdiSelectMultipleMarker as change_element_positionIcon,
	mdiServer as serverIcon,
	mdiServerNetwork as sftpIcon,
	mdiShieldKey as mfaIcon,
	mdiShimmer as special_groupIcon,
	mdiSitemap as flowIcon,
	mdiSlack as slackIcon,
	mdiSquareRoundedBadgeOutline as notificationsIcon,
	mdiSwapVertical as changePlanIcon,
	mdiTabletCellphone as deviceIcon,
	mdiTallyMark5 as check_countIcon,
	mdiTestTube as testIcon,
	mdiTextRecognition as elementTextIcon,
	mdiThumbDown as rejectedIcon,
	mdiTimer as timerIcon,
	mdiTrafficLight as runner_stopIcon,
	mdiTrayFull as flowFullIcon,
	mdiVideo as videoIcon,
	mdiWebhook as webhookIcon,
	mdiWindowMaximize as runner_headerIcon,
	mdiWindowRestore as action_switch_windowIcon,
	mdiXml as xmlIcon,
} from '@mdi/js'

export const icons: { [key: string]: string } = {
	acceptedIcon: runningIcon,
	accessibilityIcon,
	accountSettingsIcon,
	action_dragDropIcon,
	action_goTo_absoluteIcon: goToIcon,
	action_goTo_backIcon,
	action_goTo_forwardIcon,
	action_goTo_relativeIcon: goToIcon,
	action_goTo_reloadIcon,
	action_input_clearValueIcon,
	action_input_fileIcon,
	action_input_selectOptionIcon,
	action_input_sendKeysIcon: keyboardIcon,
	action_input_setMFAIcon: mfaIcon,
	action_input_setTinyMceIcon: tinyMceIcon,
	action_input_setValueIcon: keyboardIcon,
	action_scroll_intoViewIcon: scrollIcon,
	action_scroll_pageIcon: scrollIcon,
	action_switch_frameIcon: iframeIcon,
	action_switch_parentFrameIcon: iframeIcon,
	action_switch_windowIcon,
	action_touch_doubleIcon,
	action_touch_hoverIcon: touchIcon,
	action_touch_singleIcon: touchIcon,
	action_wait_forElementClickableIcon: timerIcon,
	action_wait_forElementDisabledIcon: timerIcon,
	action_wait_forElementEnabledIcon: timerIcon,
	action_wait_forElementExistIcon: timerIcon,
	action_wait_forElementIcon: timerIcon,
	action_wait_forElementNotClickableIcon: timerIcon,
	action_wait_forElementNotDisplayedIcon: timerIcon,
	action_wait_forElementNotExistIcon: timerIcon,
	action_wait_forElementNotVisibleIcon: timerIcon,
	action_wait_forElementVisibleIcon: timerIcon,
	action_wait_forSecondsIcon: pauseTimeIcon,
	addAltIcon,
	addIcon,
	agreeIcon,
	alignBottomIcon,
	alignLeftIcon,
	alignRightIcon,
	alignTopIcon,
	apiCallIcon,
	approvalIcon,
	ascIcon,
	change_element_positionIcon,
	change_element_snapshotIcon,
	changePlanIcon,
	check_attributeIcon: attributeIcon,
	check_browserAddressIcon,
	check_browserTitleIcon,
	check_computed_labelIcon: computedIcon,
	check_computed_roleIcon: computedIcon,
	check_computed_styleIcon: styleIcon,
	check_cookie_existsIcon: cookieIcon,
	check_cookie_valueIcon: cookieIcon,
	check_countIcon,
	check_input_tinyMceIcon: tinyMceIcon,
	check_is_clickableIcon: clickableIcon,
	check_is_disabledIcon: disabledIcon,
	check_is_displayedIcon: displayedIcon,
	check_is_enabledIcon: enabledIcon,
	check_is_existIcon: existIcon,
	check_is_focusedIcon: focusedIcon,
	check_is_notClickableIcon: clickableIcon,
	check_is_notDisplayedIcon: notDisplayedIcon,
	check_is_notExistIcon: notExistIcon,
	check_is_notFocusedIcon: notFocusedIcon,
	check_is_notVisibleIcon,
	check_is_visibleIcon,
	check_links_validIcon,
	check_localStorage_existsIcon: storageIcon,
	check_localStorage_valueIcon: storageIcon,
	check_sessionStorage_existsIcon: sessionStorageIcon,
	check_sessionStorage_valueIcon: sessionStorageIcon,
	check_sftp_fileCountIcon: sftpIcon,
	check_sftp_fileExistIcon: sftpIcon,
	check_sftp_fileNotExistIcon: sftpIcon,
	check_textIcon: elementTextIcon,
	check_valueIcon: elementValueIcon,
	check_valueStoreIcon: valueStoreIcon,
	checkedIcon,
	checkEmailIcon,
	chromeIcon,
	clearIcon: closeIcon,
	closeIcon,
	collapseCloseIcon: upIcon,
	collapseOpenIcon: downIcon,
	comingSoonIcon: scheduleIcon,
	completedIcon: passedIcon,
	continuedIcon: skippedIcon,
	copyIcon,
	dashboardIcon,
	data_cookie_deleteAllIcon: cookieIcon,
	data_cookie_deleteIcon: cookieIcon,
	data_cookie_setIcon: cookieIcon,
	data_localStorage_deleteAllIcon: storageIcon,
	data_localStorage_deleteIcon: storageIcon,
	data_localStorage_setIcon: storageIcon,
	data_process_calculateIcon: calculatorIcon,
	data_process_replaceIcon: replaceIcon,
	data_sessionStorage_deleteAllIcon: sessionStorageIcon,
	data_sessionStorage_deleteIcon: sessionStorageIcon,
	data_sessionStorage_setIcon: sessionStorageIcon,
	data_store_aliasIcon,
	data_store_elementAttributeIcon: attributeIcon,
	data_store_elementCountIcon: check_countIcon,
	data_store_elementHTMLIcon: xmlIcon,
	data_store_elementTextIcon: elementTextIcon,
	data_store_elementValueIcon: elementValueIcon,
	data_store_setIcon,
	descIcon,
	docsIcon,
	downIcon,
	dragIcon,
	edgeIcon,
	editIcon,
	elementIcon,
	emailIcon,
	emulated_deviceIcon: deviceIcon,
	errorIcon,
	eventIcon,
	express_adyen_afterpayIcon: adyenIcon,
	express_adyen_cardIcon: adyenIcon,
	express_adyen_googlepayIcon: adyenIcon,
	express_adyen_klarnaIcon: adyenIcon,
	express_adyen_paypalIcon: adyenIcon,
	express_doesqa_runIcon: startPackIcon,
	failedIcon: errorIcon,
	filterIcon,
	firefoxIcon,
	flowFullIcon,
	flowIcon,
	githubIcon,
	helpIcon: docsIcon,
	homeIcon,
	ignoredIcon: skippedIcon,
	infoAltIcon,
	infoIcon,
	inputIcon,
	integration_api_deleteIcon: apiIcon,
	integration_api_getIcon: apiIcon,
	integration_api_postIcon: apiIcon,
	integration_api_putIcon: apiIcon,
	integration_googleMaps_panIcon: googleMapsIcon,
	integration_googleMaps_positionIcon: googleMapsIcon,
	integration_googleMaps_zoomIcon: googleMapsIcon,
	integration_sftp_listIcon: sftpIcon,
	integration_sftp_uploadIcon: sftpIcon,
	integration_slack_sendIcon: slackIcon,
	integrationIcon,
	keyBackspaceIcon,
	keyDIcon,
	keyGIcon,
	keyUIcon,
	launchIcon,
	leftIcon,
	lighthouseIcon,
	loadingIcon: runningIcon,
	logoutIcon,
	mail_inbox_closeIcon,
	mail_inbox_openIcon: mailIcon,
	mail_message_openIcon,
	mail_message_selectIcon,
	mail_wait_forMessageIcon,
	mailIcon,
	menuIcon,
	mfaIcon,
	minusAltIcon,
	mixedResultsIcon,
	nodeAltIcon,
	nodeIcon,
	notificationsIcon,
	openInNewIcon,
	passed_with_warningIcon,
	passedIcon,
	passwordIcon,
	pendingIcon: clockIcon,
	previewIcon: clockIcon,
	previewTestIcon,
	printIcon,
	progress1Icon,
	progress2Icon,
	progress3Icon,
	progress4Icon,
	progress5Icon,
	progress6Icon,
	progress7Icon,
	progress8Icon,
	queuedIcon: clockIcon,
	recipeIcon,
	rejectedIcon,
	reloadIcon,
	removeAltIcon,
	removeIcon,
	rerunTestIcon,
	resultsIcon,
	rightIcon,
	roadmapIcon,
	runner_generate_fileIcon,
	runner_generate_imageIcon,
	runner_header_addIcon: runner_headerIcon,
	runner_header_removeAllIcon: runner_headerIcon,
	runner_header_setIcon: runner_headerIcon,
	runner_stop_elementDisplayedIcon: runner_stopIcon,
	runner_stop_elementNotDisplayedIcon: runner_stopIcon,
	runner_stop_valueIcon: runner_stopIcon,
	runner_wait_forElementTextIcon: timerIcon,
	runningIcon,
	runsIcon,
	saveIcon,
	savingIcon: saveIcon,
	scheduleAddIcon,
	scheduleIcon,
	selectByTextIcon,
	serverIcon,
	skippedIcon: skippedIcon,
	slackIcon,
	smileIcon,
	special_groupIcon,
	starter_open_advancedIcon: openAdvancedIcon,
	starter_openIcon: openIcon,
	startPackIcon,
	startTestIcon,
	stoppedIcon: runner_stopIcon,
	submenuIcon,
	successIcon: passedIcon,
	supportIcon,
	testIcon,
	triggerIcon,
	unauthenticatedIcon: passwordIcon,
	uncheckedIcon,
	upIcon,
	userIcon,
	userProfileIcon: userIcon,
	userSettingsIcons,
	usersIcon,
	utility_comment_flowIcon: commentIcon,
	utility_comment_testIcon: commentIcon,
	utility_name_testIcon,
	validate_lighthouse_checkAccessibilityIcon: lighthouseIcon,
	validate_lighthouse_checkPerformanceIcon: lighthouseIcon,
	validate_lighthouse_checkPracticesIcon: lighthouseIcon,
	validate_lighthouse_checkPwaIcon: lighthouseIcon,
	validate_lighthouse_checkSeoIcon: lighthouseIcon,
	validate_pa11yIcon: accessibilityIcon,
	valueIcon: valueStoreIcon,
	valueStoreIcon,
	videoIcon,
	warningIcon,
	webhookIcon,
	xmlIcon,
}
