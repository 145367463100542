import { INodeVariantTypes } from 'data/nodes'

export const STARTER_OPEN_GROUP = [INodeVariantTypes.starter_open, INodeVariantTypes.starter_open_advanced]
export const CHECK_IS_GROUP = [
	INodeVariantTypes.check_is_displayed,
	INodeVariantTypes.check_is_notDisplayed,
	INodeVariantTypes.check_is_visible,
	INodeVariantTypes.check_is_notVisible,
	INodeVariantTypes.check_is_enabled,
	INodeVariantTypes.check_is_disabled,
	INodeVariantTypes.check_is_clickable,
	INodeVariantTypes.check_is_notClickable,
	INodeVariantTypes.check_is_exist,
	INodeVariantTypes.check_is_notExist,
	INodeVariantTypes.check_is_focused,
	INodeVariantTypes.check_is_notFocused,
]

export const CHECK_COMPUTED_GROUP = [
	INodeVariantTypes.check_computed_label,
	INodeVariantTypes.check_computed_role,
	INodeVariantTypes.check_computed_style,
]

export const RUNNER_WAIT_GROUP = [
	INodeVariantTypes.action_wait_forElement,
	INodeVariantTypes.action_wait_forElementNotDisplayed,
	INodeVariantTypes.action_wait_forElementVisible,
	INodeVariantTypes.action_wait_forElementNotVisible,
	INodeVariantTypes.action_wait_forElementEnabled,
	INodeVariantTypes.action_wait_forElementDisabled,
	INodeVariantTypes.action_wait_forElementExist,
	INodeVariantTypes.action_wait_forElementNotExist,
	INodeVariantTypes.action_wait_forElementClickable,
	INodeVariantTypes.action_wait_forElementNotClickable,
	INodeVariantTypes.runner_wait_forElementText,
	INodeVariantTypes.action_wait_forSeconds,
]

export const ACTION_INPUT_GROUP = [
	INodeVariantTypes.action_input_sendKeys,
	INodeVariantTypes.action_input_setValue,
	INodeVariantTypes.action_input_clearValue,
]

export const ACTION_SCROLL_GROUP = [INodeVariantTypes.action_scroll_intoView, INodeVariantTypes.action_scroll_page]

export const INTEGRATION_GOOGLE_MAPS_GROUP = [
	INodeVariantTypes.integration_googleMaps_position,
	INodeVariantTypes.integration_googleMaps_pan,
	INodeVariantTypes.integration_googleMaps_zoom,
]

export const ACTION_GOTO_GROUP = [INodeVariantTypes.action_goTo_absolute, INodeVariantTypes.action_goTo_relative]

export const BROWSER_BASIC_GROUP = [
	INodeVariantTypes.action_goTo_reload,
	INodeVariantTypes.action_goTo_back,
	INodeVariantTypes.action_goTo_forward,
]

export const DATA_STORE_GROUP = [
	INodeVariantTypes.data_store_elementText,
	INodeVariantTypes.data_store_elementHTML,
	INodeVariantTypes.data_store_elementValue,
	INodeVariantTypes.data_store_elementCount,
	INodeVariantTypes.data_store_elementAttribute,
]

export const DATA_PROCESS_GROUP = [
	INodeVariantTypes.data_store_alias,
	INodeVariantTypes.data_store_set,
	INodeVariantTypes.data_process_calculate,
	INodeVariantTypes.data_process_replace,
]

export const RUNNER_HEADER_GROUP = [
	INodeVariantTypes.runner_header_add,
	INodeVariantTypes.runner_header_set,
	INodeVariantTypes.runner_header_removeAll,
]

export const RUNNER_STOP_GROUP = [
	INodeVariantTypes.runner_stop_elementDisplayed,
	INodeVariantTypes.runner_stop_elementNotDisplayed,
	INodeVariantTypes.runner_stop_value,
]

export const ADYEN_GROUP = [
	INodeVariantTypes.express_adyen_card,
	INodeVariantTypes.express_adyen_paypal,
	INodeVariantTypes.express_adyen_googlepay,
	INodeVariantTypes.express_adyen_klarna,
	INodeVariantTypes.express_adyen_afterpay,
]

export const MAIL_MESSAGE_GROUP = [INodeVariantTypes.mail_message_select, INodeVariantTypes.mail_message_open]
