// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import Color from 'color'
import debug from 'debug'
import { useAnimation } from 'framer-motion'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab } from '@mui/material'
import { amber, red } from '@mui/material/colors'
import Icon from '@mdi/react'

import { icons } from 'globals/constants/icons'
import { DIALOG_SMALL_WIDTH } from 'globals/constants/sizes'

import useThemeMode from 'hooks/useThemeMode'

import ShakeablePaperSmall from 'components/_Global/Dialogs/helpers/ShakeablePaperSmall'
import { Transition } from 'components/_Global/Dialogs/helpers/Transition'

// ? ---
// ?	Types
// ? ---
type Props = {
	state: boolean
	data?: {
		title?: string
		body?: string
		related?: string[]
	}
	options?: {
		[key: string]: any
	}
	close: () => void
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Dialogs-DeleteConfirmationDialog'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function DeleteConfirmationDialog({ close, data, options, state }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const _theme = useThemeMode()
	const controls = useAnimation()

	const isDangerous = data && data.related && data.related.length > 0

	// * ---
	// *	Return: Good
	// * ---

	return (
		<Dialog
			open={state}
			TransitionComponent={Transition}
			sx={{
				'& .MuiDialog-paperFullWidth': {
					maxWidth: DIALOG_SMALL_WIDTH,
				},
			}}
			fullWidth={true}
			onClose={() => {
				close()
				if (options?.onComplete) options.onComplete()
			}}
			PaperComponent={ShakeablePaperSmall}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			PaperProps={{ controls }}
			aria-labelledby={namespace}
		>
			<DialogTitle
				id={namespace}
				className={'dragHandle'}
				sx={{
					backgroundColor: isDangerous
						? _theme.conditional(red[100], Color(red[800]).alpha(0.2).string())
						: _theme.conditional(amber[100], Color(amber[800]).alpha(0.2).string()),
				}}
			>
				{data?.title ? data.title : 'Are you sure?'}
			</DialogTitle>
			{(data?.body || isDangerous) && (
				<DialogContent
					sx={{
						borderTop: 'none',
						borderColor: 'none !important',
						backgroundColor: isDangerous
							? _theme.conditional(red[100], Color(red[800]).alpha(0.2).string())
							: _theme.conditional(amber[100], Color(amber[800]).alpha(0.2).string()),
						paddingTop: `0 !important`,
						fontWeight: 500,
					}}
				>
					{data?.body || 'This action can not be reverted.'}
					{/*<pre>{JSON.stringify(data?.related, null, 2)}</pre>*/}
				</DialogContent>
			)}
			<DialogActions>
				<Button
					data-test-id='upx2a77BI9MxM7j3xtZCx'
					color={'secondary'}
					onClick={() => {
						close()
						if (options?.onComplete) options.onComplete()
					}}
				>
					Cancel
				</Button>
				<Fab
					data-test-id='SDdwIZJYBNpntAZYEIOzr'
					color='primary'
					aria-label='Delete'
					onClick={() => {
						if (options?.onSuccess) options.onSuccess()
						if (options?.onComplete) options.onComplete()
						close()
					}}
					sx={{
						backgroundColor: isDangerous
							? _theme.conditional(red[600], red[600])
							: _theme.conditional(amber[600], amber[700]),

						'&:hover': {
							backgroundColor: isDangerous
								? _theme.conditional(red[500], red[800])
								: _theme.conditional(amber[500], amber[900]),
						},
					}}
				>
					<Icon path={icons.removeIcon} size={1} />
				</Fab>
			</DialogActions>
		</Dialog>
	)
}
