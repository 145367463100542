// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import { SocketHandlerContext } from 'context/socketHandler'
import debug from 'debug'
import hash from 'object-hash'
import omitDeep from 'omit-deep-lodash'
import { io } from 'socket.io-client'

import { forEach, get, includes, random } from 'lodash'

import { SOCKET_IO_URL } from 'globals/constants/urls'

import { useAuth } from 'components/_Global/_____AuthProvider'

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-SocketHandler'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Types
// ? ---
export interface ISocketEvent {
	triggers: string[]
	type: string
	from: string
	id: string
	updatedAt: string
}

type Props = {
	children: React.ReactElement
}

export type ISocketSubscribeAction = (event: ISocketEvent) => Promise<void>

export type ISocketSubscribeState = {
	[key: string]: {
		[key: string]: ISocketSubscribeAction
	}
}

export interface ISocketSubscribe {
	(hook: string, action: ISocketSubscribeAction): string
}

export interface ISocketUnsubscribe {
	(hookId: string): void
}

// ? ---
// ?	Component
// ? ---
export default function SocketHandler({ children }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const { getToken } = useAuth()

	// * ---
	// *	Refs
	// * ---
	const socket = React.useRef<any>(null)
	const connected = React.useRef(false)
	const subscriptions = React.useRef<ISocketSubscribeState>({})

	// * ---
	// *	Methods
	// * ---

	// * Subscribe
	const subscribe: ISocketSubscribe = (hook, action) => {
		const hookId = hash([random(10000, 99999)]).substring(0, 8)
		subscriptions.current = {
			...subscriptions.current,
			[hook]: {
				...get(subscriptions.current, hook, {}),
				[hookId]: action,
			},
		}
		log('subscribe', hook, hookId, subscriptions.current)
		return hookId
	}

	// * Unsubscribe
	const unsubscribe: ISocketUnsubscribe = (hookId) => {
		subscriptions.current = { ...omitDeep(subscriptions.current, hookId) }
		log('unsubscribe', hookId, subscriptions.current)
	}

	// * Unsubscribe All
	const unsubscribeAll = () => {
		log('unsubscribeAll')
		subscriptions.current = {}
	}

	// * Invoke Subscribers
	const invokeSubscribers = (data: ISocketEvent) => {
		log('invokeSubscribers data', data)
		forEach(subscriptions.current, (actions, hook) => {
			if (includes(get(data, 'triggers', []), hook)) {
				log('invokeSubscribers, event hook has subscriber(s)')
				forEach(actions, (action) => {
					action(data)
				})
			}
		})
	}

	// * ---
	// *	Initialize
	// * ---
	React.useEffect(() => {
		// * Setup
		log('Connecting...')

		const connect = () => {
			socket.current = io(SOCKET_IO_URL, {
				auth: {
					token: getToken(),
				},
				reconnection: true,
				reconnectionAttempts: 50,
				reconnectionDelay: 2000,
				reconnectionDelayMax: 10000,
			})

			// * Connect
			socket.current.on('connect', () => {
				log('socket.io connect', socket.current.id, socket.current)
			})

			// ? On Connect Error
			socket.current.on('connect_error', (error: { message: string }) => {
				const token = getToken()
				log('on:connect_error', error)
				if (token) {
					socket.current.auth.token = getToken()
					socket.current.connect()
				}
			})

			// ? On Refresh Event
			socket.current.on('refresh', (data: string) => {
				log('on:refresh', data)
				try {
					const payload = JSON.parse(data) as ISocketEvent
					invokeSubscribers(payload)
				} catch (error) {
					log('on:refresh payload error', data, error)
				}
			})

			// ? On Any
			socket.current.onAny((event: any, ...args: any[]) => {
				switch (event) {
					case 'connect_error':
					case 'refresh':
						// Do nothing
						break
					default:
						log('onAny', event, args)
						break
				}
			})
		}

		// * Start Connection
		connect()

		// * Unmount
		return () => {
			socket.current.disconnect()
			connected.current = false
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// * ---
	// *	Return: Good
	// * ---
	return (
		<SocketHandlerContext.Provider
			value={{
				connected: connected.current,
				subscriptions: subscriptions.current,
				subscribe,
				unsubscribe,
				unsubscribeAll,
			}}
		>
			{children}
		</SocketHandlerContext.Provider>
	)
}
