// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'

import Link from 'next/link'
import { Alert, Autocomplete, Box, Button, FormControl, Grid, InputLabel, TextField, useTheme } from '@mui/material'
import { find, get, isEqual, map } from 'lodash'

import useAccount from 'hooks/useAccount'

import { IAccountIntegrationTypeOptions, IAccountSlackChannel } from 'data/accounts'
import { IUseNodeField } from 'data/nodes'

// ? ---
// ?	Types
// ? ---
type Props = {
	formik: any
	field: IUseNodeField

	close?: () => void
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Dialogs-UpsertFields-SelectSlackChannel'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function SelectElement({ close, field, formik }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const theme = useTheme()
	const _account = useAccount()
	const slackIntegration = find(_account.data?.attributes.integrations, {
		type: IAccountIntegrationTypeOptions.slack,
	})

	// * ---
	// *	State
	// * ---
	const [loaded, $loaded] = React.useState(false)
	const [channels, $channels] = React.useState<IAccountSlackChannel[]>(_account.slackChannels)

	// * ---
	// *	Effect: Load Channels
	// * ---
	React.useEffect(() => {
		let abort = false
		const load = async () => {
			const tempChannels = await _account.fetchSlackChannels()
			if (!isEqual(tempChannels, channels) && !abort) {
				$channels(tempChannels)
			}

			$loaded(true)
		}
		if (slackIntegration?.isActive && !loaded) {
			load()
		}
		return () => {
			abort = true
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// * ---
	// *	Helpers
	// * ---
	const channelNameFromId = (channelId: string) => `#${get(find(channels, { id: channelId }), 'name', '')}`

	// * ---
	// *	Return
	// * ---
	return (
		<>
			<Grid container direction='row' justifyContent='space-between' alignItems='flex-start'>
				<Grid item xs={12}>
					{slackIntegration?.isActive ? (
						<Autocomplete
							data-test-id={`${namespace}--${field.property}`}
							fullWidth
							id={field.property}
							value={
								get(formik?.values, field.property) !== '' ? get(formik?.values, field.property) : null
							}
							onChange={(event, value) => formik.setFieldValue(field.property, value)}
							isOptionEqualToValue={(channelId, value) => {
								return channelId === value
							}}
							getOptionLabel={(channelId) => channelNameFromId(channelId)}
							options={map(channels, 'id')}
							renderInput={(params) => (
								<TextField
									{...(params as any)}
									label={field.label}
									error={
										get(formik, `touched[${field.property}]`) &&
										Boolean(get(formik, `errors[${field.property}]`))
									}
									helperText={
										get(formik, `touched[${field.property}]`) &&
										get(formik, `errors[${field.property}]`)
									}
								/>
							)}
							renderOption={(props, channelId) => (
								<Box component='li' {...(props as any)}>
									{channelNameFromId(channelId)}
								</Box>
							)}
						/>
					) : (
						<FormControl fullWidth>
							<InputLabel shrink={true}>Slack Channel</InputLabel>
							<Alert
								severity='warning'
								sx={{ mt: 1, border: `1px solid ${theme.palette.warning.dark}` }}
								action={
									<Link
										data-test-id={`${namespace}--integrations`}
										href={`/app/settings/integrations`}
										style={{ textDecoration: 'none' }}
										passHref
										legacyBehavior
									>
										<Button
											data-test-id={`${namespace}--integrations-button`}
											color='inherit'
											size='small'
											onClick={() => close && close()}
										>
											Add
										</Button>
									</Link>
								}
							>
								Slack integration required
							</Alert>
						</FormControl>
					)}
				</Grid>
			</Grid>
		</>
	)
}
