// ? ---
// ?	Imports
// ? ---
import React from 'react'
import { ApolloProvider } from '@apollo/client'
import debug from 'debug'
import store from 'store2'

import '@fontsource-variable/montserrat'

import type { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { get, includes } from 'lodash'

import { apolloClient } from 'globals/client/clientApolloClient'
import { darkTheme, lightTheme } from 'globals/client/theme'
import { USER_PREFERENCES_STORAGE } from 'globals/constants/cookies'

import AuthProvider from 'components/_Global/_____AuthProvider'
import WaitForAuth from 'components/_Global/____WaitForAuth'
import AppData from 'components/_Global/___AppData'
import SocketHandler from 'components/_Global/__SocketHandler'

// ? ---
// ?	Constants
// ? ---
const namespace = 'pages-_app'
const log = debug(`app:${namespace}`)

// ? ---
// ?	View
// ? ---
export default function App({ Component, pageProps: { ...pageProps } }: AppProps): JSX.Element {
	// * ---
	// *	 Setup
	// * ---
	const router = useRouter()
	const client = React.useRef(apolloClient)

	// * ---
	// *	 State
	// * ---
	const [theme, $theme] = React.useState(darkTheme)

	// * ---
	// *	 Effect
	// * ---
	React.useEffect(() => {
		// * Debug
		if (process.env.NODE_ENV === 'development') {
			window.localStorage.setItem('debug', 'app:*') // NOTE: store/store2 does not work
		}

		// * Remove the server-side injected CSS.
		const jssStyles = document.querySelector('#jss-server-side')
		if (jssStyles) {
			jssStyles.parentElement?.removeChild(jssStyles)
		}

		// * Light Mode
		const userPreferences = store.get(USER_PREFERENCES_STORAGE)
		if (get(userPreferences, 'LightMode', false)) $theme(lightTheme)

		log('..')
	}, [])

	// * ---
	// *	Return
	// * ---
	return (
		<AuthProvider>
			<ThemeProvider theme={theme}>
				<>
					<Head>
						<title>DoesQA App</title>
						<meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width' />
						<style>
							{`
								@media print {
									.MuiTooltip-popper {
										display: none !important;
									}
								}
								:root {
									--tagify-dd-color-primary: ${theme.palette.primary.main} !important;
									--tagify-dd-bg-color: ${theme.palette.background.paper} !important;
								}
								.tagify {
									--tag-bg: ${theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.grey[700]} !important;
									--tag-hover: ${theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.grey[700]} !important;
									--tag-text-color: ${theme.palette.text.primary} !important;
									--tag-remove-btn-color: ${theme.palette.text.primary} !important;
								}
								.tagify__dropdown {
									box-shadow: ${theme.shadows[1]} !important;
									overflow: hidden !important;
									border-radius: ${theme.shape.borderRadius}px !important;
								}
								.tagify__dropdown > div {
									border: 0px !important;
									padding: ${theme.spacing(1)}px !important;
								}
								#widget-Outseta_chat { display: none !important; }
							`}
						</style>
					</Head>

					<CssBaseline />

					{includes(router.asPath, '/app/') ? (
						<WaitForAuth>
							<ApolloProvider client={client.current}>
								<AppData>
									<SocketHandler>
										<Component {...pageProps} />
									</SocketHandler>
								</AppData>
							</ApolloProvider>
						</WaitForAuth>
					) : (
						<Component {...pageProps} />
					)}
				</>
			</ThemeProvider>
		</AuthProvider>
	)
}
