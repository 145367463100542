var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"28TUSdGglMERIkfGJ_XHV"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

Sentry.init({
	dsn: 'https://80e3606bc0ea45f0b29eb48119b0987a@o4504317102850048.ingest.sentry.io/4504317107044353',

	// Adjust this value in production, or use tracesSampler for greater control
	tracesSampleRate: 1,

	// Setting this option to true will print useful information to the console while you're setting up Sentry.
	debug: false,

	replaysOnErrorSampleRate: 0,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0,

	// You can remove this option if you're not planning to use the Sentry Session Replay feature:
	// integrations: [
	// 	new Sentry.Replay({
	// 		// Additional Replay configuration goes in here, for example:
	// 		maskAllText: true,
	// 		blockAllMedia: true,
	// 	}),
	// ],
})
