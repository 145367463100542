// ? ---
// ?	Imports
// ? ---
import * as React from 'react'

import { Slide } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'

// ? ---
// ?	Export
// ? ---
export const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: JSX.Element
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />
})
