// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import * as yup from 'yup'

import { compact, includes } from 'lodash'

import { FAILED_STATUS_OPTIONS } from 'globals/constants/selectOptions'
import { ELEMENT_VALIDATION } from 'globals/constants/validations'

import { formatElement, formatValue, sentenceElement, sentenceValue } from 'hooks/useNode'

import { INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'
import { ITestStepStatus } from 'data/tests'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.action_input_selectOption,
	isLive: true,
	isMenuOption: true,
	isPro: false,
	isAccessibility: true,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.element',
			type: 'element',
			label: 'Element',
			validation: ELEMENT_VALIDATION,
		},
		{
			property: 'attributes.value2',
			type: 'button-group',
			asStringValue: true,
			defaultValue: 'auto',
			label: 'Selection Mode',
			options: [
				{
					label: 'Auto',
					value: 'auto',
				},
				{
					label: 'Value',
					value: 'value',
				},
				{
					label: 'Text',
					value: 'text',
				},
				{
					label: 'Element',
					value: 'element',
				},
				{
					label: 'Index',
					value: 'index',
				},
			],
		},
		{
			property: 'attributes.value',
			type: 'text-with-tags',
			label: 'Value',
			validation: yup.string().trim().required('Value is required'),
			conditional: ({ values }: { values: { [key: string]: any } }) => {
				return includes([null, 'auto'], values.attributes.value2)
			},
			helper: 'DoesQA will try to match the correct option',
		},
		{
			property: 'attributes.value',
			type: 'text-with-tags',
			label: 'Value',
			validation: yup.string().trim().required('Value is required'),
			conditional: ({ values }: { values: { [key: string]: any } }) => {
				return includes(['value'], values.attributes.value2)
			},
		},
		{
			property: 'attributes.value',
			type: 'text-with-tags',
			label: 'Visible Text',
			validation: yup.string().trim().required('Value is required'),
			conditional: ({ values }: { values: { [key: string]: any } }) => {
				return includes(['text'], values.attributes.value2)
			},
		},
		{
			property: 'attributes.value',
			type: 'text-with-tags',
			label: 'Index (0-based)',
			validation: yup.string().trim().required('Value is required'),
			conditional: ({ values }: { values: { [key: string]: any } }) => {
				return includes(['index'], values.attributes.value2)
			},
			helper: 'The first option is 0, second option is 1 etc',
		},
		{
			property: 'attributes.element2',
			type: 'element',
			label: 'Element',
			validation: yup.string().when('value2', {
				is: 'element',
				then: (schema) => schema.required('Element is required'),
				otherwise: (schema) => schema.notRequired(),
			}),
			conditional: ({ values }: { values: { [key: string]: any } }) => {
				return includes(['element'], values.attributes.value2)
			},
		},
		{
			property: 'attributes.config.failedStatus',
			type: 'button-group',
			label: 'Status on fail',
			defaultValue: ITestStepStatus.failed,
			options: FAILED_STATUS_OPTIONS,
		},
	],
	label: 'Select option',
	sentence: ({ element, element2, isExample, value, value2 }: INodeRenderProps) => {
		if (isExample) return 'Select'
		switch (value2) {
			case 'element':
				return `Select ${sentenceElement(element2)} inside ${sentenceElement(element)}`
			case 'index':
				return `Select index ${sentenceValue(value)} from ${sentenceElement(element)}`
			default:
				return `Select ${sentenceValue(value)} from ${sentenceElement(element)}`
		}
	},
	family: INodeVariantTypes.action_input_selectOption.split('_')[0],
	type: INodeVariantTypes.action_input_selectOption.split('_')[1],
	subType: INodeVariantTypes.action_input_selectOption.split('_')[2],
	tags: compact([
		INodeVariantTypes.action_input_selectOption.split('_')[0],
		INodeVariantTypes.action_input_selectOption.split('_')[1],
		INodeVariantTypes.action_input_selectOption.split('_')[2],
		'select',
		'option',
		'dropdown',
		'set',
		'value',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ element, element2, isExample, value, value2 }: INodeRenderProps) => {
			return (
				<>
					Select{' '}
					{!isExample && (
						<>
							{value2 === 'index' ? (
								<>
									index <strong>{formatValue(value)}</strong> from{' '}
									<strong>{formatElement(element)}</strong>
								</>
							) : value2 === 'element' ? (
								<>
									<strong>{formatElement(element2)}</strong> inside{' '}
									<strong>{formatElement(element)}</strong>
								</>
							) : (
								<>
									<strong>{formatValue(value)}</strong> from <strong>{formatElement(element)}</strong>
								</>
							)}
						</>
					)}
				</>
			)
		},
	}
}
