// ? ---
// ?	Imports
// ? ---
import * as React from 'react'

import { compact, isEmpty } from 'lodash'

import { INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.utility_comment_flow,
	isLive: true,
	isMenuOption: true,
	isPro: false,
	isAccessibility: true,
	isMultiLine: true,
	hasInput: false,
	hasOutput: false,
	fields: [
		{
			property: 'attributes.value',
			type: 'textarea',
			label: 'Comment',
		},
	],
	label: 'Flow comment',
	sentence: ({ isExample, value }: INodeRenderProps) => {
		return isExample ? `Flow comment` : `Comment: ${value}`
	},
	family: INodeVariantTypes.utility_comment_flow.split('_')[0],
	type: INodeVariantTypes.utility_comment_flow.split('_')[1],
	subType: INodeVariantTypes.utility_comment_flow.split('_')[2],
	tags: compact([
		INodeVariantTypes.utility_comment_flow.split('_')[0],
		INodeVariantTypes.utility_comment_flow.split('_')[1],
		INodeVariantTypes.utility_comment_flow.split('_')[2],
		'message',
		'header',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ isExample, value }: INodeRenderProps) => {
			return <>{isExample || isEmpty(value) ? <>Flow comment</> : <>{value}</>}</>
		},
	}
}
