// ? ---
// ?	Imports
// ? ---
import { amber, blueGrey, grey, indigo, lightGreen, pink } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'

import GREY from 'globals/constants/colors/brandGrey'
import BRAND_PRIMARY from 'globals/constants/colors/brandPrimary'

// ? ---
// ?	Constants
// ? ---

const shadow0 = 'rgba(0,0,0,0.4)'
const shadow1 = 'rgba(0,0,0,0.08)'
const shadow2 = 'rgba(0,0,0,0.06)'
const shadow3 = 'rgba(0,0,0,0.04)'

// ? ---
// ?	Create Theme
// ? ---
const makeTheme = (mode) => {
	const isLight = mode === 'light'

	let theme = createTheme({
		overrides: {
			MuiCssBaseline: {
				'@global': {
					body: {
						backgroundColor: isLight ? GREY[100] : GREY[900],
						fontWeight: 600,
					},
				},
			},
		},
		typography: {
			fontFamily: 'Montserrat Variable',
			fontWeight: 600,
			fontWeightLight: 600,
			fontWeightRegular: 600,
			fontWeightMedium: 600,
			fontWeightBold: 700,
			h1: {
				fontSize: '1.6rem',
				fontWeight: `700 !important`,
				lineHeight: 1.2,
			},
			h2: {
				fontSize: '1.4rem',
				fontWeight: 600,
			},
			h3: {
				fontSize: '1.2rem',
				fontWeight: 600,
			},
			h4: {
				fontSize: '1.1rem',
				fontWeight: 600,
			},
			subtitle1: {
				fontWeight: 600,
			},
			subtitle2: {
				fontWeight: 600,
			},
			body1: {
				fontWeight: 500,
			},
			body2: {
				fontWeight: 500,
			},
		},
		palette: {
			mode,
			background: {
				default: isLight ? GREY[200] : GREY[900],
				paper: isLight ? '#fff' : GREY[800],
			},
			primary: {
				light: isLight ? BRAND_PRIMARY[400] : BRAND_PRIMARY[300],
				main: isLight ? BRAND_PRIMARY[500] : BRAND_PRIMARY[400],
				dark: isLight ? BRAND_PRIMARY[600] : BRAND_PRIMARY[500],
			},
			secondary: {
				light: blueGrey[300],
				main: blueGrey[400],
				dark: blueGrey[500],
			},
			error: {
				light: pink[100],
				main: pink[300],
				dark: pink[500],
			},
			warning: {
				light: isLight ? amber[100] : amber[600],
				main: isLight ? amber[300] : amber[800],
				dark: isLight ? amber[500] : amber[900],
			},
			success: {
				light: lightGreen[300],
				main: lightGreen[400],
				dark: lightGreen[500],
			},
			grey: GREY,
			text: {
				primary: isLight ? '#404145' : GREY[100],
				secondary: blueGrey[400],
			},
			divider: isLight ? '#D6D8E1' : '#000',
		},
		spacing: 16,
		shape: {
			borderRadius: 6,
		},
		shadows: [
			'none',
			`0 8px 16px -8px rgba(0,0,0,0.1)`,
			`0 2px 8px -4px rgba(0,0,0,0.6)`,
			`0px 3px 3px -2px ${shadow1},0px 3px 4px 0px ${shadow2},0px 1px 8px 0px ${shadow3}`,
			`0px 2px 4px -1px ${shadow1},0px 4px 5px 0px ${shadow2},0px 1px 10px 0px ${shadow3}`,
			`0px 3px 5px -1px ${shadow1},0px 5px 8px 0px ${shadow2},0px 1px 14px 0px ${shadow3}`,
			`0px 3px 5px -1px ${shadow1},0px 6px 10px 0px ${shadow2},0px 1px 18px 0px ${shadow3}`,
			`0px 4px 5px -2px ${shadow1},0px 7px 10px 1px ${shadow2},0px 2px 16px 1px ${shadow3}`,
			`0px 5px 5px -3px ${shadow1},0px 8px 10px 1px ${shadow2},0px 3px 14px 2px ${shadow3}`,
			`0px 5px 6px -3px ${shadow1},0px 9px 12px 1px ${shadow2},0px 3px 16px 2px ${shadow3}`,
			`0px 6px 6px -3px ${shadow1},0px 10px 14px 1px ${shadow2},0px 4px 18px 3px ${shadow3}`,
			`0px 6px 7px -4px ${shadow1},0px 11px 15px 1px ${shadow2},0px 4px 20px 3px ${shadow3}`,
			`0px 7px 8px -4px ${shadow1},0px 12px 17px 2px ${shadow2},0px 5px 22px 4px ${shadow3}`,
			`0px 7px 8px -4px ${shadow1},0px 13px 19px 2px ${shadow2},0px 5px 24px 4px ${shadow3}`,
			`0px 7px 9px -4px ${shadow1},0px 14px 21px 2px ${shadow2},0px 5px 26px 4px ${shadow3}`,
			`0px 8px 9px -5px ${shadow1},0px 15px 22px 2px ${shadow2},0px 6px 28px 5px ${shadow3}`,
			`0px 8px 10px -5px ${shadow1},0px 16px 24px 2px ${shadow2},0px 6px 30px 5px ${shadow3}`,
			`0px 8px 11px -5px ${shadow1},0px 17px 26px 2px ${shadow2},0px 6px 32px 5px ${shadow3}`,
			`0px 9px 11px -5px ${shadow1},0px 18px 28px 2px ${shadow2},0px 7px 34px 6px ${shadow3}`,
			`0px 9px 12px -6px ${shadow1},0px 19px 29px 2px ${shadow2},0px 7px 36px 6px ${shadow3}`,
			`0px 10px 13px -6px ${shadow1},0px 20px 31px 3px ${shadow2},0px 8px 38px 7px ${shadow3}`,
			`0px 10px 13px -6px ${shadow1},0px 21px 33px 3px ${shadow2},0px 8px 40px 7px ${shadow3}`,
			`0px 10px 14px -6px ${shadow1},0px 22px 35px 3px ${shadow2},0px 8px 42px 7px ${shadow3}`,
			`0px 11px 14px -7px ${shadow1},0px 23px 36px 3px ${shadow2},0px 9px 44px 8px ${shadow3}`,
			`0px 11px 15px -7px ${shadow0},0px 24px 38px 3px ${shadow1},0px 9px 46px 8px ${shadow2}`,
		],
		transitions: {
			duration: {
				enteringScreen: 225,
				leavingScreen: 195,
			},
		},
	})

	theme = {
		...theme,
		components: {
			// ? ---
			// ?	Paper
			// ? ---
			MuiPaper: {
				styleOverrides: {
					root: {
						backgroundImage: 'none',
					},
				},
			},

			// ? ---
			// ?	Alert
			// ? ---
			MuiAlert: {
				styleOverrides: {
					root: {
						borderWidth: isLight ? '1px' : 0,
					},
				},
			},

			// ? ---
			// ?	Card
			// ? ---
			MuiCard: {
				styleOverrides: {
					root: {
						backgroundColor: theme.palette.background.paper,
					},
				},
			},
			MuiCardTitle: {
				styleOverrides: {
					root: {
						backgroundColor: theme.palette.background.paper,
					},
				},
			},
			MuiCardContent: {
				styleOverrides: {
					root: {
						backgroundColor: theme.palette.background.paper,
					},
				},
			},
			MuiCardActions: {
				styleOverrides: {
					root: {
						backgroundColor: theme.palette.background.paper,
					},
				},
			},

			// ? ---
			// ?	Table
			// ? ---
			MuiTableContainer: {
				styleOverrides: {
					root: {
						padding: 1,
						border: 0,
						'& tbody .MuiTableCell-root': {
							paddingTop: 0.6,
							paddingBottom: 0.6,
						},
					},
				},
			},
			MuiTableRow: {
				styleOverrides: {
					root: {
						'&:last-child > td': {
							borderBottom: 'none',
						},
					},
				},
			},
			MuiTableCell: {
				styleOverrides: {
					root: {
						lineHeight: 1,
						borderBottom: `1px solid ${theme.palette.divider}`,
						'&.MuiTableCell-head': {
							fontWeight: 'bold',
							fontSize: theme.typography.body2.fontSize,
							color: theme.palette.secondary.main,
							textTransform: 'uppercase',
						},
						'&:first-of-type': {
							fontWeight: 'bold',
						},
					},
				},
			},

			// ? ---
			// ?	Dialog
			// ? ---
			MuiDialogTitle: {
				styleOverrides: {
					root: {
						...theme.typography.h2,
						backgroundColor: isLight ? theme.palette.grey[200] : theme.palette.grey[800],

						'&.dragHandle': {
							cursor: 'move',
						},
					},
				},
			},
			MuiDialogContent: {
				styleOverrides: {
					root: {
						paddingTop: `${theme.spacing(1.5)}px !important`,
						backgroundColor: isLight ? theme.palette.grey[100] : theme.palette.grey[900],
						borderTop: `1px solid ${theme.palette.divider}`,
						borderBottom: `1px solid ${theme.palette.divider}`,
						paddingBottom: `20px`,
					},
				},
			},
			MuiDialogActions: {
				styleOverrides: {
					root: {
						paddingRight: `75px`,
						backgroundColor: isLight ? theme.palette.grey[200] : theme.palette.grey[800],

						'& .MuiFab-root': {
							position: 'absolute',
							marginTop: `-20px`,
							right: theme.spacing(0.5),
						},
					},
				},
			},

			// ? ---
			// ?	Input
			// ? ---
			MuiInputLabel: {
				styleOverrides: {
					root: {
						'&.Mui-focused': {
							color: theme.palette.text.secondary,
						},
					},
				},
			},
			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						backgroundColor: theme.palette.background.default,

						'& fieldset': {
							borderColor: isLight ? grey[300] : grey[800],
						},
						'& input:-webkit-autofill': {
							boxShadow: `none`,
							textFillColor: 'inherit',
						},
						'&:hover, &.Mui-focused': {
							'& fieldset': {
								borderColor: isLight ? `${grey[500]} !important` : `${grey[700]} !important`,
							},

							'& input:-webkit-autofill': {
								boxShadow: `0 0 0 100px ${indigo[50]} inset !important`,
								textFillColor: theme.palette.text.primary,
							},
						},
					},
				},
			},
			MuiFilledInput: {
				styleOverrides: {
					root: {
						backgroundColor: theme.palette.grey[100],
						'&:hover, &.Mui-focused': {
							backgroundColor: theme.palette.grey[50],
						},
					},
				},
			},

			// ? ---
			// ?	Other
			// ? ---
			MuiTouchRipple: {
				defaultProps: {
					sx: {
						zIndex: theme.zIndex.modal - 1,
					},
				},
			},

			MuiListSubheader: {
				defaultProps: {
					sx: {
						zIndex: theme.zIndex.modal + 1,
						backgroundColor: `${theme.palette.background.default} !important`,
					},
				},
			},
		},
	}

	return theme
}

// ? ---
// ?	Export Theme
// ? ---
export const lightTheme = makeTheme('light')
export const darkTheme = makeTheme('dark')

export default lightTheme
