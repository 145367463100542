import Color from 'color'

const colorMain = Color('#433490')
const colorLight = Color('#654DE3')

const BRAND_PRIMARY = {
	50: colorLight.whiten(0.8).hex(),
	100: colorLight.whiten(0.6).hex(),
	200: colorLight.whiten(0.4).hex(),
	300: '#8A74FF',
	400: colorLight.hex(),
	500: colorMain.hex(),
	600: colorMain.blacken(0.2).hex(),
	700: colorMain.blacken(0.4).hex(),
	800: colorMain.blacken(0.6).hex(),
	900: colorMain.blacken(0.8).hex(),
}

export default BRAND_PRIMARY
