// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import { useMutation } from '@apollo/client'
import { AppDataContext, IAppDataContext } from 'context/appData'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import debug from 'debug'

import { get, map, omit, unionBy } from 'lodash'

import { MAX_ELEMENTS_TO_TRACK } from 'globals/constants/activity'

import { ADD_ELEMENT, EDIT_ELEMENT, IElementContext, NUKE_ELEMENT } from 'data/elements'

import useAccount from './useAccount'
import useActivity from './useActivity'

// ? ---
// ?	Constants
// ? ---
const namespace = 'hooks-useElements'
const log = debug(`app:${namespace}`)
const type = 'Element'
const model = 'elements'

dayjs.extend(utc)

// ? ---
// ?	Hook
// ? ---
export default function useElements() {
	// * ---
	// *	Context
	// * ---
	const AppData = React.useContext<IAppDataContext>(AppDataContext)
	const _activity = useActivity()
	const _account = useAccount()

	// * ---
	// *	Mutation
	// * ---
	const [add] = useMutation(ADD_ELEMENT)
	const [edit] = useMutation(EDIT_ELEMENT)
	const [nuke] = useMutation(NUKE_ELEMENT)

	// * ---
	// *	Insert
	// * ---
	const insert: IElementContext['insert'] = async (input, options) => {
		log('insert', { input, options })
		const response = await add({
			variables: {
				data: {
					...omit(input.attributes, ['__typename']),
				},
			},
		})

		// * Track Activity
		_activity.trackElementCreated(input?.attributes?.title || 'UNKNOWN_NAME')
		const currentElementCount = get(_account, 'outseta.DqaElementCount', 0)
		if (currentElementCount < MAX_ELEMENTS_TO_TRACK) {
			_account.update({
				DqaElementCount: currentElementCount + 1,
			})
		}

		// * Callbacks
		if (options?.onSuccess) options.onSuccess(response.data[`create${type}`].data)
		if (options?.onComplete) options.onComplete(response.data[`create${type}`].data)
		return response.data[`create${type}`].data
	}

	// * ---
	// *	Update
	// * ---
	const update: IElementContext['update'] = async (input, options) => {
		log('update', { input, options })
		const response = await edit({
			variables: {
				id: input.id,
				data: {
					...omit(input.attributes, ['__typename', 'nodes', 'nodes2', 'updatedAt']),
				},
			},
		})
		if (options?.onSuccess) options.onSuccess(response.data[`update${type}`].data)
		if (options?.onComplete) options.onComplete(response.data[`update${type}`].data)
		return response.data[`update${type}`].data
	}

	// * ---
	// *	Remove
	// * ---
	const remove: IElementContext['remove'] = async (item, options) => {
		const linkedNodes = map(
			unionBy(get(item, 'attributes.nodes.data', []), get(item, 'attributes.nodes2.data', []), 'id'),
			'attributes.variantType'
		)
		log('remove', { item, options, linkedNodes })

		if (options?.force) {
			await nuke({
				variables: {
					id: item.id,
				},
			})
			if (options?.onSuccess) options.onSuccess()
			if (options?.onComplete) options.onComplete()
		} else {
			await AppData.openDeleteConfirmation(
				{
					body:
						linkedNodes.length === 1
							? 'A Node is connected to this Element.'
							: linkedNodes.length > 1
							? `${linkedNodes.length} Nodes are connected to this Element.`
							: undefined,
					related: linkedNodes.length > 0 ? linkedNodes : undefined,
				},
				{
					onSuccess: async () => {
						await nuke({
							variables: {
								id: item.id,
							},
						})
						if (options?.onSuccess) options.onSuccess()
						if (options?.onComplete) options.onComplete()
					},
				}
			)
		}
	}

	// * ---
	// *	Upsert
	// * ---
	const upsert: IElementContext['upsert'] = async (input, options) => {
		log('upsert', { input, options })
		if (input.id === undefined || input.id === '') {
			return await insert(input, options)
		} else {
			return await update(input, options)
		}
	}

	// * ---
	// *	Clone
	// * ---
	const clone: IElementContext['clone'] = async (item, options) => {
		log('clone', { item, options })
		const response = await insert(
			{
				...item,
				attributes: {
					...omit(item.attributes, ['__typename', 'nodes', 'nodes2']),
					title: `${item.attributes?.title} (copy)`,
				},
			},
			options
		)
		if (options?.onSuccess) options.onSuccess(response)
		if (options?.onComplete) options.onComplete(response)
		return response
	}

	// * ---
	// *	Return
	// * ---
	return {
		insert,
		update,
		upsert,
		clone,
		remove,
		openUpsert: AppData[model].openUpsert,
		closeUpsert: AppData[model].closeUpsert,
	}
}
