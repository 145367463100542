// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import { createContext, useContext, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { deleteCookie, setCookie } from 'cookies-next'
import debug from 'debug'
import store from 'store2'
import { v4 as uuidv4 } from 'uuid'

import { useRouter } from 'next/router'
import { endsWith, forEach, get, includes, isNil, mapKeys, pickBy, replace, startsWith, toLower } from 'lodash'

import { loadOutseta } from 'globals/client/outseta'
import { MAX_LOGINS_TO_TRACK } from 'globals/constants/activity'
import {
	OUTSETA_ACCOUNT_ID_COOKIE,
	SECRETS_COOKIE,
	STACK_TYPE_COOKIE,
	USER_PREFERENCES_STORAGE,
} from 'globals/constants/cookies'
import { BASE_HOSTNAME, DEFAULT_LANDING_PATH, POST_LOGOUT_PATH } from 'globals/constants/urls'

import { IOutsetaUser, OutsetaAuth } from 'data/outseta'

// ? ---
// ?	Types
// ? ---
type Props = {
	children: JSX.Element
}

export interface IAuthContext {
	user: IOutsetaUser | null
	getToken: () => string | null
	isLoading: boolean
	isAuth: boolean
	isLoggingIn: boolean
	logout: (options?: { redirect?: boolean | string }) => void
	gotoLogin: () => void
	openLogin: (options?: { [key: string]: any }) => void
	openSignup: (options?: { [key: string]: any }) => void
	openProfile: (options?: { [key: string]: any }) => void
	openUpgrade: (options?: { [key: string]: any }) => void
	setPreference: (key: string, value: any) => void
	preferences: { [key: string]: any }
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-AuthProvider'
const log = debug(`app:${namespace}`)

const AuthContext = createContext({} as IAuthContext)

export function useAuth(): IAuthContext {
	return useContext(AuthContext)
}

// ? ---
// ?	Provider
// ? ---
export default function AuthProvider({ children }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	const router = useRouter()
	log('.')
	const outsetaRef = useRef<null | OutsetaAuth>(null)
	const [isLoading, $isLoading] = useState(true)
	const [isAuth, $isAuth] = useState(false)
	const [isLoggingIn, $isLoggingIn] = useState(false)
	const [user, $user] = useState<null | IOutsetaUser>(null)

	// * ---
	// *	Effect: Handle Auth Updates
	// * ---
	useEffect(() => {
		const init = async () => {
			outsetaRef.current = (await loadOutseta()) as OutsetaAuth
			handleOutsetaUserEvents(updateUser)

			let accessToken = router.query.access_token
			if (Array.isArray(accessToken)) {
				accessToken = accessToken[0]
			}

			if (accessToken) {
				outsetaRef.current.setAccessToken(accessToken)
				$isLoggingIn(true)
				await updateUser(true)
			} else if (outsetaRef.current.getAccessToken()) {
				$isLoggingIn(false)
				await updateUser()
			} else {
				$isLoggingIn(false)
				$isLoading(false)
			}
		}

		if (!startsWith(router.pathname, '/mail/')) {
			$isLoading(true)
			init().then()
		}

		return () => {
			// Clean up user related event subscriptions
			handleOutsetaUserEvents(() => {
				log('handleOutsetaUserEvents')
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [router.query.access_token])

	// * ---
	// *	Get Token
	// * ---
	const getToken = () => {
		if (outsetaRef.current === null) {
			return null
		}
		return outsetaRef.current.getAccessToken()
	}

	// * ---
	// *	Remove Access Token (from URL)
	// * ---
	const removeAccessToken = (url: string) => {
		let response = `${url}`
		if (includes(response, 'access_token')) {
			const params = new URLSearchParams(response.split('?')[1])
			params.delete('access_token')
			const otherParams = params.toString()
			if (otherParams.length === 0) {
				response = response.split('?')[0]
			} else {
				response = `${response.split('?')[0]}?${otherParams}`
			}
		}
		log('removeAccessToken', { url, response })
		return response
	}

	// * ---
	// *	Update User
	// * ---
	const updateUser = async (redirect?: boolean) => {
		log('updateUser', redirect)
		if (outsetaRef.current === null) {
			log('!! updateUser: outsetaRef is null')
			logout()
			return
		}
		const outsetaUser = (await outsetaRef.current.getUser()) as IOutsetaUser

		// ! Drop out: Account missing
		log('updateUser', updateUser)
		if (!outsetaUser) return logout()

		// ! Drop out: CDNR Account
		if (outsetaUser.Account.CdnrAccount)
			return (location.href = `https://cdnr.io?access_token=${outsetaRef.current.getAccessToken()}`)

		// * Set cookies
		setCookie(OUTSETA_ACCOUNT_ID_COOKIE, outsetaUser.Account.Uid)
		setCookie(STACK_TYPE_COOKIE, outsetaUser.Account.DqaStackType)

		// * Set user preferences
		const preferences = mapKeys(
			pickBy(outsetaUser, (value, key) => startsWith(key, 'DqaPreference_')),
			(value, key) => replace(key, 'DqaPreference_', '')
		)
		const userPreferences: { [key: string]: any } = {}
		forEach(preferences, (value, key) => {
			userPreferences[key] = value === 'True' ? true : value === 'False' ? false : value
		})
		store.set(USER_PREFERENCES_STORAGE, userPreferences)

		// * Update user state
		log('updateUser', outsetaUser, { env: process.env.NODE_ENV, hosts: process.env.NEXT_PUBLIC_HOSTS })
		$user(outsetaUser)
		$isAuth(true)

		// * First Login
		if (isNil(outsetaUser.Account.DqaFirstLoginStatus)) {
			await fetch(`/api/public/first-login/${outsetaUser.Account.Uid}`, {
				method: 'POST',
			})
		}

		// * Login Count
		if (!store.session.get('o-session')) {
			store.session.set('o-session', true)

			// * Update Account
			if (get(outsetaUser, 'Account.DqaLoginCount', 0) < MAX_LOGINS_TO_TRACK) {
				await axios
					.put(
						`/api/v2/community/accounts/${outsetaUser.Account.Uid}`,
						{
							DqaLoginCount: get(outsetaUser, 'Account.DqaLoginCount', 0) + 1,
						},
						{
							headers: {
								'Content-Type': 'application/json',
								'x-auth': getToken(),
								'x-account': outsetaUser.Account.Uid,
							},
						}
					)
					.catch((err) => {
						log('!! Increment Account Login Count failed', err)
					})
			}

			// * Update User
			if (get(outsetaUser, 'DqaLoginCount', 0) < MAX_LOGINS_TO_TRACK) {
				await axios
					.put(
						`/api/v2/community/accounts/${outsetaUser.Account.Uid}/users/${outsetaUser.Uid}`,
						{
							DqaLoginCount: get(outsetaUser, 'DqaLoginCount', 0) + 1,
						},
						{
							headers: {
								'Content-Type': 'application/json',
								'x-auth': getToken(),
								'x-account': outsetaUser.Account.Uid,
							},
						}
					)
					.catch((err) => {
						log('!! Increment User Login Count failed', err)
					})
			}
		}

		// * Redirect
		if (redirect) {
			// ? ---
			// ?	Development
			// ? ---
			if (process.env.NODE_ENV === 'development' && process.env.NEXT_PUBLIC_HOSTS !== 'true') {
				log('updateUser:redirect', 'development mode, enter app')
				await router.push(removeAccessToken(router.asPath))
				return
			}

			// ? ---
			// ?	Not on app.does.qa
			// ? ---
			if (location.hostname !== BASE_HOSTNAME) {
				log('updateUser:redirect', 'user not on app.does.qa, redirect')
				const destination = `${location.protocol}//${BASE_HOSTNAME}${router.asPath}`
				location.href = `${destination}${
					includes(destination, '?') ? '&' : '?'
				}access_token=${outsetaRef.current.getAccessToken()}`
				return
			}

			// ? ---
			// ?	Default: User on app.does.qa, enter app
			// ? ---
			log('updateUser:redirect', 'user on app.does.qa, enter app')
			await router.push(removeAccessToken(router.asPath))
		} else if (includes(router.asPath, 'access_token')) {
			await router.push(removeAccessToken(router.asPath))
		}
		$isLoading(false)
	}

	const handleOutsetaUserEvents = (onEvent: any) => {
		if (outsetaRef.current === null) {
			console.warn('handleOutsetaUserEvents: outsetaRef.current === null')
			return
		}
		outsetaRef.current.on('subscription.update', onEvent)
		outsetaRef.current.on('profile.update', onEvent)
		outsetaRef.current.on('account.update', onEvent)
	}

	const logout: IAuthContext['logout'] = async (options) => {
		if (outsetaRef.current) {
			outsetaRef.current.setAccessToken('')
		}

		deleteCookie(OUTSETA_ACCOUNT_ID_COOKIE)
		deleteCookie(SECRETS_COOKIE)

		// * Clear outseta & cache storage
		store.session.clearAll()
		forEach(store.keys(), (key) => {
			if (includes(toLower(key), 'outseta') || includes(key, 'cachedAt:') || includes(key, 'apollo')) {
				store.remove(key)
			}
		})

		$user(null)
		$isAuth(false)
		$isLoading(false)

		const redirect = options?.redirect === undefined ? true : options?.redirect

		if (redirect !== false) {
			if (redirect === true) {
				await router.push(POST_LOGOUT_PATH)
			} else {
				await router.push(redirect)
			}
		}
	}

	const gotoLogin = async () => {
		await logout()
	}

	const openLogin: IAuthContext['openLogin'] = async (options) => {
		log('openLogin', options)
		if (outsetaRef.current === null) {
			console.warn('openLogin: outsetaRef.current === null')
			return
		}

		let destination = location.href
		if (location.pathname === '/' || endsWith(location.href, '/login')) {
			destination = `${location.origin}${DEFAULT_LANDING_PATH}`
		}

		outsetaRef.current.auth.open({
			widgetMode: 'login',
			authenticationCallbackUrl: `${destination}`,
			...options,
		})
	}

	const openSignup: IAuthContext['openSignup'] = async (options) => {
		log('openSignup', options)
		if (outsetaRef.current === null) {
			console.warn('openSignup: outsetaRef.current === null')
			return
		}

		const clientIdentifier = uuidv4()
		outsetaRef.current.auth.open({
			widgetMode: 'register',
			postRegistrationUrl: `${window.location.origin}/onboarding/${clientIdentifier}`,
			postPasswordResetRedirectUrl: `${window.location.origin}/onboarding/${clientIdentifier}?passwordSet`,
			...options,
			registrationDefaults: {
				Account: {
					ClientIdentifier: clientIdentifier,
				},
			},
		})
	}

	const openProfile: IAuthContext['openProfile'] = async (options) => {
		log('openProfile', options)
		if (outsetaRef.current === null) {
			console.warn('openProfile: outsetaRef.current === null')
			return
		}
		outsetaRef.current.profile.open({ tab: 'profile', ...options })
	}

	const openUpgrade: IAuthContext['openProfile'] = async (options) => {
		log('openUpgrade', options)
		if (outsetaRef.current === null) {
			console.warn('openUpgrade: outsetaRef.current === null')
			return
		}
		outsetaRef.current.profile.open({
			tab: 'planChange',
			...options,
		})
	}

	const setPreference = async (key: string, value: any) => {
		log('setPreference', key, value)
		if (outsetaRef.current === null || !user) {
			console.warn('setPreference: outsetaRef.current === null')
			return
		}

		return await axios
			.put(
				`/api/v2/${user.Account.DqaStackType}/accounts/${user.Account.Uid}/users/${user.Uid}/preferences`,
				{
					[key]: value,
				},
				{
					headers: {
						'Content-Type': 'application/json',
						'x-auth': getToken(),
						'x-account': user.Account.Uid,
					},
				}
			)
			.then(() => {
				const userPreferences = store.get(USER_PREFERENCES_STORAGE)
				store.set(USER_PREFERENCES_STORAGE, { ...userPreferences, [key]: value })
				location.reload()
			})
			.catch((err) => {
				log('!! setPreference failed', err)
			})
	}

	// * ---
	// *	Return
	// * ---
	return (
		<AuthContext.Provider
			value={{
				user,
				getToken,
				isLoading,
				isLoggingIn,
				isAuth,
				logout,
				gotoLogin,
				openLogin,
				openSignup,
				openProfile,
				openUpgrade,
				setPreference,
				preferences: store.get(USER_PREFERENCES_STORAGE),
			}}
		>
			{children}
		</AuthContext.Provider>
	)
}
