import * as yup from 'yup'

export const validationSchema = yup.object({
	attributes: yup.object({
		title: yup.string().trim().required('enter a name for this MFA'),
	}),
})

export const validationSchemaNew = yup.object({
	attributes: yup.object({
		title: yup.string().trim().required('enter a name for this MFA'),
		secret: yup.string().trim().required('enter your secret for this MFA'),
	}),
})
