// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'
import { useDebouncedCallback } from 'use-debounce'

import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import { find, get, isEmpty, map, startsWith } from 'lodash'

import { IUseNodeField } from 'data/nodes'

import TextWithTagsInput from './Partials/TextWithTagsInput'

// ? ---
// ?	Types
// ? ---
type Props = {
	formik: any
	field: IUseNodeField
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Forms-Fields-CalculatorOperation'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function CalculatorOperation({ field, formik }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')

	// * ---
	// *	Setup
	// * ---
	const options = [
		{ operation: 'add', operationLabel: 'Add', valueLabel: 'Value' },
		{ operation: 'subtract', operationLabel: 'Subtract', valueLabel: 'Value' },
		{ operation: 'multiply', operationLabel: 'Multiply', valueLabel: 'Value' },
		{ operation: 'divide', operationLabel: 'Divide', valueLabel: 'Value' },
		{ operation: 'round', operationLabel: 'Round', valueLabel: 'Decimal Places' },
		{ operation: 'floor', operationLabel: 'Floor', valueLabel: 'Decimal Places' },
		{ operation: 'ceil', operationLabel: 'Ceil', valueLabel: 'Decimal Places' },
	]

	// * ---
	// *	Input
	// * ---
	const defaultValue = { operation: options[0].operation, value: '' }
	const currentValue = get(formik.values, field.property, '')
	const input = !isEmpty(currentValue)
		? currentValue
		: field.asStringValue
		? JSON.stringify(defaultValue)
		: defaultValue
	const value = field.asStringValue && startsWith(input, '{') ? JSON.parse(input) : input

	// * ---
	// *	Method
	// * ---
	const setOperation = (operation: string) => {
		const newValue = {
			operation,
			value: value.value,
		}
		formik.setFieldValue(field.property, field.asStringValue ? JSON.stringify(newValue) : newValue)
	}
	const setInput = (input: string) => {
		const newValue = {
			operation: value.operation,
			value: input,
		}
		formik.setFieldValue(field.property, field.asStringValue ? JSON.stringify(newValue) : newValue)
	}

	// * ---
	// *	Debounced: On Change
	// * ---
	const onChange = useDebouncedCallback(
		(event) => {
			log('onChange', formik, field.property, event)
			setInput(event.detail.value)
		},
		100,
		{ leading: false, trailing: true }
	)

	// * ---
	// *	Return
	// * ---
	return (
		<Grid container direction='row' justifyContent='space-between' alignItems='flex-start' spacing={1}>
			<Grid item xs={4}>
				<FormControl variant='outlined' fullWidth>
					<InputLabel id={`${field.property}-label`}>Operation</InputLabel>
					<Select
						data-test-id={`${namespace}--${field.property}`}
						labelId={`${field.property}-label`}
						id={field.property}
						name={field.property}
						value={value.operation}
						label={field.label}
						onChange={(event) => setOperation(event.target.value)}
					>
						{map(options, (option) => (
							<MenuItem key={option.operation} value={option.operation}>
								{option.operationLabel}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={8}>
				<TextWithTagsInput
					data-test-id={`${namespace}--${field.property}`}
					name={field.property}
					label={find(options, { operation: value.operation })?.valueLabel || field.label}
					value={value.value}
					onChange={onChange}
					hasError={
						get(formik, `touched.${field.property}`) && Boolean(get(formik, `errors.${field.property}`))
					}
					helperMessage={
						get(formik, `touched.${field.property}`) && get(formik, `errors.${field.property}`)
							? get(formik, `errors.${field.property}`)
							: get(field, `helper`)
					}
				/>
			</Grid>
		</Grid>
	)
}
