// ? ---
// ?	Imports
// ? ---
import { Edge, Node } from 'reactflow'
import debug from 'debug'

import { find, forEach, get, includes, map, min, round, union } from 'lodash'

import useNode from 'hooks/useNode'

import { INode } from 'data/nodes'

// ? ---
// ?	Constants
// ? ---
const namespace = 'data-flows-helpers-getCheckRatio'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Export
// ? ---
export const getCheckRatio = (
	nodes: INode[],
	nodeMeta: Partial<Node<any, string | undefined>>[],
	edgeMeta: Pick<Edge<any>, 'target' | 'id' | 'source'>[]
): number => {
	log('node count', nodes.length)

	// ? Drop out if no nodes
	if (nodes.length === 0) {
		return -1
	}

	// * Node Meta IDs with connections
	const nodeMetaIdsWithConnections = union(map(edgeMeta, 'source'), map(edgeMeta, 'target'))

	// * Step over each node to count actions and checks
	let checkCount = 0
	let actionCount = 0
	forEach(nodes, (node) => {
		const _node = useNode()[get(node, 'attributes.variantType')]

		const meta = find(nodeMeta, { data: { id: node.id } })
		const isConnected = includes(nodeMetaIdsWithConnections, meta?.id)

		if (isConnected) {
			switch (_node.family) {
				case 'check':
				case 'change':
				case 'validate':
					checkCount++
					break
				case 'action':
					actionCount++
					break
				default:
					// Do nothing
					break
			}
		}
	})
	log('actions / checks', actionCount, checkCount)

	// * Return ratio
	if (checkCount > 0) return min([round(checkCount / actionCount, 2), 10]) || -1
	return -1
}
