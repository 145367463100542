// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'
import { unflatten } from 'flat'
import { useFormik } from 'formik'
import { AnimatePresence, motion, useAnimation } from 'framer-motion'

import {
	Alert,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Fab,
	Grid,
	IconButton,
	useTheme,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import Icon from '@mdi/react'
import { find, get, includes, isEmpty, isEqual, map } from 'lodash'

import { icons } from 'globals/constants/icons'
import { DIALOG_LARGE_WIDTH, DIALOG_MEDIUM_WIDTH } from 'globals/constants/sizes'

import useNode from 'hooks/useNode'
import useNodes from 'hooks/useNodes'
import useThemeMode from 'hooks/useThemeMode'

import ShakeablePaperLarge from 'components/_Global/Dialogs/helpers/ShakeablePaperLarge'
import ShakeablePaperMedium from 'components/_Global/Dialogs/helpers/ShakeablePaperMedium'
import { Transition } from 'components/_Global/Dialogs/helpers/Transition'
import Field from 'components/_Global/Forms/Field'
import { INode, INodeContextOptions, INodeVariantTypes, validationSchema } from 'data/nodes'

// ? ---
// ?	Types
// ? ---
type Props = {
	state: boolean
	data: undefined | Partial<INode>
	options?: Partial<INodeContextOptions>
	close: () => void
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Nodes-NodeDialog'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function NodeDialog({ close, data, options, state }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const theme = useTheme()
	const _theme = useThemeMode()
	const _nodes = useNodes()
	const _node = useNode()
	const controls = useAnimation()
	const variantType = get(data, 'attributes.variantType', INodeVariantTypes.check_text)
	const isSpecialGroup = variantType === INodeVariantTypes.special_group

	log(_node[get(data, 'attributes.variantType', INodeVariantTypes.check_text)])

	// * ---
	// *	State
	// * ---
	const [saving, $saving] = React.useState(false)
	const [currentNode, $currentNode] = React.useState(
		_node[get(data, 'attributes.variantType', INodeVariantTypes.check_text)]
	)

	// * ---
	// *	Get value / default value
	// * ---
	const value = (path: string) => {
		const currentValue = get(structuredData, path)
		if (isEmpty(currentValue)) {
			const field = find(currentNode.fields, { property: path })
			const defaultValue = includes(path, 'comparisonType') ? null : ''
			return get(field, 'defaultValue', defaultValue)
		}
		return currentValue
	}

	// * ---
	// *	Formik
	// * ---
	const structuredData = unflatten(data) as { [key: string]: any }

	const formik = useFormik({
		validationSchema: validationSchema(currentNode),
		initialValues: {
			...structuredData,
			id: data?.id || undefined,
			__groupId: get(structuredData, 'attributes.group.data.id'),
			attributes: {
				...get(structuredData, 'attributes', {}),
				nodes: map(value('attributes.nodes.data'), 'id'),
				group: undefined,
				variantType: value('attributes.variantType'),
				element: value('attributes.element.data.id'),
				element2: value('attributes.element2.data.id'),
				mfa: value('attributes.mfa.data.id'),
				comparisonType: value('attributes.comparisonType'),
				label: value('attributes.label'),
				value: value('attributes.value'),
				value2: value('attributes.value2'),
				value3: value('attributes.value3'),
				value4: value('attributes.value4'),
				value5: value('attributes.value5'),
				value6: value('attributes.value6'),
				value7: value('attributes.value7'),
				value8: value('attributes.value8'),
				value9: value('attributes.value9'),
				value10: value('attributes.value10'),
				value11: value('attributes.value11'),
				value12: value('attributes.value12'),
				value13: value('attributes.value13'),
				value14: value('attributes.value14'),
				value15: value('attributes.value15'),
				value16: value('attributes.value16'),
				value17: value('attributes.value17'),
				value18: value('attributes.value18'),
				value19: value('attributes.value19'),
				value20: value('attributes.value20'),
				config: {
					failedStatus: value('attributes.config.failedStatus'),
					timeoutOverride: value('attributes.config.timeoutOverride'),
				},
			},
		},
		onSubmit: async (values) => {
			$saving(true)
			log('onSubmit', unflatten(values))
			await _nodes.upsert(unflatten(values), {
				onSuccess: (node) => {
					close()
					$saving(false)
					if (options?.onSuccess) options.onSuccess(node)
					if (options?.onComplete) options.onComplete(node)
				},
			})
		},
	})

	// * ---
	// *	Effect: Variant Type Change
	// * ---
	React.useEffect(() => {
		if (!isEqual(formik.values.attributes.variantType, currentNode.key)) {
			$currentNode(_node[formik.values.attributes.variantType])
		}
	}, [formik.values.attributes.variantType, _node, currentNode.key])

	// * ---
	// *	Return: Good
	// * ---
	return (
		<Dialog
			open={state}
			TransitionComponent={Transition}
			sx={{
				'& .MuiDialog-paperFullWidth': {
					maxWidth: isSpecialGroup ? DIALOG_LARGE_WIDTH : DIALOG_MEDIUM_WIDTH,
				},
			}}
			fullWidth={true}
			onClose={() => (formik.dirty ? controls.start('start') : close())}
			PaperComponent={isSpecialGroup ? ShakeablePaperLarge : ShakeablePaperMedium}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			PaperProps={{ controls }}
			aria-labelledby={namespace}
		>
			<form onSubmit={formik.handleSubmit}>
				<DialogTitle id={namespace} className={'dragHandle'}>
					{isEmpty(data?.id) ? 'Create Node' : currentNode.label}
				</DialogTitle>
				<DialogContent>
					{data?.attributes?.variantType === INodeVariantTypes.special_group && (
						<Alert
							severity='warning'
							sx={{
								mt: 0,
								mb: 0.2,
								ml: -1.5,
								mr: -1.5,
								fontWeight: 600,
								borderRadius: 0,
								borderBottom: _theme.conditional(`1px solid ${theme.palette.warning.dark}`, '0'),
							}}
						>
							All instances across all Flows will be updated.
						</Alert>
					)}
					<Grid
						container
						direction='row'
						justifyContent='space-between'
						alignItems='flex-start'
						spacing={1}
						sx={{ pt: 2, maxHeight: '78vh' }}
					>
						<AnimatePresence>
							{map(currentNode.fields || [], (field, fieldIndex) =>
								field.conditional === undefined ? (
									<Grid
										item
										xs={field.grid || 12}
										key={`${field.property}-${field.type}-${fieldIndex}-static`}
									>
										<Field field={field} subject={data} formik={formik} close={close} pb={1} />
									</Grid>
								) : field.conditional({ values: formik.values }) ? (
									<Grid
										item
										xs={12}
										key={`${field.property}-${field.type}-${fieldIndex}-conditional`}
									>
										<motion.div
											initial={{ opacity: 0, height: 0, overflow: 'hidden' }}
											animate={{
												opacity: 1,
												height: 'auto',
												overflow: 'visible',
											}}
											exit={{
												opacity: 0,
												height: 0,
												overflow: 'hidden',
											}}
										>
											<Field field={field} subject={data} formik={formik} close={close} pb={1} />
										</motion.div>
									</Grid>
								) : (
									<></>
								)
							)}
						</AnimatePresence>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Grid
						container
						direction='row'
						justifyContent='space-between'
						alignItems='center'
						spacing={1}
						sx={{ pl: 1, pr: 1 }}
					>
						<Grid item>
							{options?.showDelete && !isEmpty(data?.id) && (
								<IconButton
									data-test-id='V_nFyWsI3c1AIlk62dvSf'
									title={'Delete Node'}
									sx={{ color: theme.palette.text.secondary }}
									onClick={() => {
										// ? NOTE: This is a soft delete used for removing a node from a flow (without deleting the Node)
										options?.onDelete && options.onDelete()
										close()
									}}
								>
									<Icon path={icons.removeIcon} size={1} />
								</IconButton>
							)}
						</Grid>
						<Grid item>
							<Button
								data-test-id='lRP10nm64H2G7l8EQ4BMC'
								color={'secondary'}
								onClick={() => {
									close()
								}}
							>
								Cancel
							</Button>
						</Grid>
					</Grid>
					<Fab
						color='primary'
						aria-label='Save Node'
						type='submit'
						disabled={saving}
						sx={{
							backgroundColor:
								saving || !formik.isValid ? _theme.conditional(grey[400], grey[800]) : undefined,
						}}
					>
						<Icon path={saving ? icons.loadingIcon : icons.agreeIcon} size={1} spin={saving ? 1 : 0} />
					</Fab>
				</DialogActions>
			</form>
		</Dialog>
	)
}
