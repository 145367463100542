export const CUSTOM_ACTIVITY_TYPES: { [key: string]: string } = {
	FLOW_CREATED: 'Flow Created',
	NODE_INSERTED: 'Node Inserted',
	ELEMENT_CREATED: 'Element Created',
	EVENT_CREATED: 'Event Created',
	SCHEDULE_CREATED: 'Schedule Created',
	VAlUE_CREATED: 'Value Created',
	WEBHOOK_CREATED: 'Webhook Created',
	MFA_CREATED: 'MFA Created',
	VIDEO_VIEWED: 'Video Viewed',
	SCREENSHOT_VIEWED: 'Screenshot Viewed',
	TEST_DATA_VIEWED: 'Test Data Viewed',
	PLAN_UPGRADE_VIEWED: 'Plan Upgrade Viewed',
}

export const MAX_LOGINS_TO_TRACK = 100
export const MAX_ELEMENTS_TO_TRACK = 100
