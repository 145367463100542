// ? ---
// ?	Imports
// ? ---
import * as React from 'react'

import { compact } from 'lodash'

import { ELEMENT_VALIDATION } from 'globals/constants/validations'
import { ACTION_INPUT_GROUP } from 'globals/constants/variantGroups'

import { formatElement, sentenceElement } from 'hooks/useNode'

import { INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.action_input_clearValue,
	isLive: true,
	isMenuOption: true,
	isPro: false,
	isAccessibility: true,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.variantType',
			type: 'variantType',
			label: 'Type',
			options: ACTION_INPUT_GROUP,
		},
		{
			property: 'attributes.element',
			type: 'element',
			label: 'Element',
			validation: ELEMENT_VALIDATION,
		},
	],
	label: 'Clear',
	sentence: ({ element, isExample }: INodeRenderProps) => {
		return isExample ? `Clear input` : `Clear input ${sentenceElement(element)}`
	},
	family: INodeVariantTypes.action_input_clearValue.split('_')[0],
	type: INodeVariantTypes.action_input_clearValue.split('_')[1],
	subType: INodeVariantTypes.action_input_clearValue.split('_')[2],
	tags: compact([
		INodeVariantTypes.action_input_clearValue.split('_')[0],
		INodeVariantTypes.action_input_clearValue.split('_')[1],
		INodeVariantTypes.action_input_clearValue.split('_')[2],
		'fill',
		'input',
		'type',
		'keyboard',
		'text',
		'clear',
		'value',
		'empty',
		'wipe',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ element, isExample }: INodeRenderProps) => {
			return <>Clear {!isExample && <strong>{formatElement(element)}</strong>}</>
		},
	}
}
