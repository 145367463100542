// ? ---
// ?	Export
// ? ---
import { ITestStepStatus } from 'data/tests'

export const API_STATUS_OPTIONS = [
	{ label: '1xx (100-199)', value: '1xx' },
	{ label: '2xx (200-299)', value: '2xx' },
	{ label: '3xx (300-399)', value: '3xx' },
	{ label: '4xx (400-499)', value: '4xx' },
	{ label: '200 (ok)', value: '200' },
	{ label: '201 (created)', value: '201' },
	{ label: '202 (accepted)', value: '202' },
	{ label: '400 (bad request)', value: '400' },
	{ label: '401 (unauthorized)', value: '401' },
	{ label: '402 (payment required)', value: '402' },
	{ label: '403 (forbidden)', value: '403' },
	{ label: '404 (not found)', value: '404' },
	{ label: '405 (method not allowed)', value: '405' },
]

export const API_CONTENT_TYPE_OPTIONS = [
	{ label: 'No Body', value: 'no-body' },
	{ label: 'JSON', value: 'application/json' },
	{ label: 'XML', value: 'application/xml' },
	{ label: 'Plain Text', value: 'text/plain' },
	{ label: 'x-www-form-urlencoded', value: 'application/x-www-form-urlencoded' },
]

export const FLOW_TAG_OPTIONS = [
	{ label: 'Dev', value: 'dev' },
	{ label: 'Staging', value: 'staging' },
	{ label: 'Prod', value: 'prod' },
	{ label: 'Smoke', value: 'smoke' },
	{ label: 'Pipeline', value: 'pipeline' },
	{ label: 'WIP', value: 'wip' },
]

export const FAILED_STATUS_OPTIONS = [
	{ label: 'Failed', value: ITestStepStatus.failed, icon: 'failedIcon' },
	{ label: 'Passed with warning', value: ITestStepStatus.passed_with_warning, icon: 'passed_with_warningIcon' },
	{ label: 'Passed', value: ITestStepStatus.passed, icon: 'passedIcon' },
]
