// ? ---
// ?	Imports
// ? ---
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client'
import * as Sentry from '@sentry/nextjs'
import { onError } from 'apollo-link-error'
import { RetryLink } from 'apollo-link-retry'
import debug from 'debug'

import { GRAPHQL_CLIENT_RETRY_DELAY, GRAPHQL_CLIENT_RETRY_MAX } from 'globals/constants/api'
import { GRAPHQL_PATH } from 'globals/constants/urls'

// ? ---
// ?	Constants
// ? ---
const namespace = 'globals-client-clientApolloClient'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Retry Link
// ? ---
const retryLink = new RetryLink({
	delay: {
		initial: GRAPHQL_CLIENT_RETRY_DELAY,
		max: GRAPHQL_CLIENT_RETRY_DELAY * 10,
		jitter: true,
	},
	attempts: {
		max: GRAPHQL_CLIENT_RETRY_MAX,
		retryIf: (error) => !!error,
	},
})

// ? ---
// ?	Error Link
// ? ---
const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors)
		graphQLErrors.forEach(({ locations, message, path }) => {
			log(`!! GraphQL error`)
			Sentry.captureMessage(
				`${namespace} - GraphQL error: Message: ${message}, Location: ${locations}, Path: ${path}`,
				'warning'
			)
			console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
		})
	if (networkError) {
		log(`!! Network error`)
		Sentry.captureMessage(`${namespace} [Network error]: ${networkError}`, 'warning')
		console.error(`[Network error]: ${networkError}`)
	}
})

// ? ---
// ?	HTTP Link
// ? ---
const httpLink = new HttpLink({ uri: GRAPHQL_PATH })

// ? ---
// ?	Named Link
// ? ---
const namedLink = new ApolloLink((operation, forward) => {
	operation.setContext(() => ({
		uri: `${GRAPHQL_PATH}?${operation?.operationName}`,
	}))
	return forward ? forward(operation) : null
})

// ? ---
// ?	Export
// ? ---
export const apolloClient = new ApolloClient({
	link: ApolloLink.from([errorLink, retryLink, namedLink, httpLink]),
	cache: new InMemoryCache(),
})

export default apolloClient
