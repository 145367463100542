// ? ---
// ?	Imports
// ? ---
import * as React from 'react'

import { compact } from 'lodash'

import { INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.data_cookie_deleteAll,
	isLive: true,
	isMenuOption: false,
	isPro: false,
	isAccessibility: false,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.variantType',
			type: 'variantTypeButtonGroup',
			label: 'Type',
			options: [INodeVariantTypes.data_cookie_delete, INodeVariantTypes.data_cookie_deleteAll],
		},
	],
	label: 'Delete cookies',
	optionLabel: 'All',
	sentence: () => {
		return `Delete all cookies`
	},
	family: INodeVariantTypes.data_cookie_deleteAll.split('_')[0],
	type: INodeVariantTypes.data_cookie_deleteAll.split('_')[1],
	subType: INodeVariantTypes.data_cookie_deleteAll.split('_')[2],
	tags: compact([
		INodeVariantTypes.data_cookie_deleteAll.split('_')[0],
		INodeVariantTypes.data_cookie_deleteAll.split('_')[1],
		INodeVariantTypes.data_cookie_deleteAll.split('_')[2],
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: () => {
			return <>Delete all cookies</>
		},
	}
}
