// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import debug from 'debug'

import { event as gaEvent } from 'nextjs-google-analytics/dist/interactions/event'
import { Autocomplete, Box, Button, Grid, Skeleton, TextField } from '@mui/material'
import Icon from '@mdi/react'
import { find, get, isEmpty, isEqual, map, union, unionBy, uniq } from 'lodash'

import { icons } from 'globals/constants/icons'
import { gaEventName } from 'globals/constants/integrations'

import useRecipes from 'hooks/useRecipes'

import { IUseNodeField } from 'data/nodes'
import { IRecipe, IRecipeQueryResponse, QUERY_RECIPES, QUERY_RECIPES_SELECT } from 'data/recipes'

// ? ---
// ?	Types
// ? ---
type Props = {
	formik: any
	field: IUseNodeField
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Dialogs-UpsertFields-SelectRecipe'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function SelectRecipe({ field, formik }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const _recipes = useRecipes()

	// * ---
	// *	State
	// * ---
	const [combinedRecipes, $combinedRecipes] = React.useState<IRecipe[]>([])

	// * ---
	// *	Data
	// * ---
	const {
		data: recipes,
		loading,
		refetch,
	} = useQuery(QUERY_RECIPES_SELECT, {
		variables: {
			filters: {},
		},
		fetchPolicy: 'no-cache',
	})

	const [loadRecipe] = useLazyQuery<IRecipeQueryResponse>(QUERY_RECIPES, {
		variables: {
			filters: {
				id: {
					eq: `0`,
				},
			},
		},
		fetchPolicy: 'no-cache',
	})

	// * ---
	// *	Effect: Update combinedRecipes
	// * ---
	React.useEffect(() => {
		log('..')
		const temp = unionBy(get(recipes, 'recipes.data', []), combinedRecipes, 'id')
		if (!isEqual(temp, combinedRecipes)) {
			$combinedRecipes(temp)
		}
	}, [recipes, combinedRecipes])

	// * ---
	// *	Method: Edit Recipe
	// * ---
	const editRecipe = async () => {
		const recipes = await loadRecipe({
			variables: {
				filters: {
					id: {
						eq: get(formik?.values, field.property),
					},
				},
			},
		})
		const recipe = get(recipes, 'data.recipes.data[0]', {}) as IRecipe
		log('recipe', recipe)

		if (!isEmpty(recipe)) {
			gaEvent(gaEventName('recipe', 'edit'))
			_recipes.openUpsert(recipe, {
				onSuccess: () => {
					gaEvent(gaEventName('recipe', 'save'))
					refetch()
				},
			})
		}
	}

	// * ---
	// *	Helpers
	// * ---
	const recipeTitleFromId = (recipeId: string) => get(find(combinedRecipes, { id: recipeId }), 'attributes.title', '')

	// * ---
	// *	Return
	// * ---
	return (
		<>
			<Grid container direction='row' justifyContent='space-between' alignItems='stretch' spacing={1}>
				<Grid item xs>
					{loading ? (
						<Skeleton sx={{ height: '100%', transform: 'none' }} />
					) : (
						<Autocomplete
							data-test-id={`${namespace}--${field.property}`}
							fullWidth
							loading={loading}
							id={field.property}
							value={
								get(formik?.values, field.property) !== '' ? get(formik?.values, field.property) : null
							}
							onChange={(event, value) => formik.setFieldValue(field.property, value)}
							isOptionEqualToValue={(recipeId, value) => {
								// log('isOptionEqualToValue', { recipeId, value })
								return recipeId === value
							}}
							getOptionLabel={(recipeId: string) => recipeTitleFromId(recipeId)}
							options={uniq(map(combinedRecipes, 'id'))}
							renderInput={(params) => (
								<TextField
									{...(params as any)}
									label={field.label}
									error={
										get(formik, `touched[${field.property}]`) &&
										Boolean(get(formik, `errors[${field.property}]`))
									}
									helperText={
										get(formik, `touched[${field.property}]`) &&
										get(formik, `errors[${field.property}]`)
									}
								/>
							)}
							renderOption={(props, recipeId) => (
								<Box component='li' {...(props as any)} key={recipeId}>
									{recipeTitleFromId(recipeId)}
								</Box>
							)}
						/>
					)}
				</Grid>
				<Grid
					item
					xs={2}
					sx={{
						pt: `3px`,
						pb: `3px`,
					}}
				>
					<Button
						data-test-id='s-3hbC0pHvNyOFWxPzgOu'
						variant='outlined'
						color='secondary'
						sx={{ height: `55px`, width: '100%' }}
						disabled={isEmpty(get(formik?.values, field.property))}
						onClick={() => editRecipe()}
					>
						<Icon path={icons.editIcon} size={1} />
					</Button>
				</Grid>
				<Grid
					item
					xs={2}
					sx={{
						pt: `3px`,
						pb: `3px`,
					}}
				>
					<Button
						data-test-id='us9hN_wkEmxRpjlRFK-Ya'
						variant='outlined'
						color='secondary'
						sx={{ height: `55px`, width: '100%' }}
						onClick={() =>
							_recipes.openUpsert(
								{},
								{
									onSuccess: async (recipe) => {
										$combinedRecipes(union(combinedRecipes, [recipe]) as IRecipe[])
										await formik.setFieldValue(field.property, recipe?.id)
										setTimeout(async () => {
											await refetch()
										}, 1000)
									},
								}
							)
						}
					>
						<Icon path={icons.addIcon} size={1} />
					</Button>
				</Grid>
			</Grid>
		</>
	)
}
