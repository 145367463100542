// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { AppDataContext, IAppDataContext } from 'context/appData'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import debug from 'debug'

import { get, omit } from 'lodash'

import { IFlow } from 'data/flows'
import {
	ADD_TEST,
	EDIT_TEST,
	ITest,
	ITestContext,
	ITestQueryResponse,
	ITestStatus,
	NUKE_TEST,
	QUERY_TESTS_INBOXES,
	QUERY_TESTS_MAILBOX,
	QUERY_TESTS_STATUS,
} from 'data/tests'

// ? ---
// ?	Constants
// ? ---
const namespace = 'hooks-useTests'
const log = debug(`app:${namespace}`)
const type = 'Test'

dayjs.extend(utc)

// ? ---
// ?	Hook
// ? ---
export default function useTests() {
	// * ---
	// *	Setup
	// * ---
	const AppData = React.useContext<IAppDataContext>(AppDataContext)

	// * ---
	// *	Queries
	// * ---
	const [queryTestsStatus] = useLazyQuery<ITestQueryResponse>(QUERY_TESTS_STATUS)
	const [queryEmailTests] = useLazyQuery<ITestQueryResponse>(QUERY_TESTS_INBOXES)
	const [queryTestInbox] = useLazyQuery<ITestQueryResponse>(QUERY_TESTS_MAILBOX)

	// * Get flow test status
	const getFlowTestStatuses = async ({
		flow,
		id,
	}: {
		flow?: IFlow
		id?: string
	}): Promise<ITestQueryResponse | void> => {
		id = id || flow?.id
		if (id) {
			log('getFlowTestStatuses', id)
			const response = await queryTestsStatus({
				variables: {
					filters: { flow: { flowId: { eq: parseInt(id) } } },
					pagination: {
						pageSize: 100,
					},
				},
				fetchPolicy: 'no-cache',
			})
			log('getFlowTestStatuses response', response)
			if (response?.data) {
				return response.data
			}
		}
	}

	// * Get email tests
	const getEmailTests = async (): Promise<ITest[]> => {
		log('getEmailTests')
		const response = await queryEmailTests({
			variables: {
				filters: { hasEmail: { eq: true } },
				pagination: {
					pageSize: 20,
				},
			},
			fetchPolicy: 'no-cache',
		})
		log('getEmailTests response', response)
		return get(response, 'data.tests.data', [])
	}

	// * Get test inbox
	const getTestInbox = async ({ id }: { id: string }): Promise<ITest> => {
		log('getTestInbox')
		const response = await queryTestInbox({
			variables: {
				filters: { id: { eq: id } },
				pagination: {
					pageSize: 20,
				},
			},
			fetchPolicy: 'no-cache',
		})
		log('getTestInbox response', response)
		return get(response, 'data.tests.data', [])[0]
	}

	// * ---
	// *	Mutation
	// * ---
	const [add] = useMutation(ADD_TEST)
	const [edit] = useMutation(EDIT_TEST)
	const [nuke] = useMutation(NUKE_TEST)

	// * ---
	// *	Insert
	// * ---
	const insert: ITestContext['insert'] = async (input, options) => {
		log('insert', { input, options })
		const response = await add({
			variables: {
				data: {
					...omit(input.attributes, ['__typename']),
				},
			},
		})
		if (options?.onSuccess) options.onSuccess(response.data[`create${type}`].data)
		if (options?.onComplete) options.onComplete(response.data[`create${type}`].data)
		return response.data[`create${type}`].data
	}

	// * ---
	// *	Update
	// * ---
	const update: ITestContext['update'] = async (input, options) => {
		log('update', { input, options })
		const response = await edit({
			variables: {
				id: input.id,
				data: {
					...omit(input.attributes, ['__typename', 'updatedAt']),
				},
			},
		})
		if (options?.onSuccess) options.onSuccess(response.data[`update${type}`].data)
		if (options?.onComplete) options.onComplete(response.data[`update${type}`].data)
		return response.data[`update${type}`].data
	}

	// * ---
	// *	Remove
	// * ---
	const remove: ITestContext['remove'] = async (item, options) => {
		log('remove', { item, options })
		if (options?.force) {
			await nuke({
				variables: {
					id: item.id,
				},
			})
			if (options?.onSuccess) options.onSuccess()
			if (options?.onComplete) options.onComplete()
		} else {
			await AppData.openDeleteConfirmation(
				{},
				{
					onSuccess: async () => {
						await nuke({
							variables: {
								id: item.id,
							},
						})
						if (options?.onSuccess) options.onSuccess()
						if (options?.onComplete) options.onComplete()
					},
				}
			)
		}
	}

	// * ---
	// *	Run
	// * ---
	const run: ITestContext['run'] = async (input, options) => {
		log('run', { input, options })
		await update(
			{
				id: input.id,
				attributes: {
					status: ITestStatus.pending,
					flowId: input.attributes.flowId,
				},
			},
			options
		)
	}

	// * ---
	// *	Return
	// * ---
	return {
		insert,
		update,
		run,
		remove,
		getFlowTestStatuses,
		getEmailTests,
		getTestInbox,
	}
}
