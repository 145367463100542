// * Hostnames
export const BASE_HOSTNAME = 'app.does.qa'
export const BASE_URL = 'https://app.does.qa'
export const BASE_URL_LOCAL_DEV = 'http://localhost:3000'

// * Paths
export const GRAPHQL_PATH = '/api/graphql'
export const DEFAULT_LANDING_PATH = '/app/flows'
export const POST_LOGOUT_PATH = '/login'
export const SETTINGS_PATH = '/app/settings'
export const HELP_PATH = '/app/help'
export const HOME_PATH = '/app/home'
export const INSTALL_SLACK_PATH = '/api/slack/install'

// * URLs
export const WEBSITE_URL = 'https://does.qa'
export const THANK_YOU_URL = 'https://does.qa/thank-you'
export const SIGNUP_URL = 'https://does.qa/pricing'
export const VALUES_URL = 'https://does.qa/values'
export const DOCS_URL = 'https://docs.does.qa'
export const SUPPORT_TICKET_URL = 'https://dqa.outseta.com/support/kb'
export const ROADMAP_URL = 'https://trello.com/b/cSzw3Zc9/public-roadmap'
export const CHANGELOG_URL = 'https://does.qa/changelog'
export const UNLIMITED_PLUS_FORM = 'https://does.qa/unlimited-plus-additional-info'
export const TESTING_IN_PROGRESS = 'https://does.qa/testing-in-progress'
export const SOCKET_IO_URL = 'https://socket-o-community.does.qa'
export const BASE_COMMUNITY_API = 'https://api.o-community.does.qa'
