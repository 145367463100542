// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import * as yup from 'yup'

import Link from 'next/link'
import { compact } from 'lodash'

// import { ADYEN_GROUP } from 'globals/constants/variantGroups'
import { formatValue, sentenceElement, sentenceValue } from 'hooks/useNode'

import { INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.express_adyen_card,
	isLive: true,
	isMenuOption: true,
	isPro: false,
	isAccessibility: false,
	hasInput: true,
	hasOutput: true,
	fields: [
		// {
		// 	property: 'attributes.variantType',
		// 	type: 'variantTypeButtonGroup',
		// 	label: 'Payment Option',
		// 	options: ADYEN_GROUP,
		// 	isDev: true,
		// },
		{
			property: '',
			type: 'divider',
			label: 'Payment Details',
		},
		{
			property: 'attributes.value2',
			type: 'text-with-tags',
			defaultValue: '5555 4444 3333 1111',
			label: 'Card Number',
		},
		{
			property: 'attributes.value3',
			type: 'text-with-tags',
			defaultValue: '03',
			label: 'Expiry Month',
			grid: 4,
		},
		{
			property: 'attributes.value4',
			type: 'text-with-tags',
			defaultValue: '30',
			label: 'Expiry Year',
			grid: 4,
		},
		{
			property: 'attributes.value5',
			type: 'text-with-tags',
			defaultValue: '737',
			label: 'CVC / CVV',
			grid: 4,
		},
		{
			property: 'attributes.value6',
			type: 'text-with-tags',
			defaultValue: 'Test User',
			label: 'Name on Card',
		},
		{
			property: '',
			label: '',
			type: 'message',
			children: (
				<>
					View{' '}
					<Link
						data-test-id={`${INodeVariantTypes.express_adyen_card}--docs`}
						href={'https://docs.adyen.com/development-resources/testing/test-card-numbers/'}
						target={'_blank'}
						style={{ color: 'inherit' }}
					>
						Adyen documentation for test cards
					</Link>
				</>
			),
		},
		{
			property: '',
			type: 'divider',
			label: 'Complete Checkout',
		},
		{
			property: 'attributes.value',
			type: 'button',
			label: 'Complete Checkout',
			defaultValue: 'true',
			valueFalse: 'false',
			labelFalse: 'Fields Only',
			valueTrue: 'true',
			labelTrue: 'Purchase',
		},
		{
			property: 'attributes.element',
			type: 'element',
			label: 'Complete Purchase Button',
			validation: yup.string().when('value', {
				is: 'true',
				then: (schema) => schema.required('Element is required'),
				otherwise: (schema) => schema.notRequired(),
			}),
			conditional: ({ values }: { values: { [key: string]: any } }) => {
				return values.attributes.value !== 'false'
			},
		},
	],
	label: 'Card',
	sentence: ({ element, isExample, value, value2, value3, value4, value5, value6 }: INodeRenderProps) => {
		return isExample
			? `Complete Adyen payment`
			: value === 'true'
			? `Complete Adyen payment using name ${sentenceValue(value6)} and card details of ${sentenceValue(
					value2
			  )} ${sentenceValue(value3)}/${sentenceValue(value4)} and clicking ${sentenceElement(element)}`
			: `Fill Adyen form using name ${sentenceValue(value6)} and card details of ${sentenceValue(
					value2
			  )} ${sentenceValue(value3)}/${sentenceValue(value4)} ${sentenceValue(value5)}`
	},
	// nodeStyle: 'adyen',
	family: INodeVariantTypes.express_adyen_card.split('_')[0],
	type: INodeVariantTypes.express_adyen_card.split('_')[1],
	subType: INodeVariantTypes.express_adyen_card.split('_')[2],
	tags: compact([
		INodeVariantTypes.express_adyen_card.split('_')[0],
		INodeVariantTypes.express_adyen_card.split('_')[1],
		INodeVariantTypes.express_adyen_card.split('_')[2],
		'rest',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ isExample, value2 }: INodeRenderProps) => {
			return isExample ? (
				<>{'Adyen Widget'}</>
			) : (
				<>
					{'Adyen Card: '}
					<strong>{formatValue(value2)}</strong>
				</>
			)
		},
	}
}
