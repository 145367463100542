const OUTSETA_ID = 'outseta'

const OPTIONS = {
	id: OUTSETA_ID,
	domain: 'dqa.outseta.com',
	/* Vital setting for a single page application */
	monitorDom: true,
	/* Do not load the nocode module */
	load: 'auth,profile,support,chat',
	tokenStorage: 'local',
}

let outsetaPromise = null

const createScript = () => {
	const optionsKey = `${OUTSETA_ID}Options`

	// Set the options on window
	window[optionsKey] = OPTIONS

	// Create external script element
	const script = document.createElement('script')
	script.src = 'https://cdn.outseta.com/outseta.min.js'
	// Set name of options key on window
	script.dataset.options = optionsKey

	return script
}

// eslint-disable-next-line
export const loadOutseta = async () => {
	// In case loadOutseta is called several times,
	// lets make sure we only make one promise.
	if (outsetaPromise) return outsetaPromise

	outsetaPromise = new Promise((resolve, reject) => {
		if (window[OUTSETA_ID]) {
			// If Outseta is initialized
			// lets resolve right away
			resolve(window[OUTSETA_ID])
		} else {
			const script = createScript()

			script.onload = () => {
				if (window[OUTSETA_ID]) {
					resolve(window[OUTSETA_ID])
				} else {
					reject(new Error('Outseta.js not available'))
				}
			}

			script.onerror = () => {
				reject(new Error('Failed to load Outseta.js'))
			}

			document.head.appendChild(script)
		}
	})

	return outsetaPromise
}
