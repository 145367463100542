// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import * as yup from 'yup'

import { compact, includes, isEmpty, isString } from 'lodash'

import { STANDARD_COMPARISON_TYPE_CONDITION } from 'globals/client/conditions'
import { FAILED_STATUS_OPTIONS } from 'globals/constants/selectOptions'
import {
	COMPARISON_TYPE_VALIDATION,
	STANDARD_COMPARISON_TYPE_CONDITIONAL_VALIDATION,
} from 'globals/constants/validations'
import { VALUE_REGEX } from 'globals/constants/valueStore'

import { formatComparisonType, formatValue, sentenceValue } from 'hooks/useNode'

import { INodeComparisonTypes, INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'
import { ITestStepStatus } from 'data/tests'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.check_sftp_fileCount,
	isLive: true,
	isMenuOption: false,
	isPro: false,
	isAccessibility: false,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.variantType',
			type: 'variantType',
			label: 'Command',
			options: [
				INodeVariantTypes.check_sftp_fileExist,
				INodeVariantTypes.check_sftp_fileNotExist,
				INodeVariantTypes.check_sftp_fileCount,
			],
		},
		{
			property: '',
			type: 'divider',
			label: 'Connection',
		},
		{
			property: 'attributes.value6',
			type: 'text-with-tags',
			defaultValue: 'sftp://',
			label: 'URL',
			validation: yup.string().trim().required('URL is required'),
			grid: 9,
		},
		{
			property: 'attributes.value7',
			type: 'dropdown',
			label: 'Port',
			defaultValue: '22',
			options: [{ label: '22', value: '22' }],
			grid: 3,
		},
		{
			property: 'attributes.value8',
			type: 'text-with-tags',
			label: 'User',
			grid: 6,
		},
		{
			property: 'attributes.value9',
			type: 'text-with-tags',
			label: 'Password',
			grid: 6,
		},
		{
			property: '',
			type: 'divider',
			label: 'Directory',
		},
		{
			property: 'attributes.value2',
			type: 'text-with-tags',
			label: 'Path',
			defaultValue: '/',
			validation: yup.string().test('is-valid-path', 'Path must start with "/"', (value: any) => {
				if (isString(value) && !isEmpty(value)) {
					return value.match(VALUE_REGEX) ? true : /^\//gim.test(value)
				}
				return false
			}),
		},
		{
			property: 'attributes.comparisonType',
			type: 'comparisonType',
			defaultValue: 'greaterThanOrEqual',
			label: 'Comparison Type',
			validation: COMPARISON_TYPE_VALIDATION,
		},
		{
			property: 'attributes.value3',
			type: 'text-with-tags',
			label: 'Value',
			conditional: STANDARD_COMPARISON_TYPE_CONDITION,
			validation: STANDARD_COMPARISON_TYPE_CONDITIONAL_VALIDATION,
		},
		{
			property: 'attributes.config.failedStatus',
			type: 'button-group',
			label: 'Status on fail',
			defaultValue: ITestStepStatus.failed,
			options: FAILED_STATUS_OPTIONS,
		},
	],
	label: 'Check SFTP file count',
	sentence: ({ comparisonType, isExample, value3 }: INodeRenderProps) => {
		return isExample
			? `Check SFTP file count`
			: `Check SFTP file count ${formatComparisonType(comparisonType)} ${
					!includes([INodeComparisonTypes.hasValue, INodeComparisonTypes.notHasValue], comparisonType) &&
					sentenceValue(value3)
			  }`
	},
	family: INodeVariantTypes.check_sftp_fileCount.split('_')[0],
	type: INodeVariantTypes.check_sftp_fileCount.split('_')[1],
	subType: INodeVariantTypes.check_sftp_fileCount.split('_')[2],
	tags: compact([
		INodeVariantTypes.check_sftp_fileCount.split('_')[0],
		INodeVariantTypes.check_sftp_fileCount.split('_')[1],
		INodeVariantTypes.check_sftp_fileCount.split('_')[2],
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ comparisonType, isExample, value2, value3 }: INodeRenderProps) => {
			return (
				<>
					{isExample ? (
						<>Check SFTP file count</>
					) : (
						<>
							Check SFTP <strong>{formatValue(value2)}</strong> file count{' '}
							<em>{formatComparisonType(comparisonType)}</em>{' '}
							{!includes(
								[INodeComparisonTypes.hasValue, INodeComparisonTypes.notHasValue],
								comparisonType
							) && <strong>{formatValue(value3)}</strong>}
						</>
					)}
				</>
			)
		},
	}
}
