// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'

import {
	Box,
	Checkbox,
	Chip,
	FormControl,
	FormHelperText,
	InputLabel,
	ListItemText,
	MenuItem,
	Select,
} from '@mui/material'
import { compact, find, get, includes, map, reject, uniq, uniqBy, upperFirst } from 'lodash'

import useAccount from 'hooks/useAccount'

import { IUseNodeField } from 'data/nodes'

// ? ---
// ?	Types
// ? ---
type Props = {
	formik: any
	field: IUseNodeField
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Dialogs-UpsertFields-Tags'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function Tags({ field, formik }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')

	const _account = useAccount()
	const tagOptions: { label: string; value: string }[] = reject(
		uniqBy(
			[
				..._account.tags(),
				...map(
					uniq((get(formik?.values, field.property, field.defaultValue || '') as string).split('|')),
					(tag: string) => {
						return { label: upperFirst(tag), value: tag }
					}
				),
			],
			'value'
		),
		{ label: '' }
	) as unknown as { label: string; value: string }[]

	const inputValue = field.asStringValue
		? compact(uniq(get(formik?.values, field.property, field.defaultValue || '').split('|')))
		: get(formik?.values, field.property, [])

	// * ---
	// *	Status
	// * ---
	const [internalValue, $internalValue] = React.useState<string[]>(inputValue)

	// * ---
	// *	Effect: Update Value
	// * ---
	React.useEffect(() => {
		if (field.asStringValue) {
			formik.setFieldValue(field.property, `|${internalValue.join('|')}|`)
		} else {
			formik.setFieldValue(field.property, internalValue)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [internalValue])

	// * ---
	// *	Return
	// * ---
	return (
		<FormControl variant='outlined' fullWidth>
			<InputLabel id={`${field.property}-label`}>{field.label}</InputLabel>
			<Select
				data-test-id={`${namespace}--${field.property}`}
				labelId={`${field.property}-label`}
				id={field.property}
				name={field.property}
				multiple
				value={internalValue}
				label={field.label}
				renderValue={(selected: string[]) => (
					<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
						{map(selected, (value: string) => (
							<Chip
								key={value}
								label={get(find(tagOptions, { value }), 'label', '')}
								size='small'
								sx={{ textTransform: 'uppercase', fontSize: '80%', fontWeight: 600 }}
							/>
						))}
					</Box>
				)}
				onChange={(event) => $internalValue(event.target.value as string[])}
			>
				{map(tagOptions, (option) => (
					<MenuItem key={option.value} value={option.value} dense={true}>
						<Checkbox checked={includes(get(formik?.values, field.property, []), option.value)} />
						<ListItemText primary={option.label} />
					</MenuItem>
				))}
			</Select>
			{get(field, 'helper') && <FormHelperText>{get(field, 'helper')}</FormHelperText>}
		</FormControl>
	)
}
