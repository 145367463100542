// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import * as yup from 'yup'

import { compact, map } from 'lodash'

import { ELEMENT_VALIDATION } from 'globals/constants/validations'
import { ACTION_SCROLL_GROUP } from 'globals/constants/variantGroups'

import { formatElement, sentenceElement, sentenceValue } from 'hooks/useNode'

import { INodeActionScrollOptions, INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.action_scroll_intoView,
	isLive: true,
	isMenuOption: true,
	isPro: false,
	isAccessibility: true,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.variantType',
			type: 'variantType',
			label: 'Type',
			options: ACTION_SCROLL_GROUP,
		},
		{
			property: 'attributes.element',
			type: 'element',
			label: 'Element',
			validation: ELEMENT_VALIDATION,
		},
		{
			property: 'attributes.value',
			type: 'dropdown',
			label: 'Target',
			defaultValue: 'nearest',
			options: map(INodeActionScrollOptions, (label, value) => {
				return {
					label,
					value,
				}
			}),
		},
		{
			property: '',
			type: 'divider',
			label: 'Target',
			isDev: true,
		},
		{
			property: 'attributes.value2',
			type: 'button-group',
			asStringValue: true,
			defaultValue: 'page',
			label: 'Scrollable Target',
			options: [
				{
					label: 'Page',
					value: 'page',
				},
				{
					label: 'Element',
					value: 'element',
				},
			],
			isDev: true,
		},
		{
			property: 'attributes.element2',
			type: 'element',
			label: 'Parent Element',
			validation: yup.string().when('value', {
				is: 'true',
				then: (schema) => schema.required('Element is required'),
				otherwise: (schema) => schema.notRequired(),
			}),
			conditional: ({ values }: { values: { [key: string]: any } }) => {
				return values.attributes.value2 === 'element'
			},
		},
	],
	label: 'Scroll into view',
	sentence: ({ element, element2, isExample, value, value2 }: INodeRenderProps) => {
		return isExample
			? `Scroll into view`
			: value2 === 'element'
			? `Scroll ${sentenceElement(element)} ${sentenceValue(value)} point into view within ${formatElement(
					element2
			  )}`
			: `Scroll ${sentenceElement(element)} ${sentenceValue(value)} point into view`
	},
	family: INodeVariantTypes.action_scroll_intoView.split('_')[0],
	type: INodeVariantTypes.action_scroll_intoView.split('_')[1],
	subType: INodeVariantTypes.action_scroll_intoView.split('_')[2],
	tags: compact([
		INodeVariantTypes.action_scroll_intoView.split('_')[0],
		INodeVariantTypes.action_scroll_intoView.split('_')[1],
		INodeVariantTypes.action_scroll_intoView.split('_')[2],
		'view',
		'target',
		'focus',
		'move',
		'viewport',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ element, isExample }: INodeRenderProps) => {
			return (
				<>
					{isExample ? (
						'Scroll'
					) : (
						<>
							Scroll <strong>{formatElement(element)}</strong> into view
						</>
					)}
				</>
			)
		},
	}
}
