// ? ---
// ?	Fragment
// ? ---
import gql from 'graphql-tag'

import { MAX_ITEMS_PER_PAGE } from 'globals/constants/api'

export const EVENT_FRAGMENT = `
	fragment eventFragment on EventEntity {
		id
		attributes {
			title
      key
			webhook
			webhookUrl
      webhookHeaders
      email
      emailRecipients
      runStatuses
      triggerTypes
      status
		}
	}
`

// ? ---
// ?	Queries
// ? ---
export const QUERY_EVENTS = gql(/* GraphQL */ `
	${EVENT_FRAGMENT}
	query query_events_main($filters: EventFiltersInput!) {
		events(filters: $filters, pagination: { pageSize: ${MAX_ITEMS_PER_PAGE} }, sort: "createdAt:desc") {
			data {
				...eventFragment
			}
		}
	}
`)

export const QUERY_EVENTS_WEBHOOK = gql(/* GraphQL */ `
	${EVENT_FRAGMENT}
	query query_events_webhook($filters: EventFiltersInput!) {
		events(filters: $filters, pagination: { pageSize: ${MAX_ITEMS_PER_PAGE} }, sort: "createdAt:desc") {
			data {
				...eventFragment
			}
		}
	}
`)

// ? ---
// ?	Mutations
// ? --
export const ADD_EVENT = gql(/* GraphQL */ `
	mutation create_event($data: EventInput!) {
		createEvent(data: $data) {
			data {
				id
			}
		}
	}
`)

export const EDIT_EVENT = gql(/* GraphQL */ `
	${EVENT_FRAGMENT}
	mutation update_event($id: ID!, $data: EventInput!) {
		updateEvent(id: $id, data: $data) {
			data {
				...eventFragment
			}
		}
	}
`)

export const NUKE_EVENT = gql(/* GraphQL */ `
	mutation delete_event($id: ID!) {
		deleteEvent(id: $id) {
			data {
				id
			}
		}
	}
`)
