// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'

import Image from 'next/image'

import { ITestArtifact } from 'data/tests'
import { getSignedArtifact } from 'data/tests/helpers/getSignedArtifact'

// ? ---
// ?	Types
// ? ---
type Props = {
	data?: ITestArtifact
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Dialogs-ArtifactDialogImage'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function ArtifactDialogImage({ data }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')

	// * ---
	// *	State
	// * ---
	const [signedUrl, $signedUrl] = React.useState<string | undefined>(undefined)

	// * ---
	// *	Effect: Get Signed URL
	// * ---
	React.useEffect(() => {
		const getSignedUrl = async (key: string) => {
			const temp = await getSignedArtifact(key)
			if (temp) {
				$signedUrl(temp)
			}
		}
		if (data?.key) {
			getSignedUrl(data.key)
		}
	}, [data, signedUrl])

	// ! ---
	// !	Return: No artifact
	// ! ---
	if (!data) {
		return <></>
	}

	// * ---
	// *	Return: Good
	// * ---
	return <>{signedUrl && <Image alt={data.name} src={signedUrl} fill style={{ objectFit: 'contain' }} />}</>
}
