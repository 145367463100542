// ? ---
// ?	Imports
// ? ---
import * as React from 'react'

import { compact } from 'lodash'

import { INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.special_group,
	isLive: true,
	isMenuOption: false,
	isPro: false,
	isAccessibility: false,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.label',
			type: 'text',
			label: 'Name',
		},
		{
			property: 'attributes.nodes',
			type: 'nodes',
			label: 'Nodes',
		},
	],
	label: 'Node group',
	sentence: ({ label }: INodeRenderProps) => {
		return `Run: ${label}`
	},
	family: INodeVariantTypes.special_group.split('_')[0],
	type: INodeVariantTypes.special_group.split('_')[1],
	subType: INodeVariantTypes.special_group.split('_')[2],
	tags: compact([
		INodeVariantTypes.special_group.split('_')[0],
		INodeVariantTypes.special_group.split('_')[1],
		INodeVariantTypes.special_group.split('_')[2],
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ label }: INodeRenderProps) => {
			return <>{label}</>
		},
	}
}
