// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import Draggable from 'react-draggable'
import { AnimationControls, motion } from 'framer-motion'

import { Box, Paper, PaperProps } from '@mui/material'

import { DIALOG_SMALL_WIDTH } from 'globals/constants/sizes'

// ? ---
// ?	Types
// ? ---
interface ShakableDialog extends Partial<PaperProps<'div', any>> {
	controls?: AnimationControls
}

// ? ---
// ?	Component
// ? ---
export default function ShakeablePaperSmall({ controls, ...props }: ShakableDialog): JSX.Element {
	return (
		<Draggable handle={'.MuiDialogTitle-root'} cancel={'[class*="MuiDialogContent-root"]'}>
			<Box sx={{ width: '100%', maxWidth: DIALOG_SMALL_WIDTH }}>
				<motion.div
					variants={{
						start: {
							rotate: [-1, 1, 0],
							transition: {
								repeat: 5,
								duration: 0.12,
							},
						},
					}}
					animate={controls}
				>
					<Paper {...props} />
				</motion.div>
			</Box>
		</Draggable>
	)
}
