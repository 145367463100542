// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import * as yup from 'yup'

import { compact } from 'lodash'

import { RUNNER_HEADER_GROUP } from 'globals/constants/variantGroups'

import { formatValue, sentenceValue } from 'hooks/useNode'

import { INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.runner_header_add,
	isLive: true,
	isMenuOption: false,
	isPro: false,
	isAccessibility: false,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.variantType',
			type: 'variantType',
			label: 'Type',
			options: RUNNER_HEADER_GROUP,
		},
		{
			property: 'attributes.value2',
			type: 'text',
			label: 'Key',
			validation: yup.string().trim().required(),
		},
		{
			property: 'attributes.value3',
			type: 'text-with-tags',
			label: 'Value',
			validation: yup.string(),
		},
	],
	label: 'Add header',
	sentence: ({ isExample, value2, value3 }: INodeRenderProps) => {
		return isExample
			? `Add browser header`
			: `Add browser header ${sentenceValue(value2)} with value ${sentenceValue(value3)}`
	},
	family: INodeVariantTypes.runner_header_add.split('_')[0],
	type: INodeVariantTypes.runner_header_add.split('_')[1],
	subType: INodeVariantTypes.runner_header_add.split('_')[2],
	tags: compact([
		INodeVariantTypes.runner_header_add.split('_')[0],
		INodeVariantTypes.runner_header_add.split('_')[1],
		INodeVariantTypes.runner_header_add.split('_')[2],
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ isExample, value2, value3 }: INodeRenderProps) => {
			return (
				<>
					{isExample ? (
						<>Add Header</>
					) : (
						<>
							Add Header <strong>{formatValue(value2)}</strong> with value{' '}
							<strong>{formatValue(value3)}</strong>
						</>
					)}
				</>
			)
		},
	}
}
