// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { get, map } from 'lodash'

import useNode from 'hooks/useNode'

import { IUseNodeField } from 'data/nodes'

// ? ---
// ?	Types
// ? ---
type Props = {
	formik: any
	field: IUseNodeField
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Dialogs-UpsertFields-VariantType'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function VariantType({ field, formik }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const _node = useNode()

	// * ---
	// *	Return
	// * ---
	return (
		<FormControl variant='outlined' fullWidth>
			<InputLabel id={`${field.property}-label`}>{field.label}</InputLabel>
			<Select
				data-test-id={`${namespace}--${field.property}`}
				labelId={`${field.property}-label`}
				id={field.property}
				name={field.property}
				value={get(formik?.values, field.property)}
				label={field.label}
				onChange={(event) => formik.setFieldValue(field.property, event.target.value)}
			>
				{map(field.options, (option) => (
					<MenuItem key={option} value={option}>
						{_node[option].label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	)
}
