// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import * as yup from 'yup'

import { compact } from 'lodash'

import { formatValue, sentenceValue } from 'hooks/useNode'

import { INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.integration_slack_send,
	isLive: true,
	isMenuOption: true,
	isPro: false,
	isAccessibility: false,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.value',
			type: 'slackChannel',
			label: 'Channel',
			validation: yup.string().trim().required('Channel required'),
		},
		{
			property: 'attributes.value2',
			type: 'text-with-tags',
			label: 'Message',
			validation: yup.string().trim().required('Message required'),
		},
	],
	label: 'Slack send',
	sentence: ({ isExample, value2 }: INodeRenderProps) => {
		return isExample ? `Slack send` : `Slack send: ${sentenceValue(value2)}`
	},
	family: INodeVariantTypes.integration_slack_send.split('_')[0],
	type: INodeVariantTypes.integration_slack_send.split('_')[1],
	subType: INodeVariantTypes.integration_slack_send.split('_')[2],
	tags: compact([
		INodeVariantTypes.integration_slack_send.split('_')[0],
		INodeVariantTypes.integration_slack_send.split('_')[1],
		INodeVariantTypes.integration_slack_send.split('_')[2],
		'message',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ isExample, value2 }: INodeRenderProps) => {
			return (
				<>
					Slack send
					{!isExample && (
						<>
							{': '}
							<strong>{formatValue(value2)}</strong>
						</>
					)}
				</>
			)
		},
	}
}
