// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import * as yup from 'yup'

import { compact } from 'lodash'

import { formatValue, sentenceValue } from 'hooks/useNode'

import { INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.data_cookie_delete,
	isLive: true,
	isMenuOption: true,
	isPro: false,
	isAccessibility: false,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.variantType',
			type: 'variantTypeButtonGroup',
			label: 'Type',
			options: [INodeVariantTypes.data_cookie_delete, INodeVariantTypes.data_cookie_deleteAll],
		},
		{
			property: 'attributes.value',
			type: 'text',
			label: 'Cookie Name',
			validation: yup.string().trim().required('Cookie name is required'),
		},
	],
	label: 'Delete cookies',
	optionLabel: 'One',
	sentence: ({ isExample, value: cookieName }: INodeRenderProps) => {
		return isExample ? `Delete cookie` : `Delete cookie ${sentenceValue(cookieName)}`
	},
	family: INodeVariantTypes.data_cookie_delete.split('_')[0],
	type: INodeVariantTypes.data_cookie_delete.split('_')[1],
	subType: INodeVariantTypes.data_cookie_delete.split('_')[2],
	tags: compact([
		INodeVariantTypes.data_cookie_delete.split('_')[0],
		INodeVariantTypes.data_cookie_delete.split('_')[1],
		INodeVariantTypes.data_cookie_delete.split('_')[2],
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ isExample, value: cookieName }: INodeRenderProps) => {
			return <>Delete cookie {!isExample && <strong>{formatValue(cookieName)}</strong>}</>
		},
	}
}
