// ? ---
// ?	Imports
// ? ---
// import * as React from 'react'
// import debug from 'debug'

import { get, isEqual } from 'lodash'

import { useAuth } from 'components/_Global/_____AuthProvider'
import { IUseNode } from 'data/nodes'

// ? ---
// ?	Constants
// ? ---
// const namespace = 'hooks-useCan'
// const log = debug(`app:${namespace}`)

// ? ---
// ?	Hook
// ? ---
export default function useCan() {
	// * ---
	// *	Setup
	// * ---
	// log('.')
	const { user } = useAuth()
	const isPrimaryContact = isEqual(get(user, 'Uid', 'me'), get(user, 'Account.PrimaryContact.Uid', 'admin'))

	// * ---
	// *	MANAGE
	// * ---

	// * Users
	const manageUsers = () => {
		// ? User can manage users
		return isPrimaryContact
	}

	// * Plan
	const managePlan = () => {
		// ? User can manage plan
		return isPrimaryContact
	}

	// * ---
	// *	ACCESS
	// * ---

	// * Dev Feature
	const accessDevFeature = () => {
		// ? Account has development features
		return get(user, 'Account.DqaDevFeatures', false)
	}

	// * Pro Feature
	const accessProFeature = () => {
		return true
	}

	// * Node
	const accessNode = (node: IUseNode) => {
		// ? NOT pro node OR account have pro features
		return !node.isPro || accessProFeature()
	}

	// * ---
	// *	SEE
	// * ---

	// * Node
	const seeNode = (node: IUseNode) => {
		// ? Node is live OR account can see development features
		return node.isLive || accessDevFeature()
	}

	// * ---
	// *	Return
	// * ---
	return {
		see: {
			node: (node: IUseNode) => seeNode(node),
		},
		access: {
			node: (node: IUseNode) => accessNode(node),
			devFeature: () => accessDevFeature(),
		},
		manage: {
			users: () => manageUsers(),
			plan: () => managePlan(),
		},
	}
}
