// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'
import { useDebouncedCallback } from 'use-debounce'

import { Grid } from '@mui/material'
import { get, isEmpty, startsWith } from 'lodash'

import { IUseNodeField } from 'data/nodes'

import TextWithTagsInput from './Partials/TextWithTagsInput'

// ? ---
// ?	Types
// ? ---
type Props = {
	formik: any
	field: IUseNodeField
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Forms-Fields-ReplacementOperation'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function ReplacementOperation({ field, formik }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')

	// * ---
	// *	Setup
	// * ---

	// * ---
	// *	Input
	// * ---
	const defaultValue = { pattern: '', replacement: '' }
	const currentValue = get(formik.values, field.property, '')
	const input = !isEmpty(currentValue)
		? currentValue
		: field.asStringValue
		? JSON.stringify(defaultValue)
		: defaultValue
	const value = field.asStringValue && startsWith(input, '{') ? JSON.parse(input) : input

	// * ---
	// *	Method
	// * ---
	const setInputLeft = (input: string) => {
		const newValue = {
			pattern: input,
			replacement: value.replacement,
		}
		formik.setFieldValue(field.property, field.asStringValue ? JSON.stringify(newValue) : newValue)
	}
	const setInputRight = (input: string) => {
		const newValue = {
			pattern: value.pattern,
			replacement: input,
		}
		formik.setFieldValue(field.property, field.asStringValue ? JSON.stringify(newValue) : newValue)
	}

	// * ---
	// *	Debounced: On Change
	// * ---
	const onChangeLeft = useDebouncedCallback(
		(event) => {
			log('onChange', formik, field.property, event)
			setInputLeft(event.detail.value)
		},
		100,
		{ leading: false, trailing: true }
	)

	const onChangeRight = useDebouncedCallback(
		(event) => {
			log('onChange', formik, field.property, event)
			setInputRight(event.detail.value)
		},
		100,
		{ leading: false, trailing: true }
	)

	// * ---
	// *	Return
	// * ---
	return (
		<Grid container direction='row' justifyContent='space-between' alignItems='flex-start' spacing={1}>
			<Grid item xs={6}>
				<TextWithTagsInput
					data-test-id={`${namespace}--${field.property}-pattern`}
					name={`${field.property}-pattern`}
					label={'Find'}
					value={value.pattern}
					onChange={onChangeLeft}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextWithTagsInput
					data-test-id={`${namespace}--${field.property}-replacement`}
					name={`${field.property}-replacement`}
					label={field.label || 'Replace'}
					value={value.replacement}
					onChange={onChangeRight}
					hasError={
						get(formik, `touched.${field.property}`) && Boolean(get(formik, `errors.${field.property}`))
					}
					helperMessage={
						get(formik, `touched.${field.property}`) && get(formik, `errors.${field.property}`)
							? get(formik, `errors.${field.property}`)
							: get(field, `helper`)
					}
				/>
			</Grid>
		</Grid>
	)
}
