export const GREY = {
	50: '#FDFDFD',
	100: '#f7f9fc',
	200: '#F1F3F8',
	300: '#EBEDF6',
	400: '#E6E8EE',
	500: '#C5C7D3',
	600: '#93969F',
	700: '#404449',
	800: '#1F2327',
	900: '#161A1C',
}

export default GREY
