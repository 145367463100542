// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import * as yup from 'yup'

import { compact } from 'lodash'

import { formatValue, sentenceValue } from 'hooks/useNode'

import { INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.express_doesqa_run,
	isLive: true,
	isMenuOption: true,
	isPro: false,
	isAccessibility: false,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.value6',
			type: 'text-with-tags',
			label: 'Run Label',
			validation: yup.string().trim().required('Run Label is required'),
		},
		{
			property: 'divider-0',
			type: 'divider',
			label: 'Flows',
		},
		{
			property: 'attributes.value',
			type: 'button-group',
			asStringValue: true,
			defaultValue: 'ids',
			label: 'Flow Selection',
			options: [
				{
					label: 'Pick',
					value: 'ids',
				},
				{
					label: 'Filter',
					value: 'tags',
				},
			],
		},
		{
			property: 'attributes.value2',
			type: 'flows',
			label: 'Flows',
			asStringValue: true,
			conditional: ({ values }: { values: { [key: string]: any } }) => {
				return values.attributes.value !== 'tags'
			},
		},
		{
			property: 'attributes.value3',
			type: 'tags',
			label: 'With All Tags',
			asStringValue: true,
			conditional: ({ values }: { values: { [key: string]: any } }) => {
				return values.attributes.value === 'tags'
			},
			helper: 'Flow must have all of these tags',
		},
		{
			property: 'attributes.value4',
			type: 'tags',
			label: 'Without Any Tags',
			asStringValue: true,
			conditional: ({ values }: { values: { [key: string]: any } }) => {
				return values.attributes.value === 'tags'
			},
			helper: 'Flows will be excluded if they have any of these tags',
		},
		{
			property: 'divider-1',
			type: 'divider',
			label: 'Values',
		},
		{
			property: 'attributes.value5',
			type: 'json',
			label: 'Values',
			asStringValue: true,
			helper: `Values can be used inside strings using the format: "{{runId}}".`,
		},
	],
	label: 'DoesQA Run',
	sentence: ({ isExample, value, value2, value3, value4 }: INodeRenderProps) => {
		return isExample
			? `DoesQA Run:`
			: value === 'ids'
			? `DoesQA Run: Flows with the ids: ${sentenceValue(compact((value2 || '').split('|')).join(', '))}`
			: `DoesQA Run: Flows tagged with ${sentenceValue(
					compact((value3 || '').split('|')).join(', ')
			  )} without ${sentenceValue(compact((value4 || '').split('|')).join(', '))}`
	},
	// nodeStyle: 'adyen',
	family: INodeVariantTypes.express_doesqa_run.split('_')[0],
	type: INodeVariantTypes.express_doesqa_run.split('_')[1],
	subType: INodeVariantTypes.express_doesqa_run.split('_')[2],
	tags: compact([
		INodeVariantTypes.express_doesqa_run.split('_')[0],
		INodeVariantTypes.express_doesqa_run.split('_')[1],
		INodeVariantTypes.express_doesqa_run.split('_')[2],
		'rest',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ isExample, value, value2, value3, value4 }: INodeRenderProps) => {
			return isExample ? (
				<>{'DoesQA Run'}</>
			) : (
				<>
					{'DoesQA Run: '}
					{value === 'ids' && compact((value2 || '').split('|')).length === 1 ? (
						<>
							<strong>1</strong> Flow
						</>
					) : value === 'ids' && compact((value2 || '').split('|')).length > 1 ? (
						<>
							<strong>{compact((value2 || '').split('|')).length}</strong> Flows
						</>
					) : (
						<>
							Flows{' '}
							{compact((value3 || '').split('|')).length > 0 && (
								<>
									with <strong>{formatValue(compact((value3 || '').split('|')).join(', '))}</strong>
									{compact((value4 || '').split('|')).length > 0 && <>, and </>}
								</>
							)}
							{compact((value4 || '').split('|')).length > 0 && (
								<>
									without{' '}
									<strong>{formatValue(compact((value4 || '').split('|')).join(', '))}</strong>
								</>
							)}
						</>
					)}
				</>
			)
		},
	}
}
