// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'

import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { event as gaEvent } from 'nextjs-google-analytics/dist/interactions/event'
import { Box, Button, Container, Grid, Typography, useTheme } from '@mui/material'

import { gaEventName } from 'globals/constants/integrations'
import { BASE_HOSTNAME, DEFAULT_LANDING_PATH, SIGNUP_URL, WEBSITE_URL } from 'globals/constants/urls'

import { useAuth } from 'components/_Global/_____AuthProvider'

import Loading from './Loading'

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-E403'
const log = debug(`app:${namespace}`)

// ? ---
// ?	View
// ? ---
export default function View(): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const theme = useTheme()
	const router = useRouter()
	const { isAuth, isLoading, openLogin } = useAuth()

	log('router', router)

	// * ---
	// *	State
	// * ---
	const [loginReady, $loginReady] = React.useState(false)
	const [origin, $origin] = React.useState(`https://${BASE_HOSTNAME}`)

	// * ---
	// *	Effect: Initial Load
	// * ---
	React.useEffect(() => {
		if (origin !== location.origin) {
			$origin(location.origin)
		}
	}, [origin])

	// * ---
	// *	Effect: Auto open login
	// * ---
	React.useEffect(() => {
		let loginTimeoutHandler: NodeJS.Timeout
		let buttonTimeoutHandler: NodeJS.Timeout
		if (!isLoading) {
			if (!isAuth) {
				loginTimeoutHandler = setTimeout(() => {
					openLogin()
				}, 0)
				buttonTimeoutHandler = setTimeout(() => {
					$loginReady(true)
				}, 2000)
			} else {
				router.push(DEFAULT_LANDING_PATH)
			}
		}
		return () => {
			clearTimeout(loginTimeoutHandler)
			clearTimeout(buttonTimeoutHandler)
		}
	}, [openLogin, isLoading, isAuth, router, origin])

	// * ---
	// *	Return: View
	// * ---
	return (
		<>
			{loginReady ? (
				<Container maxWidth='md'>
					<Grid
						container
						direction='column'
						justifyContent='space-between'
						alignItems='stretch'
						sx={{ minHeight: '100vh' }}
					>
						<Grid item>
							<Box sx={{ mt: 4, mb: 3, textAlign: 'center' }}>
								<Box sx={{ mb: 1 }}>
									<Link data-test-id={`${namespace}--logo`} href={WEBSITE_URL}>
										<Image
											src='/dqa-logo-color.svg'
											alt={'Does.QA Logo'}
											height={100}
											width={100}
										/>
									</Link>
								</Box>
								<Box>
									<Typography
										variant={'h1'}
										sx={{ fontSize: '300%', fontWeight: 'bold !important' }}
										gutterBottom
									>
										Please login
									</Typography>
									<Typography variant={'body1'} sx={{ mb: 1.5 }} color={theme.palette.text.secondary}>
										You need to login to view this page.
									</Typography>
									<div id='login-embed'></div>

									<>
										<Box>
											<Button
												data-test-id='J6NzmlDWPNAK9NAnJrqMY'
												variant={'contained'}
												size={'large'}
												sx={{
													fontSize: '110%',
													fontWeight: 'bold',
													p: `${theme.spacing(1)} ${theme.spacing(2)}`,
													borderRadius: theme.shape.borderRadius,
													m: 1,
												}}
												onClick={() => {
													gaEvent(gaEventName('login', 'login-button'))
													openLogin()
												}}
											>
												{`Login`}
											</Button>
										</Box>
										<Box>
											<Button
												data-test-id='fuAhJyL-ZARLOAqdZ_PyA'
												data-testid={'button__login'}
												onClick={() => gaEvent(gaEventName('login', 'exit', 'signup'))}
												href={SIGNUP_URL}
											>
												Create Account
											</Button>
										</Box>
									</>
								</Box>
							</Box>
						</Grid>
						<Grid item></Grid>
					</Grid>
				</Container>
			) : (
				<Loading />
			)}
		</>
	)
}
