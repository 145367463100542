// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'
import { useAnimation } from 'framer-motion'

import { event as gaEvent } from 'nextjs-google-analytics/dist/interactions/event'
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography, useTheme } from '@mui/material'
import Icon from '@mdi/react'

import { icons } from 'globals/constants/icons'
import { gaEventName } from 'globals/constants/integrations'
import { DIALOG_MEDIUM_WIDTH } from 'globals/constants/sizes'

import useAccount from 'hooks/useAccount'
import useActivity from 'hooks/useActivity'

import { useAuth } from 'components/_Global/_____AuthProvider'
import ShakeablePaperMedium from 'components/_Global/Dialogs/helpers/ShakeablePaperMedium'
import { Transition } from 'components/_Global/Dialogs/helpers/Transition'

// ? ---
// ?	Types
// ? ---
type Props = {
	state: boolean
	close: () => void
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Dialogs-MinutesRequiredDialog'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function MinutesRequiredDialog({ close, state }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const theme = useTheme()
	const { openUpgrade, user } = useAuth()
	const controls = useAnimation()
	const _account = useAccount()
	const _activity = useActivity()

	// * ---
	// *	Return: Good
	// * ---

	return (
		<Dialog
			open={state}
			TransitionComponent={Transition}
			sx={{
				'& .MuiDialog-paperFullWidth': {
					maxWidth: DIALOG_MEDIUM_WIDTH,
				},
			}}
			fullWidth={true}
			onClose={() => {
				close()
			}}
			PaperComponent={ShakeablePaperMedium}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			PaperProps={{ controls }}
			aria-labelledby={namespace}
		>
			<DialogTitle id={namespace} className={'dragHandle'}>
				<Grid container direction='row' justifyContent='space-between' alignItems='center'>
					<Grid item xs>
						{`Upgrade Required`}
					</Grid>
					<Grid item>
						<IconButton
							data-test-id='PKlfKNx2fn0-KaP3uod4i'
							aria-label='close'
							onClick={close}
							sx={{
								color: theme.palette.text.secondary,
							}}
						>
							<Icon path={icons.closeIcon} size={1} />
						</IconButton>
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent>
				<Typography variant={'body1'} sx={{ mt: 1, mb: 1 }}>
					{`Great work, you've done so much testing this month you have used all your testing time.`}
				</Typography>

				{_account.outseta?.PrimaryContact.Uid === user?.Uid ? (
					<>
						<Typography
							variant={'body1'}
							sx={{ mb: 1 }}
						>{`Upgrade your plan to continue running tests.`}</Typography>
						<Button
							data-test-id='gKKiiXU02hQXyRDL-NId1'
							variant={'contained'}
							fullWidth
							size={'large'}
							onClick={() => {
								openUpgrade()
								_activity.trackPlanUpgradeViewed('no-minutes')
								gaEvent(gaEventName('upgrade', 'run', 'no-minutes'))
								close()
							}}
						>
							Upgrade
						</Button>
					</>
				) : (
					<>
						<Typography
							variant={'body1'}
							sx={{ mb: 1 }}
						>{`Please ask your account holder to upgrade your subscription.`}</Typography>
					</>
				)}
			</DialogContent>
		</Dialog>
	)
}
