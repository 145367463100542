export const TEST_RAPID_POLLING_MILLISECONDS = 5000 // 5 seconds
export const FLOW_RAPID_POLLING_MILLISECONDS = 5000 // 5 seconds
export const FLOW_POLLING_MILLISECONDS = 15000 // 15 seconds
export const SNAPPY_TRANSITION_SECONDS = 0.4 // 400 milliseconds
export const MINIMUM_SAVE_MILLISECONDS = 750 // 750 milliseconds
export const MINIMUM_LOAD_MILLISECONDS = 500 // 500 milliseconds
export const MAXIMUM_BUSY_TASK_LENGTH_SECONDS = 10 // 10 seconds
export const SOCKET_PING_INTERVAL_MILLISECONDS = 5 * 60 * 1000 // 5 minutes
export const MINIMUM_SOCKET_DEBOUNCE_GAP_MILLISECONDS = 5000 // 5 seconds
export const MAXIMUM_SOCKET_DEBOUNCE_GAP_MILLISECONDS = 10000 // 10 seconds
export const SOCKET_SMOOTHING_MAX_RANDOM_DELAY = 5000 // 5 seconds
export const MAXIMUM_CACHE_AGE_SECONDS = 60 * 5 // 5 minutes
export const FLOW_BUILDER_DEBOUNCE_MILLISECONDS = 500 // 500 milliseconds

export const STEP_TIMEOUT_SECONDS_DEFAULT = 60 * 2 // 2 minutes
export const STEP_TIMEOUT_SECONDS_MIN = 5 // 2 minutes
export const STEP_TIMEOUT_SECONDS_MAX = 60 * 5 // 5 minutes

export const SECOND_BREAKPOINT = 1000 // 1 Second
export const SECONDS_BREAKPOINT = SECOND_BREAKPOINT * 2 // 2 Seconds
export const MINUTE_BREAKPOINT = SECOND_BREAKPOINT * 60 // 1 Minute
export const MINUTES_BREAKPOINT = MINUTE_BREAKPOINT * 1.1 // 2 Minutes
export const HOUR_BREAKPOINT = MINUTE_BREAKPOINT * 60 // 1 Hour
export const HOURS_BREAKPOINT = HOUR_BREAKPOINT * 1.1 // 2 Hours
export const DAY_BREAKPOINT = HOUR_BREAKPOINT * 24 // 1 Day
export const DAYS_BREAKPOINT = DAY_BREAKPOINT * 1.1 // 2 Days
export const DATE_BREAKPOINT = DAY_BREAKPOINT * 6 // 6 Days

export const LARGE_FLOW_GRID_START_DATE = '2024-05-14T00:00:00.000Z'
