// ? ---
// ?	Imports
// ? ---
import * as React from 'react'

import { compact } from 'lodash'

import { formatValue, sentenceValue } from 'hooks/useNode'

import { INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.action_switch_window,
	isLive: true,
	isMenuOption: true,
	isPro: false,
	isAccessibility: false,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.value',
			type: 'text-with-tags',
			label: 'Domain / Title',
		},
	],
	label: 'Switch window',
	sentence: ({ isExample, value }: INodeRenderProps) => {
		return isExample ? `Switch window` : `Switch window to ${sentenceValue(value)}`
	},
	family: 'runner',
	type: INodeVariantTypes.action_switch_window.split('_')[1],
	subType: INodeVariantTypes.action_switch_window.split('_')[2],
	tags: compact([
		INodeVariantTypes.action_switch_window.split('_')[0],
		INodeVariantTypes.action_switch_window.split('_')[1],
		INodeVariantTypes.action_switch_window.split('_')[2],
		'popup',
		'pop-up',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ isExample, value }: INodeRenderProps) => {
			return (
				<>
					Switch window{' '}
					{!isExample && (
						<>
							to <strong>{formatValue(value)}</strong>
						</>
					)}
				</>
			)
		},
	}
}
