// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'

import Image from 'next/image'
import { Box, CircularProgress, Grid, useTheme } from '@mui/material'

import useThemeMode from 'hooks/useThemeMode'

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Loading'
const log = debug(`app:${namespace}`)

// ? ---
// ?	View
// ? ---
export default function View(): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const theme = useTheme()
	const _theme = useThemeMode()

	// * ---
	// *	Return: View
	// * ---
	return (
		<Grid
			container
			direction='column'
			justifyContent='space-between'
			alignItems='stretch'
			sx={{ minHeight: '100vh' }}
		>
			<Grid item></Grid>
			<Grid item>
				<Box sx={{ mb: 2, textAlign: 'center' }}>
					<Box sx={{ position: 'relative', mb: 1 }}>
						<CircularProgress
							size={150}
							thickness={2}
							sx={{ color: _theme.conditional(theme.palette.grey[400], theme.palette.grey[800]) }}
						/>
						<Box
							sx={{
								position: 'absolute',
								top: '50%',
								left: '50%',
								transform: 'translateX(-50%) translateY(-50%)',
							}}
						>
							<Image src='/dqa-logo-color.svg' alt={'Does.QA Logo'} height={80} width={80} />
						</Box>
					</Box>
				</Box>
			</Grid>
			<Grid item></Grid>
		</Grid>
	)
}
