// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import * as yup from 'yup'

import { compact } from 'lodash'

import { formatValue, sentenceValue } from 'hooks/useNode'

import { INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.runner_generate_image,
	isLive: true,
	isMenuOption: true,
	isPro: false,
	isAccessibility: false,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.value2',
			type: 'dropdown',
			label: 'Type',
			defaultValue: 'image/jpeg',
			options: [
				{ label: 'JPEG', value: 'image/jpeg' },
				{ label: 'PNG', value: 'image/png' },
			],
		},
		{
			property: 'attributes.value',
			type: 'text-with-tags',
			label: 'Filename',
			validation: yup.string().trim().required('Filename is required'),
			isFilename: true,
		},
		{
			property: 'attributes.value3',
			type: 'slider',
			label: 'Width',
			defaultValue: '600',
			config: {
				min: 1,
				max: 2000,
				step: 1,
			},
		},
		{
			property: 'attributes.value4',
			type: 'slider',
			label: 'Height',
			defaultValue: '400',
			config: {
				min: 1,
				max: 2000,
				step: 1,
			},
		},
	],
	label: 'Generate image',
	sentence: ({ isExample, value }: INodeRenderProps) => {
		return isExample ? `Generate image` : `Generate image ${sentenceValue(value)}`
	},
	family: INodeVariantTypes.runner_generate_image.split('_')[0],
	type: INodeVariantTypes.runner_generate_image.split('_')[1],
	subType: INodeVariantTypes.runner_generate_image.split('_')[2],
	tags: compact([
		INodeVariantTypes.runner_generate_image.split('_')[0],
		INodeVariantTypes.runner_generate_image.split('_')[1],
		INodeVariantTypes.runner_generate_image.split('_')[2],
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ isExample, value }: INodeRenderProps) => {
			return (
				<>
					{isExample ? (
						<>Generate image</>
					) : (
						<>
							Generate image <strong>{formatValue(value)}</strong>
						</>
					)}
				</>
			)
		},
	}
}
