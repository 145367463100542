// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'

import dynamic from 'next/dynamic'
import { Box, FormHelperText } from '@mui/material'
import { get } from 'lodash'

import { IDE_STYLE } from 'globals/client/styles'

import { IUseNodeField } from 'data/nodes'

// ? ---
// ?	Types
// ? ---
type Props = {
	formik: any
	field: IUseNodeField
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Dialogs-UpsertFields-Css'
const log = debug(`app:${namespace}`)

const IDE = dynamic(() => import('./Partials/CssIDE').then((mod) => mod.default), { ssr: false })

// ? ---
// ?	Component
// ? ---
export default function Css({ field, formik }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')

	// * ---
	// *	Callback: Helper Text
	// * ---
	const helperText = React.useCallback(() => {
		// ? Touched & Error
		if (get(formik, `touched.${field.property}`) && get(formik, `errors.${field.property}`)) {
			return {
				active: true,
				isError: true,
				message: get(formik, `errors.${field.property}`),
			}
		}
		// ? Helper Text
		if (get(field, `helper`)) {
			return {
				active: true,
				isError: false,
				message: field.helper,
			}
		}
		return {
			active: false,
			isError: false,
			message: field.helper,
		}
	}, [formik, field])

	// * ---
	// *	Return
	// * ---
	return (
		<Box sx={IDE_STYLE}>
			<fieldset>
				<legend>{field.label}</legend>
				<Box sx={{ pt: '15px', pb: '17px', pl: 0.5, pr: 0.5 }}>
					<IDE formik={formik} field={field} />
				</Box>
			</fieldset>

			{helperText().active && (
				<FormHelperText error={helperText().isError}>{helperText().message}</FormHelperText>
			)}
		</Box>
	)
}
