// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import Color from 'color'
import { AppDataContext, IAppDataContext } from 'context/appData'
import debug from 'debug'

import Image from 'next/image'
import { Box, CircularProgress, Grid, Typography, useTheme } from '@mui/material'
import { find } from 'lodash'

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-LoadingOverlay'
const log = debug(`app:${namespace}`)

// ? ---
// ?	View
// ? ---
export default function LoadingOverlay(): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const AppData = React.useContext<IAppDataContext>(AppDataContext)
	const theme = useTheme()

	// * ---
	// *	Callback: Status
	// * ---
	const getTaskName = React.useCallback(() => {
		const blockingTask = find(AppData.busy.tasks, { isBlocking: true })
		return blockingTask?.label
	}, [AppData.busy.tasks])

	// * ---
	// *	Return: View
	// * ---
	return (
		<Box
			sx={{
				position: 'fixed',
				top: 0,
				bottom: 0,
				left: 0,
				right: 0,
				zIndex: 999999,
				pointerEvents: 'all',
				backgroundColor: Color(theme.palette.primary.main).alpha(0.75).string(),
			}}
		>
			<Grid
				container
				direction='column'
				justifyContent='space-between'
				alignItems='stretch'
				sx={{ minHeight: '100vh' }}
			>
				<Grid item></Grid>
				<Grid item>
					<Box sx={{ mb: 2, textAlign: 'center' }}>
						<Box sx={{ position: 'relative', mb: 1 }}>
							<CircularProgress size={150} thickness={2} sx={{ color: theme.palette.grey[400] }} />
							<Box
								sx={{
									position: 'absolute',
									top: '50%',
									left: '50%',
									transform: 'translateX(-50%) translateY(-50%)',
								}}
							>
								<Image src='/dqa-logo.svg' alt={'Does.QA Logo'} height={80} width={80} />
							</Box>
						</Box>
						{getTaskName() && (
							<Typography variant='h4' sx={{ color: 'white', mt: 1 }}>
								{getTaskName()}
							</Typography>
						)}
					</Box>
				</Grid>
				<Grid item></Grid>
			</Grid>
		</Box>
	)
}
