import * as yup from 'yup'

import { values } from 'lodash'

import { IElementSelectorType } from './type'

export const validationSchema = yup.object({
	attributes: yup.object({
		title: yup.string().trim().required('enter a name for this element'),
		type: yup.string().oneOf(values(IElementSelectorType)).required('select a type'),
		selector: yup.string().trim().required('enter a selector for this type '),
	}),
})
