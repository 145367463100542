// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'
import { useFormik } from 'formik'
import { useAnimation } from 'framer-motion'

import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Fab,
	Grid,
	IconButton,
	Stack,
	useTheme,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import Icon from '@mdi/react'
import { compact, isEmpty, map, omit, uniq, uniqBy, upperFirst } from 'lodash'

import { icons } from 'globals/constants/icons'
import { DIALOG_MEDIUM_WIDTH } from 'globals/constants/sizes'

import useAccount from 'hooks/useAccount'
import useRecipes from 'hooks/useRecipes'

import ShakeablePaperMedium from 'components/_Global/Dialogs/helpers/ShakeablePaperMedium'
import { Transition } from 'components/_Global/Dialogs/helpers/Transition'
import Field from 'components/_Global/Forms/Field'
import { IRecipe, validationSchema } from 'data/recipes'

// ? ---
// ?	Types
// ? ---
type Props = {
	state: boolean
	data: undefined | Partial<IRecipe>
	options?: {
		[key: string]: any
	}
	close: () => void
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Recipes-RecipeDialog'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function RecipeDialog({ close, data, options, state }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const theme = useTheme()
	const _account = useAccount()
	const _recipes = useRecipes()
	const controls = useAnimation()

	// * ---
	// *	State
	// * ---
	const [saving, $saving] = React.useState(false)

	// * ---
	// *	Formik
	// * ---
	const formik = useFormik({
		validationSchema,
		initialValues: {
			...data,
			attributes: {
				...omit(data?.attributes, ['updatedAt']),
				title: data?.attributes?.title || '',
				withAll: compact(uniq((data?.attributes?.withAll || '').split('|'))),
				withoutAny: compact(uniq((data?.attributes?.withoutAny || '').split('|'))),
				value: data?.attributes?.value || '',
			},
		},
		onSubmit: async (values) => {
			$saving(true)
			log('onSubmit', values)
			await _recipes.upsert(
				{
					...values,
					attributes: {
						...omit(values.attributes, []),
						withAll: `|${values.attributes.withAll.join('|')}|`,
						withoutAny: `|${values.attributes.withoutAny.join('|')}|`,
					},
				},
				{
					onSuccess: (schedule) => {
						log('onSubmit onSuccess', { schedule, options })
						close()
						$saving(false)
						if (options?.onSuccess) options.onSuccess(schedule)
						if (options?.onComplete) options.onComplete(schedule)
					},
				}
			)
		},
	})

	// * ---
	// *	Return: Good
	// * ---
	return (
		<Dialog
			open={state}
			TransitionComponent={Transition}
			sx={{
				'& .MuiDialog-paperFullWidth': {
					maxWidth: DIALOG_MEDIUM_WIDTH,
				},
			}}
			fullWidth={true}
			onClose={() => (formik.dirty ? controls.start('start') : close())}
			PaperComponent={ShakeablePaperMedium}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			PaperProps={{ controls }}
			aria-labelledby={namespace}
		>
			<form onSubmit={formik.handleSubmit}>
				<DialogTitle id={namespace} className={'dragHandle'}>
					{isEmpty(data?.id) ? 'Create Recipe' : 'Update Recipe'}
				</DialogTitle>
				<DialogContent>
					<Stack sx={{ pt: 2, maxHeight: '78vh' }}>
						<Stack direction={'row'} spacing={1}>
							<Box sx={{ width: '100%' }}>
								<Field
									field={{
										property: 'attributes.title',
										type: 'text',
										label: 'Title',
									}}
									formik={formik}
								/>
							</Box>
						</Stack>
						<Stack direction={'row'} spacing={1}>
							<Box sx={{ width: '50%' }}>
								<Field
									field={{
										property: 'attributes.withAll',
										type: 'multiSelect',
										label: 'Include Tags',
										options: uniqBy(
											[
												..._account.tags(),
												...map(formik.values.attributes.withAll, (tag) => {
													return { label: upperFirst(tag), value: tag }
												}),
											],
											'value'
										),
										helper: 'Flow must have all selected tags',
									}}
									formik={formik}
								/>
							</Box>
							<Box sx={{ width: '50%' }}>
								<Field
									field={{
										property: 'attributes.withoutAny',
										type: 'multiSelect',
										label: `Exclude Tags`,
										options: uniqBy(
											[
												..._account.tags(),
												...map(formik.values.attributes.withoutAny, (tag) => {
													return { label: upperFirst(tag), value: tag }
												}),
											],
											'value'
										),
										helper: `Flow cannot have any selected tags`,
									}}
									formik={formik}
								/>
							</Box>
						</Stack>
						<Field
							field={{
								property: 'attributes.value',
								type: 'json',
								label: 'Value Overwrites',
							}}
							formik={formik}
						/>
					</Stack>
				</DialogContent>
				<DialogActions>
					<Grid
						container
						direction='row'
						justifyContent='space-between'
						alignItems='center'
						spacing={1}
						sx={{ pl: 1, pr: 1 }}
					>
						<Grid item>
							{!isEmpty(data?.id) && (
								<IconButton
									data-test-id='xBPMetwDL6aUHKDA6-Qua'
									title={'Delete Recipe'}
									sx={{ color: theme.palette.text.secondary }}
									onClick={() =>
										_recipes.remove(data as IRecipe, {
											onSuccess: () => {
												close()
												if (options?.onDelete) {
													setTimeout(() => options.onDelete(), 0)
												}
											},
										})
									}
								>
									<Icon path={icons.removeIcon} size={1} />
								</IconButton>
							)}
						</Grid>
						<Grid item>
							<Button
								data-test-id='YeYDJCJCGbTIjHbqm9_sC'
								color={'secondary'}
								onClick={() => {
									close()
								}}
							>
								Cancel
							</Button>
						</Grid>
					</Grid>
					<Fab
						color='primary'
						aria-label='Save Recipe'
						type='submit'
						disabled={saving}
						sx={{
							backgroundColor: saving || !formik.isValid ? grey[400] : undefined,
						}}
					>
						<Icon path={saving ? icons.loadingIcon : icons.agreeIcon} size={1} spin={saving ? 1 : 0} />
					</Fab>
				</DialogActions>
			</form>
		</Dialog>
	)
}
