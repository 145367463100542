// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import MixedTags from '@yaireo/tagify/dist/react.tagify'
import debug from 'debug'
import { useDebouncedCallback } from 'use-debounce'

import '@yaireo/tagify/dist/tagify.css'

import { Box, Button, FormControl, FormHelperText, useTheme } from '@mui/material'
import { get, map } from 'lodash'

import { TAGIFY_STYLE } from 'globals/client/styles'
import { KEYS } from 'globals/constants/keys'

import useThemeMode from 'hooks/useThemeMode'

import { IUseNodeField } from 'data/nodes'

// ? ---
// ?	Types
// ? ---
type Props = {
	formik: any
	field: IUseNodeField
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Dialogs-UpsertFields-TextWithKeys'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function TextWithKeys({ field, formik }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const theme = useTheme()
	const _theme = useThemeMode()
	const tagRef = React.useRef<Tagify<{ value: string; label?: string | undefined }> | undefined>()

	// * ---
	// *	Debounced: On Change
	// * ---
	const onChange = useDebouncedCallback(
		(event) => {
			log('onChange', formik, field.property, event)
			formik.setFieldValue(field.property, event.detail.value)
		},
		100,
		{ leading: false, trailing: true }
	)

	// * ---
	// *	Return
	// * ---
	return (
		<FormControl variant='outlined' fullWidth>
			<Box sx={TAGIFY_STYLE}>
				<fieldset>
					<legend>{field.label}</legend>
					{/* @ts-expect-error Server Component */}
					<MixedTags
						data-test-id={`${namespace}--${field.property}`}
						name={field.property}
						tagifyRef={tagRef}
						settings={{
							mode: 'mix',
							pattern: /\$/,
							whitelist: KEYS,
							onChangeAfterBlur: false,
							duplicates: true,
							mixTagsInterpolator: ['-{{-', '-}}-'],
							mixMode: {
								insertAfterTag: '',
							},
						}}
						onChange={onChange}
						defaultValue={get(formik?.values, field.property)}
					/>
				</fieldset>
				{get(formik, `touched[${field.property}]`) && Boolean(get(formik, `errors[${field.property}]`)) && (
					<FormHelperText error={true}>
						{get(formik, `touched[${field.property}]`) && get(formik, `errors[${field.property}]`)}
					</FormHelperText>
				)}
				<Box>
					{map(KEYS, (key) => (
						<Button
							data-test-id='WVXUrmFKvOS3jddt1lBAr'
							key={key.value}
							onClick={() => {
								tagRef.current && tagRef.current?.addMixTags(key.value)
							}}
							sx={{
								backgroundColor: _theme.conditional(theme.palette.grey[500], theme.palette.grey[700]),
								color: theme.palette.text.primary,
								textTransform: 'none',
								fontWeight: 500,
								mt: 0.5,
								mr: 0.5,
							}}
						>
							{key.label}
						</Button>
					))}
				</Box>
			</Box>
		</FormControl>
	)
}
