// ? ---
// ?	Imports
// ? ---
import axios from 'axios'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import debug from 'debug'

import { values } from 'lodash'

import { IUser } from 'data/users'

// ? ---
// ?	Constants
// ? ---
const namespace = 'hooks-useUsers'
const log = debug(`app:${namespace}`)

dayjs.extend(utc)

// ? ---
// ?	Hook
// ? ---
export default function useUsers() {
	// * ---
	// *	Context
	// * ---

	// * ---
	// *	Get All
	// * ---
	const getAll = async (): Promise<IUser[]> => {
		log('getAll')
		return await axios
			.get(`/api/accounts/users`)
			.then((response) => {
				log('getAll response.data', response.data)
				return values(response.data)
			})
			.catch((err) => {
				log('!! getAll failed', err)
				return []
			})
	}

	// * ---
	// *	Get Count
	// * ---
	const getCount = async (): Promise<number> => {
		log('getCount')
		const users = await getAll()
		return users.length
	}

	// * ---
	// *	Return
	// * ---
	return {
		getAll,
		getCount,
	}
}
