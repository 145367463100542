// ? ---
// ?	Imports
// ? ---
import * as React from 'react'

import { compact } from 'lodash'

import { INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.mail_inbox_close,
	isLive: true,
	isMenuOption: true,
	isPro: false,
	isAccessibility: false,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'message-0',
			label: '',
			type: 'message',
			children: <>Closes all open inboxes</>,
		},
	],
	label: 'Close inbox',
	sentence: () => {
		return `Close inbox`
	},
	family: INodeVariantTypes.mail_inbox_close.split('_')[0],
	type: INodeVariantTypes.mail_inbox_close.split('_')[1],
	subType: INodeVariantTypes.mail_inbox_close.split('_')[2],
	tags: compact([
		INodeVariantTypes.mail_inbox_close.split('_')[0],
		INodeVariantTypes.mail_inbox_close.split('_')[1],
		INodeVariantTypes.mail_inbox_close.split('_')[2],
		'email',
		'messages',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: () => {
			return <>Close inbox</>
		},
	}
}
