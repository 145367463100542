// ? ---
// ?	Imports
// ? ---
import { INodeComparisonTypes, INodeConfig, INodeVariantTypes } from 'data/nodes'
import { ITestArtifact, ITestResponseObject } from 'data/tests'

// ? ---
// ?	Types & Enums
// ? ---
export enum ITestStepStatus {
	pending = 'pending',
	running = 'running',
	ignored = 'ignored',
	skipped = 'skipped',
	passed = 'passed',
	passed_with_warning = 'passed_with_warning',
	failed = 'failed',
	stopped = 'stopped',
	continued = 'continued',
}

export type ITestStep = {
	id?: string
	status: ITestStepStatus
	startedAt?: string
	endedAt?: string
	index: number
	hash: string
	url?: string
	artifacts: ITestArtifact[]
	response: ITestResponseObject[]
	isChild?: boolean
	node: {
		// * Node Props
		nodeId: number
		nodeInstanceId: string
		label: string
		variantType: INodeVariantTypes
		comparisonType?: INodeComparisonTypes
		value?: string
		value2?: string
		value3?: string
		value4?: string
		value5?: string
		value6?: string
		value7?: string
		value8?: string
		value9?: string
		value10?: string
		value11?: string
		value12?: string
		value13?: string
		value14?: string
		value15?: string
		value16?: string
		value17?: string
		value18?: string
		value19?: string
		value20?: string

		// * Element Props
		elementId?: number
		elementTitle?: string
		elementType?: string
		elementSelector?: string

		// * Element2 Props
		element2Id?: number
		element2Title?: string
		element2Type?: string
		element2Selector?: string

		// * MFA Props
		mfaId?: number
		mfaTitle?: string

		// * Config
		config?: INodeConfig
	}
}
