// ? ---
// ?	Imports
// ? ---
import * as React from 'react'

import { compact } from 'lodash'

import { ELEMENT_VALIDATION } from 'globals/constants/validations'
import { INTEGRATION_GOOGLE_MAPS_GROUP } from 'globals/constants/variantGroups'

import { formatElement, formatValue, sentenceElement, sentenceValue } from 'hooks/useNode'

import { INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.integration_googleMaps_zoom,
	isLive: false,
	isMenuOption: false,
	isPro: false,
	isAccessibility: false,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.variantType',
			type: 'variantType',
			label: 'Method',
			options: INTEGRATION_GOOGLE_MAPS_GROUP,
		},
		{
			property: 'attributes.element',
			type: 'element',
			label: 'Element',
			validation: ELEMENT_VALIDATION,
		},
		{
			property: 'attributes.value3',
			label: 'Zoom In / Out',
			type: 'slider',
			defaultValue: '0',
			config: {
				min: -22,
				max: 22,
				step: 1,
			},
		},
	],
	label: 'Zoom',
	sentence: ({ element, isExample, value3 }: INodeRenderProps) => {
		return isExample
			? `Zoom map`
			: [
					`Zoom map ${sentenceElement(element)}`,
					parseInt(value3 || '0') > 0 ? 'in by' : parseInt(value3 || '0') < 0 ? 'out by' : 'by',
					sentenceValue(value3),
			  ].join(' ')
	},
	family: INodeVariantTypes.integration_googleMaps_zoom.split('_')[0],
	type: INodeVariantTypes.integration_googleMaps_zoom.split('_')[1],
	subType: INodeVariantTypes.integration_googleMaps_zoom.split('_')[2],
	tags: compact([
		INodeVariantTypes.integration_googleMaps_zoom.split('_')[0],
		INodeVariantTypes.integration_googleMaps_zoom.split('_')[1],
		INodeVariantTypes.integration_googleMaps_zoom.split('_')[2],
		'maps',
		'google',
		'zoom',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ element, isExample, value3 }: INodeRenderProps) => {
			return (
				<>
					{isExample ? (
						<>Zoom</>
					) : (
						<>
							Zoom <strong>{formatElement(element)}</strong>
							{` `}
							{parseInt(value3 || '0') > 0 ? 'in by' : parseInt(value3 || '0') < 0 ? 'out by' : 'by'}{' '}
							<strong>{formatValue(`${Math.abs(parseInt(`${value3}`))}`)}</strong>
						</>
					)}
				</>
			)
		},
	}
}
