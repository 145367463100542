// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'

import dynamic from 'next/dynamic'
import { Box, FormControl, FormHelperText, Typography, useTheme } from '@mui/material'
import { get } from 'lodash'

import { IUseNodeField } from 'data/nodes'

// ? ---
// ?	Types
// ? ---
type Props = {
	formik: any
	field: IUseNodeField
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Dialogs-UpsertFields-Json'
const log = debug(`app:${namespace}`)

const IDE = dynamic(() => import('./Partials/JsonIDE').then((mod) => mod.default), { ssr: false })

// ? ---
// ?	Component
// ? ---
export default function Json({ field, formik }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const theme = useTheme()

	// * ---
	// *	Callback: Helper Text
	// * ---
	const helperText = React.useCallback(() => {
		// ? Touched & Error
		if (get(formik, `touched.${field.property}`) && get(formik, `errors.${field.property}`)) {
			return {
				active: true,
				isError: true,
				message: get(formik, `errors.${field.property}`),
			}
		}
		// ? Helper Text
		if (get(field, `helper`)) {
			return {
				active: true,
				isError: false,
				message: field.helper,
			}
		}
		return {
			active: false,
			isError: false,
			message: field.helper,
		}
	}, [formik, field])

	// * ---
	// *	Return
	// * ---
	return (
		<FormControl variant='outlined' fullWidth>
			<Box
				sx={{
					'& .ace_hidden-cursors .ace_cursor': {
						display: 'none !important',
					},
				}}
			>
				<Typography
					component='legend'
					variant='caption'
					sx={{
						ml: 1,
						mr: 1,
						mb: 1,
						color: theme.palette.text.secondary,
						lineHeight: 0,
						fontWeight: 500,
					}}
				>
					{field.label}
				</Typography>
				<IDE formik={formik} field={field} />

				{helperText().active && (
					<FormHelperText error={helperText().isError}>{helperText().message}</FormHelperText>
				)}
			</Box>
		</FormControl>
	)
}
