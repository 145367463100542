// ? ---
// ?	Imports
// ? ---
import * as React from 'react'

import { compact } from 'lodash'

import { FAILED_STATUS_OPTIONS } from 'globals/constants/selectOptions'
import { ELEMENT_VALIDATION } from 'globals/constants/validations'
import { CHECK_IS_GROUP } from 'globals/constants/variantGroups'

import { formatElement, sentenceElement } from 'hooks/useNode'

import { INodeRenderProps, INodeVariantTypes, IUseNode, IUseNodeConfig } from 'data/nodes'
import { ITestStepStatus } from 'data/tests'

// ? ---
// ?	Export Node
// ? ---
export const node: IUseNodeConfig = {
	key: INodeVariantTypes.check_is_notVisible,
	isLive: true,
	isMenuOption: false,
	isPro: false,
	isAccessibility: false,
	hasInput: true,
	hasOutput: true,
	fields: [
		{
			property: 'attributes.variantType',
			type: 'variantType',
			label: 'Type',
			options: CHECK_IS_GROUP,
		},
		{
			property: 'attributes.element',
			type: 'element',
			label: 'Element',
			validation: ELEMENT_VALIDATION,
		},
		{
			property: 'attributes.config.failedStatus',
			type: 'button-group',
			label: 'Status on fail',
			defaultValue: ITestStepStatus.failed,
			options: FAILED_STATUS_OPTIONS,
		},
	],
	label: 'Check not visible',
	sentence: ({ element, isExample }: INodeRenderProps) => {
		return isExample ? `Check not visible` : `Check ${sentenceElement(element)} is not visible`
	},
	family: INodeVariantTypes.check_is_notVisible.split('_')[0],
	type: INodeVariantTypes.check_is_notVisible.split('_')[1],
	subType: INodeVariantTypes.check_is_notVisible.split('_')[2],
	tags: compact([
		INodeVariantTypes.check_is_notVisible.split('_')[0],
		INodeVariantTypes.check_is_notVisible.split('_')[1],
		INodeVariantTypes.check_is_notVisible.split('_')[2],
		'test',
		'expect',
		'assert',
		'element',
		'not',
		'visible',
		'hidden',
		'state',
	]),
}

// ? ---
// ?	Export useNode
// ? ---
export function useNode(): IUseNode {
	return {
		...node,
		render: ({ element, isExample }: INodeRenderProps) => {
			return (
				<>
					Check{' '}
					{!isExample && (
						<>
							<strong>{formatElement(element)}</strong> is
						</>
					)}{' '}
					not visible
				</>
			)
		},
	}
}
