// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'

import { FormControl, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material'
import { find, get, map } from 'lodash'

import useNode from 'hooks/useNode'

import { IUseNodeField } from 'data/nodes'

// ? ---
// ?	Types
// ? ---
type Props = {
	formik: any
	field: IUseNodeField
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Dialogs-UpsertFields-variantTypeButtonGroup'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function VariantTypeButtonGroup({ field, formik }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const theme = useTheme()
	const _node = useNode()

	// * ---
	// *	Method: Get Options
	// * ---
	const getOptions = React.useCallback(() => {
		if (field.options) {
			return map(field.options, (option) => {
				return {
					label: _node[option].optionLabel || _node[option].label,
					value: option,
				}
			})
		} else {
			return []
		}
	}, [field, _node])

	// * ---
	// *	Method: Get Current Values
	// * ---
	const getCurrentValue = React.useCallback(() => {
		const value = get(formik?.values, field.property)
		const options = getOptions()
		if (find(options, { value })) {
			return value
		} else {
			const temp = get(options, '[0].value', '')
			if (get(formik?.values, field.property) !== temp) {
				formik.setFieldValue(field.property, temp)
			}
			return temp
		}
	}, [field, formik, getOptions])

	// * ---
	// *	Return
	// * ---
	return (
		<FormControl variant='outlined' fullWidth>
			<Typography
				component='legend'
				variant='caption'
				sx={{ ml: 1, mr: 1, mb: 0.5, color: theme.palette.text.secondary }}
			>
				{field.label}
			</Typography>
			<ToggleButtonGroup
				data-test-id={`${namespace}--${field.property}`}
				id={field.property}
				value={getCurrentValue()}
				onChange={(event, newValue) => formik.setFieldValue(field.property, newValue)}
				aria-label={field.label}
				exclusive
				fullWidth
				sx={{ pl: 0, pr: 0 }}
			>
				{map(getOptions(), (option) => (
					<ToggleButton
						data-test-id={`${namespace}--${option.value}`}
						key={option.value}
						value={option.value}
						aria-label={option.label}
						sx={{ fontSize: theme.typography.body2.fontSize }}
					>
						{option.label}
					</ToggleButton>
				))}
			</ToggleButtonGroup>
		</FormControl>
	)
}
