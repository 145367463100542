// ? ---
// ?	Imports
// ? ---
import * as yup from 'yup'

import { filter, forEach, set } from 'lodash'

import { INodeVariantTypes, IUseNode } from './type'

// ? ---
// ?	Constants
// ? ---
// const namespace = 'data-nodes-validation'
// const log = debug(`app:${namespace}`)

// ? ---
// ?	Export
// ? ---
export const validationSchema = (currentNode: IUseNode) => {
	const fieldValidation: { [key: string]: any } = {}
	forEach(filter(currentNode.fields, 'validation'), (field: any) => {
		set(fieldValidation, field.property, field.validation)
	})

	if (currentNode.key === INodeVariantTypes.special_group) {
		set(fieldValidation, 'attributes.label', yup.string().trim().required('name is required'))
	}

	// log({ fieldValidation, currentNode })
	return yup.object({
		...fieldValidation,
		attributes: yup.object({
			...fieldValidation.attributes,
		}),
	})
}
